@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap);
/* You can add global styles to this file, and also import other style files */
/* === Plugin styles === */

@-webkit-keyframes flash {
  0% {
    opacity: 1; }
  25% {
    opacity: 0; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes flash {
  0% {
    opacity: 1; }
  25% {
    opacity: 0; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px); }
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px); }
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  10% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  20% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  30% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  40% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  90% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes shake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  10% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  20% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  30% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  40% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  90% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  10% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  30% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  40% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  50% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  60% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  70% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0); } }

@keyframes tada {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  10% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  30% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  40% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  50% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  60% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  70% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0); } }

@-webkit-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center 50%;
    transform-origin: top center 50%; }
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }

@keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center 50%;
    transform-origin: top center 50%; }
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); } }

@keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); } }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  50% {
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1); }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  50% {
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1); }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
    transform: skewX(9deg); }
  10% {
    -webkit-transform: skewX(-8deg);
    transform: skewX(-8deg); }
  20% {
    -webkit-transform: skewX(7deg);
    transform: skewX(7deg); }
  30% {
    -webkit-transform: skewX(-6deg);
    transform: skewX(-6deg); }
  40% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg); }
  50% {
    -webkit-transform: skewX(-4deg);
    transform: skewX(-4deg); }
  60% {
    -webkit-transform: skewX(3deg);
    transform: skewX(3deg); }
  70% {
    -webkit-transform: skewX(-2deg);
    transform: skewX(-2deg); }
  80% {
    -webkit-transform: skewX(1deg);
    transform: skewX(1deg); }
  90% {
    -webkit-transform: skewX(0deg);
    transform: skewX(0deg); }
  100% {
    -webkit-transform: skewX(0deg);
    transform: skewX(0deg); } }

@keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
    transform: skewX(9deg); }
  10% {
    -webkit-transform: skewX(-8deg);
    transform: skewX(-8deg); }
  20% {
    -webkit-transform: skewX(7deg);
    transform: skewX(7deg); }
  30% {
    -webkit-transform: skewX(-6deg);
    transform: skewX(-6deg); }
  40% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg); }
  50% {
    -webkit-transform: skewX(-4deg);
    transform: skewX(-4deg); }
  60% {
    -webkit-transform: skewX(3deg);
    transform: skewX(3deg); }
  70% {
    -webkit-transform: skewX(-2deg);
    transform: skewX(-2deg); }
  80% {
    -webkit-transform: skewX(1deg);
    transform: skewX(1deg); }
  90% {
    -webkit-transform: skewX(0deg);
    transform: skewX(0deg); }
  100% {
    -webkit-transform: skewX(0deg);
    transform: skewX(0deg); } }

@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  25% {
    -webkit-transform: scale(0.95, 0.95);
    transform: scale(0.95, 0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3); } }

@keyframes bounceOut {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  25% {
    -webkit-transform: scale(0.95, 0.95);
    transform: scale(0.95, 0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3); } }

@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05); }
  70% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9); }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05); }
  70% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9); }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    transform: translateY(30px); }
  80% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    transform: translateY(30px); }
  80% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fadeOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0; } }

@keyframes fadeOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0; } }

@-webkit-keyframes fadeOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0; } }

@keyframes fadeOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0; } }

@-webkit-keyframes fadeOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0; } }

@keyframes fadeOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0; } }

@-webkit-keyframes fadeOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0; } }

@keyframes fadeOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0; } }

@-webkit-keyframes fadeOutUpBig {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px);
    opacity: 0; } }

@keyframes fadeOutUpBig {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px);
    opacity: 0; } }

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@-webkit-keyframes fadeInLeft {
  0% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes fadeInLeft {
  0% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@-webkit-keyframes fadeInUpBig {
  0% {
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fadeInUpBig {
  0% {
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; } }

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; } }

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; } }

@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; } }

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; } }

@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; } }

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; } }

@keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; } }

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; } }

@keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; } }

@-webkit-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; } }

@keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; } }

@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0; } }

@keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0; } }

@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; } }

@keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; } }

@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; } }

@keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; } }

@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; } }

@keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; } }

@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; } }

@keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; } }

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@-webkit-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  100% {
    -webkit-transform: translateY(700px);
    transform: translateY(700px);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; } }

@keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  100% {
    -webkit-transform: translateY(700px);
    transform: translateY(700px);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; } }

@-webkit-keyframes rollIn {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); } }

@keyframes rollIn {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); } }

@-webkit-keyframes rollOut {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); } }

@keyframes rollOut {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); } }

/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #5E50F9;
  --indigo: #6610f2;
  --purple: #6a008a;
  --pink: #E91E63;
  --red: #f96868;
  --orange: #f2a654;
  --yellow: #f6e84e;
  --green: #46c35f;
  --teal: #58d8a3;
  --cyan: #57c7d4;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #0f1531;
  --blue: #5E50F9;
  --indigo: #6610f2;
  --purple: #6a008a;
  --pink: #E91E63;
  --red: #f96868;
  --orange: #f2a654;
  --yellow: #f6e84e;
  --green: #46c35f;
  --teal: #58d8a3;
  --cyan: #57c7d4;
  --white: #ffffff;
  --gray: #434a54;
  --gray-light: #aab2bd;
  --gray-lighter: #e8eff4;
  --gray-lightest: #e6e9ed;
  --black: #000000;
  --primary: #0062ff;
  --secondary: #8e94a9;
  --success: #44ce42;
  --info: #a461d8;
  --warning: #ffc542;
  --danger: #fc5a5a;
  --light: #aab2bd;
  --dark: #001737;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #a7afb7;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875em;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 9px;
  padding-left: 9px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row, .pills-vertical {
  display: flex;
  flex-wrap: wrap;
  margin-right: -9px;
  margin-left: -9px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .tab-vertical-custom .nav-tabs, .col-4, .pills-vertical .nav-pills, .col-5, .col-6, .col-7, .col-8, .pills-vertical .tab-content, .col-9, .tab-vertical-custom.tab-content, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 9px;
  padding-left: 9px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3, .tab-vertical-custom .nav-tabs {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4, .pills-vertical .nav-pills {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8, .pills-vertical .tab-content {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9, .tab-vertical-custom.tab-content {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.9375rem;
    vertical-align: top;
    border-top: 1px solid rgba(151, 151, 151, 0.3); }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid rgba(151, 151, 151, 0.3); }
  .table tbody + tbody {
    border-top: 2px solid rgba(151, 151, 151, 0.3); }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid rgba(151, 151, 151, 0.3); }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid rgba(151, 151, 151, 0.3); }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f0f1f6; }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: #f0f1f6; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8d3ff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7aadff; }

.table-hover .table-primary:hover {
  background-color: #9fc3ff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fc3ff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dfe1e7; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #c4c7d2; }

.table-hover .table-secondary:hover {
  background-color: #d0d3dc; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d0d3dc; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cbf1ca; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #9ee69d; }

.table-hover .table-success:hover {
  background-color: #b7ecb6; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b7ecb6; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #e6d3f4; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #d0adeb; }

.table-hover .table-info:hover {
  background-color: #dabfef; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #dabfef; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffefca; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffe19d; }

.table-hover .table-warning:hover {
  background-color: #ffe7b1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe7b1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fed1d1; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fda9a9; }

.table-hover .table-danger:hover {
  background-color: #fdb8b8; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fdb8b8; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #e7e9ed; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #d3d7dd; }

.table-hover .table-light:hover {
  background-color: #d8dce2; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #d8dce2; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #b8bec7; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #7a8697; }

.table-hover .table-dark:hover {
  background-color: #aab1bc; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #aab1bc; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: rgba(151, 151, 151, 0.3); }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: 2.875rem;
  padding: 0.875rem 1.375rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #c9c8c8;
    opacity: 1; }
  .form-control::placeholder {
    color: #c9c8c8;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
          appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.875rem + 1px);
  padding-bottom: calc(0.875rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1; }

.col-form-label-lg {
  padding-top: calc(0.94rem + 1px);
  padding-bottom: calc(0.94rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.875rem 0;
  margin-bottom: 0;
  font-size: 0.8125rem;
  line-height: 1;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: 2.575rem;
  padding: 0.5rem 0.81rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: 3.175rem;
  padding: 0.94rem 1.94rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #a7afb7; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 5.5rem !important;
  background-position: right 2.75rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 5.5rem !important;
  background-position: right 2.75rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #a7afb7;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.775rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #a7afb7;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary, .custom-file .custom-file-label:after {
  color: #fff;
  background-color: #0062ff;
  border-color: #0062ff; }
  .btn-primary:hover, .custom-file .custom-file-label:hover:after {
    color: #fff;
    background-color: #0053d9;
    border-color: #004ecc; }
  .btn-primary:focus, .custom-file .custom-file-label:focus:after, .btn-primary.focus, .custom-file .focus.custom-file-label:after {
    color: #fff;
    background-color: #0053d9;
    border-color: #004ecc;
    box-shadow: 0 0 0 0.2rem rgba(38, 122, 255, 0.5); }
  .btn-primary.disabled, .custom-file .disabled.custom-file-label:after, .btn-primary:disabled, .custom-file .custom-file-label:disabled:after {
    color: #fff;
    background-color: #0062ff;
    border-color: #0062ff; }
  .btn-primary:not(:disabled):not(.disabled):active, .custom-file .custom-file-label:not(:disabled):not(.disabled):active:after, .btn-primary:not(:disabled):not(.disabled).active, .custom-file .custom-file-label:not(:disabled):not(.disabled).active:after,
  .show > .btn-primary.dropdown-toggle,
  .custom-file .show > .dropdown-toggle.custom-file-label:after {
    color: #fff;
    background-color: #004ecc;
    border-color: #004abf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .custom-file .custom-file-label:not(:disabled):not(.disabled):active:focus:after, .btn-primary:not(:disabled):not(.disabled).active:focus, .custom-file .custom-file-label:not(:disabled):not(.disabled).active:focus:after,
    .show > .btn-primary.dropdown-toggle:focus,
    .custom-file .show > .dropdown-toggle.custom-file-label:focus:after {
      box-shadow: 0 0 0 0.2rem rgba(38, 122, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #8e94a9;
  border-color: #8e94a9; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #787f98;
    border-color: #717993; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #787f98;
    border-color: #717993;
    box-shadow: 0 0 0 0.2rem rgba(159, 164, 182, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #8e94a9;
    border-color: #8e94a9; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #717993;
    border-color: #6b728c; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(159, 164, 182, 0.5); }

.btn-success {
  color: #fff;
  background-color: #44ce42;
  border-color: #44ce42; }
  .btn-success:hover {
    color: #fff;
    background-color: #32ba30;
    border-color: #2fb02e; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #32ba30;
    border-color: #2fb02e;
    box-shadow: 0 0 0 0.2rem rgba(96, 213, 94, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #44ce42;
    border-color: #44ce42; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #2fb02e;
    border-color: #2da52b; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(96, 213, 94, 0.5); }

.btn-info {
  color: #fff;
  background-color: #a461d8;
  border-color: #a461d8; }
  .btn-info:hover {
    color: #fff;
    background-color: #9242d0;
    border-color: #8c38ce; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #9242d0;
    border-color: #8c38ce;
    box-shadow: 0 0 0 0.2rem rgba(178, 121, 222, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #a461d8;
    border-color: #a461d8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #8c38ce;
    border-color: #8631c8; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(178, 121, 222, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc542;
  border-color: #ffc542; }
  .btn-warning:hover {
    color: #212529;
    background-color: #ffb91c;
    border-color: #ffb50f; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #ffb91c;
    border-color: #ffb50f;
    box-shadow: 0 0 0 0.2rem rgba(222, 173, 62, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc542;
    border-color: #ffc542; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffb50f;
    border-color: #ffb102; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 173, 62, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #fc5a5a;
  border-color: #fc5a5a; }
  .btn-danger:hover {
    color: #fff;
    background-color: #fb3434;
    border-color: #fb2828; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #fb3434;
    border-color: #fb2828;
    box-shadow: 0 0 0 0.2rem rgba(252, 115, 115, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #fc5a5a;
    border-color: #fc5a5a; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #fb2828;
    border-color: #fb1b1b; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(252, 115, 115, 0.5); }

.btn-light {
  color: #212529;
  background-color: #aab2bd;
  border-color: #aab2bd; }
  .btn-light:hover {
    color: #212529;
    background-color: #949eac;
    border-color: #8d98a7; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #949eac;
    border-color: #8d98a7;
    box-shadow: 0 0 0 0.2rem rgba(149, 157, 167, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #aab2bd;
    border-color: #aab2bd; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #8d98a7;
    border-color: #8691a1; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(149, 157, 167, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #001737;
  border-color: #001737; }
  .btn-dark:hover {
    color: #fff;
    background-color: #000711;
    border-color: #000204; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #000711;
    border-color: #000204;
    box-shadow: 0 0 0 0.2rem rgba(38, 58, 85, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #001737;
    border-color: #001737; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #000204;
    border-color: black; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 58, 85, 0.5); }

.btn-outline-primary {
  color: #0062ff;
  border-color: #0062ff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0062ff;
    border-color: #0062ff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 98, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0062ff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062ff;
    border-color: #0062ff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 98, 255, 0.5); }

.btn-outline-secondary {
  color: #8e94a9;
  border-color: #8e94a9; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #8e94a9;
    border-color: #8e94a9; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(142, 148, 169, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #8e94a9;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #8e94a9;
    border-color: #8e94a9; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(142, 148, 169, 0.5); }

.btn-outline-success {
  color: #44ce42;
  border-color: #44ce42; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #44ce42;
    border-color: #44ce42; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(68, 206, 66, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #44ce42;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #44ce42;
    border-color: #44ce42; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(68, 206, 66, 0.5); }

.btn-outline-info {
  color: #a461d8;
  border-color: #a461d8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #a461d8;
    border-color: #a461d8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(164, 97, 216, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #a461d8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #a461d8;
    border-color: #a461d8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(164, 97, 216, 0.5); }

.btn-outline-warning {
  color: #ffc542;
  border-color: #ffc542; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc542;
    border-color: #ffc542; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 197, 66, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc542;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc542;
    border-color: #ffc542; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 197, 66, 0.5); }

.btn-outline-danger {
  color: #fc5a5a;
  border-color: #fc5a5a; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #fc5a5a;
    border-color: #fc5a5a; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 90, 90, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #fc5a5a;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #fc5a5a;
    border-color: #fc5a5a; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(252, 90, 90, 0.5); }

.btn-outline-light {
  color: #aab2bd;
  border-color: #aab2bd; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #aab2bd;
    border-color: #aab2bd; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(170, 178, 189, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #aab2bd;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #aab2bd;
    border-color: #aab2bd; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(170, 178, 189, 0.5); }

.btn-outline-dark {
  color: #001737;
  border-color: #001737; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #001737;
    border-color: #001737; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 23, 55, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #001737;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #001737;
    border-color: #001737; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 23, 55, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 1rem 3rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1875rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.5rem 0.81rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1875rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(151, 151, 151, 0.3); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #a7afb7;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #a7afb7;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #a7afb7; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.6075rem;
  padding-left: 0.6075rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 2.25rem;
  padding-left: 2.25rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.875rem 1.375rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 2px; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: 3.175rem; }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.94rem 1.94rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: 2.575rem; }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.5rem 0.81rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #ffffff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #ebedf2; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #ebedf2 #ebedf2 #ebedf2; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #a7afb7;
    background-color: #ffffff;
    border-color: #ebedf2 #ebedf2 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.3125rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.56rem 1.13rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #495057; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #0062ff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #004ecc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 98, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #8e94a9; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #717993; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(142, 148, 169, 0.5); }

.badge-success, .preview-list .preview-item .preview-thumbnail .badge.badge-online {
  color: #fff;
  background-color: #44ce42; }
  a.badge-success:hover, .preview-list .preview-item .preview-thumbnail a.badge.badge-online:hover, a.badge-success:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-online:focus {
    color: #fff;
    background-color: #2fb02e; }
  a.badge-success:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-online:focus, a.badge-success.focus, .preview-list .preview-item .preview-thumbnail a.focus.badge.badge-online {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 206, 66, 0.5); }

.badge-info, .preview-list .preview-item .preview-thumbnail .badge.badge-offline {
  color: #fff;
  background-color: #a461d8; }
  a.badge-info:hover, .preview-list .preview-item .preview-thumbnail a.badge.badge-offline:hover, a.badge-info:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-offline:focus {
    color: #fff;
    background-color: #8c38ce; }
  a.badge-info:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-offline:focus, a.badge-info.focus, .preview-list .preview-item .preview-thumbnail a.focus.badge.badge-offline {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(164, 97, 216, 0.5); }

.badge-warning, .preview-list .preview-item .preview-thumbnail .badge.badge-busy {
  color: #212529;
  background-color: #ffc542; }
  a.badge-warning:hover, .preview-list .preview-item .preview-thumbnail a.badge.badge-busy:hover, a.badge-warning:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-busy:focus {
    color: #212529;
    background-color: #ffb50f; }
  a.badge-warning:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-busy:focus, a.badge-warning.focus, .preview-list .preview-item .preview-thumbnail a.focus.badge.badge-busy {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 197, 66, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #fc5a5a; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #fb2828; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(252, 90, 90, 0.5); }

.badge-light {
  color: #212529;
  background-color: #aab2bd; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #8d98a7; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(170, 178, 189, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #001737; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #000204; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 23, 55, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #003385;
  background-color: #cce0ff;
  border-color: #b8d3ff; }
  .alert-primary hr {
    border-top-color: #9fc3ff; }
  .alert-primary .alert-link {
    color: #001f52; }

.alert-secondary {
  color: #4a4d58;
  background-color: #e8eaee;
  border-color: #dfe1e7; }
  .alert-secondary hr {
    border-top-color: #d0d3dc; }
  .alert-secondary .alert-link {
    color: #33353c; }

.alert-success {
  color: #236b22;
  background-color: #daf5d9;
  border-color: #cbf1ca; }
  .alert-success hr {
    border-top-color: #b7ecb6; }
  .alert-success .alert-link {
    color: #164416; }

.alert-info {
  color: #553270;
  background-color: #eddff7;
  border-color: #e6d3f4; }
  .alert-info hr {
    border-top-color: #dabfef; }
  .alert-info .alert-link {
    color: #3a224d; }

.alert-warning {
  color: #856622;
  background-color: #fff3d9;
  border-color: #ffefca; }
  .alert-warning hr {
    border-top-color: #ffe7b1; }
  .alert-warning .alert-link {
    color: #5c4718; }

.alert-danger {
  color: #832f2f;
  background-color: #fedede;
  border-color: #fed1d1; }
  .alert-danger hr {
    border-top-color: #fdb8b8; }
  .alert-danger .alert-link {
    color: #5d2222; }

.alert-light {
  color: #585d62;
  background-color: #eef0f2;
  border-color: #e7e9ed; }
  .alert-light hr {
    border-top-color: #d8dce2; }
  .alert-light .alert-link {
    color: #404447; }

.alert-dark {
  color: #000c1d;
  background-color: #ccd1d7;
  border-color: #b8bec7; }
  .alert-dark hr {
    border-top-color: #aab1bc; }
  .alert-dark .alert-link {
    color: black; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
          animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #003385;
  background-color: #b8d3ff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #003385;
    background-color: #9fc3ff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #003385;
    border-color: #003385; }

.list-group-item-secondary {
  color: #4a4d58;
  background-color: #dfe1e7; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #4a4d58;
    background-color: #d0d3dc; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #4a4d58;
    border-color: #4a4d58; }

.list-group-item-success {
  color: #236b22;
  background-color: #cbf1ca; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #236b22;
    background-color: #b7ecb6; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #236b22;
    border-color: #236b22; }

.list-group-item-info {
  color: #553270;
  background-color: #e6d3f4; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #553270;
    background-color: #dabfef; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #553270;
    border-color: #553270; }

.list-group-item-warning {
  color: #856622;
  background-color: #ffefca; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856622;
    background-color: #ffe7b1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #856622;
    border-color: #856622; }

.list-group-item-danger {
  color: #832f2f;
  background-color: #fed1d1; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #832f2f;
    background-color: #fdb8b8; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #832f2f;
    border-color: #832f2f; }

.list-group-item-light {
  color: #585d62;
  background-color: #e7e9ed; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #585d62;
    background-color: #d8dce2; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #585d62;
    border-color: #585d62; }

.list-group-item-dark {
  color: #000c1d;
  background-color: #b8bec7; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #000c1d;
    background-color: #aab1bc; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #000c1d;
    border-color: #000c1d; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 20px); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 20px);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 20px); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 20px);
    height: -webkit-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #f0f1f6;
  background-clip: padding-box;
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -25px -26px -25px auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid rgba(151, 151, 151, 0.3);
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 60px); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 60px); }
  .modal-dialog-centered {
    min-height: calc(100% - 60px); }
    .modal-dialog-centered::before {
      height: calc(100vh - 60px);
      height: -webkit-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 90%; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.4rem 0.75rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.375rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
          animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
          animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary, .settings-panel .color-tiles .tiles.primary, #theme-settings .settings-close {
  background-color: #0062ff !important; }

a.bg-primary:hover, .settings-panel .color-tiles a.tiles.primary:hover, #theme-settings a.settings-close:hover, a.bg-primary:focus, .settings-panel .color-tiles a.tiles.primary:focus, #theme-settings a.settings-close:focus,
button.bg-primary:hover,
.settings-panel .color-tiles button.tiles.primary:hover,
#theme-settings button.settings-close:hover,
button.bg-primary:focus,
.settings-panel .color-tiles button.tiles.primary:focus,
#theme-settings button.settings-close:focus {
  background-color: #004ecc !important; }

.bg-secondary {
  background-color: #8e94a9 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #717993 !important; }

.bg-success, .settings-panel .color-tiles .tiles.success {
  background-color: #44ce42 !important; }

a.bg-success:hover, .settings-panel .color-tiles a.tiles.success:hover, a.bg-success:focus, .settings-panel .color-tiles a.tiles.success:focus,
button.bg-success:hover,
.settings-panel .color-tiles button.tiles.success:hover,
button.bg-success:focus,
.settings-panel .color-tiles button.tiles.success:focus {
  background-color: #2fb02e !important; }

.bg-info, .settings-panel .color-tiles .tiles.info {
  background-color: #a461d8 !important; }

a.bg-info:hover, .settings-panel .color-tiles a.tiles.info:hover, a.bg-info:focus, .settings-panel .color-tiles a.tiles.info:focus,
button.bg-info:hover,
.settings-panel .color-tiles button.tiles.info:hover,
button.bg-info:focus,
.settings-panel .color-tiles button.tiles.info:focus {
  background-color: #8c38ce !important; }

.bg-warning, .settings-panel .color-tiles .tiles.warning {
  background-color: #ffc542 !important; }

a.bg-warning:hover, .settings-panel .color-tiles a.tiles.warning:hover, a.bg-warning:focus, .settings-panel .color-tiles a.tiles.warning:focus,
button.bg-warning:hover,
.settings-panel .color-tiles button.tiles.warning:hover,
button.bg-warning:focus,
.settings-panel .color-tiles button.tiles.warning:focus {
  background-color: #ffb50f !important; }

.bg-danger, .settings-panel .color-tiles .tiles.danger {
  background-color: #fc5a5a !important; }

a.bg-danger:hover, .settings-panel .color-tiles a.tiles.danger:hover, a.bg-danger:focus, .settings-panel .color-tiles a.tiles.danger:focus,
button.bg-danger:hover,
.settings-panel .color-tiles button.tiles.danger:hover,
button.bg-danger:focus,
.settings-panel .color-tiles button.tiles.danger:focus {
  background-color: #fb2828 !important; }

.bg-light, .settings-panel .color-tiles .tiles.light {
  background-color: #aab2bd !important; }

a.bg-light:hover, .settings-panel .color-tiles a.tiles.light:hover, a.bg-light:focus, .settings-panel .color-tiles a.tiles.light:focus,
button.bg-light:hover,
.settings-panel .color-tiles button.tiles.light:hover,
button.bg-light:focus,
.settings-panel .color-tiles button.tiles.light:focus {
  background-color: #8d98a7 !important; }

.bg-dark, .settings-panel .color-tiles .tiles.dark {
  background-color: #001737 !important; }

a.bg-dark:hover, .settings-panel .color-tiles a.tiles.dark:hover, a.bg-dark:focus, .settings-panel .color-tiles a.tiles.dark:focus,
button.bg-dark:hover,
.settings-panel .color-tiles button.tiles.dark:hover,
button.bg-dark:focus,
.settings-panel .color-tiles button.tiles.dark:focus {
  background-color: #000204 !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border, .loader-demo-box {
  border: 1px solid rgba(151, 151, 151, 0.3) !important; }

.border-top {
  border-top: 1px solid rgba(151, 151, 151, 0.3) !important; }

.border-right {
  border-right: 1px solid rgba(151, 151, 151, 0.3) !important; }

.border-bottom {
  border-bottom: 1px solid rgba(151, 151, 151, 0.3) !important; }

.border-left {
  border-left: 1px solid rgba(151, 151, 151, 0.3) !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0062ff !important; }

.border-secondary, .loader-demo-box {
  border-color: #8e94a9 !important; }

.border-success {
  border-color: #44ce42 !important; }

.border-info {
  border-color: #a461d8 !important; }

.border-warning {
  border-color: #ffc542 !important; }

.border-danger {
  border-color: #fc5a5a !important; }

.border-light {
  border-color: #aab2bd !important; }

.border-dark {
  border-color: #001737 !important; }

.border-white {
  border-color: #ffffff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded, .loader-demo-box {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle, .settings-panel .color-tiles .tiles {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, .page-header, .loader-demo-box, .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-menu.navbar-dropdown .dropdown-item, .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link, .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-language .nav-link {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row, .tab-pills-horizontal .nav.nav-pills, .tab-custom-pills-horizontal .nav.nav-pills {
  flex-direction: row !important; }

.flex-column, .pills-vertical .nav-pills {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between, .page-header {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center, .page-header, .loader-demo-box, .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-menu.navbar-dropdown .dropdown-item {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0, .demo-modal .modal-dialog,
.my-0 {
  margin-top: 0 !important; }

.mr-0, .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-menu.navbar-dropdown .dropdown-item i, .rtl .settings-panel .sidebar-bg-options .rounded-circle, .rtl .settings-panel .sidebar-bg-options .color-tiles .tiles, .rtl .settings-panel .color-tiles .sidebar-bg-options .tiles,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0, .rtl .preview-list .preview-item .preview-thumbnail .preview-icon i, .rtl .navbar .navbar-menu-wrapper .search-field,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2, .template-demo > .btn-group,
.template-demo > .btn-group-vertical, .template-demo .circle-progress,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2, .template-demo > .btn-group,
.template-demo > .btn-group-vertical, .template-demo > .dropdown, .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-menu.navbar-dropdown .dropdown-item i,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2, .btn-toolbar .btn-group + .btn-group,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3, .template-demo > .btn, .template-demo > .btn-toolbar,
.my-3 {
  margin-top: 1rem !important; }

.mr-3, .template-demo > .btn, .template-demo > .btn-toolbar,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3, .template-demo .circle-progress-block,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3, .rtl .settings-panel .sidebar-bg-options .rounded-circle, .rtl .settings-panel .sidebar-bg-options .color-tiles .tiles, .rtl .settings-panel .color-tiles .sidebar-bg-options .tiles,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4, .rtl .navbar .navbar-menu-wrapper .search-field,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2,
.template-demo .circle-progress-block {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2,
.template-demo .circle-progress-block {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #ffffff !important; }

.text-primary {
  color: #0062ff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0045b3 !important; }

.text-secondary {
  color: #8e94a9 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #656c85 !important; }

.text-success {
  color: #44ce42 !important; }

a.text-success:hover, a.text-success:focus {
  color: #2a9b28 !important; }

.text-info {
  color: #a461d8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #7f2fbe !important; }

.text-warning {
  color: #ffc542 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #f5a900 !important; }

.text-danger {
  color: #fc5a5a !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #fb0f0f !important; }

.text-light {
  color: #aab2bd !important; }

a.text-light:hover, a.text-light:focus {
  color: #7f8b9c !important; }

.text-dark {
  color: #001737 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-body {
  color: #a7afb7 !important; }

.text-muted, .preview-list .preview-item .preview-item-content p .content-category {
  color: #a7afb7 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #ffffff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: rgba(151, 151, 151, 0.3); }
  .table .thead-dark th {
    color: inherit;
    border-color: rgba(151, 151, 151, 0.3); } }

/* === Icon fonts === */
/* MaterialDesignIcons.com */
@font-face {
  font-family: "Material Design Icons";
  src: url(/static/media/materialdesignicons-webfont.2d0a0d8f.eot);
  src: url(/static/media/materialdesignicons-webfont.2d0a0d8f.eot?#iefix&v=4.9.95) format("embedded-opentype"), url(/static/media/materialdesignicons-webfont.d0066537.woff2) format("woff2"), url(/static/media/materialdesignicons-webfont.b4917be2.woff) format("woff"), url(/static/media/materialdesignicons-webfont.f5111234.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.mdi-ab-testing::before {
  content: "\F001C"; }

.mdi-abjad-arabic::before {
  content: "\F0353"; }

.mdi-abjad-hebrew::before {
  content: "\F0354"; }

.mdi-abugida-devanagari::before {
  content: "\F0355"; }

.mdi-abugida-thai::before {
  content: "\F0356"; }

.mdi-access-point::before {
  content: "\F002"; }

.mdi-access-point-network::before {
  content: "\F003"; }

.mdi-access-point-network-off::before {
  content: "\FBBD"; }

.mdi-account::before {
  content: "\F004"; }

.mdi-account-alert::before {
  content: "\F005"; }

.mdi-account-alert-outline::before {
  content: "\FB2C"; }

.mdi-account-arrow-left::before {
  content: "\FB2D"; }

.mdi-account-arrow-left-outline::before {
  content: "\FB2E"; }

.mdi-account-arrow-right::before {
  content: "\FB2F"; }

.mdi-account-arrow-right-outline::before {
  content: "\FB30"; }

.mdi-account-badge::before {
  content: "\FD83"; }

.mdi-account-badge-alert::before {
  content: "\FD84"; }

.mdi-account-badge-alert-outline::before {
  content: "\FD85"; }

.mdi-account-badge-horizontal::before {
  content: "\FDF0"; }

.mdi-account-badge-horizontal-outline::before {
  content: "\FDF1"; }

.mdi-account-badge-outline::before {
  content: "\FD86"; }

.mdi-account-box::before {
  content: "\F006"; }

.mdi-account-box-multiple::before {
  content: "\F933"; }

.mdi-account-box-multiple-outline::before {
  content: "\F002C"; }

.mdi-account-box-outline::before {
  content: "\F007"; }

.mdi-account-cancel::before {
  content: "\F030A"; }

.mdi-account-cancel-outline::before {
  content: "\F030B"; }

.mdi-account-card-details::before {
  content: "\F5D2"; }

.mdi-account-card-details-outline::before {
  content: "\FD87"; }

.mdi-account-cash::before {
  content: "\F00C2"; }

.mdi-account-cash-outline::before {
  content: "\F00C3"; }

.mdi-account-check::before {
  content: "\F008"; }

.mdi-account-check-outline::before {
  content: "\FBBE"; }

.mdi-account-child::before {
  content: "\FA88"; }

.mdi-account-child-circle::before {
  content: "\FA89"; }

.mdi-account-child-outline::before {
  content: "\F00F3"; }

.mdi-account-circle::before {
  content: "\F009"; }

.mdi-account-circle-outline::before {
  content: "\FB31"; }

.mdi-account-clock::before {
  content: "\FB32"; }

.mdi-account-clock-outline::before {
  content: "\FB33"; }

.mdi-account-cog::before {
  content: "\F039B"; }

.mdi-account-cog-outline::before {
  content: "\F039C"; }

.mdi-account-convert::before {
  content: "\F00A"; }

.mdi-account-convert-outline::before {
  content: "\F032C"; }

.mdi-account-details::before {
  content: "\F631"; }

.mdi-account-details-outline::before {
  content: "\F039D"; }

.mdi-account-edit::before {
  content: "\F6BB"; }

.mdi-account-edit-outline::before {
  content: "\F001D"; }

.mdi-account-group::before {
  content: "\F848"; }

.mdi-account-group-outline::before {
  content: "\FB34"; }

.mdi-account-heart::before {
  content: "\F898"; }

.mdi-account-heart-outline::before {
  content: "\FBBF"; }

.mdi-account-key::before {
  content: "\F00B"; }

.mdi-account-key-outline::before {
  content: "\FBC0"; }

.mdi-account-lock::before {
  content: "\F0189"; }

.mdi-account-lock-outline::before {
  content: "\F018A"; }

.mdi-account-minus::before {
  content: "\F00D"; }

.mdi-account-minus-outline::before {
  content: "\FAEB"; }

.mdi-account-multiple::before {
  content: "\F00E"; }

.mdi-account-multiple-check::before {
  content: "\F8C4"; }

.mdi-account-multiple-check-outline::before {
  content: "\F0229"; }

.mdi-account-multiple-minus::before {
  content: "\F5D3"; }

.mdi-account-multiple-minus-outline::before {
  content: "\FBC1"; }

.mdi-account-multiple-outline::before {
  content: "\F00F"; }

.mdi-account-multiple-plus::before {
  content: "\F010"; }

.mdi-account-multiple-plus-outline::before {
  content: "\F7FF"; }

.mdi-account-multiple-remove::before {
  content: "\F0235"; }

.mdi-account-multiple-remove-outline::before {
  content: "\F0236"; }

.mdi-account-network::before {
  content: "\F011"; }

.mdi-account-network-outline::before {
  content: "\FBC2"; }

.mdi-account-off::before {
  content: "\F012"; }

.mdi-account-off-outline::before {
  content: "\FBC3"; }

.mdi-account-outline::before {
  content: "\F013"; }

.mdi-account-plus::before {
  content: "\F014"; }

.mdi-account-plus-outline::before {
  content: "\F800"; }

.mdi-account-question::before {
  content: "\FB35"; }

.mdi-account-question-outline::before {
  content: "\FB36"; }

.mdi-account-remove::before {
  content: "\F015"; }

.mdi-account-remove-outline::before {
  content: "\FAEC"; }

.mdi-account-search::before {
  content: "\F016"; }

.mdi-account-search-outline::before {
  content: "\F934"; }

.mdi-account-settings::before {
  content: "\F630"; }

.mdi-account-settings-outline::before {
  content: "\F00F4"; }

.mdi-account-star::before {
  content: "\F017"; }

.mdi-account-star-outline::before {
  content: "\FBC4"; }

.mdi-account-supervisor::before {
  content: "\FA8A"; }

.mdi-account-supervisor-circle::before {
  content: "\FA8B"; }

.mdi-account-supervisor-outline::before {
  content: "\F0158"; }

.mdi-account-switch::before {
  content: "\F019"; }

.mdi-account-tie::before {
  content: "\FCBF"; }

.mdi-account-tie-outline::before {
  content: "\F00F5"; }

.mdi-account-tie-voice::before {
  content: "\F0333"; }

.mdi-account-tie-voice-off::before {
  content: "\F0335"; }

.mdi-account-tie-voice-off-outline::before {
  content: "\F0336"; }

.mdi-account-tie-voice-outline::before {
  content: "\F0334"; }

.mdi-accusoft::before {
  content: "\F849"; }

.mdi-adjust::before {
  content: "\F01A"; }

.mdi-adobe::before {
  content: "\F935"; }

.mdi-adobe-acrobat::before {
  content: "\FFBD"; }

.mdi-air-conditioner::before {
  content: "\F01B"; }

.mdi-air-filter::before {
  content: "\FD1F"; }

.mdi-air-horn::before {
  content: "\FD88"; }

.mdi-air-humidifier::before {
  content: "\F00C4"; }

.mdi-air-purifier::before {
  content: "\FD20"; }

.mdi-airbag::before {
  content: "\FBC5"; }

.mdi-airballoon::before {
  content: "\F01C"; }

.mdi-airballoon-outline::before {
  content: "\F002D"; }

.mdi-airplane::before {
  content: "\F01D"; }

.mdi-airplane-landing::before {
  content: "\F5D4"; }

.mdi-airplane-off::before {
  content: "\F01E"; }

.mdi-airplane-takeoff::before {
  content: "\F5D5"; }

.mdi-airplay::before {
  content: "\F01F"; }

.mdi-airport::before {
  content: "\F84A"; }

.mdi-alarm::before {
  content: "\F020"; }

.mdi-alarm-bell::before {
  content: "\F78D"; }

.mdi-alarm-check::before {
  content: "\F021"; }

.mdi-alarm-light::before {
  content: "\F78E"; }

.mdi-alarm-light-outline::before {
  content: "\FBC6"; }

.mdi-alarm-multiple::before {
  content: "\F022"; }

.mdi-alarm-note::before {
  content: "\FE8E"; }

.mdi-alarm-note-off::before {
  content: "\FE8F"; }

.mdi-alarm-off::before {
  content: "\F023"; }

.mdi-alarm-plus::before {
  content: "\F024"; }

.mdi-alarm-snooze::before {
  content: "\F68D"; }

.mdi-album::before {
  content: "\F025"; }

.mdi-alert::before {
  content: "\F026"; }

.mdi-alert-box::before {
  content: "\F027"; }

.mdi-alert-box-outline::before {
  content: "\FCC0"; }

.mdi-alert-circle::before {
  content: "\F028"; }

.mdi-alert-circle-check::before {
  content: "\F0218"; }

.mdi-alert-circle-check-outline::before {
  content: "\F0219"; }

.mdi-alert-circle-outline::before {
  content: "\F5D6"; }

.mdi-alert-decagram::before {
  content: "\F6BC"; }

.mdi-alert-decagram-outline::before {
  content: "\FCC1"; }

.mdi-alert-octagon::before {
  content: "\F029"; }

.mdi-alert-octagon-outline::before {
  content: "\FCC2"; }

.mdi-alert-octagram::before {
  content: "\F766"; }

.mdi-alert-octagram-outline::before {
  content: "\FCC3"; }

.mdi-alert-outline::before {
  content: "\F02A"; }

.mdi-alert-rhombus::before {
  content: "\F01F9"; }

.mdi-alert-rhombus-outline::before {
  content: "\F01FA"; }

.mdi-alien::before {
  content: "\F899"; }

.mdi-alien-outline::before {
  content: "\F00F6"; }

.mdi-align-horizontal-center::before {
  content: "\F01EE"; }

.mdi-align-horizontal-left::before {
  content: "\F01ED"; }

.mdi-align-horizontal-right::before {
  content: "\F01EF"; }

.mdi-align-vertical-bottom::before {
  content: "\F01F0"; }

.mdi-align-vertical-center::before {
  content: "\F01F1"; }

.mdi-align-vertical-top::before {
  content: "\F01F2"; }

.mdi-all-inclusive::before {
  content: "\F6BD"; }

.mdi-allergy::before {
  content: "\F0283"; }

.mdi-alpha::before {
  content: "\F02B"; }

.mdi-alpha-a::before {
  content: "\41"; }

.mdi-alpha-a-box::before {
  content: "\FAED"; }

.mdi-alpha-a-box-outline::before {
  content: "\FBC7"; }

.mdi-alpha-a-circle::before {
  content: "\FBC8"; }

.mdi-alpha-a-circle-outline::before {
  content: "\FBC9"; }

.mdi-alpha-b::before {
  content: "\42"; }

.mdi-alpha-b-box::before {
  content: "\FAEE"; }

.mdi-alpha-b-box-outline::before {
  content: "\FBCA"; }

.mdi-alpha-b-circle::before {
  content: "\FBCB"; }

.mdi-alpha-b-circle-outline::before {
  content: "\FBCC"; }

.mdi-alpha-c::before {
  content: "\43"; }

.mdi-alpha-c-box::before {
  content: "\FAEF"; }

.mdi-alpha-c-box-outline::before {
  content: "\FBCD"; }

.mdi-alpha-c-circle::before {
  content: "\FBCE"; }

.mdi-alpha-c-circle-outline::before {
  content: "\FBCF"; }

.mdi-alpha-d::before {
  content: "\44"; }

.mdi-alpha-d-box::before {
  content: "\FAF0"; }

.mdi-alpha-d-box-outline::before {
  content: "\FBD0"; }

.mdi-alpha-d-circle::before {
  content: "\FBD1"; }

.mdi-alpha-d-circle-outline::before {
  content: "\FBD2"; }

.mdi-alpha-e::before {
  content: "\45"; }

.mdi-alpha-e-box::before {
  content: "\FAF1"; }

.mdi-alpha-e-box-outline::before {
  content: "\FBD3"; }

.mdi-alpha-e-circle::before {
  content: "\FBD4"; }

.mdi-alpha-e-circle-outline::before {
  content: "\FBD5"; }

.mdi-alpha-f::before {
  content: "\46"; }

.mdi-alpha-f-box::before {
  content: "\FAF2"; }

.mdi-alpha-f-box-outline::before {
  content: "\FBD6"; }

.mdi-alpha-f-circle::before {
  content: "\FBD7"; }

.mdi-alpha-f-circle-outline::before {
  content: "\FBD8"; }

.mdi-alpha-g::before {
  content: "\47"; }

.mdi-alpha-g-box::before {
  content: "\FAF3"; }

.mdi-alpha-g-box-outline::before {
  content: "\FBD9"; }

.mdi-alpha-g-circle::before {
  content: "\FBDA"; }

.mdi-alpha-g-circle-outline::before {
  content: "\FBDB"; }

.mdi-alpha-h::before {
  content: "\48"; }

.mdi-alpha-h-box::before {
  content: "\FAF4"; }

.mdi-alpha-h-box-outline::before {
  content: "\FBDC"; }

.mdi-alpha-h-circle::before {
  content: "\FBDD"; }

.mdi-alpha-h-circle-outline::before {
  content: "\FBDE"; }

.mdi-alpha-i::before {
  content: "\49"; }

.mdi-alpha-i-box::before {
  content: "\FAF5"; }

.mdi-alpha-i-box-outline::before {
  content: "\FBDF"; }

.mdi-alpha-i-circle::before {
  content: "\FBE0"; }

.mdi-alpha-i-circle-outline::before {
  content: "\FBE1"; }

.mdi-alpha-j::before {
  content: "\4A"; }

.mdi-alpha-j-box::before {
  content: "\FAF6"; }

.mdi-alpha-j-box-outline::before {
  content: "\FBE2"; }

.mdi-alpha-j-circle::before {
  content: "\FBE3"; }

.mdi-alpha-j-circle-outline::before {
  content: "\FBE4"; }

.mdi-alpha-k::before {
  content: "\4B"; }

.mdi-alpha-k-box::before {
  content: "\FAF7"; }

.mdi-alpha-k-box-outline::before {
  content: "\FBE5"; }

.mdi-alpha-k-circle::before {
  content: "\FBE6"; }

.mdi-alpha-k-circle-outline::before {
  content: "\FBE7"; }

.mdi-alpha-l::before {
  content: "\4C"; }

.mdi-alpha-l-box::before {
  content: "\FAF8"; }

.mdi-alpha-l-box-outline::before {
  content: "\FBE8"; }

.mdi-alpha-l-circle::before {
  content: "\FBE9"; }

.mdi-alpha-l-circle-outline::before {
  content: "\FBEA"; }

.mdi-alpha-m::before {
  content: "\4D"; }

.mdi-alpha-m-box::before {
  content: "\FAF9"; }

.mdi-alpha-m-box-outline::before {
  content: "\FBEB"; }

.mdi-alpha-m-circle::before {
  content: "\FBEC"; }

.mdi-alpha-m-circle-outline::before {
  content: "\FBED"; }

.mdi-alpha-n::before {
  content: "\4E"; }

.mdi-alpha-n-box::before {
  content: "\FAFA"; }

.mdi-alpha-n-box-outline::before {
  content: "\FBEE"; }

.mdi-alpha-n-circle::before {
  content: "\FBEF"; }

.mdi-alpha-n-circle-outline::before {
  content: "\FBF0"; }

.mdi-alpha-o::before {
  content: "\4F"; }

.mdi-alpha-o-box::before {
  content: "\FAFB"; }

.mdi-alpha-o-box-outline::before {
  content: "\FBF1"; }

.mdi-alpha-o-circle::before {
  content: "\FBF2"; }

.mdi-alpha-o-circle-outline::before {
  content: "\FBF3"; }

.mdi-alpha-p::before {
  content: "\50"; }

.mdi-alpha-p-box::before {
  content: "\FAFC"; }

.mdi-alpha-p-box-outline::before {
  content: "\FBF4"; }

.mdi-alpha-p-circle::before {
  content: "\FBF5"; }

.mdi-alpha-p-circle-outline::before {
  content: "\FBF6"; }

.mdi-alpha-q::before {
  content: "\51"; }

.mdi-alpha-q-box::before {
  content: "\FAFD"; }

.mdi-alpha-q-box-outline::before {
  content: "\FBF7"; }

.mdi-alpha-q-circle::before {
  content: "\FBF8"; }

.mdi-alpha-q-circle-outline::before {
  content: "\FBF9"; }

.mdi-alpha-r::before {
  content: "\52"; }

.mdi-alpha-r-box::before {
  content: "\FAFE"; }

.mdi-alpha-r-box-outline::before {
  content: "\FBFA"; }

.mdi-alpha-r-circle::before {
  content: "\FBFB"; }

.mdi-alpha-r-circle-outline::before {
  content: "\FBFC"; }

.mdi-alpha-s::before {
  content: "\53"; }

.mdi-alpha-s-box::before {
  content: "\FAFF"; }

.mdi-alpha-s-box-outline::before {
  content: "\FBFD"; }

.mdi-alpha-s-circle::before {
  content: "\FBFE"; }

.mdi-alpha-s-circle-outline::before {
  content: "\FBFF"; }

.mdi-alpha-t::before {
  content: "\54"; }

.mdi-alpha-t-box::before {
  content: "\FB00"; }

.mdi-alpha-t-box-outline::before {
  content: "\FC00"; }

.mdi-alpha-t-circle::before {
  content: "\FC01"; }

.mdi-alpha-t-circle-outline::before {
  content: "\FC02"; }

.mdi-alpha-u::before {
  content: "\55"; }

.mdi-alpha-u-box::before {
  content: "\FB01"; }

.mdi-alpha-u-box-outline::before {
  content: "\FC03"; }

.mdi-alpha-u-circle::before {
  content: "\FC04"; }

.mdi-alpha-u-circle-outline::before {
  content: "\FC05"; }

.mdi-alpha-v::before {
  content: "\56"; }

.mdi-alpha-v-box::before {
  content: "\FB02"; }

.mdi-alpha-v-box-outline::before {
  content: "\FC06"; }

.mdi-alpha-v-circle::before {
  content: "\FC07"; }

.mdi-alpha-v-circle-outline::before {
  content: "\FC08"; }

.mdi-alpha-w::before {
  content: "\57"; }

.mdi-alpha-w-box::before {
  content: "\FB03"; }

.mdi-alpha-w-box-outline::before {
  content: "\FC09"; }

.mdi-alpha-w-circle::before {
  content: "\FC0A"; }

.mdi-alpha-w-circle-outline::before {
  content: "\FC0B"; }

.mdi-alpha-x::before {
  content: "\58"; }

.mdi-alpha-x-box::before {
  content: "\FB04"; }

.mdi-alpha-x-box-outline::before {
  content: "\FC0C"; }

.mdi-alpha-x-circle::before {
  content: "\FC0D"; }

.mdi-alpha-x-circle-outline::before {
  content: "\FC0E"; }

.mdi-alpha-y::before {
  content: "\59"; }

.mdi-alpha-y-box::before {
  content: "\FB05"; }

.mdi-alpha-y-box-outline::before {
  content: "\FC0F"; }

.mdi-alpha-y-circle::before {
  content: "\FC10"; }

.mdi-alpha-y-circle-outline::before {
  content: "\FC11"; }

.mdi-alpha-z::before {
  content: "\5A"; }

.mdi-alpha-z-box::before {
  content: "\FB06"; }

.mdi-alpha-z-box-outline::before {
  content: "\FC12"; }

.mdi-alpha-z-circle::before {
  content: "\FC13"; }

.mdi-alpha-z-circle-outline::before {
  content: "\FC14"; }

.mdi-alphabet-aurebesh::before {
  content: "\F0357"; }

.mdi-alphabet-cyrillic::before {
  content: "\F0358"; }

.mdi-alphabet-greek::before {
  content: "\F0359"; }

.mdi-alphabet-latin::before {
  content: "\F035A"; }

.mdi-alphabet-piqad::before {
  content: "\F035B"; }

.mdi-alphabet-tengwar::before {
  content: "\F0362"; }

.mdi-alphabetical::before {
  content: "\F02C"; }

.mdi-alphabetical-off::before {
  content: "\F002E"; }

.mdi-alphabetical-variant::before {
  content: "\F002F"; }

.mdi-alphabetical-variant-off::before {
  content: "\F0030"; }

.mdi-altimeter::before {
  content: "\F5D7"; }

.mdi-amazon::before {
  content: "\F02D"; }

.mdi-amazon-alexa::before {
  content: "\F8C5"; }

.mdi-amazon-drive::before {
  content: "\F02E"; }

.mdi-ambulance::before {
  content: "\F02F"; }

.mdi-ammunition::before {
  content: "\FCC4"; }

.mdi-ampersand::before {
  content: "\FA8C"; }

.mdi-amplifier::before {
  content: "\F030"; }

.mdi-amplifier-off::before {
  content: "\F01E0"; }

.mdi-anchor::before {
  content: "\F031"; }

.mdi-android::before {
  content: "\F032"; }

.mdi-android-auto::before {
  content: "\FA8D"; }

.mdi-android-debug-bridge::before {
  content: "\F033"; }

.mdi-android-head::before {
  content: "\F78F"; }

.mdi-android-messages::before {
  content: "\FD21"; }

.mdi-android-studio::before {
  content: "\F034"; }

.mdi-angle-acute::before {
  content: "\F936"; }

.mdi-angle-obtuse::before {
  content: "\F937"; }

.mdi-angle-right::before {
  content: "\F938"; }

.mdi-angular::before {
  content: "\F6B1"; }

.mdi-angularjs::before {
  content: "\F6BE"; }

.mdi-animation::before {
  content: "\F5D8"; }

.mdi-animation-outline::before {
  content: "\FA8E"; }

.mdi-animation-play::before {
  content: "\F939"; }

.mdi-animation-play-outline::before {
  content: "\FA8F"; }

.mdi-ansible::before {
  content: "\F00C5"; }

.mdi-antenna::before {
  content: "\F0144"; }

.mdi-anvil::before {
  content: "\F89A"; }

.mdi-apache-kafka::before {
  content: "\F0031"; }

.mdi-api::before {
  content: "\F00C6"; }

.mdi-api-off::before {
  content: "\F0282"; }

.mdi-apple::before {
  content: "\F035"; }

.mdi-apple-finder::before {
  content: "\F036"; }

.mdi-apple-icloud::before {
  content: "\F038"; }

.mdi-apple-ios::before {
  content: "\F037"; }

.mdi-apple-keyboard-caps::before {
  content: "\F632"; }

.mdi-apple-keyboard-command::before {
  content: "\F633"; }

.mdi-apple-keyboard-control::before {
  content: "\F634"; }

.mdi-apple-keyboard-option::before {
  content: "\F635"; }

.mdi-apple-keyboard-shift::before {
  content: "\F636"; }

.mdi-apple-safari::before {
  content: "\F039"; }

.mdi-application::before {
  content: "\F614"; }

.mdi-application-export::before {
  content: "\FD89"; }

.mdi-application-import::before {
  content: "\FD8A"; }

.mdi-approximately-equal::before {
  content: "\FFBE"; }

.mdi-approximately-equal-box::before {
  content: "\FFBF"; }

.mdi-apps::before {
  content: "\F03B"; }

.mdi-apps-box::before {
  content: "\FD22"; }

.mdi-arch::before {
  content: "\F8C6"; }

.mdi-archive::before {
  content: "\F03C"; }

.mdi-archive-arrow-down::before {
  content: "\F0284"; }

.mdi-archive-arrow-down-outline::before {
  content: "\F0285"; }

.mdi-archive-arrow-up::before {
  content: "\F0286"; }

.mdi-archive-arrow-up-outline::before {
  content: "\F0287"; }

.mdi-archive-outline::before {
  content: "\F0239"; }

.mdi-arm-flex::before {
  content: "\F008F"; }

.mdi-arm-flex-outline::before {
  content: "\F0090"; }

.mdi-arrange-bring-forward::before {
  content: "\F03D"; }

.mdi-arrange-bring-to-front::before {
  content: "\F03E"; }

.mdi-arrange-send-backward::before {
  content: "\F03F"; }

.mdi-arrange-send-to-back::before {
  content: "\F040"; }

.mdi-arrow-all::before {
  content: "\F041"; }

.mdi-arrow-bottom-left::before {
  content: "\F042"; }

.mdi-arrow-bottom-left-bold-outline::before {
  content: "\F9B6"; }

.mdi-arrow-bottom-left-thick::before {
  content: "\F9B7"; }

.mdi-arrow-bottom-right::before {
  content: "\F043"; }

.mdi-arrow-bottom-right-bold-outline::before {
  content: "\F9B8"; }

.mdi-arrow-bottom-right-thick::before {
  content: "\F9B9"; }

.mdi-arrow-collapse::before {
  content: "\F615"; }

.mdi-arrow-collapse-all::before {
  content: "\F044"; }

.mdi-arrow-collapse-down::before {
  content: "\F791"; }

.mdi-arrow-collapse-horizontal::before {
  content: "\F84B"; }

.mdi-arrow-collapse-left::before {
  content: "\F792"; }

.mdi-arrow-collapse-right::before {
  content: "\F793"; }

.mdi-arrow-collapse-up::before {
  content: "\F794"; }

.mdi-arrow-collapse-vertical::before {
  content: "\F84C"; }

.mdi-arrow-decision::before {
  content: "\F9BA"; }

.mdi-arrow-decision-auto::before {
  content: "\F9BB"; }

.mdi-arrow-decision-auto-outline::before {
  content: "\F9BC"; }

.mdi-arrow-decision-outline::before {
  content: "\F9BD"; }

.mdi-arrow-down::before {
  content: "\F045"; }

.mdi-arrow-down-bold::before {
  content: "\F72D"; }

.mdi-arrow-down-bold-box::before {
  content: "\F72E"; }

.mdi-arrow-down-bold-box-outline::before {
  content: "\F72F"; }

.mdi-arrow-down-bold-circle::before {
  content: "\F047"; }

.mdi-arrow-down-bold-circle-outline::before {
  content: "\F048"; }

.mdi-arrow-down-bold-hexagon-outline::before {
  content: "\F049"; }

.mdi-arrow-down-bold-outline::before {
  content: "\F9BE"; }

.mdi-arrow-down-box::before {
  content: "\F6BF"; }

.mdi-arrow-down-circle::before {
  content: "\FCB7"; }

.mdi-arrow-down-circle-outline::before {
  content: "\FCB8"; }

.mdi-arrow-down-drop-circle::before {
  content: "\F04A"; }

.mdi-arrow-down-drop-circle-outline::before {
  content: "\F04B"; }

.mdi-arrow-down-thick::before {
  content: "\F046"; }

.mdi-arrow-expand::before {
  content: "\F616"; }

.mdi-arrow-expand-all::before {
  content: "\F04C"; }

.mdi-arrow-expand-down::before {
  content: "\F795"; }

.mdi-arrow-expand-horizontal::before {
  content: "\F84D"; }

.mdi-arrow-expand-left::before {
  content: "\F796"; }

.mdi-arrow-expand-right::before {
  content: "\F797"; }

.mdi-arrow-expand-up::before {
  content: "\F798"; }

.mdi-arrow-expand-vertical::before {
  content: "\F84E"; }

.mdi-arrow-horizontal-lock::before {
  content: "\F0186"; }

.mdi-arrow-left::before {
  content: "\F04D"; }

.mdi-arrow-left-bold::before {
  content: "\F730"; }

.mdi-arrow-left-bold-box::before {
  content: "\F731"; }

.mdi-arrow-left-bold-box-outline::before {
  content: "\F732"; }

.mdi-arrow-left-bold-circle::before {
  content: "\F04F"; }

.mdi-arrow-left-bold-circle-outline::before {
  content: "\F050"; }

.mdi-arrow-left-bold-hexagon-outline::before {
  content: "\F051"; }

.mdi-arrow-left-bold-outline::before {
  content: "\F9BF"; }

.mdi-arrow-left-box::before {
  content: "\F6C0"; }

.mdi-arrow-left-circle::before {
  content: "\FCB9"; }

.mdi-arrow-left-circle-outline::before {
  content: "\FCBA"; }

.mdi-arrow-left-drop-circle::before {
  content: "\F052"; }

.mdi-arrow-left-drop-circle-outline::before {
  content: "\F053"; }

.mdi-arrow-left-right::before {
  content: "\FE90"; }

.mdi-arrow-left-right-bold::before {
  content: "\FE91"; }

.mdi-arrow-left-right-bold-outline::before {
  content: "\F9C0"; }

.mdi-arrow-left-thick::before {
  content: "\F04E"; }

.mdi-arrow-right::before {
  content: "\F054"; }

.mdi-arrow-right-bold::before {
  content: "\F733"; }

.mdi-arrow-right-bold-box::before {
  content: "\F734"; }

.mdi-arrow-right-bold-box-outline::before {
  content: "\F735"; }

.mdi-arrow-right-bold-circle::before {
  content: "\F056"; }

.mdi-arrow-right-bold-circle-outline::before {
  content: "\F057"; }

.mdi-arrow-right-bold-hexagon-outline::before {
  content: "\F058"; }

.mdi-arrow-right-bold-outline::before {
  content: "\F9C1"; }

.mdi-arrow-right-box::before {
  content: "\F6C1"; }

.mdi-arrow-right-circle::before {
  content: "\FCBB"; }

.mdi-arrow-right-circle-outline::before {
  content: "\FCBC"; }

.mdi-arrow-right-drop-circle::before {
  content: "\F059"; }

.mdi-arrow-right-drop-circle-outline::before {
  content: "\F05A"; }

.mdi-arrow-right-thick::before {
  content: "\F055"; }

.mdi-arrow-split-horizontal::before {
  content: "\F93A"; }

.mdi-arrow-split-vertical::before {
  content: "\F93B"; }

.mdi-arrow-top-left::before {
  content: "\F05B"; }

.mdi-arrow-top-left-bold-outline::before {
  content: "\F9C2"; }

.mdi-arrow-top-left-bottom-right::before {
  content: "\FE92"; }

.mdi-arrow-top-left-bottom-right-bold::before {
  content: "\FE93"; }

.mdi-arrow-top-left-thick::before {
  content: "\F9C3"; }

.mdi-arrow-top-right::before {
  content: "\F05C"; }

.mdi-arrow-top-right-bold-outline::before {
  content: "\F9C4"; }

.mdi-arrow-top-right-bottom-left::before {
  content: "\FE94"; }

.mdi-arrow-top-right-bottom-left-bold::before {
  content: "\FE95"; }

.mdi-arrow-top-right-thick::before {
  content: "\F9C5"; }

.mdi-arrow-up::before {
  content: "\F05D"; }

.mdi-arrow-up-bold::before {
  content: "\F736"; }

.mdi-arrow-up-bold-box::before {
  content: "\F737"; }

.mdi-arrow-up-bold-box-outline::before {
  content: "\F738"; }

.mdi-arrow-up-bold-circle::before {
  content: "\F05F"; }

.mdi-arrow-up-bold-circle-outline::before {
  content: "\F060"; }

.mdi-arrow-up-bold-hexagon-outline::before {
  content: "\F061"; }

.mdi-arrow-up-bold-outline::before {
  content: "\F9C6"; }

.mdi-arrow-up-box::before {
  content: "\F6C2"; }

.mdi-arrow-up-circle::before {
  content: "\FCBD"; }

.mdi-arrow-up-circle-outline::before {
  content: "\FCBE"; }

.mdi-arrow-up-down::before {
  content: "\FE96"; }

.mdi-arrow-up-down-bold::before {
  content: "\FE97"; }

.mdi-arrow-up-down-bold-outline::before {
  content: "\F9C7"; }

.mdi-arrow-up-drop-circle::before {
  content: "\F062"; }

.mdi-arrow-up-drop-circle-outline::before {
  content: "\F063"; }

.mdi-arrow-up-thick::before {
  content: "\F05E"; }

.mdi-arrow-vertical-lock::before {
  content: "\F0187"; }

.mdi-artist::before {
  content: "\F802"; }

.mdi-artist-outline::before {
  content: "\FCC5"; }

.mdi-artstation::before {
  content: "\FB37"; }

.mdi-aspect-ratio::before {
  content: "\FA23"; }

.mdi-assistant::before {
  content: "\F064"; }

.mdi-asterisk::before {
  content: "\F6C3"; }

.mdi-at::before {
  content: "\F065"; }

.mdi-atlassian::before {
  content: "\F803"; }

.mdi-atm::before {
  content: "\FD23"; }

.mdi-atom::before {
  content: "\F767"; }

.mdi-atom-variant::before {
  content: "\FE98"; }

.mdi-attachment::before {
  content: "\F066"; }

.mdi-audio-video::before {
  content: "\F93C"; }

.mdi-audio-video-off::before {
  content: "\F01E1"; }

.mdi-audiobook::before {
  content: "\F067"; }

.mdi-augmented-reality::before {
  content: "\F84F"; }

.mdi-auto-download::before {
  content: "\F03A9"; }

.mdi-auto-fix::before {
  content: "\F068"; }

.mdi-auto-upload::before {
  content: "\F069"; }

.mdi-autorenew::before {
  content: "\F06A"; }

.mdi-av-timer::before {
  content: "\F06B"; }

.mdi-aws::before {
  content: "\FDF2"; }

.mdi-axe::before {
  content: "\F8C7"; }

.mdi-axis::before {
  content: "\FD24"; }

.mdi-axis-arrow::before {
  content: "\FD25"; }

.mdi-axis-arrow-lock::before {
  content: "\FD26"; }

.mdi-axis-lock::before {
  content: "\FD27"; }

.mdi-axis-x-arrow::before {
  content: "\FD28"; }

.mdi-axis-x-arrow-lock::before {
  content: "\FD29"; }

.mdi-axis-x-rotate-clockwise::before {
  content: "\FD2A"; }

.mdi-axis-x-rotate-counterclockwise::before {
  content: "\FD2B"; }

.mdi-axis-x-y-arrow-lock::before {
  content: "\FD2C"; }

.mdi-axis-y-arrow::before {
  content: "\FD2D"; }

.mdi-axis-y-arrow-lock::before {
  content: "\FD2E"; }

.mdi-axis-y-rotate-clockwise::before {
  content: "\FD2F"; }

.mdi-axis-y-rotate-counterclockwise::before {
  content: "\FD30"; }

.mdi-axis-z-arrow::before {
  content: "\FD31"; }

.mdi-axis-z-arrow-lock::before {
  content: "\FD32"; }

.mdi-axis-z-rotate-clockwise::before {
  content: "\FD33"; }

.mdi-axis-z-rotate-counterclockwise::before {
  content: "\FD34"; }

.mdi-azure::before {
  content: "\F804"; }

.mdi-azure-devops::before {
  content: "\F0091"; }

.mdi-babel::before {
  content: "\FA24"; }

.mdi-baby::before {
  content: "\F06C"; }

.mdi-baby-bottle::before {
  content: "\FF56"; }

.mdi-baby-bottle-outline::before {
  content: "\FF57"; }

.mdi-baby-carriage::before {
  content: "\F68E"; }

.mdi-baby-carriage-off::before {
  content: "\FFC0"; }

.mdi-baby-face::before {
  content: "\FE99"; }

.mdi-baby-face-outline::before {
  content: "\FE9A"; }

.mdi-backburger::before {
  content: "\F06D"; }

.mdi-backspace::before {
  content: "\F06E"; }

.mdi-backspace-outline::before {
  content: "\FB38"; }

.mdi-backspace-reverse::before {
  content: "\FE9B"; }

.mdi-backspace-reverse-outline::before {
  content: "\FE9C"; }

.mdi-backup-restore::before {
  content: "\F06F"; }

.mdi-bacteria::before {
  content: "\FEF2"; }

.mdi-bacteria-outline::before {
  content: "\FEF3"; }

.mdi-badminton::before {
  content: "\F850"; }

.mdi-bag-carry-on::before {
  content: "\FF58"; }

.mdi-bag-carry-on-check::before {
  content: "\FD41"; }

.mdi-bag-carry-on-off::before {
  content: "\FF59"; }

.mdi-bag-checked::before {
  content: "\FF5A"; }

.mdi-bag-personal::before {
  content: "\FDF3"; }

.mdi-bag-personal-off::before {
  content: "\FDF4"; }

.mdi-bag-personal-off-outline::before {
  content: "\FDF5"; }

.mdi-bag-personal-outline::before {
  content: "\FDF6"; }

.mdi-baguette::before {
  content: "\FF5B"; }

.mdi-balloon::before {
  content: "\FA25"; }

.mdi-ballot::before {
  content: "\F9C8"; }

.mdi-ballot-outline::before {
  content: "\F9C9"; }

.mdi-ballot-recount::before {
  content: "\FC15"; }

.mdi-ballot-recount-outline::before {
  content: "\FC16"; }

.mdi-bandage::before {
  content: "\FD8B"; }

.mdi-bandcamp::before {
  content: "\F674"; }

.mdi-bank::before {
  content: "\F070"; }

.mdi-bank-minus::before {
  content: "\FD8C"; }

.mdi-bank-outline::before {
  content: "\FE9D"; }

.mdi-bank-plus::before {
  content: "\FD8D"; }

.mdi-bank-remove::before {
  content: "\FD8E"; }

.mdi-bank-transfer::before {
  content: "\FA26"; }

.mdi-bank-transfer-in::before {
  content: "\FA27"; }

.mdi-bank-transfer-out::before {
  content: "\FA28"; }

.mdi-barcode::before {
  content: "\F071"; }

.mdi-barcode-off::before {
  content: "\F0261"; }

.mdi-barcode-scan::before {
  content: "\F072"; }

.mdi-barley::before {
  content: "\F073"; }

.mdi-barley-off::before {
  content: "\FB39"; }

.mdi-barn::before {
  content: "\FB3A"; }

.mdi-barrel::before {
  content: "\F074"; }

.mdi-baseball::before {
  content: "\F851"; }

.mdi-baseball-bat::before {
  content: "\F852"; }

.mdi-basecamp::before {
  content: "\F075"; }

.mdi-bash::before {
  content: "\F01AE"; }

.mdi-basket::before {
  content: "\F076"; }

.mdi-basket-fill::before {
  content: "\F077"; }

.mdi-basket-outline::before {
  content: "\F01AC"; }

.mdi-basket-unfill::before {
  content: "\F078"; }

.mdi-basketball::before {
  content: "\F805"; }

.mdi-basketball-hoop::before {
  content: "\FC17"; }

.mdi-basketball-hoop-outline::before {
  content: "\FC18"; }

.mdi-bat::before {
  content: "\FB3B"; }

.mdi-battery::before {
  content: "\F079"; }

.mdi-battery-10::before {
  content: "\F07A"; }

.mdi-battery-10-bluetooth::before {
  content: "\F93D"; }

.mdi-battery-20::before {
  content: "\F07B"; }

.mdi-battery-20-bluetooth::before {
  content: "\F93E"; }

.mdi-battery-30::before {
  content: "\F07C"; }

.mdi-battery-30-bluetooth::before {
  content: "\F93F"; }

.mdi-battery-40::before {
  content: "\F07D"; }

.mdi-battery-40-bluetooth::before {
  content: "\F940"; }

.mdi-battery-50::before {
  content: "\F07E"; }

.mdi-battery-50-bluetooth::before {
  content: "\F941"; }

.mdi-battery-60::before {
  content: "\F07F"; }

.mdi-battery-60-bluetooth::before {
  content: "\F942"; }

.mdi-battery-70::before {
  content: "\F080"; }

.mdi-battery-70-bluetooth::before {
  content: "\F943"; }

.mdi-battery-80::before {
  content: "\F081"; }

.mdi-battery-80-bluetooth::before {
  content: "\F944"; }

.mdi-battery-90::before {
  content: "\F082"; }

.mdi-battery-90-bluetooth::before {
  content: "\F945"; }

.mdi-battery-alert::before {
  content: "\F083"; }

.mdi-battery-alert-bluetooth::before {
  content: "\F946"; }

.mdi-battery-alert-variant::before {
  content: "\F00F7"; }

.mdi-battery-alert-variant-outline::before {
  content: "\F00F8"; }

.mdi-battery-bluetooth::before {
  content: "\F947"; }

.mdi-battery-bluetooth-variant::before {
  content: "\F948"; }

.mdi-battery-charging::before {
  content: "\F084"; }

.mdi-battery-charging-10::before {
  content: "\F89B"; }

.mdi-battery-charging-100::before {
  content: "\F085"; }

.mdi-battery-charging-20::before {
  content: "\F086"; }

.mdi-battery-charging-30::before {
  content: "\F087"; }

.mdi-battery-charging-40::before {
  content: "\F088"; }

.mdi-battery-charging-50::before {
  content: "\F89C"; }

.mdi-battery-charging-60::before {
  content: "\F089"; }

.mdi-battery-charging-70::before {
  content: "\F89D"; }

.mdi-battery-charging-80::before {
  content: "\F08A"; }

.mdi-battery-charging-90::before {
  content: "\F08B"; }

.mdi-battery-charging-high::before {
  content: "\F02D1"; }

.mdi-battery-charging-low::before {
  content: "\F02CF"; }

.mdi-battery-charging-medium::before {
  content: "\F02D0"; }

.mdi-battery-charging-outline::before {
  content: "\F89E"; }

.mdi-battery-charging-wireless::before {
  content: "\F806"; }

.mdi-battery-charging-wireless-10::before {
  content: "\F807"; }

.mdi-battery-charging-wireless-20::before {
  content: "\F808"; }

.mdi-battery-charging-wireless-30::before {
  content: "\F809"; }

.mdi-battery-charging-wireless-40::before {
  content: "\F80A"; }

.mdi-battery-charging-wireless-50::before {
  content: "\F80B"; }

.mdi-battery-charging-wireless-60::before {
  content: "\F80C"; }

.mdi-battery-charging-wireless-70::before {
  content: "\F80D"; }

.mdi-battery-charging-wireless-80::before {
  content: "\F80E"; }

.mdi-battery-charging-wireless-90::before {
  content: "\F80F"; }

.mdi-battery-charging-wireless-alert::before {
  content: "\F810"; }

.mdi-battery-charging-wireless-outline::before {
  content: "\F811"; }

.mdi-battery-heart::before {
  content: "\F023A"; }

.mdi-battery-heart-outline::before {
  content: "\F023B"; }

.mdi-battery-heart-variant::before {
  content: "\F023C"; }

.mdi-battery-high::before {
  content: "\F02CE"; }

.mdi-battery-low::before {
  content: "\F02CC"; }

.mdi-battery-medium::before {
  content: "\F02CD"; }

.mdi-battery-minus::before {
  content: "\F08C"; }

.mdi-battery-negative::before {
  content: "\F08D"; }

.mdi-battery-off::before {
  content: "\F0288"; }

.mdi-battery-off-outline::before {
  content: "\F0289"; }

.mdi-battery-outline::before {
  content: "\F08E"; }

.mdi-battery-plus::before {
  content: "\F08F"; }

.mdi-battery-positive::before {
  content: "\F090"; }

.mdi-battery-unknown::before {
  content: "\F091"; }

.mdi-battery-unknown-bluetooth::before {
  content: "\F949"; }

.mdi-battlenet::before {
  content: "\FB3C"; }

.mdi-beach::before {
  content: "\F092"; }

.mdi-beaker::before {
  content: "\FCC6"; }

.mdi-beaker-alert::before {
  content: "\F0254"; }

.mdi-beaker-alert-outline::before {
  content: "\F0255"; }

.mdi-beaker-check::before {
  content: "\F0256"; }

.mdi-beaker-check-outline::before {
  content: "\F0257"; }

.mdi-beaker-minus::before {
  content: "\F0258"; }

.mdi-beaker-minus-outline::before {
  content: "\F0259"; }

.mdi-beaker-outline::before {
  content: "\F68F"; }

.mdi-beaker-plus::before {
  content: "\F025A"; }

.mdi-beaker-plus-outline::before {
  content: "\F025B"; }

.mdi-beaker-question::before {
  content: "\F025C"; }

.mdi-beaker-question-outline::before {
  content: "\F025D"; }

.mdi-beaker-remove::before {
  content: "\F025E"; }

.mdi-beaker-remove-outline::before {
  content: "\F025F"; }

.mdi-beats::before {
  content: "\F097"; }

.mdi-bed-double::before {
  content: "\F0092"; }

.mdi-bed-double-outline::before {
  content: "\F0093"; }

.mdi-bed-empty::before {
  content: "\F89F"; }

.mdi-bed-king::before {
  content: "\F0094"; }

.mdi-bed-king-outline::before {
  content: "\F0095"; }

.mdi-bed-queen::before {
  content: "\F0096"; }

.mdi-bed-queen-outline::before {
  content: "\F0097"; }

.mdi-bed-single::before {
  content: "\F0098"; }

.mdi-bed-single-outline::before {
  content: "\F0099"; }

.mdi-bee::before {
  content: "\FFC1"; }

.mdi-bee-flower::before {
  content: "\FFC2"; }

.mdi-beehive-outline::before {
  content: "\F00F9"; }

.mdi-beer::before {
  content: "\F098"; }

.mdi-beer-outline::before {
  content: "\F0337"; }

.mdi-behance::before {
  content: "\F099"; }

.mdi-bell::before {
  content: "\F09A"; }

.mdi-bell-alert::before {
  content: "\FD35"; }

.mdi-bell-alert-outline::before {
  content: "\FE9E"; }

.mdi-bell-check::before {
  content: "\F0210"; }

.mdi-bell-check-outline::before {
  content: "\F0211"; }

.mdi-bell-circle::before {
  content: "\FD36"; }

.mdi-bell-circle-outline::before {
  content: "\FD37"; }

.mdi-bell-off::before {
  content: "\F09B"; }

.mdi-bell-off-outline::before {
  content: "\FA90"; }

.mdi-bell-outline::before {
  content: "\F09C"; }

.mdi-bell-plus::before {
  content: "\F09D"; }

.mdi-bell-plus-outline::before {
  content: "\FA91"; }

.mdi-bell-ring::before {
  content: "\F09E"; }

.mdi-bell-ring-outline::before {
  content: "\F09F"; }

.mdi-bell-sleep::before {
  content: "\F0A0"; }

.mdi-bell-sleep-outline::before {
  content: "\FA92"; }

.mdi-beta::before {
  content: "\F0A1"; }

.mdi-betamax::before {
  content: "\F9CA"; }

.mdi-biathlon::before {
  content: "\FDF7"; }

.mdi-bible::before {
  content: "\F0A2"; }

.mdi-bicycle::before {
  content: "\F00C7"; }

.mdi-bicycle-basket::before {
  content: "\F0260"; }

.mdi-bike::before {
  content: "\F0A3"; }

.mdi-bike-fast::before {
  content: "\F014A"; }

.mdi-billboard::before {
  content: "\F0032"; }

.mdi-billiards::before {
  content: "\FB3D"; }

.mdi-billiards-rack::before {
  content: "\FB3E"; }

.mdi-bing::before {
  content: "\F0A4"; }

.mdi-binoculars::before {
  content: "\F0A5"; }

.mdi-bio::before {
  content: "\F0A6"; }

.mdi-biohazard::before {
  content: "\F0A7"; }

.mdi-bitbucket::before {
  content: "\F0A8"; }

.mdi-bitcoin::before {
  content: "\F812"; }

.mdi-black-mesa::before {
  content: "\F0A9"; }

.mdi-blackberry::before {
  content: "\F0AA"; }

.mdi-blender::before {
  content: "\FCC7"; }

.mdi-blender-software::before {
  content: "\F0AB"; }

.mdi-blinds::before {
  content: "\F0AC"; }

.mdi-blinds-open::before {
  content: "\F0033"; }

.mdi-block-helper::before {
  content: "\F0AD"; }

.mdi-blogger::before {
  content: "\F0AE"; }

.mdi-blood-bag::before {
  content: "\FCC8"; }

.mdi-bluetooth::before {
  content: "\F0AF"; }

.mdi-bluetooth-audio::before {
  content: "\F0B0"; }

.mdi-bluetooth-connect::before {
  content: "\F0B1"; }

.mdi-bluetooth-off::before {
  content: "\F0B2"; }

.mdi-bluetooth-settings::before {
  content: "\F0B3"; }

.mdi-bluetooth-transfer::before {
  content: "\F0B4"; }

.mdi-blur::before {
  content: "\F0B5"; }

.mdi-blur-linear::before {
  content: "\F0B6"; }

.mdi-blur-off::before {
  content: "\F0B7"; }

.mdi-blur-radial::before {
  content: "\F0B8"; }

.mdi-bolnisi-cross::before {
  content: "\FCC9"; }

.mdi-bolt::before {
  content: "\FD8F"; }

.mdi-bomb::before {
  content: "\F690"; }

.mdi-bomb-off::before {
  content: "\F6C4"; }

.mdi-bone::before {
  content: "\F0B9"; }

.mdi-book::before {
  content: "\F0BA"; }

.mdi-book-information-variant::before {
  content: "\F009A"; }

.mdi-book-lock::before {
  content: "\F799"; }

.mdi-book-lock-open::before {
  content: "\F79A"; }

.mdi-book-minus::before {
  content: "\F5D9"; }

.mdi-book-minus-multiple::before {
  content: "\FA93"; }

.mdi-book-multiple::before {
  content: "\F0BB"; }

.mdi-book-open::before {
  content: "\F0BD"; }

.mdi-book-open-outline::before {
  content: "\FB3F"; }

.mdi-book-open-page-variant::before {
  content: "\F5DA"; }

.mdi-book-open-variant::before {
  content: "\F0BE"; }

.mdi-book-outline::before {
  content: "\FB40"; }

.mdi-book-play::before {
  content: "\FE9F"; }

.mdi-book-play-outline::before {
  content: "\FEA0"; }

.mdi-book-plus::before {
  content: "\F5DB"; }

.mdi-book-plus-multiple::before {
  content: "\FA94"; }

.mdi-book-remove::before {
  content: "\FA96"; }

.mdi-book-remove-multiple::before {
  content: "\FA95"; }

.mdi-book-search::before {
  content: "\FEA1"; }

.mdi-book-search-outline::before {
  content: "\FEA2"; }

.mdi-book-variant::before {
  content: "\F0BF"; }

.mdi-book-variant-multiple::before {
  content: "\F0BC"; }

.mdi-bookmark::before {
  content: "\F0C0"; }

.mdi-bookmark-check::before {
  content: "\F0C1"; }

.mdi-bookmark-check-outline::before {
  content: "\F03A6"; }

.mdi-bookmark-minus::before {
  content: "\F9CB"; }

.mdi-bookmark-minus-outline::before {
  content: "\F9CC"; }

.mdi-bookmark-multiple::before {
  content: "\FDF8"; }

.mdi-bookmark-multiple-outline::before {
  content: "\FDF9"; }

.mdi-bookmark-music::before {
  content: "\F0C2"; }

.mdi-bookmark-music-outline::before {
  content: "\F03A4"; }

.mdi-bookmark-off::before {
  content: "\F9CD"; }

.mdi-bookmark-off-outline::before {
  content: "\F9CE"; }

.mdi-bookmark-outline::before {
  content: "\F0C3"; }

.mdi-bookmark-plus::before {
  content: "\F0C5"; }

.mdi-bookmark-plus-outline::before {
  content: "\F0C4"; }

.mdi-bookmark-remove::before {
  content: "\F0C6"; }

.mdi-bookmark-remove-outline::before {
  content: "\F03A5"; }

.mdi-bookshelf::before {
  content: "\F028A"; }

.mdi-boom-gate::before {
  content: "\FEA3"; }

.mdi-boom-gate-alert::before {
  content: "\FEA4"; }

.mdi-boom-gate-alert-outline::before {
  content: "\FEA5"; }

.mdi-boom-gate-down::before {
  content: "\FEA6"; }

.mdi-boom-gate-down-outline::before {
  content: "\FEA7"; }

.mdi-boom-gate-outline::before {
  content: "\FEA8"; }

.mdi-boom-gate-up::before {
  content: "\FEA9"; }

.mdi-boom-gate-up-outline::before {
  content: "\FEAA"; }

.mdi-boombox::before {
  content: "\F5DC"; }

.mdi-boomerang::before {
  content: "\F00FA"; }

.mdi-bootstrap::before {
  content: "\F6C5"; }

.mdi-border-all::before {
  content: "\F0C7"; }

.mdi-border-all-variant::before {
  content: "\F8A0"; }

.mdi-border-bottom::before {
  content: "\F0C8"; }

.mdi-border-bottom-variant::before {
  content: "\F8A1"; }

.mdi-border-color::before {
  content: "\F0C9"; }

.mdi-border-horizontal::before {
  content: "\F0CA"; }

.mdi-border-inside::before {
  content: "\F0CB"; }

.mdi-border-left::before {
  content: "\F0CC"; }

.mdi-border-left-variant::before {
  content: "\F8A2"; }

.mdi-border-none::before {
  content: "\F0CD"; }

.mdi-border-none-variant::before {
  content: "\F8A3"; }

.mdi-border-outside::before {
  content: "\F0CE"; }

.mdi-border-right::before {
  content: "\F0CF"; }

.mdi-border-right-variant::before {
  content: "\F8A4"; }

.mdi-border-style::before {
  content: "\F0D0"; }

.mdi-border-top::before {
  content: "\F0D1"; }

.mdi-border-top-variant::before {
  content: "\F8A5"; }

.mdi-border-vertical::before {
  content: "\F0D2"; }

.mdi-bottle-soda::before {
  content: "\F009B"; }

.mdi-bottle-soda-classic::before {
  content: "\F009C"; }

.mdi-bottle-soda-classic-outline::before {
  content: "\F038E"; }

.mdi-bottle-soda-outline::before {
  content: "\F009D"; }

.mdi-bottle-tonic::before {
  content: "\F0159"; }

.mdi-bottle-tonic-outline::before {
  content: "\F015A"; }

.mdi-bottle-tonic-plus::before {
  content: "\F015B"; }

.mdi-bottle-tonic-plus-outline::before {
  content: "\F015C"; }

.mdi-bottle-tonic-skull::before {
  content: "\F015D"; }

.mdi-bottle-tonic-skull-outline::before {
  content: "\F015E"; }

.mdi-bottle-wine::before {
  content: "\F853"; }

.mdi-bottle-wine-outline::before {
  content: "\F033B"; }

.mdi-bow-tie::before {
  content: "\F677"; }

.mdi-bowl::before {
  content: "\F617"; }

.mdi-bowling::before {
  content: "\F0D3"; }

.mdi-box::before {
  content: "\F0D4"; }

.mdi-box-cutter::before {
  content: "\F0D5"; }

.mdi-box-shadow::before {
  content: "\F637"; }

.mdi-boxing-glove::before {
  content: "\FB41"; }

.mdi-braille::before {
  content: "\F9CF"; }

.mdi-brain::before {
  content: "\F9D0"; }

.mdi-bread-slice::before {
  content: "\FCCA"; }

.mdi-bread-slice-outline::before {
  content: "\FCCB"; }

.mdi-bridge::before {
  content: "\F618"; }

.mdi-briefcase::before {
  content: "\F0D6"; }

.mdi-briefcase-account::before {
  content: "\FCCC"; }

.mdi-briefcase-account-outline::before {
  content: "\FCCD"; }

.mdi-briefcase-check::before {
  content: "\F0D7"; }

.mdi-briefcase-check-outline::before {
  content: "\F0349"; }

.mdi-briefcase-clock::before {
  content: "\F00FB"; }

.mdi-briefcase-clock-outline::before {
  content: "\F00FC"; }

.mdi-briefcase-download::before {
  content: "\F0D8"; }

.mdi-briefcase-download-outline::before {
  content: "\FC19"; }

.mdi-briefcase-edit::before {
  content: "\FA97"; }

.mdi-briefcase-edit-outline::before {
  content: "\FC1A"; }

.mdi-briefcase-minus::before {
  content: "\FA29"; }

.mdi-briefcase-minus-outline::before {
  content: "\FC1B"; }

.mdi-briefcase-outline::before {
  content: "\F813"; }

.mdi-briefcase-plus::before {
  content: "\FA2A"; }

.mdi-briefcase-plus-outline::before {
  content: "\FC1C"; }

.mdi-briefcase-remove::before {
  content: "\FA2B"; }

.mdi-briefcase-remove-outline::before {
  content: "\FC1D"; }

.mdi-briefcase-search::before {
  content: "\FA2C"; }

.mdi-briefcase-search-outline::before {
  content: "\FC1E"; }

.mdi-briefcase-upload::before {
  content: "\F0D9"; }

.mdi-briefcase-upload-outline::before {
  content: "\FC1F"; }

.mdi-brightness-1::before {
  content: "\F0DA"; }

.mdi-brightness-2::before {
  content: "\F0DB"; }

.mdi-brightness-3::before {
  content: "\F0DC"; }

.mdi-brightness-4::before {
  content: "\F0DD"; }

.mdi-brightness-5::before {
  content: "\F0DE"; }

.mdi-brightness-6::before {
  content: "\F0DF"; }

.mdi-brightness-7::before {
  content: "\F0E0"; }

.mdi-brightness-auto::before {
  content: "\F0E1"; }

.mdi-brightness-percent::before {
  content: "\FCCE"; }

.mdi-broom::before {
  content: "\F0E2"; }

.mdi-brush::before {
  content: "\F0E3"; }

.mdi-buddhism::before {
  content: "\F94A"; }

.mdi-buffer::before {
  content: "\F619"; }

.mdi-bug::before {
  content: "\F0E4"; }

.mdi-bug-check::before {
  content: "\FA2D"; }

.mdi-bug-check-outline::before {
  content: "\FA2E"; }

.mdi-bug-outline::before {
  content: "\FA2F"; }

.mdi-bugle::before {
  content: "\FD90"; }

.mdi-bulldozer::before {
  content: "\FB07"; }

.mdi-bullet::before {
  content: "\FCCF"; }

.mdi-bulletin-board::before {
  content: "\F0E5"; }

.mdi-bullhorn::before {
  content: "\F0E6"; }

.mdi-bullhorn-outline::before {
  content: "\FB08"; }

.mdi-bullseye::before {
  content: "\F5DD"; }

.mdi-bullseye-arrow::before {
  content: "\F8C8"; }

.mdi-bulma::before {
  content: "\F0312"; }

.mdi-bunk-bed::before {
  content: "\F032D"; }

.mdi-bus::before {
  content: "\F0E7"; }

.mdi-bus-alert::before {
  content: "\FA98"; }

.mdi-bus-articulated-end::before {
  content: "\F79B"; }

.mdi-bus-articulated-front::before {
  content: "\F79C"; }

.mdi-bus-clock::before {
  content: "\F8C9"; }

.mdi-bus-double-decker::before {
  content: "\F79D"; }

.mdi-bus-marker::before {
  content: "\F023D"; }

.mdi-bus-multiple::before {
  content: "\FF5C"; }

.mdi-bus-school::before {
  content: "\F79E"; }

.mdi-bus-side::before {
  content: "\F79F"; }

.mdi-bus-stop::before {
  content: "\F0034"; }

.mdi-bus-stop-covered::before {
  content: "\F0035"; }

.mdi-bus-stop-uncovered::before {
  content: "\F0036"; }

.mdi-cached::before {
  content: "\F0E8"; }

.mdi-cactus::before {
  content: "\FD91"; }

.mdi-cake::before {
  content: "\F0E9"; }

.mdi-cake-layered::before {
  content: "\F0EA"; }

.mdi-cake-variant::before {
  content: "\F0EB"; }

.mdi-calculator::before {
  content: "\F0EC"; }

.mdi-calculator-variant::before {
  content: "\FA99"; }

.mdi-calendar::before {
  content: "\F0ED"; }

.mdi-calendar-account::before {
  content: "\FEF4"; }

.mdi-calendar-account-outline::before {
  content: "\FEF5"; }

.mdi-calendar-alert::before {
  content: "\FA30"; }

.mdi-calendar-arrow-left::before {
  content: "\F015F"; }

.mdi-calendar-arrow-right::before {
  content: "\F0160"; }

.mdi-calendar-blank::before {
  content: "\F0EE"; }

.mdi-calendar-blank-multiple::before {
  content: "\F009E"; }

.mdi-calendar-blank-outline::before {
  content: "\FB42"; }

.mdi-calendar-check::before {
  content: "\F0EF"; }

.mdi-calendar-check-outline::before {
  content: "\FC20"; }

.mdi-calendar-clock::before {
  content: "\F0F0"; }

.mdi-calendar-edit::before {
  content: "\F8A6"; }

.mdi-calendar-export::before {
  content: "\FB09"; }

.mdi-calendar-heart::before {
  content: "\F9D1"; }

.mdi-calendar-import::before {
  content: "\FB0A"; }

.mdi-calendar-minus::before {
  content: "\FD38"; }

.mdi-calendar-month::before {
  content: "\FDFA"; }

.mdi-calendar-month-outline::before {
  content: "\FDFB"; }

.mdi-calendar-multiple::before {
  content: "\F0F1"; }

.mdi-calendar-multiple-check::before {
  content: "\F0F2"; }

.mdi-calendar-multiselect::before {
  content: "\FA31"; }

.mdi-calendar-outline::before {
  content: "\FB43"; }

.mdi-calendar-plus::before {
  content: "\F0F3"; }

.mdi-calendar-question::before {
  content: "\F691"; }

.mdi-calendar-range::before {
  content: "\F678"; }

.mdi-calendar-range-outline::before {
  content: "\FB44"; }

.mdi-calendar-remove::before {
  content: "\F0F4"; }

.mdi-calendar-remove-outline::before {
  content: "\FC21"; }

.mdi-calendar-repeat::before {
  content: "\FEAB"; }

.mdi-calendar-repeat-outline::before {
  content: "\FEAC"; }

.mdi-calendar-search::before {
  content: "\F94B"; }

.mdi-calendar-star::before {
  content: "\F9D2"; }

.mdi-calendar-text::before {
  content: "\F0F5"; }

.mdi-calendar-text-outline::before {
  content: "\FC22"; }

.mdi-calendar-today::before {
  content: "\F0F6"; }

.mdi-calendar-week::before {
  content: "\FA32"; }

.mdi-calendar-week-begin::before {
  content: "\FA33"; }

.mdi-calendar-weekend::before {
  content: "\FEF6"; }

.mdi-calendar-weekend-outline::before {
  content: "\FEF7"; }

.mdi-call-made::before {
  content: "\F0F7"; }

.mdi-call-merge::before {
  content: "\F0F8"; }

.mdi-call-missed::before {
  content: "\F0F9"; }

.mdi-call-received::before {
  content: "\F0FA"; }

.mdi-call-split::before {
  content: "\F0FB"; }

.mdi-camcorder::before {
  content: "\F0FC"; }

.mdi-camcorder-box::before {
  content: "\F0FD"; }

.mdi-camcorder-box-off::before {
  content: "\F0FE"; }

.mdi-camcorder-off::before {
  content: "\F0FF"; }

.mdi-camera::before {
  content: "\F100"; }

.mdi-camera-account::before {
  content: "\F8CA"; }

.mdi-camera-burst::before {
  content: "\F692"; }

.mdi-camera-control::before {
  content: "\FB45"; }

.mdi-camera-enhance::before {
  content: "\F101"; }

.mdi-camera-enhance-outline::before {
  content: "\FB46"; }

.mdi-camera-front::before {
  content: "\F102"; }

.mdi-camera-front-variant::before {
  content: "\F103"; }

.mdi-camera-gopro::before {
  content: "\F7A0"; }

.mdi-camera-image::before {
  content: "\F8CB"; }

.mdi-camera-iris::before {
  content: "\F104"; }

.mdi-camera-metering-center::before {
  content: "\F7A1"; }

.mdi-camera-metering-matrix::before {
  content: "\F7A2"; }

.mdi-camera-metering-partial::before {
  content: "\F7A3"; }

.mdi-camera-metering-spot::before {
  content: "\F7A4"; }

.mdi-camera-off::before {
  content: "\F5DF"; }

.mdi-camera-outline::before {
  content: "\FD39"; }

.mdi-camera-party-mode::before {
  content: "\F105"; }

.mdi-camera-plus::before {
  content: "\FEF8"; }

.mdi-camera-plus-outline::before {
  content: "\FEF9"; }

.mdi-camera-rear::before {
  content: "\F106"; }

.mdi-camera-rear-variant::before {
  content: "\F107"; }

.mdi-camera-retake::before {
  content: "\FDFC"; }

.mdi-camera-retake-outline::before {
  content: "\FDFD"; }

.mdi-camera-switch::before {
  content: "\F108"; }

.mdi-camera-timer::before {
  content: "\F109"; }

.mdi-camera-wireless::before {
  content: "\FD92"; }

.mdi-camera-wireless-outline::before {
  content: "\FD93"; }

.mdi-campfire::before {
  content: "\FEFA"; }

.mdi-cancel::before {
  content: "\F739"; }

.mdi-candle::before {
  content: "\F5E2"; }

.mdi-candycane::before {
  content: "\F10A"; }

.mdi-cannabis::before {
  content: "\F7A5"; }

.mdi-caps-lock::before {
  content: "\FA9A"; }

.mdi-car::before {
  content: "\F10B"; }

.mdi-car-2-plus::before {
  content: "\F0037"; }

.mdi-car-3-plus::before {
  content: "\F0038"; }

.mdi-car-back::before {
  content: "\FDFE"; }

.mdi-car-battery::before {
  content: "\F10C"; }

.mdi-car-brake-abs::before {
  content: "\FC23"; }

.mdi-car-brake-alert::before {
  content: "\FC24"; }

.mdi-car-brake-hold::before {
  content: "\FD3A"; }

.mdi-car-brake-parking::before {
  content: "\FD3B"; }

.mdi-car-brake-retarder::before {
  content: "\F0039"; }

.mdi-car-child-seat::before {
  content: "\FFC3"; }

.mdi-car-clutch::before {
  content: "\F003A"; }

.mdi-car-connected::before {
  content: "\F10D"; }

.mdi-car-convertible::before {
  content: "\F7A6"; }

.mdi-car-coolant-level::before {
  content: "\F003B"; }

.mdi-car-cruise-control::before {
  content: "\FD3C"; }

.mdi-car-defrost-front::before {
  content: "\FD3D"; }

.mdi-car-defrost-rear::before {
  content: "\FD3E"; }

.mdi-car-door::before {
  content: "\FB47"; }

.mdi-car-door-lock::before {
  content: "\F00C8"; }

.mdi-car-electric::before {
  content: "\FB48"; }

.mdi-car-esp::before {
  content: "\FC25"; }

.mdi-car-estate::before {
  content: "\F7A7"; }

.mdi-car-hatchback::before {
  content: "\F7A8"; }

.mdi-car-info::before {
  content: "\F01E9"; }

.mdi-car-key::before {
  content: "\FB49"; }

.mdi-car-light-dimmed::before {
  content: "\FC26"; }

.mdi-car-light-fog::before {
  content: "\FC27"; }

.mdi-car-light-high::before {
  content: "\FC28"; }

.mdi-car-limousine::before {
  content: "\F8CC"; }

.mdi-car-multiple::before {
  content: "\FB4A"; }

.mdi-car-off::before {
  content: "\FDFF"; }

.mdi-car-parking-lights::before {
  content: "\FD3F"; }

.mdi-car-pickup::before {
  content: "\F7A9"; }

.mdi-car-seat::before {
  content: "\FFC4"; }

.mdi-car-seat-cooler::before {
  content: "\FFC5"; }

.mdi-car-seat-heater::before {
  content: "\FFC6"; }

.mdi-car-shift-pattern::before {
  content: "\FF5D"; }

.mdi-car-side::before {
  content: "\F7AA"; }

.mdi-car-sports::before {
  content: "\F7AB"; }

.mdi-car-tire-alert::before {
  content: "\FC29"; }

.mdi-car-traction-control::before {
  content: "\FD40"; }

.mdi-car-turbocharger::before {
  content: "\F003C"; }

.mdi-car-wash::before {
  content: "\F10E"; }

.mdi-car-windshield::before {
  content: "\F003D"; }

.mdi-car-windshield-outline::before {
  content: "\F003E"; }

.mdi-caravan::before {
  content: "\F7AC"; }

.mdi-card::before {
  content: "\FB4B"; }

.mdi-card-bulleted::before {
  content: "\FB4C"; }

.mdi-card-bulleted-off::before {
  content: "\FB4D"; }

.mdi-card-bulleted-off-outline::before {
  content: "\FB4E"; }

.mdi-card-bulleted-outline::before {
  content: "\FB4F"; }

.mdi-card-bulleted-settings::before {
  content: "\FB50"; }

.mdi-card-bulleted-settings-outline::before {
  content: "\FB51"; }

.mdi-card-outline::before {
  content: "\FB52"; }

.mdi-card-plus::before {
  content: "\F022A"; }

.mdi-card-plus-outline::before {
  content: "\F022B"; }

.mdi-card-search::before {
  content: "\F009F"; }

.mdi-card-search-outline::before {
  content: "\F00A0"; }

.mdi-card-text::before {
  content: "\FB53"; }

.mdi-card-text-outline::before {
  content: "\FB54"; }

.mdi-cards::before {
  content: "\F638"; }

.mdi-cards-club::before {
  content: "\F8CD"; }

.mdi-cards-diamond::before {
  content: "\F8CE"; }

.mdi-cards-diamond-outline::before {
  content: "\F003F"; }

.mdi-cards-heart::before {
  content: "\F8CF"; }

.mdi-cards-outline::before {
  content: "\F639"; }

.mdi-cards-playing-outline::before {
  content: "\F63A"; }

.mdi-cards-spade::before {
  content: "\F8D0"; }

.mdi-cards-variant::before {
  content: "\F6C6"; }

.mdi-carrot::before {
  content: "\F10F"; }

.mdi-cart::before {
  content: "\F110"; }

.mdi-cart-arrow-down::before {
  content: "\FD42"; }

.mdi-cart-arrow-right::before {
  content: "\FC2A"; }

.mdi-cart-arrow-up::before {
  content: "\FD43"; }

.mdi-cart-minus::before {
  content: "\FD44"; }

.mdi-cart-off::before {
  content: "\F66B"; }

.mdi-cart-outline::before {
  content: "\F111"; }

.mdi-cart-plus::before {
  content: "\F112"; }

.mdi-cart-remove::before {
  content: "\FD45"; }

.mdi-case-sensitive-alt::before {
  content: "\F113"; }

.mdi-cash::before {
  content: "\F114"; }

.mdi-cash-100::before {
  content: "\F115"; }

.mdi-cash-marker::before {
  content: "\FD94"; }

.mdi-cash-minus::before {
  content: "\F028B"; }

.mdi-cash-multiple::before {
  content: "\F116"; }

.mdi-cash-plus::before {
  content: "\F028C"; }

.mdi-cash-refund::before {
  content: "\FA9B"; }

.mdi-cash-register::before {
  content: "\FCD0"; }

.mdi-cash-remove::before {
  content: "\F028D"; }

.mdi-cash-usd::before {
  content: "\F01A1"; }

.mdi-cash-usd-outline::before {
  content: "\F117"; }

.mdi-cassette::before {
  content: "\F9D3"; }

.mdi-cast::before {
  content: "\F118"; }

.mdi-cast-audio::before {
  content: "\F0040"; }

.mdi-cast-connected::before {
  content: "\F119"; }

.mdi-cast-education::before {
  content: "\FE6D"; }

.mdi-cast-off::before {
  content: "\F789"; }

.mdi-castle::before {
  content: "\F11A"; }

.mdi-cat::before {
  content: "\F11B"; }

.mdi-cctv::before {
  content: "\F7AD"; }

.mdi-ceiling-light::before {
  content: "\F768"; }

.mdi-cellphone::before {
  content: "\F11C"; }

.mdi-cellphone-android::before {
  content: "\F11D"; }

.mdi-cellphone-arrow-down::before {
  content: "\F9D4"; }

.mdi-cellphone-basic::before {
  content: "\F11E"; }

.mdi-cellphone-dock::before {
  content: "\F11F"; }

.mdi-cellphone-erase::before {
  content: "\F94C"; }

.mdi-cellphone-information::before {
  content: "\FF5E"; }

.mdi-cellphone-iphone::before {
  content: "\F120"; }

.mdi-cellphone-key::before {
  content: "\F94D"; }

.mdi-cellphone-link::before {
  content: "\F121"; }

.mdi-cellphone-link-off::before {
  content: "\F122"; }

.mdi-cellphone-lock::before {
  content: "\F94E"; }

.mdi-cellphone-message::before {
  content: "\F8D2"; }

.mdi-cellphone-message-off::before {
  content: "\F00FD"; }

.mdi-cellphone-nfc::before {
  content: "\FEAD"; }

.mdi-cellphone-nfc-off::before {
  content: "\F0303"; }

.mdi-cellphone-off::before {
  content: "\F94F"; }

.mdi-cellphone-play::before {
  content: "\F0041"; }

.mdi-cellphone-screenshot::before {
  content: "\FA34"; }

.mdi-cellphone-settings::before {
  content: "\F123"; }

.mdi-cellphone-settings-variant::before {
  content: "\F950"; }

.mdi-cellphone-sound::before {
  content: "\F951"; }

.mdi-cellphone-text::before {
  content: "\F8D1"; }

.mdi-cellphone-wireless::before {
  content: "\F814"; }

.mdi-celtic-cross::before {
  content: "\FCD1"; }

.mdi-centos::before {
  content: "\F0145"; }

.mdi-certificate::before {
  content: "\F124"; }

.mdi-certificate-outline::before {
  content: "\F01B3"; }

.mdi-chair-rolling::before {
  content: "\FFBA"; }

.mdi-chair-school::before {
  content: "\F125"; }

.mdi-charity::before {
  content: "\FC2B"; }

.mdi-chart-arc::before {
  content: "\F126"; }

.mdi-chart-areaspline::before {
  content: "\F127"; }

.mdi-chart-areaspline-variant::before {
  content: "\FEAE"; }

.mdi-chart-bar::before {
  content: "\F128"; }

.mdi-chart-bar-stacked::before {
  content: "\F769"; }

.mdi-chart-bell-curve::before {
  content: "\FC2C"; }

.mdi-chart-bell-curve-cumulative::before {
  content: "\FFC7"; }

.mdi-chart-bubble::before {
  content: "\F5E3"; }

.mdi-chart-donut::before {
  content: "\F7AE"; }

.mdi-chart-donut-variant::before {
  content: "\F7AF"; }

.mdi-chart-gantt::before {
  content: "\F66C"; }

.mdi-chart-histogram::before {
  content: "\F129"; }

.mdi-chart-line::before {
  content: "\F12A"; }

.mdi-chart-line-stacked::before {
  content: "\F76A"; }

.mdi-chart-line-variant::before {
  content: "\F7B0"; }

.mdi-chart-multiline::before {
  content: "\F8D3"; }

.mdi-chart-multiple::before {
  content: "\F023E"; }

.mdi-chart-pie::before {
  content: "\F12B"; }

.mdi-chart-ppf::before {
  content: "\F03AB"; }

.mdi-chart-scatter-plot::before {
  content: "\FEAF"; }

.mdi-chart-scatter-plot-hexbin::before {
  content: "\F66D"; }

.mdi-chart-snakey::before {
  content: "\F020A"; }

.mdi-chart-snakey-variant::before {
  content: "\F020B"; }

.mdi-chart-timeline::before {
  content: "\F66E"; }

.mdi-chart-timeline-variant::before {
  content: "\FEB0"; }

.mdi-chart-tree::before {
  content: "\FEB1"; }

.mdi-chat::before {
  content: "\FB55"; }

.mdi-chat-alert::before {
  content: "\FB56"; }

.mdi-chat-alert-outline::before {
  content: "\F02F4"; }

.mdi-chat-outline::before {
  content: "\FEFB"; }

.mdi-chat-processing::before {
  content: "\FB57"; }

.mdi-chat-processing-outline::before {
  content: "\F02F5"; }

.mdi-chat-sleep::before {
  content: "\F02FC"; }

.mdi-chat-sleep-outline::before {
  content: "\F02FD"; }

.mdi-check::before {
  content: "\F12C"; }

.mdi-check-all::before {
  content: "\F12D"; }

.mdi-check-bold::before {
  content: "\FE6E"; }

.mdi-check-box-multiple-outline::before {
  content: "\FC2D"; }

.mdi-check-box-outline::before {
  content: "\FC2E"; }

.mdi-check-circle::before {
  content: "\F5E0"; }

.mdi-check-circle-outline::before {
  content: "\F5E1"; }

.mdi-check-decagram::before {
  content: "\F790"; }

.mdi-check-network::before {
  content: "\FC2F"; }

.mdi-check-network-outline::before {
  content: "\FC30"; }

.mdi-check-outline::before {
  content: "\F854"; }

.mdi-check-underline::before {
  content: "\FE70"; }

.mdi-check-underline-circle::before {
  content: "\FE71"; }

.mdi-check-underline-circle-outline::before {
  content: "\FE72"; }

.mdi-checkbook::before {
  content: "\FA9C"; }

.mdi-checkbox-blank::before {
  content: "\F12E"; }

.mdi-checkbox-blank-circle::before {
  content: "\F12F"; }

.mdi-checkbox-blank-circle-outline::before {
  content: "\F130"; }

.mdi-checkbox-blank-off::before {
  content: "\F0317"; }

.mdi-checkbox-blank-off-outline::before {
  content: "\F0318"; }

.mdi-checkbox-blank-outline::before {
  content: "\F131"; }

.mdi-checkbox-intermediate::before {
  content: "\F855"; }

.mdi-checkbox-marked::before {
  content: "\F132"; }

.mdi-checkbox-marked-circle::before {
  content: "\F133"; }

.mdi-checkbox-marked-circle-outline::before {
  content: "\F134"; }

.mdi-checkbox-marked-outline::before {
  content: "\F135"; }

.mdi-checkbox-multiple-blank::before {
  content: "\F136"; }

.mdi-checkbox-multiple-blank-circle::before {
  content: "\F63B"; }

.mdi-checkbox-multiple-blank-circle-outline::before {
  content: "\F63C"; }

.mdi-checkbox-multiple-blank-outline::before {
  content: "\F137"; }

.mdi-checkbox-multiple-marked::before {
  content: "\F138"; }

.mdi-checkbox-multiple-marked-circle::before {
  content: "\F63D"; }

.mdi-checkbox-multiple-marked-circle-outline::before {
  content: "\F63E"; }

.mdi-checkbox-multiple-marked-outline::before {
  content: "\F139"; }

.mdi-checkerboard::before {
  content: "\F13A"; }

.mdi-checkerboard-minus::before {
  content: "\F022D"; }

.mdi-checkerboard-plus::before {
  content: "\F022C"; }

.mdi-checkerboard-remove::before {
  content: "\F022E"; }

.mdi-cheese::before {
  content: "\F02E4"; }

.mdi-chef-hat::before {
  content: "\FB58"; }

.mdi-chemical-weapon::before {
  content: "\F13B"; }

.mdi-chess-bishop::before {
  content: "\F85B"; }

.mdi-chess-king::before {
  content: "\F856"; }

.mdi-chess-knight::before {
  content: "\F857"; }

.mdi-chess-pawn::before {
  content: "\F858"; }

.mdi-chess-queen::before {
  content: "\F859"; }

.mdi-chess-rook::before {
  content: "\F85A"; }

.mdi-chevron-double-down::before {
  content: "\F13C"; }

.mdi-chevron-double-left::before {
  content: "\F13D"; }

.mdi-chevron-double-right::before {
  content: "\F13E"; }

.mdi-chevron-double-up::before {
  content: "\F13F"; }

.mdi-chevron-down::before {
  content: "\F140"; }

.mdi-chevron-down-box::before {
  content: "\F9D5"; }

.mdi-chevron-down-box-outline::before {
  content: "\F9D6"; }

.mdi-chevron-down-circle::before {
  content: "\FB0B"; }

.mdi-chevron-down-circle-outline::before {
  content: "\FB0C"; }

.mdi-chevron-left::before {
  content: "\F141"; }

.mdi-chevron-left-box::before {
  content: "\F9D7"; }

.mdi-chevron-left-box-outline::before {
  content: "\F9D8"; }

.mdi-chevron-left-circle::before {
  content: "\FB0D"; }

.mdi-chevron-left-circle-outline::before {
  content: "\FB0E"; }

.mdi-chevron-right::before {
  content: "\F142"; }

.mdi-chevron-right-box::before {
  content: "\F9D9"; }

.mdi-chevron-right-box-outline::before {
  content: "\F9DA"; }

.mdi-chevron-right-circle::before {
  content: "\FB0F"; }

.mdi-chevron-right-circle-outline::before {
  content: "\FB10"; }

.mdi-chevron-triple-down::before {
  content: "\FD95"; }

.mdi-chevron-triple-left::before {
  content: "\FD96"; }

.mdi-chevron-triple-right::before {
  content: "\FD97"; }

.mdi-chevron-triple-up::before {
  content: "\FD98"; }

.mdi-chevron-up::before {
  content: "\F143"; }

.mdi-chevron-up-box::before {
  content: "\F9DB"; }

.mdi-chevron-up-box-outline::before {
  content: "\F9DC"; }

.mdi-chevron-up-circle::before {
  content: "\FB11"; }

.mdi-chevron-up-circle-outline::before {
  content: "\FB12"; }

.mdi-chili-hot::before {
  content: "\F7B1"; }

.mdi-chili-medium::before {
  content: "\F7B2"; }

.mdi-chili-mild::before {
  content: "\F7B3"; }

.mdi-chip::before {
  content: "\F61A"; }

.mdi-christianity::before {
  content: "\F952"; }

.mdi-christianity-outline::before {
  content: "\FCD2"; }

.mdi-church::before {
  content: "\F144"; }

.mdi-cigar::before {
  content: "\F01B4"; }

.mdi-circle::before {
  content: "\F764"; }

.mdi-circle-double::before {
  content: "\FEB2"; }

.mdi-circle-edit-outline::before {
  content: "\F8D4"; }

.mdi-circle-expand::before {
  content: "\FEB3"; }

.mdi-circle-medium::before {
  content: "\F9DD"; }

.mdi-circle-off-outline::before {
  content: "\F00FE"; }

.mdi-circle-outline::before {
  content: "\F765"; }

.mdi-circle-slice-1::before {
  content: "\FA9D"; }

.mdi-circle-slice-2::before {
  content: "\FA9E"; }

.mdi-circle-slice-3::before {
  content: "\FA9F"; }

.mdi-circle-slice-4::before {
  content: "\FAA0"; }

.mdi-circle-slice-5::before {
  content: "\FAA1"; }

.mdi-circle-slice-6::before {
  content: "\FAA2"; }

.mdi-circle-slice-7::before {
  content: "\FAA3"; }

.mdi-circle-slice-8::before {
  content: "\FAA4"; }

.mdi-circle-small::before {
  content: "\F9DE"; }

.mdi-circular-saw::before {
  content: "\FE73"; }

.mdi-cisco-webex::before {
  content: "\F145"; }

.mdi-city::before {
  content: "\F146"; }

.mdi-city-variant::before {
  content: "\FA35"; }

.mdi-city-variant-outline::before {
  content: "\FA36"; }

.mdi-clipboard::before {
  content: "\F147"; }

.mdi-clipboard-account::before {
  content: "\F148"; }

.mdi-clipboard-account-outline::before {
  content: "\FC31"; }

.mdi-clipboard-alert::before {
  content: "\F149"; }

.mdi-clipboard-alert-outline::before {
  content: "\FCD3"; }

.mdi-clipboard-arrow-down::before {
  content: "\F14A"; }

.mdi-clipboard-arrow-down-outline::before {
  content: "\FC32"; }

.mdi-clipboard-arrow-left::before {
  content: "\F14B"; }

.mdi-clipboard-arrow-left-outline::before {
  content: "\FCD4"; }

.mdi-clipboard-arrow-right::before {
  content: "\FCD5"; }

.mdi-clipboard-arrow-right-outline::before {
  content: "\FCD6"; }

.mdi-clipboard-arrow-up::before {
  content: "\FC33"; }

.mdi-clipboard-arrow-up-outline::before {
  content: "\FC34"; }

.mdi-clipboard-check::before {
  content: "\F14C"; }

.mdi-clipboard-check-multiple::before {
  content: "\F028E"; }

.mdi-clipboard-check-multiple-outline::before {
  content: "\F028F"; }

.mdi-clipboard-check-outline::before {
  content: "\F8A7"; }

.mdi-clipboard-file::before {
  content: "\F0290"; }

.mdi-clipboard-file-outline::before {
  content: "\F0291"; }

.mdi-clipboard-flow::before {
  content: "\F6C7"; }

.mdi-clipboard-flow-outline::before {
  content: "\F0142"; }

.mdi-clipboard-list::before {
  content: "\F00FF"; }

.mdi-clipboard-list-outline::before {
  content: "\F0100"; }

.mdi-clipboard-multiple::before {
  content: "\F0292"; }

.mdi-clipboard-multiple-outline::before {
  content: "\F0293"; }

.mdi-clipboard-outline::before {
  content: "\F14D"; }

.mdi-clipboard-play::before {
  content: "\FC35"; }

.mdi-clipboard-play-multiple::before {
  content: "\F0294"; }

.mdi-clipboard-play-multiple-outline::before {
  content: "\F0295"; }

.mdi-clipboard-play-outline::before {
  content: "\FC36"; }

.mdi-clipboard-plus::before {
  content: "\F750"; }

.mdi-clipboard-plus-outline::before {
  content: "\F034A"; }

.mdi-clipboard-pulse::before {
  content: "\F85C"; }

.mdi-clipboard-pulse-outline::before {
  content: "\F85D"; }

.mdi-clipboard-text::before {
  content: "\F14E"; }

.mdi-clipboard-text-multiple::before {
  content: "\F0296"; }

.mdi-clipboard-text-multiple-outline::before {
  content: "\F0297"; }

.mdi-clipboard-text-outline::before {
  content: "\FA37"; }

.mdi-clipboard-text-play::before {
  content: "\FC37"; }

.mdi-clipboard-text-play-outline::before {
  content: "\FC38"; }

.mdi-clippy::before {
  content: "\F14F"; }

.mdi-clock::before {
  content: "\F953"; }

.mdi-clock-alert::before {
  content: "\F954"; }

.mdi-clock-alert-outline::before {
  content: "\F5CE"; }

.mdi-clock-check::before {
  content: "\FFC8"; }

.mdi-clock-check-outline::before {
  content: "\FFC9"; }

.mdi-clock-digital::before {
  content: "\FEB4"; }

.mdi-clock-end::before {
  content: "\F151"; }

.mdi-clock-fast::before {
  content: "\F152"; }

.mdi-clock-in::before {
  content: "\F153"; }

.mdi-clock-out::before {
  content: "\F154"; }

.mdi-clock-outline::before {
  content: "\F150"; }

.mdi-clock-start::before {
  content: "\F155"; }

.mdi-close::before {
  content: "\F156"; }

.mdi-close-box::before {
  content: "\F157"; }

.mdi-close-box-multiple::before {
  content: "\FC39"; }

.mdi-close-box-multiple-outline::before {
  content: "\FC3A"; }

.mdi-close-box-outline::before {
  content: "\F158"; }

.mdi-close-circle::before {
  content: "\F159"; }

.mdi-close-circle-outline::before {
  content: "\F15A"; }

.mdi-close-network::before {
  content: "\F15B"; }

.mdi-close-network-outline::before {
  content: "\FC3B"; }

.mdi-close-octagon::before {
  content: "\F15C"; }

.mdi-close-octagon-outline::before {
  content: "\F15D"; }

.mdi-close-outline::before {
  content: "\F6C8"; }

.mdi-closed-caption::before {
  content: "\F15E"; }

.mdi-closed-caption-outline::before {
  content: "\FD99"; }

.mdi-cloud::before {
  content: "\F15F"; }

.mdi-cloud-alert::before {
  content: "\F9DF"; }

.mdi-cloud-braces::before {
  content: "\F7B4"; }

.mdi-cloud-check::before {
  content: "\F160"; }

.mdi-cloud-check-outline::before {
  content: "\F02F7"; }

.mdi-cloud-circle::before {
  content: "\F161"; }

.mdi-cloud-download::before {
  content: "\F162"; }

.mdi-cloud-download-outline::before {
  content: "\FB59"; }

.mdi-cloud-lock::before {
  content: "\F021C"; }

.mdi-cloud-lock-outline::before {
  content: "\F021D"; }

.mdi-cloud-off-outline::before {
  content: "\F164"; }

.mdi-cloud-outline::before {
  content: "\F163"; }

.mdi-cloud-print::before {
  content: "\F165"; }

.mdi-cloud-print-outline::before {
  content: "\F166"; }

.mdi-cloud-question::before {
  content: "\FA38"; }

.mdi-cloud-search::before {
  content: "\F955"; }

.mdi-cloud-search-outline::before {
  content: "\F956"; }

.mdi-cloud-sync::before {
  content: "\F63F"; }

.mdi-cloud-sync-outline::before {
  content: "\F0301"; }

.mdi-cloud-tags::before {
  content: "\F7B5"; }

.mdi-cloud-upload::before {
  content: "\F167"; }

.mdi-cloud-upload-outline::before {
  content: "\FB5A"; }

.mdi-clover::before {
  content: "\F815"; }

.mdi-coach-lamp::before {
  content: "\F0042"; }

.mdi-coat-rack::before {
  content: "\F00C9"; }

.mdi-code-array::before {
  content: "\F168"; }

.mdi-code-braces::before {
  content: "\F169"; }

.mdi-code-braces-box::before {
  content: "\F0101"; }

.mdi-code-brackets::before {
  content: "\F16A"; }

.mdi-code-equal::before {
  content: "\F16B"; }

.mdi-code-greater-than::before {
  content: "\F16C"; }

.mdi-code-greater-than-or-equal::before {
  content: "\F16D"; }

.mdi-code-less-than::before {
  content: "\F16E"; }

.mdi-code-less-than-or-equal::before {
  content: "\F16F"; }

.mdi-code-not-equal::before {
  content: "\F170"; }

.mdi-code-not-equal-variant::before {
  content: "\F171"; }

.mdi-code-parentheses::before {
  content: "\F172"; }

.mdi-code-parentheses-box::before {
  content: "\F0102"; }

.mdi-code-string::before {
  content: "\F173"; }

.mdi-code-tags::before {
  content: "\F174"; }

.mdi-code-tags-check::before {
  content: "\F693"; }

.mdi-codepen::before {
  content: "\F175"; }

.mdi-coffee::before {
  content: "\F176"; }

.mdi-coffee-maker::before {
  content: "\F00CA"; }

.mdi-coffee-off::before {
  content: "\FFCA"; }

.mdi-coffee-off-outline::before {
  content: "\FFCB"; }

.mdi-coffee-outline::before {
  content: "\F6C9"; }

.mdi-coffee-to-go::before {
  content: "\F177"; }

.mdi-coffee-to-go-outline::before {
  content: "\F0339"; }

.mdi-coffin::before {
  content: "\FB5B"; }

.mdi-cog-clockwise::before {
  content: "\F0208"; }

.mdi-cog-counterclockwise::before {
  content: "\F0209"; }

.mdi-cogs::before {
  content: "\F8D5"; }

.mdi-coin::before {
  content: "\F0196"; }

.mdi-coin-outline::before {
  content: "\F178"; }

.mdi-coins::before {
  content: "\F694"; }

.mdi-collage::before {
  content: "\F640"; }

.mdi-collapse-all::before {
  content: "\FAA5"; }

.mdi-collapse-all-outline::before {
  content: "\FAA6"; }

.mdi-color-helper::before {
  content: "\F179"; }

.mdi-comma::before {
  content: "\FE74"; }

.mdi-comma-box::before {
  content: "\FE75"; }

.mdi-comma-box-outline::before {
  content: "\FE76"; }

.mdi-comma-circle::before {
  content: "\FE77"; }

.mdi-comma-circle-outline::before {
  content: "\FE78"; }

.mdi-comment::before {
  content: "\F17A"; }

.mdi-comment-account::before {
  content: "\F17B"; }

.mdi-comment-account-outline::before {
  content: "\F17C"; }

.mdi-comment-alert::before {
  content: "\F17D"; }

.mdi-comment-alert-outline::before {
  content: "\F17E"; }

.mdi-comment-arrow-left::before {
  content: "\F9E0"; }

.mdi-comment-arrow-left-outline::before {
  content: "\F9E1"; }

.mdi-comment-arrow-right::before {
  content: "\F9E2"; }

.mdi-comment-arrow-right-outline::before {
  content: "\F9E3"; }

.mdi-comment-check::before {
  content: "\F17F"; }

.mdi-comment-check-outline::before {
  content: "\F180"; }

.mdi-comment-edit::before {
  content: "\F01EA"; }

.mdi-comment-edit-outline::before {
  content: "\F02EF"; }

.mdi-comment-eye::before {
  content: "\FA39"; }

.mdi-comment-eye-outline::before {
  content: "\FA3A"; }

.mdi-comment-multiple::before {
  content: "\F85E"; }

.mdi-comment-multiple-outline::before {
  content: "\F181"; }

.mdi-comment-outline::before {
  content: "\F182"; }

.mdi-comment-plus::before {
  content: "\F9E4"; }

.mdi-comment-plus-outline::before {
  content: "\F183"; }

.mdi-comment-processing::before {
  content: "\F184"; }

.mdi-comment-processing-outline::before {
  content: "\F185"; }

.mdi-comment-question::before {
  content: "\F816"; }

.mdi-comment-question-outline::before {
  content: "\F186"; }

.mdi-comment-quote::before {
  content: "\F0043"; }

.mdi-comment-quote-outline::before {
  content: "\F0044"; }

.mdi-comment-remove::before {
  content: "\F5DE"; }

.mdi-comment-remove-outline::before {
  content: "\F187"; }

.mdi-comment-search::before {
  content: "\FA3B"; }

.mdi-comment-search-outline::before {
  content: "\FA3C"; }

.mdi-comment-text::before {
  content: "\F188"; }

.mdi-comment-text-multiple::before {
  content: "\F85F"; }

.mdi-comment-text-multiple-outline::before {
  content: "\F860"; }

.mdi-comment-text-outline::before {
  content: "\F189"; }

.mdi-compare::before {
  content: "\F18A"; }

.mdi-compass::before {
  content: "\F18B"; }

.mdi-compass-off::before {
  content: "\FB5C"; }

.mdi-compass-off-outline::before {
  content: "\FB5D"; }

.mdi-compass-outline::before {
  content: "\F18C"; }

.mdi-compass-rose::before {
  content: "\F03AD"; }

.mdi-concourse-ci::before {
  content: "\F00CB"; }

.mdi-console::before {
  content: "\F18D"; }

.mdi-console-line::before {
  content: "\F7B6"; }

.mdi-console-network::before {
  content: "\F8A8"; }

.mdi-console-network-outline::before {
  content: "\FC3C"; }

.mdi-consolidate::before {
  content: "\F0103"; }

.mdi-contact-mail::before {
  content: "\F18E"; }

.mdi-contact-mail-outline::before {
  content: "\FEB5"; }

.mdi-contact-phone::before {
  content: "\FEB6"; }

.mdi-contact-phone-outline::before {
  content: "\FEB7"; }

.mdi-contactless-payment::before {
  content: "\FD46"; }

.mdi-contacts::before {
  content: "\F6CA"; }

.mdi-contain::before {
  content: "\FA3D"; }

.mdi-contain-end::before {
  content: "\FA3E"; }

.mdi-contain-start::before {
  content: "\FA3F"; }

.mdi-content-copy::before {
  content: "\F18F"; }

.mdi-content-cut::before {
  content: "\F190"; }

.mdi-content-duplicate::before {
  content: "\F191"; }

.mdi-content-paste::before {
  content: "\F192"; }

.mdi-content-save::before {
  content: "\F193"; }

.mdi-content-save-alert::before {
  content: "\FF5F"; }

.mdi-content-save-alert-outline::before {
  content: "\FF60"; }

.mdi-content-save-all::before {
  content: "\F194"; }

.mdi-content-save-all-outline::before {
  content: "\FF61"; }

.mdi-content-save-edit::before {
  content: "\FCD7"; }

.mdi-content-save-edit-outline::before {
  content: "\FCD8"; }

.mdi-content-save-move::before {
  content: "\FE79"; }

.mdi-content-save-move-outline::before {
  content: "\FE7A"; }

.mdi-content-save-outline::before {
  content: "\F817"; }

.mdi-content-save-settings::before {
  content: "\F61B"; }

.mdi-content-save-settings-outline::before {
  content: "\FB13"; }

.mdi-contrast::before {
  content: "\F195"; }

.mdi-contrast-box::before {
  content: "\F196"; }

.mdi-contrast-circle::before {
  content: "\F197"; }

.mdi-controller-classic::before {
  content: "\FB5E"; }

.mdi-controller-classic-outline::before {
  content: "\FB5F"; }

.mdi-cookie::before {
  content: "\F198"; }

.mdi-coolant-temperature::before {
  content: "\F3C8"; }

.mdi-copyright::before {
  content: "\F5E6"; }

.mdi-cordova::before {
  content: "\F957"; }

.mdi-corn::before {
  content: "\F7B7"; }

.mdi-counter::before {
  content: "\F199"; }

.mdi-cow::before {
  content: "\F19A"; }

.mdi-cowboy::before {
  content: "\FEB8"; }

.mdi-cpu-32-bit::before {
  content: "\FEFC"; }

.mdi-cpu-64-bit::before {
  content: "\FEFD"; }

.mdi-crane::before {
  content: "\F861"; }

.mdi-creation::before {
  content: "\F1C9"; }

.mdi-creative-commons::before {
  content: "\FD47"; }

.mdi-credit-card::before {
  content: "\F0010"; }

.mdi-credit-card-clock::before {
  content: "\FEFE"; }

.mdi-credit-card-clock-outline::before {
  content: "\FFBC"; }

.mdi-credit-card-marker::before {
  content: "\F6A7"; }

.mdi-credit-card-marker-outline::before {
  content: "\FD9A"; }

.mdi-credit-card-minus::before {
  content: "\FFCC"; }

.mdi-credit-card-minus-outline::before {
  content: "\FFCD"; }

.mdi-credit-card-multiple::before {
  content: "\F0011"; }

.mdi-credit-card-multiple-outline::before {
  content: "\F19C"; }

.mdi-credit-card-off::before {
  content: "\F0012"; }

.mdi-credit-card-off-outline::before {
  content: "\F5E4"; }

.mdi-credit-card-outline::before {
  content: "\F19B"; }

.mdi-credit-card-plus::before {
  content: "\F0013"; }

.mdi-credit-card-plus-outline::before {
  content: "\F675"; }

.mdi-credit-card-refund::before {
  content: "\F0014"; }

.mdi-credit-card-refund-outline::before {
  content: "\FAA7"; }

.mdi-credit-card-remove::before {
  content: "\FFCE"; }

.mdi-credit-card-remove-outline::before {
  content: "\FFCF"; }

.mdi-credit-card-scan::before {
  content: "\F0015"; }

.mdi-credit-card-scan-outline::before {
  content: "\F19D"; }

.mdi-credit-card-settings::before {
  content: "\F0016"; }

.mdi-credit-card-settings-outline::before {
  content: "\F8D6"; }

.mdi-credit-card-wireless::before {
  content: "\F801"; }

.mdi-credit-card-wireless-outline::before {
  content: "\FD48"; }

.mdi-cricket::before {
  content: "\FD49"; }

.mdi-crop::before {
  content: "\F19E"; }

.mdi-crop-free::before {
  content: "\F19F"; }

.mdi-crop-landscape::before {
  content: "\F1A0"; }

.mdi-crop-portrait::before {
  content: "\F1A1"; }

.mdi-crop-rotate::before {
  content: "\F695"; }

.mdi-crop-square::before {
  content: "\F1A2"; }

.mdi-crosshairs::before {
  content: "\F1A3"; }

.mdi-crosshairs-gps::before {
  content: "\F1A4"; }

.mdi-crosshairs-off::before {
  content: "\FF62"; }

.mdi-crosshairs-question::before {
  content: "\F0161"; }

.mdi-crown::before {
  content: "\F1A5"; }

.mdi-crown-outline::before {
  content: "\F01FB"; }

.mdi-cryengine::before {
  content: "\F958"; }

.mdi-crystal-ball::before {
  content: "\FB14"; }

.mdi-cube::before {
  content: "\F1A6"; }

.mdi-cube-outline::before {
  content: "\F1A7"; }

.mdi-cube-scan::before {
  content: "\FB60"; }

.mdi-cube-send::before {
  content: "\F1A8"; }

.mdi-cube-unfolded::before {
  content: "\F1A9"; }

.mdi-cup::before {
  content: "\F1AA"; }

.mdi-cup-off::before {
  content: "\F5E5"; }

.mdi-cup-off-outline::before {
  content: "\F03A8"; }

.mdi-cup-outline::before {
  content: "\F033A"; }

.mdi-cup-water::before {
  content: "\F1AB"; }

.mdi-cupboard::before {
  content: "\FF63"; }

.mdi-cupboard-outline::before {
  content: "\FF64"; }

.mdi-cupcake::before {
  content: "\F959"; }

.mdi-curling::before {
  content: "\F862"; }

.mdi-currency-bdt::before {
  content: "\F863"; }

.mdi-currency-brl::before {
  content: "\FB61"; }

.mdi-currency-btc::before {
  content: "\F1AC"; }

.mdi-currency-cny::before {
  content: "\F7B9"; }

.mdi-currency-eth::before {
  content: "\F7BA"; }

.mdi-currency-eur::before {
  content: "\F1AD"; }

.mdi-currency-eur-off::before {
  content: "\F0340"; }

.mdi-currency-gbp::before {
  content: "\F1AE"; }

.mdi-currency-ils::before {
  content: "\FC3D"; }

.mdi-currency-inr::before {
  content: "\F1AF"; }

.mdi-currency-jpy::before {
  content: "\F7BB"; }

.mdi-currency-krw::before {
  content: "\F7BC"; }

.mdi-currency-kzt::before {
  content: "\F864"; }

.mdi-currency-ngn::before {
  content: "\F1B0"; }

.mdi-currency-php::before {
  content: "\F9E5"; }

.mdi-currency-rial::before {
  content: "\FEB9"; }

.mdi-currency-rub::before {
  content: "\F1B1"; }

.mdi-currency-sign::before {
  content: "\F7BD"; }

.mdi-currency-try::before {
  content: "\F1B2"; }

.mdi-currency-twd::before {
  content: "\F7BE"; }

.mdi-currency-usd::before {
  content: "\F1B3"; }

.mdi-currency-usd-off::before {
  content: "\F679"; }

.mdi-current-ac::before {
  content: "\F95A"; }

.mdi-current-dc::before {
  content: "\F95B"; }

.mdi-cursor-default::before {
  content: "\F1B4"; }

.mdi-cursor-default-click::before {
  content: "\FCD9"; }

.mdi-cursor-default-click-outline::before {
  content: "\FCDA"; }

.mdi-cursor-default-gesture::before {
  content: "\F0152"; }

.mdi-cursor-default-gesture-outline::before {
  content: "\F0153"; }

.mdi-cursor-default-outline::before {
  content: "\F1B5"; }

.mdi-cursor-move::before {
  content: "\F1B6"; }

.mdi-cursor-pointer::before {
  content: "\F1B7"; }

.mdi-cursor-text::before {
  content: "\F5E7"; }

.mdi-database::before {
  content: "\F1B8"; }

.mdi-database-check::before {
  content: "\FAA8"; }

.mdi-database-edit::before {
  content: "\FB62"; }

.mdi-database-export::before {
  content: "\F95D"; }

.mdi-database-import::before {
  content: "\F95C"; }

.mdi-database-lock::before {
  content: "\FAA9"; }

.mdi-database-marker::before {
  content: "\F0321"; }

.mdi-database-minus::before {
  content: "\F1B9"; }

.mdi-database-plus::before {
  content: "\F1BA"; }

.mdi-database-refresh::before {
  content: "\FCDB"; }

.mdi-database-remove::before {
  content: "\FCDC"; }

.mdi-database-search::before {
  content: "\F865"; }

.mdi-database-settings::before {
  content: "\FCDD"; }

.mdi-death-star::before {
  content: "\F8D7"; }

.mdi-death-star-variant::before {
  content: "\F8D8"; }

.mdi-deathly-hallows::before {
  content: "\FB63"; }

.mdi-debian::before {
  content: "\F8D9"; }

.mdi-debug-step-into::before {
  content: "\F1BB"; }

.mdi-debug-step-out::before {
  content: "\F1BC"; }

.mdi-debug-step-over::before {
  content: "\F1BD"; }

.mdi-decagram::before {
  content: "\F76B"; }

.mdi-decagram-outline::before {
  content: "\F76C"; }

.mdi-decimal::before {
  content: "\F00CC"; }

.mdi-decimal-comma::before {
  content: "\F00CD"; }

.mdi-decimal-comma-decrease::before {
  content: "\F00CE"; }

.mdi-decimal-comma-increase::before {
  content: "\F00CF"; }

.mdi-decimal-decrease::before {
  content: "\F1BE"; }

.mdi-decimal-increase::before {
  content: "\F1BF"; }

.mdi-delete::before {
  content: "\F1C0"; }

.mdi-delete-alert::before {
  content: "\F00D0"; }

.mdi-delete-alert-outline::before {
  content: "\F00D1"; }

.mdi-delete-circle::before {
  content: "\F682"; }

.mdi-delete-circle-outline::before {
  content: "\FB64"; }

.mdi-delete-empty::before {
  content: "\F6CB"; }

.mdi-delete-empty-outline::before {
  content: "\FEBA"; }

.mdi-delete-forever::before {
  content: "\F5E8"; }

.mdi-delete-forever-outline::before {
  content: "\FB65"; }

.mdi-delete-off::before {
  content: "\F00D2"; }

.mdi-delete-off-outline::before {
  content: "\F00D3"; }

.mdi-delete-outline::before {
  content: "\F9E6"; }

.mdi-delete-restore::before {
  content: "\F818"; }

.mdi-delete-sweep::before {
  content: "\F5E9"; }

.mdi-delete-sweep-outline::before {
  content: "\FC3E"; }

.mdi-delete-variant::before {
  content: "\F1C1"; }

.mdi-delta::before {
  content: "\F1C2"; }

.mdi-desk::before {
  content: "\F0264"; }

.mdi-desk-lamp::before {
  content: "\F95E"; }

.mdi-deskphone::before {
  content: "\F1C3"; }

.mdi-desktop-classic::before {
  content: "\F7BF"; }

.mdi-desktop-mac::before {
  content: "\F1C4"; }

.mdi-desktop-mac-dashboard::before {
  content: "\F9E7"; }

.mdi-desktop-tower::before {
  content: "\F1C5"; }

.mdi-desktop-tower-monitor::before {
  content: "\FAAA"; }

.mdi-details::before {
  content: "\F1C6"; }

.mdi-dev-to::before {
  content: "\FD4A"; }

.mdi-developer-board::before {
  content: "\F696"; }

.mdi-deviantart::before {
  content: "\F1C7"; }

.mdi-devices::before {
  content: "\FFD0"; }

.mdi-diabetes::before {
  content: "\F0151"; }

.mdi-dialpad::before {
  content: "\F61C"; }

.mdi-diameter::before {
  content: "\FC3F"; }

.mdi-diameter-outline::before {
  content: "\FC40"; }

.mdi-diameter-variant::before {
  content: "\FC41"; }

.mdi-diamond::before {
  content: "\FB66"; }

.mdi-diamond-outline::before {
  content: "\FB67"; }

.mdi-diamond-stone::before {
  content: "\F1C8"; }

.mdi-dice-1::before {
  content: "\F1CA"; }

.mdi-dice-1-outline::before {
  content: "\F0175"; }

.mdi-dice-2::before {
  content: "\F1CB"; }

.mdi-dice-2-outline::before {
  content: "\F0176"; }

.mdi-dice-3::before {
  content: "\F1CC"; }

.mdi-dice-3-outline::before {
  content: "\F0177"; }

.mdi-dice-4::before {
  content: "\F1CD"; }

.mdi-dice-4-outline::before {
  content: "\F0178"; }

.mdi-dice-5::before {
  content: "\F1CE"; }

.mdi-dice-5-outline::before {
  content: "\F0179"; }

.mdi-dice-6::before {
  content: "\F1CF"; }

.mdi-dice-6-outline::before {
  content: "\F017A"; }

.mdi-dice-d10::before {
  content: "\F017E"; }

.mdi-dice-d10-outline::before {
  content: "\F76E"; }

.mdi-dice-d12::before {
  content: "\F017F"; }

.mdi-dice-d12-outline::before {
  content: "\F866"; }

.mdi-dice-d20::before {
  content: "\F0180"; }

.mdi-dice-d20-outline::before {
  content: "\F5EA"; }

.mdi-dice-d4::before {
  content: "\F017B"; }

.mdi-dice-d4-outline::before {
  content: "\F5EB"; }

.mdi-dice-d6::before {
  content: "\F017C"; }

.mdi-dice-d6-outline::before {
  content: "\F5EC"; }

.mdi-dice-d8::before {
  content: "\F017D"; }

.mdi-dice-d8-outline::before {
  content: "\F5ED"; }

.mdi-dice-multiple::before {
  content: "\F76D"; }

.mdi-dice-multiple-outline::before {
  content: "\F0181"; }

.mdi-dictionary::before {
  content: "\F61D"; }

.mdi-digital-ocean::before {
  content: "\F0262"; }

.mdi-dip-switch::before {
  content: "\F7C0"; }

.mdi-directions::before {
  content: "\F1D0"; }

.mdi-directions-fork::before {
  content: "\F641"; }

.mdi-disc::before {
  content: "\F5EE"; }

.mdi-disc-alert::before {
  content: "\F1D1"; }

.mdi-disc-player::before {
  content: "\F95F"; }

.mdi-discord::before {
  content: "\F66F"; }

.mdi-dishwasher::before {
  content: "\FAAB"; }

.mdi-dishwasher-alert::before {
  content: "\F01E3"; }

.mdi-dishwasher-off::before {
  content: "\F01E4"; }

.mdi-disqus::before {
  content: "\F1D2"; }

.mdi-disqus-outline::before {
  content: "\F1D3"; }

.mdi-distribute-horizontal-center::before {
  content: "\F01F4"; }

.mdi-distribute-horizontal-left::before {
  content: "\F01F3"; }

.mdi-distribute-horizontal-right::before {
  content: "\F01F5"; }

.mdi-distribute-vertical-bottom::before {
  content: "\F01F6"; }

.mdi-distribute-vertical-center::before {
  content: "\F01F7"; }

.mdi-distribute-vertical-top::before {
  content: "\F01F8"; }

.mdi-diving-flippers::before {
  content: "\FD9B"; }

.mdi-diving-helmet::before {
  content: "\FD9C"; }

.mdi-diving-scuba::before {
  content: "\FD9D"; }

.mdi-diving-scuba-flag::before {
  content: "\FD9E"; }

.mdi-diving-scuba-tank::before {
  content: "\FD9F"; }

.mdi-diving-scuba-tank-multiple::before {
  content: "\FDA0"; }

.mdi-diving-snorkel::before {
  content: "\FDA1"; }

.mdi-division::before {
  content: "\F1D4"; }

.mdi-division-box::before {
  content: "\F1D5"; }

.mdi-dlna::before {
  content: "\FA40"; }

.mdi-dna::before {
  content: "\F683"; }

.mdi-dns::before {
  content: "\F1D6"; }

.mdi-dns-outline::before {
  content: "\FB68"; }

.mdi-do-not-disturb::before {
  content: "\F697"; }

.mdi-do-not-disturb-off::before {
  content: "\F698"; }

.mdi-dock-bottom::before {
  content: "\F00D4"; }

.mdi-dock-left::before {
  content: "\F00D5"; }

.mdi-dock-right::before {
  content: "\F00D6"; }

.mdi-dock-window::before {
  content: "\F00D7"; }

.mdi-docker::before {
  content: "\F867"; }

.mdi-doctor::before {
  content: "\FA41"; }

.mdi-dog::before {
  content: "\FA42"; }

.mdi-dog-service::before {
  content: "\FAAC"; }

.mdi-dog-side::before {
  content: "\FA43"; }

.mdi-dolby::before {
  content: "\F6B2"; }

.mdi-dolly::before {
  content: "\FEBB"; }

.mdi-domain::before {
  content: "\F1D7"; }

.mdi-domain-off::before {
  content: "\FD4B"; }

.mdi-domain-plus::before {
  content: "\F00D8"; }

.mdi-domain-remove::before {
  content: "\F00D9"; }

.mdi-domino-mask::before {
  content: "\F0045"; }

.mdi-donkey::before {
  content: "\F7C1"; }

.mdi-door::before {
  content: "\F819"; }

.mdi-door-closed::before {
  content: "\F81A"; }

.mdi-door-closed-lock::before {
  content: "\F00DA"; }

.mdi-door-open::before {
  content: "\F81B"; }

.mdi-doorbell::before {
  content: "\F0311"; }

.mdi-doorbell-video::before {
  content: "\F868"; }

.mdi-dot-net::before {
  content: "\FAAD"; }

.mdi-dots-horizontal::before {
  content: "\F1D8"; }

.mdi-dots-horizontal-circle::before {
  content: "\F7C2"; }

.mdi-dots-horizontal-circle-outline::before {
  content: "\FB69"; }

.mdi-dots-vertical::before {
  content: "\F1D9"; }

.mdi-dots-vertical-circle::before {
  content: "\F7C3"; }

.mdi-dots-vertical-circle-outline::before {
  content: "\FB6A"; }

.mdi-douban::before {
  content: "\F699"; }

.mdi-download::before {
  content: "\F1DA"; }

.mdi-download-lock::before {
  content: "\F034B"; }

.mdi-download-lock-outline::before {
  content: "\F034C"; }

.mdi-download-multiple::before {
  content: "\F9E8"; }

.mdi-download-network::before {
  content: "\F6F3"; }

.mdi-download-network-outline::before {
  content: "\FC42"; }

.mdi-download-off::before {
  content: "\F00DB"; }

.mdi-download-off-outline::before {
  content: "\F00DC"; }

.mdi-download-outline::before {
  content: "\FB6B"; }

.mdi-drag::before {
  content: "\F1DB"; }

.mdi-drag-horizontal::before {
  content: "\F1DC"; }

.mdi-drag-horizontal-variant::before {
  content: "\F031B"; }

.mdi-drag-variant::before {
  content: "\FB6C"; }

.mdi-drag-vertical::before {
  content: "\F1DD"; }

.mdi-drag-vertical-variant::before {
  content: "\F031C"; }

.mdi-drama-masks::before {
  content: "\FCDE"; }

.mdi-draw::before {
  content: "\FF66"; }

.mdi-drawing::before {
  content: "\F1DE"; }

.mdi-drawing-box::before {
  content: "\F1DF"; }

.mdi-dresser::before {
  content: "\FF67"; }

.mdi-dresser-outline::before {
  content: "\FF68"; }

.mdi-dribbble::before {
  content: "\F1E0"; }

.mdi-dribbble-box::before {
  content: "\F1E1"; }

.mdi-drone::before {
  content: "\F1E2"; }

.mdi-dropbox::before {
  content: "\F1E3"; }

.mdi-drupal::before {
  content: "\F1E4"; }

.mdi-duck::before {
  content: "\F1E5"; }

.mdi-dumbbell::before {
  content: "\F1E6"; }

.mdi-dump-truck::before {
  content: "\FC43"; }

.mdi-ear-hearing::before {
  content: "\F7C4"; }

.mdi-ear-hearing-off::before {
  content: "\FA44"; }

.mdi-earth::before {
  content: "\F1E7"; }

.mdi-earth-arrow-right::before {
  content: "\F033C"; }

.mdi-earth-box::before {
  content: "\F6CC"; }

.mdi-earth-box-off::before {
  content: "\F6CD"; }

.mdi-earth-off::before {
  content: "\F1E8"; }

.mdi-edge::before {
  content: "\F1E9"; }

.mdi-edge-legacy::before {
  content: "\F027B"; }

.mdi-egg::before {
  content: "\FAAE"; }

.mdi-egg-easter::before {
  content: "\FAAF"; }

.mdi-eight-track::before {
  content: "\F9E9"; }

.mdi-eject::before {
  content: "\F1EA"; }

.mdi-eject-outline::before {
  content: "\FB6D"; }

.mdi-electric-switch::before {
  content: "\FEBC"; }

.mdi-electric-switch-closed::before {
  content: "\F0104"; }

.mdi-electron-framework::before {
  content: "\F0046"; }

.mdi-elephant::before {
  content: "\F7C5"; }

.mdi-elevation-decline::before {
  content: "\F1EB"; }

.mdi-elevation-rise::before {
  content: "\F1EC"; }

.mdi-elevator::before {
  content: "\F1ED"; }

.mdi-elevator-down::before {
  content: "\F02ED"; }

.mdi-elevator-passenger::before {
  content: "\F03AC"; }

.mdi-elevator-up::before {
  content: "\F02EC"; }

.mdi-ellipse::before {
  content: "\FEBD"; }

.mdi-ellipse-outline::before {
  content: "\FEBE"; }

.mdi-email::before {
  content: "\F1EE"; }

.mdi-email-alert::before {
  content: "\F6CE"; }

.mdi-email-alert-outline::before {
  content: "\FD1E"; }

.mdi-email-box::before {
  content: "\FCDF"; }

.mdi-email-check::before {
  content: "\FAB0"; }

.mdi-email-check-outline::before {
  content: "\FAB1"; }

.mdi-email-edit::before {
  content: "\FF00"; }

.mdi-email-edit-outline::before {
  content: "\FF01"; }

.mdi-email-lock::before {
  content: "\F1F1"; }

.mdi-email-mark-as-unread::before {
  content: "\FB6E"; }

.mdi-email-minus::before {
  content: "\FF02"; }

.mdi-email-minus-outline::before {
  content: "\FF03"; }

.mdi-email-multiple::before {
  content: "\FF04"; }

.mdi-email-multiple-outline::before {
  content: "\FF05"; }

.mdi-email-newsletter::before {
  content: "\FFD1"; }

.mdi-email-open::before {
  content: "\F1EF"; }

.mdi-email-open-multiple::before {
  content: "\FF06"; }

.mdi-email-open-multiple-outline::before {
  content: "\FF07"; }

.mdi-email-open-outline::before {
  content: "\F5EF"; }

.mdi-email-outline::before {
  content: "\F1F0"; }

.mdi-email-plus::before {
  content: "\F9EA"; }

.mdi-email-plus-outline::before {
  content: "\F9EB"; }

.mdi-email-receive::before {
  content: "\F0105"; }

.mdi-email-receive-outline::before {
  content: "\F0106"; }

.mdi-email-search::before {
  content: "\F960"; }

.mdi-email-search-outline::before {
  content: "\F961"; }

.mdi-email-send::before {
  content: "\F0107"; }

.mdi-email-send-outline::before {
  content: "\F0108"; }

.mdi-email-sync::before {
  content: "\F02F2"; }

.mdi-email-sync-outline::before {
  content: "\F02F3"; }

.mdi-email-variant::before {
  content: "\F5F0"; }

.mdi-ember::before {
  content: "\FB15"; }

.mdi-emby::before {
  content: "\F6B3"; }

.mdi-emoticon::before {
  content: "\FC44"; }

.mdi-emoticon-angry::before {
  content: "\FC45"; }

.mdi-emoticon-angry-outline::before {
  content: "\FC46"; }

.mdi-emoticon-confused::before {
  content: "\F0109"; }

.mdi-emoticon-confused-outline::before {
  content: "\F010A"; }

.mdi-emoticon-cool::before {
  content: "\FC47"; }

.mdi-emoticon-cool-outline::before {
  content: "\F1F3"; }

.mdi-emoticon-cry::before {
  content: "\FC48"; }

.mdi-emoticon-cry-outline::before {
  content: "\FC49"; }

.mdi-emoticon-dead::before {
  content: "\FC4A"; }

.mdi-emoticon-dead-outline::before {
  content: "\F69A"; }

.mdi-emoticon-devil::before {
  content: "\FC4B"; }

.mdi-emoticon-devil-outline::before {
  content: "\F1F4"; }

.mdi-emoticon-excited::before {
  content: "\FC4C"; }

.mdi-emoticon-excited-outline::before {
  content: "\F69B"; }

.mdi-emoticon-frown::before {
  content: "\FF69"; }

.mdi-emoticon-frown-outline::before {
  content: "\FF6A"; }

.mdi-emoticon-happy::before {
  content: "\FC4D"; }

.mdi-emoticon-happy-outline::before {
  content: "\F1F5"; }

.mdi-emoticon-kiss::before {
  content: "\FC4E"; }

.mdi-emoticon-kiss-outline::before {
  content: "\FC4F"; }

.mdi-emoticon-lol::before {
  content: "\F023F"; }

.mdi-emoticon-lol-outline::before {
  content: "\F0240"; }

.mdi-emoticon-neutral::before {
  content: "\FC50"; }

.mdi-emoticon-neutral-outline::before {
  content: "\F1F6"; }

.mdi-emoticon-outline::before {
  content: "\F1F2"; }

.mdi-emoticon-poop::before {
  content: "\F1F7"; }

.mdi-emoticon-poop-outline::before {
  content: "\FC51"; }

.mdi-emoticon-sad::before {
  content: "\FC52"; }

.mdi-emoticon-sad-outline::before {
  content: "\F1F8"; }

.mdi-emoticon-tongue::before {
  content: "\F1F9"; }

.mdi-emoticon-tongue-outline::before {
  content: "\FC53"; }

.mdi-emoticon-wink::before {
  content: "\FC54"; }

.mdi-emoticon-wink-outline::before {
  content: "\FC55"; }

.mdi-engine::before {
  content: "\F1FA"; }

.mdi-engine-off::before {
  content: "\FA45"; }

.mdi-engine-off-outline::before {
  content: "\FA46"; }

.mdi-engine-outline::before {
  content: "\F1FB"; }

.mdi-epsilon::before {
  content: "\F010B"; }

.mdi-equal::before {
  content: "\F1FC"; }

.mdi-equal-box::before {
  content: "\F1FD"; }

.mdi-equalizer::before {
  content: "\FEBF"; }

.mdi-equalizer-outline::before {
  content: "\FEC0"; }

.mdi-eraser::before {
  content: "\F1FE"; }

.mdi-eraser-variant::before {
  content: "\F642"; }

.mdi-escalator::before {
  content: "\F1FF"; }

.mdi-escalator-down::before {
  content: "\F02EB"; }

.mdi-escalator-up::before {
  content: "\F02EA"; }

.mdi-eslint::before {
  content: "\FC56"; }

.mdi-et::before {
  content: "\FAB2"; }

.mdi-ethereum::before {
  content: "\F869"; }

.mdi-ethernet::before {
  content: "\F200"; }

.mdi-ethernet-cable::before {
  content: "\F201"; }

.mdi-ethernet-cable-off::before {
  content: "\F202"; }

.mdi-etsy::before {
  content: "\F203"; }

.mdi-ev-station::before {
  content: "\F5F1"; }

.mdi-eventbrite::before {
  content: "\F7C6"; }

.mdi-evernote::before {
  content: "\F204"; }

.mdi-excavator::before {
  content: "\F0047"; }

.mdi-exclamation::before {
  content: "\F205"; }

.mdi-exclamation-thick::before {
  content: "\F0263"; }

.mdi-exit-run::before {
  content: "\FA47"; }

.mdi-exit-to-app::before {
  content: "\F206"; }

.mdi-expand-all::before {
  content: "\FAB3"; }

.mdi-expand-all-outline::before {
  content: "\FAB4"; }

.mdi-expansion-card::before {
  content: "\F8AD"; }

.mdi-expansion-card-variant::before {
  content: "\FFD2"; }

.mdi-exponent::before {
  content: "\F962"; }

.mdi-exponent-box::before {
  content: "\F963"; }

.mdi-export::before {
  content: "\F207"; }

.mdi-export-variant::before {
  content: "\FB6F"; }

.mdi-eye::before {
  content: "\F208"; }

.mdi-eye-check::before {
  content: "\FCE0"; }

.mdi-eye-check-outline::before {
  content: "\FCE1"; }

.mdi-eye-circle::before {
  content: "\FB70"; }

.mdi-eye-circle-outline::before {
  content: "\FB71"; }

.mdi-eye-minus::before {
  content: "\F0048"; }

.mdi-eye-minus-outline::before {
  content: "\F0049"; }

.mdi-eye-off::before {
  content: "\F209"; }

.mdi-eye-off-outline::before {
  content: "\F6D0"; }

.mdi-eye-outline::before {
  content: "\F6CF"; }

.mdi-eye-plus::before {
  content: "\F86A"; }

.mdi-eye-plus-outline::before {
  content: "\F86B"; }

.mdi-eye-settings::before {
  content: "\F86C"; }

.mdi-eye-settings-outline::before {
  content: "\F86D"; }

.mdi-eyedropper::before {
  content: "\F20A"; }

.mdi-eyedropper-variant::before {
  content: "\F20B"; }

.mdi-face::before {
  content: "\F643"; }

.mdi-face-agent::before {
  content: "\FD4C"; }

.mdi-face-outline::before {
  content: "\FB72"; }

.mdi-face-profile::before {
  content: "\F644"; }

.mdi-face-profile-woman::before {
  content: "\F00A1"; }

.mdi-face-recognition::before {
  content: "\FC57"; }

.mdi-face-woman::before {
  content: "\F00A2"; }

.mdi-face-woman-outline::before {
  content: "\F00A3"; }

.mdi-facebook::before {
  content: "\F20C"; }

.mdi-facebook-box::before {
  content: "\F20D"; }

.mdi-facebook-messenger::before {
  content: "\F20E"; }

.mdi-facebook-workplace::before {
  content: "\FB16"; }

.mdi-factory::before {
  content: "\F20F"; }

.mdi-fan::before {
  content: "\F210"; }

.mdi-fan-off::before {
  content: "\F81C"; }

.mdi-fast-forward::before {
  content: "\F211"; }

.mdi-fast-forward-10::before {
  content: "\FD4D"; }

.mdi-fast-forward-30::before {
  content: "\FCE2"; }

.mdi-fast-forward-5::before {
  content: "\F0223"; }

.mdi-fast-forward-outline::before {
  content: "\F6D1"; }

.mdi-fax::before {
  content: "\F212"; }

.mdi-feather::before {
  content: "\F6D2"; }

.mdi-feature-search::before {
  content: "\FA48"; }

.mdi-feature-search-outline::before {
  content: "\FA49"; }

.mdi-fedora::before {
  content: "\F8DA"; }

.mdi-ferris-wheel::before {
  content: "\FEC1"; }

.mdi-ferry::before {
  content: "\F213"; }

.mdi-file::before {
  content: "\F214"; }

.mdi-file-account::before {
  content: "\F73A"; }

.mdi-file-account-outline::before {
  content: "\F004A"; }

.mdi-file-alert::before {
  content: "\FA4A"; }

.mdi-file-alert-outline::before {
  content: "\FA4B"; }

.mdi-file-cabinet::before {
  content: "\FAB5"; }

.mdi-file-cad::before {
  content: "\FF08"; }

.mdi-file-cad-box::before {
  content: "\FF09"; }

.mdi-file-cancel::before {
  content: "\FDA2"; }

.mdi-file-cancel-outline::before {
  content: "\FDA3"; }

.mdi-file-certificate::before {
  content: "\F01B1"; }

.mdi-file-certificate-outline::before {
  content: "\F01B2"; }

.mdi-file-chart::before {
  content: "\F215"; }

.mdi-file-chart-outline::before {
  content: "\F004B"; }

.mdi-file-check::before {
  content: "\F216"; }

.mdi-file-check-outline::before {
  content: "\FE7B"; }

.mdi-file-clock::before {
  content: "\F030C"; }

.mdi-file-clock-outline::before {
  content: "\F030D"; }

.mdi-file-cloud::before {
  content: "\F217"; }

.mdi-file-cloud-outline::before {
  content: "\F004C"; }

.mdi-file-code::before {
  content: "\F22E"; }

.mdi-file-code-outline::before {
  content: "\F004D"; }

.mdi-file-compare::before {
  content: "\F8A9"; }

.mdi-file-delimited::before {
  content: "\F218"; }

.mdi-file-delimited-outline::before {
  content: "\FEC2"; }

.mdi-file-document::before {
  content: "\F219"; }

.mdi-file-document-box::before {
  content: "\F21A"; }

.mdi-file-document-box-check::before {
  content: "\FEC3"; }

.mdi-file-document-box-check-outline::before {
  content: "\FEC4"; }

.mdi-file-document-box-minus::before {
  content: "\FEC5"; }

.mdi-file-document-box-minus-outline::before {
  content: "\FEC6"; }

.mdi-file-document-box-multiple::before {
  content: "\FAB6"; }

.mdi-file-document-box-multiple-outline::before {
  content: "\FAB7"; }

.mdi-file-document-box-outline::before {
  content: "\F9EC"; }

.mdi-file-document-box-plus::before {
  content: "\FEC7"; }

.mdi-file-document-box-plus-outline::before {
  content: "\FEC8"; }

.mdi-file-document-box-remove::before {
  content: "\FEC9"; }

.mdi-file-document-box-remove-outline::before {
  content: "\FECA"; }

.mdi-file-document-box-search::before {
  content: "\FECB"; }

.mdi-file-document-box-search-outline::before {
  content: "\FECC"; }

.mdi-file-document-edit::before {
  content: "\FDA4"; }

.mdi-file-document-edit-outline::before {
  content: "\FDA5"; }

.mdi-file-document-outline::before {
  content: "\F9ED"; }

.mdi-file-download::before {
  content: "\F964"; }

.mdi-file-download-outline::before {
  content: "\F965"; }

.mdi-file-edit::before {
  content: "\F0212"; }

.mdi-file-edit-outline::before {
  content: "\F0213"; }

.mdi-file-excel::before {
  content: "\F21B"; }

.mdi-file-excel-box::before {
  content: "\F21C"; }

.mdi-file-excel-box-outline::before {
  content: "\F004E"; }

.mdi-file-excel-outline::before {
  content: "\F004F"; }

.mdi-file-export::before {
  content: "\F21D"; }

.mdi-file-export-outline::before {
  content: "\F0050"; }

.mdi-file-eye::before {
  content: "\FDA6"; }

.mdi-file-eye-outline::before {
  content: "\FDA7"; }

.mdi-file-find::before {
  content: "\F21E"; }

.mdi-file-find-outline::before {
  content: "\FB73"; }

.mdi-file-hidden::before {
  content: "\F613"; }

.mdi-file-image::before {
  content: "\F21F"; }

.mdi-file-image-outline::before {
  content: "\FECD"; }

.mdi-file-import::before {
  content: "\F220"; }

.mdi-file-import-outline::before {
  content: "\F0051"; }

.mdi-file-key::before {
  content: "\F01AF"; }

.mdi-file-key-outline::before {
  content: "\F01B0"; }

.mdi-file-link::before {
  content: "\F01A2"; }

.mdi-file-link-outline::before {
  content: "\F01A3"; }

.mdi-file-lock::before {
  content: "\F221"; }

.mdi-file-lock-outline::before {
  content: "\F0052"; }

.mdi-file-move::before {
  content: "\FAB8"; }

.mdi-file-move-outline::before {
  content: "\F0053"; }

.mdi-file-multiple::before {
  content: "\F222"; }

.mdi-file-multiple-outline::before {
  content: "\F0054"; }

.mdi-file-music::before {
  content: "\F223"; }

.mdi-file-music-outline::before {
  content: "\FE7C"; }

.mdi-file-outline::before {
  content: "\F224"; }

.mdi-file-pdf::before {
  content: "\F225"; }

.mdi-file-pdf-box::before {
  content: "\F226"; }

.mdi-file-pdf-box-outline::before {
  content: "\FFD3"; }

.mdi-file-pdf-outline::before {
  content: "\FE7D"; }

.mdi-file-percent::before {
  content: "\F81D"; }

.mdi-file-percent-outline::before {
  content: "\F0055"; }

.mdi-file-phone::before {
  content: "\F01A4"; }

.mdi-file-phone-outline::before {
  content: "\F01A5"; }

.mdi-file-plus::before {
  content: "\F751"; }

.mdi-file-plus-outline::before {
  content: "\FF0A"; }

.mdi-file-powerpoint::before {
  content: "\F227"; }

.mdi-file-powerpoint-box::before {
  content: "\F228"; }

.mdi-file-powerpoint-box-outline::before {
  content: "\F0056"; }

.mdi-file-powerpoint-outline::before {
  content: "\F0057"; }

.mdi-file-presentation-box::before {
  content: "\F229"; }

.mdi-file-question::before {
  content: "\F86E"; }

.mdi-file-question-outline::before {
  content: "\F0058"; }

.mdi-file-remove::before {
  content: "\FB74"; }

.mdi-file-remove-outline::before {
  content: "\F0059"; }

.mdi-file-replace::before {
  content: "\FB17"; }

.mdi-file-replace-outline::before {
  content: "\FB18"; }

.mdi-file-restore::before {
  content: "\F670"; }

.mdi-file-restore-outline::before {
  content: "\F005A"; }

.mdi-file-search::before {
  content: "\FC58"; }

.mdi-file-search-outline::before {
  content: "\FC59"; }

.mdi-file-send::before {
  content: "\F22A"; }

.mdi-file-send-outline::before {
  content: "\F005B"; }

.mdi-file-settings::before {
  content: "\F00A4"; }

.mdi-file-settings-outline::before {
  content: "\F00A5"; }

.mdi-file-settings-variant::before {
  content: "\F00A6"; }

.mdi-file-settings-variant-outline::before {
  content: "\F00A7"; }

.mdi-file-star::before {
  content: "\F005C"; }

.mdi-file-star-outline::before {
  content: "\F005D"; }

.mdi-file-swap::before {
  content: "\FFD4"; }

.mdi-file-swap-outline::before {
  content: "\FFD5"; }

.mdi-file-sync::before {
  content: "\F0241"; }

.mdi-file-sync-outline::before {
  content: "\F0242"; }

.mdi-file-table::before {
  content: "\FC5A"; }

.mdi-file-table-box::before {
  content: "\F010C"; }

.mdi-file-table-box-multiple::before {
  content: "\F010D"; }

.mdi-file-table-box-multiple-outline::before {
  content: "\F010E"; }

.mdi-file-table-box-outline::before {
  content: "\F010F"; }

.mdi-file-table-outline::before {
  content: "\FC5B"; }

.mdi-file-tree::before {
  content: "\F645"; }

.mdi-file-undo::before {
  content: "\F8DB"; }

.mdi-file-undo-outline::before {
  content: "\F005E"; }

.mdi-file-upload::before {
  content: "\FA4C"; }

.mdi-file-upload-outline::before {
  content: "\FA4D"; }

.mdi-file-video::before {
  content: "\F22B"; }

.mdi-file-video-outline::before {
  content: "\FE10"; }

.mdi-file-word::before {
  content: "\F22C"; }

.mdi-file-word-box::before {
  content: "\F22D"; }

.mdi-file-word-box-outline::before {
  content: "\F005F"; }

.mdi-file-word-outline::before {
  content: "\F0060"; }

.mdi-film::before {
  content: "\F22F"; }

.mdi-filmstrip::before {
  content: "\F230"; }

.mdi-filmstrip-off::before {
  content: "\F231"; }

.mdi-filter::before {
  content: "\F232"; }

.mdi-filter-menu::before {
  content: "\F0110"; }

.mdi-filter-menu-outline::before {
  content: "\F0111"; }

.mdi-filter-minus::before {
  content: "\FF0B"; }

.mdi-filter-minus-outline::before {
  content: "\FF0C"; }

.mdi-filter-outline::before {
  content: "\F233"; }

.mdi-filter-plus::before {
  content: "\FF0D"; }

.mdi-filter-plus-outline::before {
  content: "\FF0E"; }

.mdi-filter-remove::before {
  content: "\F234"; }

.mdi-filter-remove-outline::before {
  content: "\F235"; }

.mdi-filter-variant::before {
  content: "\F236"; }

.mdi-filter-variant-minus::before {
  content: "\F013D"; }

.mdi-filter-variant-plus::before {
  content: "\F013E"; }

.mdi-filter-variant-remove::before {
  content: "\F0061"; }

.mdi-finance::before {
  content: "\F81E"; }

.mdi-find-replace::before {
  content: "\F6D3"; }

.mdi-fingerprint::before {
  content: "\F237"; }

.mdi-fingerprint-off::before {
  content: "\FECE"; }

.mdi-fire::before {
  content: "\F238"; }

.mdi-fire-extinguisher::before {
  content: "\FF0F"; }

.mdi-fire-hydrant::before {
  content: "\F0162"; }

.mdi-fire-hydrant-alert::before {
  content: "\F0163"; }

.mdi-fire-hydrant-off::before {
  content: "\F0164"; }

.mdi-fire-truck::before {
  content: "\F8AA"; }

.mdi-firebase::before {
  content: "\F966"; }

.mdi-firefox::before {
  content: "\F239"; }

.mdi-fireplace::before {
  content: "\FE11"; }

.mdi-fireplace-off::before {
  content: "\FE12"; }

.mdi-firework::before {
  content: "\FE13"; }

.mdi-fish::before {
  content: "\F23A"; }

.mdi-fishbowl::before {
  content: "\FF10"; }

.mdi-fishbowl-outline::before {
  content: "\FF11"; }

.mdi-fit-to-page::before {
  content: "\FF12"; }

.mdi-fit-to-page-outline::before {
  content: "\FF13"; }

.mdi-flag::before {
  content: "\F23B"; }

.mdi-flag-checkered::before {
  content: "\F23C"; }

.mdi-flag-minus::before {
  content: "\FB75"; }

.mdi-flag-minus-outline::before {
  content: "\F00DD"; }

.mdi-flag-outline::before {
  content: "\F23D"; }

.mdi-flag-plus::before {
  content: "\FB76"; }

.mdi-flag-plus-outline::before {
  content: "\F00DE"; }

.mdi-flag-remove::before {
  content: "\FB77"; }

.mdi-flag-remove-outline::before {
  content: "\F00DF"; }

.mdi-flag-triangle::before {
  content: "\F23F"; }

.mdi-flag-variant::before {
  content: "\F240"; }

.mdi-flag-variant-outline::before {
  content: "\F23E"; }

.mdi-flare::before {
  content: "\FD4E"; }

.mdi-flash::before {
  content: "\F241"; }

.mdi-flash-alert::before {
  content: "\FF14"; }

.mdi-flash-alert-outline::before {
  content: "\FF15"; }

.mdi-flash-auto::before {
  content: "\F242"; }

.mdi-flash-circle::before {
  content: "\F81F"; }

.mdi-flash-off::before {
  content: "\F243"; }

.mdi-flash-outline::before {
  content: "\F6D4"; }

.mdi-flash-red-eye::before {
  content: "\F67A"; }

.mdi-flashlight::before {
  content: "\F244"; }

.mdi-flashlight-off::before {
  content: "\F245"; }

.mdi-flask::before {
  content: "\F093"; }

.mdi-flask-empty::before {
  content: "\F094"; }

.mdi-flask-empty-minus::before {
  content: "\F0265"; }

.mdi-flask-empty-minus-outline::before {
  content: "\F0266"; }

.mdi-flask-empty-outline::before {
  content: "\F095"; }

.mdi-flask-empty-plus::before {
  content: "\F0267"; }

.mdi-flask-empty-plus-outline::before {
  content: "\F0268"; }

.mdi-flask-empty-remove::before {
  content: "\F0269"; }

.mdi-flask-empty-remove-outline::before {
  content: "\F026A"; }

.mdi-flask-minus::before {
  content: "\F026B"; }

.mdi-flask-minus-outline::before {
  content: "\F026C"; }

.mdi-flask-outline::before {
  content: "\F096"; }

.mdi-flask-plus::before {
  content: "\F026D"; }

.mdi-flask-plus-outline::before {
  content: "\F026E"; }

.mdi-flask-remove::before {
  content: "\F026F"; }

.mdi-flask-remove-outline::before {
  content: "\F0270"; }

.mdi-flask-round-bottom::before {
  content: "\F0276"; }

.mdi-flask-round-bottom-empty::before {
  content: "\F0277"; }

.mdi-flask-round-bottom-empty-outline::before {
  content: "\F0278"; }

.mdi-flask-round-bottom-outline::before {
  content: "\F0279"; }

.mdi-flattr::before {
  content: "\F246"; }

.mdi-fleur-de-lis::before {
  content: "\F032E"; }

.mdi-flickr::before {
  content: "\FCE3"; }

.mdi-flip-horizontal::before {
  content: "\F0112"; }

.mdi-flip-to-back::before {
  content: "\F247"; }

.mdi-flip-to-front::before {
  content: "\F248"; }

.mdi-flip-vertical::before {
  content: "\F0113"; }

.mdi-floor-lamp::before {
  content: "\F8DC"; }

.mdi-floor-lamp-dual::before {
  content: "\F0062"; }

.mdi-floor-lamp-variant::before {
  content: "\F0063"; }

.mdi-floor-plan::before {
  content: "\F820"; }

.mdi-floppy::before {
  content: "\F249"; }

.mdi-floppy-variant::before {
  content: "\F9EE"; }

.mdi-flower::before {
  content: "\F24A"; }

.mdi-flower-outline::before {
  content: "\F9EF"; }

.mdi-flower-poppy::before {
  content: "\FCE4"; }

.mdi-flower-tulip::before {
  content: "\F9F0"; }

.mdi-flower-tulip-outline::before {
  content: "\F9F1"; }

.mdi-focus-auto::before {
  content: "\FF6B"; }

.mdi-focus-field::before {
  content: "\FF6C"; }

.mdi-focus-field-horizontal::before {
  content: "\FF6D"; }

.mdi-focus-field-vertical::before {
  content: "\FF6E"; }

.mdi-folder::before {
  content: "\F24B"; }

.mdi-folder-account::before {
  content: "\F24C"; }

.mdi-folder-account-outline::before {
  content: "\FB78"; }

.mdi-folder-alert::before {
  content: "\FDA8"; }

.mdi-folder-alert-outline::before {
  content: "\FDA9"; }

.mdi-folder-clock::before {
  content: "\FAB9"; }

.mdi-folder-clock-outline::before {
  content: "\FABA"; }

.mdi-folder-download::before {
  content: "\F24D"; }

.mdi-folder-download-outline::before {
  content: "\F0114"; }

.mdi-folder-edit::before {
  content: "\F8DD"; }

.mdi-folder-edit-outline::before {
  content: "\FDAA"; }

.mdi-folder-google-drive::before {
  content: "\F24E"; }

.mdi-folder-heart::before {
  content: "\F0115"; }

.mdi-folder-heart-outline::before {
  content: "\F0116"; }

.mdi-folder-home::before {
  content: "\F00E0"; }

.mdi-folder-home-outline::before {
  content: "\F00E1"; }

.mdi-folder-image::before {
  content: "\F24F"; }

.mdi-folder-information::before {
  content: "\F00E2"; }

.mdi-folder-information-outline::before {
  content: "\F00E3"; }

.mdi-folder-key::before {
  content: "\F8AB"; }

.mdi-folder-key-network::before {
  content: "\F8AC"; }

.mdi-folder-key-network-outline::before {
  content: "\FC5C"; }

.mdi-folder-key-outline::before {
  content: "\F0117"; }

.mdi-folder-lock::before {
  content: "\F250"; }

.mdi-folder-lock-open::before {
  content: "\F251"; }

.mdi-folder-marker::before {
  content: "\F0298"; }

.mdi-folder-marker-outline::before {
  content: "\F0299"; }

.mdi-folder-move::before {
  content: "\F252"; }

.mdi-folder-move-outline::before {
  content: "\F0271"; }

.mdi-folder-multiple::before {
  content: "\F253"; }

.mdi-folder-multiple-image::before {
  content: "\F254"; }

.mdi-folder-multiple-outline::before {
  content: "\F255"; }

.mdi-folder-music::before {
  content: "\F0384"; }

.mdi-folder-music-outline::before {
  content: "\F0385"; }

.mdi-folder-network::before {
  content: "\F86F"; }

.mdi-folder-network-outline::before {
  content: "\FC5D"; }

.mdi-folder-open::before {
  content: "\F76F"; }

.mdi-folder-open-outline::before {
  content: "\FDAB"; }

.mdi-folder-outline::before {
  content: "\F256"; }

.mdi-folder-plus::before {
  content: "\F257"; }

.mdi-folder-plus-outline::before {
  content: "\FB79"; }

.mdi-folder-pound::before {
  content: "\FCE5"; }

.mdi-folder-pound-outline::before {
  content: "\FCE6"; }

.mdi-folder-remove::before {
  content: "\F258"; }

.mdi-folder-remove-outline::before {
  content: "\FB7A"; }

.mdi-folder-search::before {
  content: "\F967"; }

.mdi-folder-search-outline::before {
  content: "\F968"; }

.mdi-folder-settings::before {
  content: "\F00A8"; }

.mdi-folder-settings-outline::before {
  content: "\F00A9"; }

.mdi-folder-settings-variant::before {
  content: "\F00AA"; }

.mdi-folder-settings-variant-outline::before {
  content: "\F00AB"; }

.mdi-folder-star::before {
  content: "\F69C"; }

.mdi-folder-star-outline::before {
  content: "\FB7B"; }

.mdi-folder-swap::before {
  content: "\FFD6"; }

.mdi-folder-swap-outline::before {
  content: "\FFD7"; }

.mdi-folder-sync::before {
  content: "\FCE7"; }

.mdi-folder-sync-outline::before {
  content: "\FCE8"; }

.mdi-folder-table::before {
  content: "\F030E"; }

.mdi-folder-table-outline::before {
  content: "\F030F"; }

.mdi-folder-text::before {
  content: "\FC5E"; }

.mdi-folder-text-outline::before {
  content: "\FC5F"; }

.mdi-folder-upload::before {
  content: "\F259"; }

.mdi-folder-upload-outline::before {
  content: "\F0118"; }

.mdi-folder-zip::before {
  content: "\F6EA"; }

.mdi-folder-zip-outline::before {
  content: "\F7B8"; }

.mdi-font-awesome::before {
  content: "\F03A"; }

.mdi-food::before {
  content: "\F25A"; }

.mdi-food-apple::before {
  content: "\F25B"; }

.mdi-food-apple-outline::before {
  content: "\FC60"; }

.mdi-food-croissant::before {
  content: "\F7C7"; }

.mdi-food-fork-drink::before {
  content: "\F5F2"; }

.mdi-food-off::before {
  content: "\F5F3"; }

.mdi-food-variant::before {
  content: "\F25C"; }

.mdi-foot-print::before {
  content: "\FF6F"; }

.mdi-football::before {
  content: "\F25D"; }

.mdi-football-australian::before {
  content: "\F25E"; }

.mdi-football-helmet::before {
  content: "\F25F"; }

.mdi-forklift::before {
  content: "\F7C8"; }

.mdi-format-align-bottom::before {
  content: "\F752"; }

.mdi-format-align-center::before {
  content: "\F260"; }

.mdi-format-align-justify::before {
  content: "\F261"; }

.mdi-format-align-left::before {
  content: "\F262"; }

.mdi-format-align-middle::before {
  content: "\F753"; }

.mdi-format-align-right::before {
  content: "\F263"; }

.mdi-format-align-top::before {
  content: "\F754"; }

.mdi-format-annotation-minus::before {
  content: "\FABB"; }

.mdi-format-annotation-plus::before {
  content: "\F646"; }

.mdi-format-bold::before {
  content: "\F264"; }

.mdi-format-clear::before {
  content: "\F265"; }

.mdi-format-color-fill::before {
  content: "\F266"; }

.mdi-format-color-highlight::before {
  content: "\FE14"; }

.mdi-format-color-marker-cancel::before {
  content: "\F033E"; }

.mdi-format-color-text::before {
  content: "\F69D"; }

.mdi-format-columns::before {
  content: "\F8DE"; }

.mdi-format-float-center::before {
  content: "\F267"; }

.mdi-format-float-left::before {
  content: "\F268"; }

.mdi-format-float-none::before {
  content: "\F269"; }

.mdi-format-float-right::before {
  content: "\F26A"; }

.mdi-format-font::before {
  content: "\F6D5"; }

.mdi-format-font-size-decrease::before {
  content: "\F9F2"; }

.mdi-format-font-size-increase::before {
  content: "\F9F3"; }

.mdi-format-header-1::before {
  content: "\F26B"; }

.mdi-format-header-2::before {
  content: "\F26C"; }

.mdi-format-header-3::before {
  content: "\F26D"; }

.mdi-format-header-4::before {
  content: "\F26E"; }

.mdi-format-header-5::before {
  content: "\F26F"; }

.mdi-format-header-6::before {
  content: "\F270"; }

.mdi-format-header-decrease::before {
  content: "\F271"; }

.mdi-format-header-equal::before {
  content: "\F272"; }

.mdi-format-header-increase::before {
  content: "\F273"; }

.mdi-format-header-pound::before {
  content: "\F274"; }

.mdi-format-horizontal-align-center::before {
  content: "\F61E"; }

.mdi-format-horizontal-align-left::before {
  content: "\F61F"; }

.mdi-format-horizontal-align-right::before {
  content: "\F620"; }

.mdi-format-indent-decrease::before {
  content: "\F275"; }

.mdi-format-indent-increase::before {
  content: "\F276"; }

.mdi-format-italic::before {
  content: "\F277"; }

.mdi-format-letter-case::before {
  content: "\FB19"; }

.mdi-format-letter-case-lower::before {
  content: "\FB1A"; }

.mdi-format-letter-case-upper::before {
  content: "\FB1B"; }

.mdi-format-letter-ends-with::before {
  content: "\FFD8"; }

.mdi-format-letter-matches::before {
  content: "\FFD9"; }

.mdi-format-letter-starts-with::before {
  content: "\FFDA"; }

.mdi-format-line-spacing::before {
  content: "\F278"; }

.mdi-format-line-style::before {
  content: "\F5C8"; }

.mdi-format-line-weight::before {
  content: "\F5C9"; }

.mdi-format-list-bulleted::before {
  content: "\F279"; }

.mdi-format-list-bulleted-square::before {
  content: "\FDAC"; }

.mdi-format-list-bulleted-triangle::before {
  content: "\FECF"; }

.mdi-format-list-bulleted-type::before {
  content: "\F27A"; }

.mdi-format-list-checkbox::before {
  content: "\F969"; }

.mdi-format-list-checks::before {
  content: "\F755"; }

.mdi-format-list-numbered::before {
  content: "\F27B"; }

.mdi-format-list-numbered-rtl::before {
  content: "\FCE9"; }

.mdi-format-list-text::before {
  content: "\F029A"; }

.mdi-format-overline::before {
  content: "\FED0"; }

.mdi-format-page-break::before {
  content: "\F6D6"; }

.mdi-format-paint::before {
  content: "\F27C"; }

.mdi-format-paragraph::before {
  content: "\F27D"; }

.mdi-format-pilcrow::before {
  content: "\F6D7"; }

.mdi-format-quote-close::before {
  content: "\F27E"; }

.mdi-format-quote-close-outline::before {
  content: "\F01D3"; }

.mdi-format-quote-open::before {
  content: "\F756"; }

.mdi-format-quote-open-outline::before {
  content: "\F01D2"; }

.mdi-format-rotate-90::before {
  content: "\F6A9"; }

.mdi-format-section::before {
  content: "\F69E"; }

.mdi-format-size::before {
  content: "\F27F"; }

.mdi-format-strikethrough::before {
  content: "\F280"; }

.mdi-format-strikethrough-variant::before {
  content: "\F281"; }

.mdi-format-subscript::before {
  content: "\F282"; }

.mdi-format-superscript::before {
  content: "\F283"; }

.mdi-format-text::before {
  content: "\F284"; }

.mdi-format-text-rotation-angle-down::before {
  content: "\FFDB"; }

.mdi-format-text-rotation-angle-up::before {
  content: "\FFDC"; }

.mdi-format-text-rotation-down::before {
  content: "\FD4F"; }

.mdi-format-text-rotation-down-vertical::before {
  content: "\FFDD"; }

.mdi-format-text-rotation-none::before {
  content: "\FD50"; }

.mdi-format-text-rotation-up::before {
  content: "\FFDE"; }

.mdi-format-text-rotation-vertical::before {
  content: "\FFDF"; }

.mdi-format-text-variant::before {
  content: "\FE15"; }

.mdi-format-text-wrapping-clip::before {
  content: "\FCEA"; }

.mdi-format-text-wrapping-overflow::before {
  content: "\FCEB"; }

.mdi-format-text-wrapping-wrap::before {
  content: "\FCEC"; }

.mdi-format-textbox::before {
  content: "\FCED"; }

.mdi-format-textdirection-l-to-r::before {
  content: "\F285"; }

.mdi-format-textdirection-r-to-l::before {
  content: "\F286"; }

.mdi-format-title::before {
  content: "\F5F4"; }

.mdi-format-underline::before {
  content: "\F287"; }

.mdi-format-vertical-align-bottom::before {
  content: "\F621"; }

.mdi-format-vertical-align-center::before {
  content: "\F622"; }

.mdi-format-vertical-align-top::before {
  content: "\F623"; }

.mdi-format-wrap-inline::before {
  content: "\F288"; }

.mdi-format-wrap-square::before {
  content: "\F289"; }

.mdi-format-wrap-tight::before {
  content: "\F28A"; }

.mdi-format-wrap-top-bottom::before {
  content: "\F28B"; }

.mdi-forum::before {
  content: "\F28C"; }

.mdi-forum-outline::before {
  content: "\F821"; }

.mdi-forward::before {
  content: "\F28D"; }

.mdi-forwardburger::before {
  content: "\FD51"; }

.mdi-fountain::before {
  content: "\F96A"; }

.mdi-fountain-pen::before {
  content: "\FCEE"; }

.mdi-fountain-pen-tip::before {
  content: "\FCEF"; }

.mdi-foursquare::before {
  content: "\F28E"; }

.mdi-freebsd::before {
  content: "\F8DF"; }

.mdi-frequently-asked-questions::before {
  content: "\FED1"; }

.mdi-fridge::before {
  content: "\F290"; }

.mdi-fridge-alert::before {
  content: "\F01DC"; }

.mdi-fridge-alert-outline::before {
  content: "\F01DD"; }

.mdi-fridge-bottom::before {
  content: "\F292"; }

.mdi-fridge-off::before {
  content: "\F01DA"; }

.mdi-fridge-off-outline::before {
  content: "\F01DB"; }

.mdi-fridge-outline::before {
  content: "\F28F"; }

.mdi-fridge-top::before {
  content: "\F291"; }

.mdi-fruit-cherries::before {
  content: "\F0064"; }

.mdi-fruit-citrus::before {
  content: "\F0065"; }

.mdi-fruit-grapes::before {
  content: "\F0066"; }

.mdi-fruit-grapes-outline::before {
  content: "\F0067"; }

.mdi-fruit-pineapple::before {
  content: "\F0068"; }

.mdi-fruit-watermelon::before {
  content: "\F0069"; }

.mdi-fuel::before {
  content: "\F7C9"; }

.mdi-fullscreen::before {
  content: "\F293"; }

.mdi-fullscreen-exit::before {
  content: "\F294"; }

.mdi-function::before {
  content: "\F295"; }

.mdi-function-variant::before {
  content: "\F870"; }

.mdi-furigana-horizontal::before {
  content: "\F00AC"; }

.mdi-furigana-vertical::before {
  content: "\F00AD"; }

.mdi-fuse::before {
  content: "\FC61"; }

.mdi-fuse-blade::before {
  content: "\FC62"; }

.mdi-gamepad::before {
  content: "\F296"; }

.mdi-gamepad-circle::before {
  content: "\FE16"; }

.mdi-gamepad-circle-down::before {
  content: "\FE17"; }

.mdi-gamepad-circle-left::before {
  content: "\FE18"; }

.mdi-gamepad-circle-outline::before {
  content: "\FE19"; }

.mdi-gamepad-circle-right::before {
  content: "\FE1A"; }

.mdi-gamepad-circle-up::before {
  content: "\FE1B"; }

.mdi-gamepad-down::before {
  content: "\FE1C"; }

.mdi-gamepad-left::before {
  content: "\FE1D"; }

.mdi-gamepad-right::before {
  content: "\FE1E"; }

.mdi-gamepad-round::before {
  content: "\FE1F"; }

.mdi-gamepad-round-down::before {
  content: "\FE7E"; }

.mdi-gamepad-round-left::before {
  content: "\FE7F"; }

.mdi-gamepad-round-outline::before {
  content: "\FE80"; }

.mdi-gamepad-round-right::before {
  content: "\FE81"; }

.mdi-gamepad-round-up::before {
  content: "\FE82"; }

.mdi-gamepad-square::before {
  content: "\FED2"; }

.mdi-gamepad-square-outline::before {
  content: "\FED3"; }

.mdi-gamepad-up::before {
  content: "\FE83"; }

.mdi-gamepad-variant::before {
  content: "\F297"; }

.mdi-gamepad-variant-outline::before {
  content: "\FED4"; }

.mdi-gamma::before {
  content: "\F0119"; }

.mdi-gantry-crane::before {
  content: "\FDAD"; }

.mdi-garage::before {
  content: "\F6D8"; }

.mdi-garage-alert::before {
  content: "\F871"; }

.mdi-garage-alert-variant::before {
  content: "\F0300"; }

.mdi-garage-open::before {
  content: "\F6D9"; }

.mdi-garage-open-variant::before {
  content: "\F02FF"; }

.mdi-garage-variant::before {
  content: "\F02FE"; }

.mdi-gas-cylinder::before {
  content: "\F647"; }

.mdi-gas-station::before {
  content: "\F298"; }

.mdi-gas-station-outline::before {
  content: "\FED5"; }

.mdi-gate::before {
  content: "\F299"; }

.mdi-gate-and::before {
  content: "\F8E0"; }

.mdi-gate-arrow-right::before {
  content: "\F0194"; }

.mdi-gate-nand::before {
  content: "\F8E1"; }

.mdi-gate-nor::before {
  content: "\F8E2"; }

.mdi-gate-not::before {
  content: "\F8E3"; }

.mdi-gate-open::before {
  content: "\F0195"; }

.mdi-gate-or::before {
  content: "\F8E4"; }

.mdi-gate-xnor::before {
  content: "\F8E5"; }

.mdi-gate-xor::before {
  content: "\F8E6"; }

.mdi-gatsby::before {
  content: "\FE84"; }

.mdi-gauge::before {
  content: "\F29A"; }

.mdi-gauge-empty::before {
  content: "\F872"; }

.mdi-gauge-full::before {
  content: "\F873"; }

.mdi-gauge-low::before {
  content: "\F874"; }

.mdi-gavel::before {
  content: "\F29B"; }

.mdi-gender-female::before {
  content: "\F29C"; }

.mdi-gender-male::before {
  content: "\F29D"; }

.mdi-gender-male-female::before {
  content: "\F29E"; }

.mdi-gender-male-female-variant::before {
  content: "\F016A"; }

.mdi-gender-non-binary::before {
  content: "\F016B"; }

.mdi-gender-transgender::before {
  content: "\F29F"; }

.mdi-gentoo::before {
  content: "\F8E7"; }

.mdi-gesture::before {
  content: "\F7CA"; }

.mdi-gesture-double-tap::before {
  content: "\F73B"; }

.mdi-gesture-pinch::before {
  content: "\FABC"; }

.mdi-gesture-spread::before {
  content: "\FABD"; }

.mdi-gesture-swipe::before {
  content: "\FD52"; }

.mdi-gesture-swipe-down::before {
  content: "\F73C"; }

.mdi-gesture-swipe-horizontal::before {
  content: "\FABE"; }

.mdi-gesture-swipe-left::before {
  content: "\F73D"; }

.mdi-gesture-swipe-right::before {
  content: "\F73E"; }

.mdi-gesture-swipe-up::before {
  content: "\F73F"; }

.mdi-gesture-swipe-vertical::before {
  content: "\FABF"; }

.mdi-gesture-tap::before {
  content: "\F740"; }

.mdi-gesture-tap-box::before {
  content: "\F02D4"; }

.mdi-gesture-tap-button::before {
  content: "\F02D3"; }

.mdi-gesture-tap-hold::before {
  content: "\FD53"; }

.mdi-gesture-two-double-tap::before {
  content: "\F741"; }

.mdi-gesture-two-tap::before {
  content: "\F742"; }

.mdi-ghost::before {
  content: "\F2A0"; }

.mdi-ghost-off::before {
  content: "\F9F4"; }

.mdi-gif::before {
  content: "\FD54"; }

.mdi-gift::before {
  content: "\FE85"; }

.mdi-gift-outline::before {
  content: "\F2A1"; }

.mdi-git::before {
  content: "\F2A2"; }

.mdi-github-box::before {
  content: "\F2A3"; }

.mdi-github-circle::before {
  content: "\F2A4"; }

.mdi-github-face::before {
  content: "\F6DA"; }

.mdi-gitlab::before {
  content: "\FB7C"; }

.mdi-glass-cocktail::before {
  content: "\F356"; }

.mdi-glass-flute::before {
  content: "\F2A5"; }

.mdi-glass-mug::before {
  content: "\F2A6"; }

.mdi-glass-mug-variant::before {
  content: "\F0141"; }

.mdi-glass-pint-outline::before {
  content: "\F0338"; }

.mdi-glass-stange::before {
  content: "\F2A7"; }

.mdi-glass-tulip::before {
  content: "\F2A8"; }

.mdi-glass-wine::before {
  content: "\F875"; }

.mdi-glassdoor::before {
  content: "\F2A9"; }

.mdi-glasses::before {
  content: "\F2AA"; }

.mdi-globe-light::before {
  content: "\F0302"; }

.mdi-globe-model::before {
  content: "\F8E8"; }

.mdi-gmail::before {
  content: "\F2AB"; }

.mdi-gnome::before {
  content: "\F2AC"; }

.mdi-go-kart::before {
  content: "\FD55"; }

.mdi-go-kart-track::before {
  content: "\FD56"; }

.mdi-gog::before {
  content: "\FB7D"; }

.mdi-gold::before {
  content: "\F027A"; }

.mdi-golf::before {
  content: "\F822"; }

.mdi-golf-cart::before {
  content: "\F01CF"; }

.mdi-golf-tee::before {
  content: "\F00AE"; }

.mdi-gondola::before {
  content: "\F685"; }

.mdi-goodreads::before {
  content: "\FD57"; }

.mdi-google::before {
  content: "\F2AD"; }

.mdi-google-adwords::before {
  content: "\FC63"; }

.mdi-google-analytics::before {
  content: "\F7CB"; }

.mdi-google-assistant::before {
  content: "\F7CC"; }

.mdi-google-cardboard::before {
  content: "\F2AE"; }

.mdi-google-chrome::before {
  content: "\F2AF"; }

.mdi-google-circles::before {
  content: "\F2B0"; }

.mdi-google-circles-communities::before {
  content: "\F2B1"; }

.mdi-google-circles-extended::before {
  content: "\F2B2"; }

.mdi-google-circles-group::before {
  content: "\F2B3"; }

.mdi-google-classroom::before {
  content: "\F2C0"; }

.mdi-google-cloud::before {
  content: "\F0221"; }

.mdi-google-controller::before {
  content: "\F2B4"; }

.mdi-google-controller-off::before {
  content: "\F2B5"; }

.mdi-google-downasaur::before {
  content: "\F038D"; }

.mdi-google-drive::before {
  content: "\F2B6"; }

.mdi-google-earth::before {
  content: "\F2B7"; }

.mdi-google-fit::before {
  content: "\F96B"; }

.mdi-google-glass::before {
  content: "\F2B8"; }

.mdi-google-hangouts::before {
  content: "\F2C9"; }

.mdi-google-home::before {
  content: "\F823"; }

.mdi-google-keep::before {
  content: "\F6DB"; }

.mdi-google-lens::before {
  content: "\F9F5"; }

.mdi-google-maps::before {
  content: "\F5F5"; }

.mdi-google-my-business::before {
  content: "\F006A"; }

.mdi-google-nearby::before {
  content: "\F2B9"; }

.mdi-google-pages::before {
  content: "\F2BA"; }

.mdi-google-photos::before {
  content: "\F6DC"; }

.mdi-google-physical-web::before {
  content: "\F2BB"; }

.mdi-google-play::before {
  content: "\F2BC"; }

.mdi-google-plus::before {
  content: "\F2BD"; }

.mdi-google-plus-box::before {
  content: "\F2BE"; }

.mdi-google-podcast::before {
  content: "\FED6"; }

.mdi-google-spreadsheet::before {
  content: "\F9F6"; }

.mdi-google-street-view::before {
  content: "\FC64"; }

.mdi-google-translate::before {
  content: "\F2BF"; }

.mdi-gradient::before {
  content: "\F69F"; }

.mdi-grain::before {
  content: "\FD58"; }

.mdi-graph::before {
  content: "\F006B"; }

.mdi-graph-outline::before {
  content: "\F006C"; }

.mdi-graphql::before {
  content: "\F876"; }

.mdi-grave-stone::before {
  content: "\FB7E"; }

.mdi-grease-pencil::before {
  content: "\F648"; }

.mdi-greater-than::before {
  content: "\F96C"; }

.mdi-greater-than-or-equal::before {
  content: "\F96D"; }

.mdi-grid::before {
  content: "\F2C1"; }

.mdi-grid-large::before {
  content: "\F757"; }

.mdi-grid-off::before {
  content: "\F2C2"; }

.mdi-grill::before {
  content: "\FE86"; }

.mdi-grill-outline::before {
  content: "\F01B5"; }

.mdi-group::before {
  content: "\F2C3"; }

.mdi-guitar-acoustic::before {
  content: "\F770"; }

.mdi-guitar-electric::before {
  content: "\F2C4"; }

.mdi-guitar-pick::before {
  content: "\F2C5"; }

.mdi-guitar-pick-outline::before {
  content: "\F2C6"; }

.mdi-guy-fawkes-mask::before {
  content: "\F824"; }

.mdi-hackernews::before {
  content: "\F624"; }

.mdi-hail::before {
  content: "\FAC0"; }

.mdi-hair-dryer::before {
  content: "\F011A"; }

.mdi-hair-dryer-outline::before {
  content: "\F011B"; }

.mdi-halloween::before {
  content: "\FB7F"; }

.mdi-hamburger::before {
  content: "\F684"; }

.mdi-hammer::before {
  content: "\F8E9"; }

.mdi-hammer-screwdriver::before {
  content: "\F034D"; }

.mdi-hammer-wrench::before {
  content: "\F034E"; }

.mdi-hand::before {
  content: "\FA4E"; }

.mdi-hand-heart::before {
  content: "\F011C"; }

.mdi-hand-left::before {
  content: "\FE87"; }

.mdi-hand-okay::before {
  content: "\FA4F"; }

.mdi-hand-peace::before {
  content: "\FA50"; }

.mdi-hand-peace-variant::before {
  content: "\FA51"; }

.mdi-hand-pointing-down::before {
  content: "\FA52"; }

.mdi-hand-pointing-left::before {
  content: "\FA53"; }

.mdi-hand-pointing-right::before {
  content: "\F2C7"; }

.mdi-hand-pointing-up::before {
  content: "\FA54"; }

.mdi-hand-right::before {
  content: "\FE88"; }

.mdi-hand-saw::before {
  content: "\FE89"; }

.mdi-handball::before {
  content: "\FF70"; }

.mdi-handcuffs::before {
  content: "\F0169"; }

.mdi-handshake::before {
  content: "\F0243"; }

.mdi-hanger::before {
  content: "\F2C8"; }

.mdi-hard-hat::before {
  content: "\F96E"; }

.mdi-harddisk::before {
  content: "\F2CA"; }

.mdi-harddisk-plus::before {
  content: "\F006D"; }

.mdi-harddisk-remove::before {
  content: "\F006E"; }

.mdi-hat-fedora::before {
  content: "\FB80"; }

.mdi-hazard-lights::before {
  content: "\FC65"; }

.mdi-hdr::before {
  content: "\FD59"; }

.mdi-hdr-off::before {
  content: "\FD5A"; }

.mdi-head::before {
  content: "\F0389"; }

.mdi-head-alert::before {
  content: "\F0363"; }

.mdi-head-alert-outline::before {
  content: "\F0364"; }

.mdi-head-check::before {
  content: "\F0365"; }

.mdi-head-check-outline::before {
  content: "\F0366"; }

.mdi-head-cog::before {
  content: "\F0367"; }

.mdi-head-cog-outline::before {
  content: "\F0368"; }

.mdi-head-dots-horizontal::before {
  content: "\F0369"; }

.mdi-head-dots-horizontal-outline::before {
  content: "\F036A"; }

.mdi-head-flash::before {
  content: "\F036B"; }

.mdi-head-flash-outline::before {
  content: "\F036C"; }

.mdi-head-heart::before {
  content: "\F036D"; }

.mdi-head-heart-outline::before {
  content: "\F036E"; }

.mdi-head-lightbulb::before {
  content: "\F036F"; }

.mdi-head-lightbulb-outline::before {
  content: "\F0370"; }

.mdi-head-minus::before {
  content: "\F0371"; }

.mdi-head-minus-outline::before {
  content: "\F0372"; }

.mdi-head-outline::before {
  content: "\F038A"; }

.mdi-head-plus::before {
  content: "\F0373"; }

.mdi-head-plus-outline::before {
  content: "\F0374"; }

.mdi-head-question::before {
  content: "\F0375"; }

.mdi-head-question-outline::before {
  content: "\F0376"; }

.mdi-head-remove::before {
  content: "\F0377"; }

.mdi-head-remove-outline::before {
  content: "\F0378"; }

.mdi-head-snowflake::before {
  content: "\F0379"; }

.mdi-head-snowflake-outline::before {
  content: "\F037A"; }

.mdi-head-sync::before {
  content: "\F037B"; }

.mdi-head-sync-outline::before {
  content: "\F037C"; }

.mdi-headphones::before {
  content: "\F2CB"; }

.mdi-headphones-bluetooth::before {
  content: "\F96F"; }

.mdi-headphones-box::before {
  content: "\F2CC"; }

.mdi-headphones-off::before {
  content: "\F7CD"; }

.mdi-headphones-settings::before {
  content: "\F2CD"; }

.mdi-headset::before {
  content: "\F2CE"; }

.mdi-headset-dock::before {
  content: "\F2CF"; }

.mdi-headset-off::before {
  content: "\F2D0"; }

.mdi-heart::before {
  content: "\F2D1"; }

.mdi-heart-box::before {
  content: "\F2D2"; }

.mdi-heart-box-outline::before {
  content: "\F2D3"; }

.mdi-heart-broken::before {
  content: "\F2D4"; }

.mdi-heart-broken-outline::before {
  content: "\FCF0"; }

.mdi-heart-circle::before {
  content: "\F970"; }

.mdi-heart-circle-outline::before {
  content: "\F971"; }

.mdi-heart-flash::before {
  content: "\FF16"; }

.mdi-heart-half::before {
  content: "\F6DE"; }

.mdi-heart-half-full::before {
  content: "\F6DD"; }

.mdi-heart-half-outline::before {
  content: "\F6DF"; }

.mdi-heart-multiple::before {
  content: "\FA55"; }

.mdi-heart-multiple-outline::before {
  content: "\FA56"; }

.mdi-heart-off::before {
  content: "\F758"; }

.mdi-heart-outline::before {
  content: "\F2D5"; }

.mdi-heart-pulse::before {
  content: "\F5F6"; }

.mdi-helicopter::before {
  content: "\FAC1"; }

.mdi-help::before {
  content: "\F2D6"; }

.mdi-help-box::before {
  content: "\F78A"; }

.mdi-help-circle::before {
  content: "\F2D7"; }

.mdi-help-circle-outline::before {
  content: "\F625"; }

.mdi-help-network::before {
  content: "\F6F4"; }

.mdi-help-network-outline::before {
  content: "\FC66"; }

.mdi-help-rhombus::before {
  content: "\FB81"; }

.mdi-help-rhombus-outline::before {
  content: "\FB82"; }

.mdi-hexadecimal::before {
  content: "\F02D2"; }

.mdi-hexagon::before {
  content: "\F2D8"; }

.mdi-hexagon-multiple::before {
  content: "\F6E0"; }

.mdi-hexagon-multiple-outline::before {
  content: "\F011D"; }

.mdi-hexagon-outline::before {
  content: "\F2D9"; }

.mdi-hexagon-slice-1::before {
  content: "\FAC2"; }

.mdi-hexagon-slice-2::before {
  content: "\FAC3"; }

.mdi-hexagon-slice-3::before {
  content: "\FAC4"; }

.mdi-hexagon-slice-4::before {
  content: "\FAC5"; }

.mdi-hexagon-slice-5::before {
  content: "\FAC6"; }

.mdi-hexagon-slice-6::before {
  content: "\FAC7"; }

.mdi-hexagram::before {
  content: "\FAC8"; }

.mdi-hexagram-outline::before {
  content: "\FAC9"; }

.mdi-high-definition::before {
  content: "\F7CE"; }

.mdi-high-definition-box::before {
  content: "\F877"; }

.mdi-highway::before {
  content: "\F5F7"; }

.mdi-hiking::before {
  content: "\FD5B"; }

.mdi-hinduism::before {
  content: "\F972"; }

.mdi-history::before {
  content: "\F2DA"; }

.mdi-hockey-puck::before {
  content: "\F878"; }

.mdi-hockey-sticks::before {
  content: "\F879"; }

.mdi-hololens::before {
  content: "\F2DB"; }

.mdi-home::before {
  content: "\F2DC"; }

.mdi-home-account::before {
  content: "\F825"; }

.mdi-home-alert::before {
  content: "\F87A"; }

.mdi-home-analytics::before {
  content: "\FED7"; }

.mdi-home-assistant::before {
  content: "\F7CF"; }

.mdi-home-automation::before {
  content: "\F7D0"; }

.mdi-home-circle::before {
  content: "\F7D1"; }

.mdi-home-circle-outline::before {
  content: "\F006F"; }

.mdi-home-city::before {
  content: "\FCF1"; }

.mdi-home-city-outline::before {
  content: "\FCF2"; }

.mdi-home-currency-usd::before {
  content: "\F8AE"; }

.mdi-home-edit::before {
  content: "\F0184"; }

.mdi-home-edit-outline::before {
  content: "\F0185"; }

.mdi-home-export-outline::before {
  content: "\FFB8"; }

.mdi-home-flood::before {
  content: "\FF17"; }

.mdi-home-floor-0::before {
  content: "\FDAE"; }

.mdi-home-floor-1::before {
  content: "\FD5C"; }

.mdi-home-floor-2::before {
  content: "\FD5D"; }

.mdi-home-floor-3::before {
  content: "\FD5E"; }

.mdi-home-floor-a::before {
  content: "\FD5F"; }

.mdi-home-floor-b::before {
  content: "\FD60"; }

.mdi-home-floor-g::before {
  content: "\FD61"; }

.mdi-home-floor-l::before {
  content: "\FD62"; }

.mdi-home-floor-negative-1::before {
  content: "\FDAF"; }

.mdi-home-group::before {
  content: "\FDB0"; }

.mdi-home-heart::before {
  content: "\F826"; }

.mdi-home-import-outline::before {
  content: "\FFB9"; }

.mdi-home-lightbulb::before {
  content: "\F027C"; }

.mdi-home-lightbulb-outline::before {
  content: "\F027D"; }

.mdi-home-lock::before {
  content: "\F8EA"; }

.mdi-home-lock-open::before {
  content: "\F8EB"; }

.mdi-home-map-marker::before {
  content: "\F5F8"; }

.mdi-home-minus::before {
  content: "\F973"; }

.mdi-home-modern::before {
  content: "\F2DD"; }

.mdi-home-outline::before {
  content: "\F6A0"; }

.mdi-home-plus::before {
  content: "\F974"; }

.mdi-home-remove::before {
  content: "\F0272"; }

.mdi-home-roof::before {
  content: "\F0156"; }

.mdi-home-thermometer::before {
  content: "\FF71"; }

.mdi-home-thermometer-outline::before {
  content: "\FF72"; }

.mdi-home-variant::before {
  content: "\F2DE"; }

.mdi-home-variant-outline::before {
  content: "\FB83"; }

.mdi-hook::before {
  content: "\F6E1"; }

.mdi-hook-off::before {
  content: "\F6E2"; }

.mdi-hops::before {
  content: "\F2DF"; }

.mdi-horizontal-rotate-clockwise::before {
  content: "\F011E"; }

.mdi-horizontal-rotate-counterclockwise::before {
  content: "\F011F"; }

.mdi-horseshoe::before {
  content: "\FA57"; }

.mdi-hospital::before {
  content: "\F0017"; }

.mdi-hospital-box::before {
  content: "\F2E0"; }

.mdi-hospital-box-outline::before {
  content: "\F0018"; }

.mdi-hospital-building::before {
  content: "\F2E1"; }

.mdi-hospital-marker::before {
  content: "\F2E2"; }

.mdi-hot-tub::before {
  content: "\F827"; }

.mdi-hotel::before {
  content: "\F2E3"; }

.mdi-houzz::before {
  content: "\F2E4"; }

.mdi-houzz-box::before {
  content: "\F2E5"; }

.mdi-hubspot::before {
  content: "\FCF3"; }

.mdi-hulu::before {
  content: "\F828"; }

.mdi-human::before {
  content: "\F2E6"; }

.mdi-human-child::before {
  content: "\F2E7"; }

.mdi-human-female::before {
  content: "\F649"; }

.mdi-human-female-boy::before {
  content: "\FA58"; }

.mdi-human-female-female::before {
  content: "\FA59"; }

.mdi-human-female-girl::before {
  content: "\FA5A"; }

.mdi-human-greeting::before {
  content: "\F64A"; }

.mdi-human-handsdown::before {
  content: "\F64B"; }

.mdi-human-handsup::before {
  content: "\F64C"; }

.mdi-human-male::before {
  content: "\F64D"; }

.mdi-human-male-boy::before {
  content: "\FA5B"; }

.mdi-human-male-female::before {
  content: "\F2E8"; }

.mdi-human-male-girl::before {
  content: "\FA5C"; }

.mdi-human-male-height::before {
  content: "\FF18"; }

.mdi-human-male-height-variant::before {
  content: "\FF19"; }

.mdi-human-male-male::before {
  content: "\FA5D"; }

.mdi-human-pregnant::before {
  content: "\F5CF"; }

.mdi-humble-bundle::before {
  content: "\F743"; }

.mdi-hvac::before {
  content: "\F037D"; }

.mdi-hydraulic-oil-level::before {
  content: "\F034F"; }

.mdi-hydraulic-oil-temperature::before {
  content: "\F0350"; }

.mdi-hydro-power::before {
  content: "\F0310"; }

.mdi-ice-cream::before {
  content: "\F829"; }

.mdi-ice-pop::before {
  content: "\FF1A"; }

.mdi-id-card::before {
  content: "\FFE0"; }

.mdi-identifier::before {
  content: "\FF1B"; }

.mdi-ideogram-cjk::before {
  content: "\F035C"; }

.mdi-ideogram-cjk-variant::before {
  content: "\F035D"; }

.mdi-iframe::before {
  content: "\FC67"; }

.mdi-iframe-array::before {
  content: "\F0120"; }

.mdi-iframe-array-outline::before {
  content: "\F0121"; }

.mdi-iframe-braces::before {
  content: "\F0122"; }

.mdi-iframe-braces-outline::before {
  content: "\F0123"; }

.mdi-iframe-outline::before {
  content: "\FC68"; }

.mdi-iframe-parentheses::before {
  content: "\F0124"; }

.mdi-iframe-parentheses-outline::before {
  content: "\F0125"; }

.mdi-iframe-variable::before {
  content: "\F0126"; }

.mdi-iframe-variable-outline::before {
  content: "\F0127"; }

.mdi-image::before {
  content: "\F2E9"; }

.mdi-image-album::before {
  content: "\F2EA"; }

.mdi-image-area::before {
  content: "\F2EB"; }

.mdi-image-area-close::before {
  content: "\F2EC"; }

.mdi-image-auto-adjust::before {
  content: "\FFE1"; }

.mdi-image-broken::before {
  content: "\F2ED"; }

.mdi-image-broken-variant::before {
  content: "\F2EE"; }

.mdi-image-edit::before {
  content: "\F020E"; }

.mdi-image-edit-outline::before {
  content: "\F020F"; }

.mdi-image-filter::before {
  content: "\F2EF"; }

.mdi-image-filter-black-white::before {
  content: "\F2F0"; }

.mdi-image-filter-center-focus::before {
  content: "\F2F1"; }

.mdi-image-filter-center-focus-strong::before {
  content: "\FF1C"; }

.mdi-image-filter-center-focus-strong-outline::before {
  content: "\FF1D"; }

.mdi-image-filter-center-focus-weak::before {
  content: "\F2F2"; }

.mdi-image-filter-drama::before {
  content: "\F2F3"; }

.mdi-image-filter-frames::before {
  content: "\F2F4"; }

.mdi-image-filter-hdr::before {
  content: "\F2F5"; }

.mdi-image-filter-none::before {
  content: "\F2F6"; }

.mdi-image-filter-tilt-shift::before {
  content: "\F2F7"; }

.mdi-image-filter-vintage::before {
  content: "\F2F8"; }

.mdi-image-frame::before {
  content: "\FE8A"; }

.mdi-image-move::before {
  content: "\F9F7"; }

.mdi-image-multiple::before {
  content: "\F2F9"; }

.mdi-image-off::before {
  content: "\F82A"; }

.mdi-image-off-outline::before {
  content: "\F01FC"; }

.mdi-image-outline::before {
  content: "\F975"; }

.mdi-image-plus::before {
  content: "\F87B"; }

.mdi-image-search::before {
  content: "\F976"; }

.mdi-image-search-outline::before {
  content: "\F977"; }

.mdi-image-size-select-actual::before {
  content: "\FC69"; }

.mdi-image-size-select-large::before {
  content: "\FC6A"; }

.mdi-image-size-select-small::before {
  content: "\FC6B"; }

.mdi-import::before {
  content: "\F2FA"; }

.mdi-inbox::before {
  content: "\F686"; }

.mdi-inbox-arrow-down::before {
  content: "\F2FB"; }

.mdi-inbox-arrow-down-outline::before {
  content: "\F029B"; }

.mdi-inbox-arrow-up::before {
  content: "\F3D1"; }

.mdi-inbox-arrow-up-outline::before {
  content: "\F029C"; }

.mdi-inbox-full::before {
  content: "\F029D"; }

.mdi-inbox-full-outline::before {
  content: "\F029E"; }

.mdi-inbox-multiple::before {
  content: "\F8AF"; }

.mdi-inbox-multiple-outline::before {
  content: "\FB84"; }

.mdi-inbox-outline::before {
  content: "\F029F"; }

.mdi-incognito::before {
  content: "\F5F9"; }

.mdi-infinity::before {
  content: "\F6E3"; }

.mdi-information::before {
  content: "\F2FC"; }

.mdi-information-outline::before {
  content: "\F2FD"; }

.mdi-information-variant::before {
  content: "\F64E"; }

.mdi-instagram::before {
  content: "\F2FE"; }

.mdi-instapaper::before {
  content: "\F2FF"; }

.mdi-instrument-triangle::before {
  content: "\F0070"; }

.mdi-internet-explorer::before {
  content: "\F300"; }

.mdi-invert-colors::before {
  content: "\F301"; }

.mdi-invert-colors-off::before {
  content: "\FE8B"; }

.mdi-iobroker::before {
  content: "\F0313"; }

.mdi-ip::before {
  content: "\FA5E"; }

.mdi-ip-network::before {
  content: "\FA5F"; }

.mdi-ip-network-outline::before {
  content: "\FC6C"; }

.mdi-ipod::before {
  content: "\FC6D"; }

.mdi-islam::before {
  content: "\F978"; }

.mdi-island::before {
  content: "\F0071"; }

.mdi-itunes::before {
  content: "\F676"; }

.mdi-iv-bag::before {
  content: "\F00E4"; }

.mdi-jabber::before {
  content: "\FDB1"; }

.mdi-jeepney::before {
  content: "\F302"; }

.mdi-jellyfish::before {
  content: "\FF1E"; }

.mdi-jellyfish-outline::before {
  content: "\FF1F"; }

.mdi-jira::before {
  content: "\F303"; }

.mdi-jquery::before {
  content: "\F87C"; }

.mdi-jsfiddle::before {
  content: "\F304"; }

.mdi-json::before {
  content: "\F626"; }

.mdi-judaism::before {
  content: "\F979"; }

.mdi-jump-rope::before {
  content: "\F032A"; }

.mdi-kabaddi::before {
  content: "\FD63"; }

.mdi-karate::before {
  content: "\F82B"; }

.mdi-keg::before {
  content: "\F305"; }

.mdi-kettle::before {
  content: "\F5FA"; }

.mdi-kettle-alert::before {
  content: "\F0342"; }

.mdi-kettle-alert-outline::before {
  content: "\F0343"; }

.mdi-kettle-off::before {
  content: "\F0346"; }

.mdi-kettle-off-outline::before {
  content: "\F0347"; }

.mdi-kettle-outline::before {
  content: "\FF73"; }

.mdi-kettle-steam::before {
  content: "\F0344"; }

.mdi-kettle-steam-outline::before {
  content: "\F0345"; }

.mdi-kettlebell::before {
  content: "\F032B"; }

.mdi-key::before {
  content: "\F306"; }

.mdi-key-arrow-right::before {
  content: "\F033D"; }

.mdi-key-change::before {
  content: "\F307"; }

.mdi-key-link::before {
  content: "\F01CA"; }

.mdi-key-minus::before {
  content: "\F308"; }

.mdi-key-outline::before {
  content: "\FDB2"; }

.mdi-key-plus::before {
  content: "\F309"; }

.mdi-key-remove::before {
  content: "\F30A"; }

.mdi-key-star::before {
  content: "\F01C9"; }

.mdi-key-variant::before {
  content: "\F30B"; }

.mdi-key-wireless::before {
  content: "\FFE2"; }

.mdi-keyboard::before {
  content: "\F30C"; }

.mdi-keyboard-backspace::before {
  content: "\F30D"; }

.mdi-keyboard-caps::before {
  content: "\F30E"; }

.mdi-keyboard-close::before {
  content: "\F30F"; }

.mdi-keyboard-esc::before {
  content: "\F02E2"; }

.mdi-keyboard-f1::before {
  content: "\F02D6"; }

.mdi-keyboard-f10::before {
  content: "\F02DF"; }

.mdi-keyboard-f11::before {
  content: "\F02E0"; }

.mdi-keyboard-f12::before {
  content: "\F02E1"; }

.mdi-keyboard-f2::before {
  content: "\F02D7"; }

.mdi-keyboard-f3::before {
  content: "\F02D8"; }

.mdi-keyboard-f4::before {
  content: "\F02D9"; }

.mdi-keyboard-f5::before {
  content: "\F02DA"; }

.mdi-keyboard-f6::before {
  content: "\F02DB"; }

.mdi-keyboard-f7::before {
  content: "\F02DC"; }

.mdi-keyboard-f8::before {
  content: "\F02DD"; }

.mdi-keyboard-f9::before {
  content: "\F02DE"; }

.mdi-keyboard-off::before {
  content: "\F310"; }

.mdi-keyboard-off-outline::before {
  content: "\FE8C"; }

.mdi-keyboard-outline::before {
  content: "\F97A"; }

.mdi-keyboard-return::before {
  content: "\F311"; }

.mdi-keyboard-settings::before {
  content: "\F9F8"; }

.mdi-keyboard-settings-outline::before {
  content: "\F9F9"; }

.mdi-keyboard-space::before {
  content: "\F0072"; }

.mdi-keyboard-tab::before {
  content: "\F312"; }

.mdi-keyboard-variant::before {
  content: "\F313"; }

.mdi-khanda::before {
  content: "\F0128"; }

.mdi-kickstarter::before {
  content: "\F744"; }

.mdi-klingon::before {
  content: "\F0386"; }

.mdi-knife::before {
  content: "\F9FA"; }

.mdi-knife-military::before {
  content: "\F9FB"; }

.mdi-kodi::before {
  content: "\F314"; }

.mdi-kotlin::before {
  content: "\F0244"; }

.mdi-kubernetes::before {
  content: "\F0129"; }

.mdi-label::before {
  content: "\F315"; }

.mdi-label-multiple::before {
  content: "\F03A0"; }

.mdi-label-multiple-outline::before {
  content: "\F03A1"; }

.mdi-label-off::before {
  content: "\FACA"; }

.mdi-label-off-outline::before {
  content: "\FACB"; }

.mdi-label-outline::before {
  content: "\F316"; }

.mdi-label-percent::before {
  content: "\F0315"; }

.mdi-label-percent-outline::before {
  content: "\F0316"; }

.mdi-label-variant::before {
  content: "\FACC"; }

.mdi-label-variant-outline::before {
  content: "\FACD"; }

.mdi-ladybug::before {
  content: "\F82C"; }

.mdi-lambda::before {
  content: "\F627"; }

.mdi-lamp::before {
  content: "\F6B4"; }

.mdi-lan::before {
  content: "\F317"; }

.mdi-lan-check::before {
  content: "\F02D5"; }

.mdi-lan-connect::before {
  content: "\F318"; }

.mdi-lan-disconnect::before {
  content: "\F319"; }

.mdi-lan-pending::before {
  content: "\F31A"; }

.mdi-language-c::before {
  content: "\F671"; }

.mdi-language-cpp::before {
  content: "\F672"; }

.mdi-language-csharp::before {
  content: "\F31B"; }

.mdi-language-css3::before {
  content: "\F31C"; }

.mdi-language-fortran::before {
  content: "\F0245"; }

.mdi-language-go::before {
  content: "\F7D2"; }

.mdi-language-haskell::before {
  content: "\FC6E"; }

.mdi-language-html5::before {
  content: "\F31D"; }

.mdi-language-java::before {
  content: "\FB1C"; }

.mdi-language-javascript::before {
  content: "\F31E"; }

.mdi-language-lua::before {
  content: "\F8B0"; }

.mdi-language-php::before {
  content: "\F31F"; }

.mdi-language-python::before {
  content: "\F320"; }

.mdi-language-python-text::before {
  content: "\F321"; }

.mdi-language-r::before {
  content: "\F7D3"; }

.mdi-language-ruby-on-rails::before {
  content: "\FACE"; }

.mdi-language-swift::before {
  content: "\F6E4"; }

.mdi-language-typescript::before {
  content: "\F6E5"; }

.mdi-laptop::before {
  content: "\F322"; }

.mdi-laptop-chromebook::before {
  content: "\F323"; }

.mdi-laptop-mac::before {
  content: "\F324"; }

.mdi-laptop-off::before {
  content: "\F6E6"; }

.mdi-laptop-windows::before {
  content: "\F325"; }

.mdi-laravel::before {
  content: "\FACF"; }

.mdi-lasso::before {
  content: "\FF20"; }

.mdi-lastfm::before {
  content: "\F326"; }

.mdi-lastpass::before {
  content: "\F446"; }

.mdi-latitude::before {
  content: "\FF74"; }

.mdi-launch::before {
  content: "\F327"; }

.mdi-lava-lamp::before {
  content: "\F7D4"; }

.mdi-layers::before {
  content: "\F328"; }

.mdi-layers-minus::before {
  content: "\FE8D"; }

.mdi-layers-off::before {
  content: "\F329"; }

.mdi-layers-off-outline::before {
  content: "\F9FC"; }

.mdi-layers-outline::before {
  content: "\F9FD"; }

.mdi-layers-plus::before {
  content: "\FE30"; }

.mdi-layers-remove::before {
  content: "\FE31"; }

.mdi-layers-search::before {
  content: "\F0231"; }

.mdi-layers-search-outline::before {
  content: "\F0232"; }

.mdi-layers-triple::before {
  content: "\FF75"; }

.mdi-layers-triple-outline::before {
  content: "\FF76"; }

.mdi-lead-pencil::before {
  content: "\F64F"; }

.mdi-leaf::before {
  content: "\F32A"; }

.mdi-leaf-maple::before {
  content: "\FC6F"; }

.mdi-leaf-maple-off::before {
  content: "\F0305"; }

.mdi-leaf-off::before {
  content: "\F0304"; }

.mdi-leak::before {
  content: "\FDB3"; }

.mdi-leak-off::before {
  content: "\FDB4"; }

.mdi-led-off::before {
  content: "\F32B"; }

.mdi-led-on::before {
  content: "\F32C"; }

.mdi-led-outline::before {
  content: "\F32D"; }

.mdi-led-strip::before {
  content: "\F7D5"; }

.mdi-led-strip-variant::before {
  content: "\F0073"; }

.mdi-led-variant-off::before {
  content: "\F32E"; }

.mdi-led-variant-on::before {
  content: "\F32F"; }

.mdi-led-variant-outline::before {
  content: "\F330"; }

.mdi-leek::before {
  content: "\F01A8"; }

.mdi-less-than::before {
  content: "\F97B"; }

.mdi-less-than-or-equal::before {
  content: "\F97C"; }

.mdi-library::before {
  content: "\F331"; }

.mdi-library-books::before {
  content: "\F332"; }

.mdi-library-movie::before {
  content: "\FCF4"; }

.mdi-library-music::before {
  content: "\F333"; }

.mdi-library-music-outline::before {
  content: "\FF21"; }

.mdi-library-shelves::before {
  content: "\FB85"; }

.mdi-library-video::before {
  content: "\FCF5"; }

.mdi-license::before {
  content: "\FFE3"; }

.mdi-lifebuoy::before {
  content: "\F87D"; }

.mdi-light-switch::before {
  content: "\F97D"; }

.mdi-lightbulb::before {
  content: "\F335"; }

.mdi-lightbulb-cfl::before {
  content: "\F0233"; }

.mdi-lightbulb-cfl-off::before {
  content: "\F0234"; }

.mdi-lightbulb-cfl-spiral::before {
  content: "\F02A0"; }

.mdi-lightbulb-cfl-spiral-off::before {
  content: "\F02EE"; }

.mdi-lightbulb-group::before {
  content: "\F027E"; }

.mdi-lightbulb-group-off::before {
  content: "\F02F8"; }

.mdi-lightbulb-group-off-outline::before {
  content: "\F02F9"; }

.mdi-lightbulb-group-outline::before {
  content: "\F027F"; }

.mdi-lightbulb-multiple::before {
  content: "\F0280"; }

.mdi-lightbulb-multiple-off::before {
  content: "\F02FA"; }

.mdi-lightbulb-multiple-off-outline::before {
  content: "\F02FB"; }

.mdi-lightbulb-multiple-outline::before {
  content: "\F0281"; }

.mdi-lightbulb-off::before {
  content: "\FE32"; }

.mdi-lightbulb-off-outline::before {
  content: "\FE33"; }

.mdi-lightbulb-on::before {
  content: "\F6E7"; }

.mdi-lightbulb-on-outline::before {
  content: "\F6E8"; }

.mdi-lightbulb-outline::before {
  content: "\F336"; }

.mdi-lighthouse::before {
  content: "\F9FE"; }

.mdi-lighthouse-on::before {
  content: "\F9FF"; }

.mdi-link::before {
  content: "\F337"; }

.mdi-link-box::before {
  content: "\FCF6"; }

.mdi-link-box-outline::before {
  content: "\FCF7"; }

.mdi-link-box-variant::before {
  content: "\FCF8"; }

.mdi-link-box-variant-outline::before {
  content: "\FCF9"; }

.mdi-link-lock::before {
  content: "\F00E5"; }

.mdi-link-off::before {
  content: "\F338"; }

.mdi-link-plus::before {
  content: "\FC70"; }

.mdi-link-variant::before {
  content: "\F339"; }

.mdi-link-variant-minus::before {
  content: "\F012A"; }

.mdi-link-variant-off::before {
  content: "\F33A"; }

.mdi-link-variant-plus::before {
  content: "\F012B"; }

.mdi-link-variant-remove::before {
  content: "\F012C"; }

.mdi-linkedin::before {
  content: "\F33B"; }

.mdi-linkedin-box::before {
  content: "\F33C"; }

.mdi-linux::before {
  content: "\F33D"; }

.mdi-linux-mint::before {
  content: "\F8EC"; }

.mdi-litecoin::before {
  content: "\FA60"; }

.mdi-loading::before {
  content: "\F771"; }

.mdi-location-enter::before {
  content: "\FFE4"; }

.mdi-location-exit::before {
  content: "\FFE5"; }

.mdi-lock::before {
  content: "\F33E"; }

.mdi-lock-alert::before {
  content: "\F8ED"; }

.mdi-lock-clock::before {
  content: "\F97E"; }

.mdi-lock-open::before {
  content: "\F33F"; }

.mdi-lock-open-outline::before {
  content: "\F340"; }

.mdi-lock-open-variant::before {
  content: "\FFE6"; }

.mdi-lock-open-variant-outline::before {
  content: "\FFE7"; }

.mdi-lock-outline::before {
  content: "\F341"; }

.mdi-lock-pattern::before {
  content: "\F6E9"; }

.mdi-lock-plus::before {
  content: "\F5FB"; }

.mdi-lock-question::before {
  content: "\F8EE"; }

.mdi-lock-reset::before {
  content: "\F772"; }

.mdi-lock-smart::before {
  content: "\F8B1"; }

.mdi-locker::before {
  content: "\F7D6"; }

.mdi-locker-multiple::before {
  content: "\F7D7"; }

.mdi-login::before {
  content: "\F342"; }

.mdi-login-variant::before {
  content: "\F5FC"; }

.mdi-logout::before {
  content: "\F343"; }

.mdi-logout-variant::before {
  content: "\F5FD"; }

.mdi-longitude::before {
  content: "\FF77"; }

.mdi-looks::before {
  content: "\F344"; }

.mdi-loupe::before {
  content: "\F345"; }

.mdi-lumx::before {
  content: "\F346"; }

.mdi-lungs::before {
  content: "\F00AF"; }

.mdi-lyft::before {
  content: "\FB1D"; }

.mdi-magnet::before {
  content: "\F347"; }

.mdi-magnet-on::before {
  content: "\F348"; }

.mdi-magnify::before {
  content: "\F349"; }

.mdi-magnify-close::before {
  content: "\F97F"; }

.mdi-magnify-minus::before {
  content: "\F34A"; }

.mdi-magnify-minus-cursor::before {
  content: "\FA61"; }

.mdi-magnify-minus-outline::before {
  content: "\F6EB"; }

.mdi-magnify-plus::before {
  content: "\F34B"; }

.mdi-magnify-plus-cursor::before {
  content: "\FA62"; }

.mdi-magnify-plus-outline::before {
  content: "\F6EC"; }

.mdi-magnify-remove-cursor::before {
  content: "\F0237"; }

.mdi-magnify-remove-outline::before {
  content: "\F0238"; }

.mdi-magnify-scan::before {
  content: "\F02A1"; }

.mdi-mail::before {
  content: "\FED8"; }

.mdi-mail-ru::before {
  content: "\F34C"; }

.mdi-mailbox::before {
  content: "\F6ED"; }

.mdi-mailbox-open::before {
  content: "\FD64"; }

.mdi-mailbox-open-outline::before {
  content: "\FD65"; }

.mdi-mailbox-open-up::before {
  content: "\FD66"; }

.mdi-mailbox-open-up-outline::before {
  content: "\FD67"; }

.mdi-mailbox-outline::before {
  content: "\FD68"; }

.mdi-mailbox-up::before {
  content: "\FD69"; }

.mdi-mailbox-up-outline::before {
  content: "\FD6A"; }

.mdi-map::before {
  content: "\F34D"; }

.mdi-map-check::before {
  content: "\FED9"; }

.mdi-map-check-outline::before {
  content: "\FEDA"; }

.mdi-map-clock::before {
  content: "\FCFA"; }

.mdi-map-clock-outline::before {
  content: "\FCFB"; }

.mdi-map-legend::before {
  content: "\FA00"; }

.mdi-map-marker::before {
  content: "\F34E"; }

.mdi-map-marker-alert::before {
  content: "\FF22"; }

.mdi-map-marker-alert-outline::before {
  content: "\FF23"; }

.mdi-map-marker-check::before {
  content: "\FC71"; }

.mdi-map-marker-check-outline::before {
  content: "\F0326"; }

.mdi-map-marker-circle::before {
  content: "\F34F"; }

.mdi-map-marker-distance::before {
  content: "\F8EF"; }

.mdi-map-marker-down::before {
  content: "\F012D"; }

.mdi-map-marker-left::before {
  content: "\F0306"; }

.mdi-map-marker-left-outline::before {
  content: "\F0308"; }

.mdi-map-marker-minus::before {
  content: "\F650"; }

.mdi-map-marker-minus-outline::before {
  content: "\F0324"; }

.mdi-map-marker-multiple::before {
  content: "\F350"; }

.mdi-map-marker-multiple-outline::before {
  content: "\F02A2"; }

.mdi-map-marker-off::before {
  content: "\F351"; }

.mdi-map-marker-off-outline::before {
  content: "\F0328"; }

.mdi-map-marker-outline::before {
  content: "\F7D8"; }

.mdi-map-marker-path::before {
  content: "\FCFC"; }

.mdi-map-marker-plus::before {
  content: "\F651"; }

.mdi-map-marker-plus-outline::before {
  content: "\F0323"; }

.mdi-map-marker-question::before {
  content: "\FF24"; }

.mdi-map-marker-question-outline::before {
  content: "\FF25"; }

.mdi-map-marker-radius::before {
  content: "\F352"; }

.mdi-map-marker-radius-outline::before {
  content: "\F0327"; }

.mdi-map-marker-remove::before {
  content: "\FF26"; }

.mdi-map-marker-remove-outline::before {
  content: "\F0325"; }

.mdi-map-marker-remove-variant::before {
  content: "\FF27"; }

.mdi-map-marker-right::before {
  content: "\F0307"; }

.mdi-map-marker-right-outline::before {
  content: "\F0309"; }

.mdi-map-marker-up::before {
  content: "\F012E"; }

.mdi-map-minus::before {
  content: "\F980"; }

.mdi-map-outline::before {
  content: "\F981"; }

.mdi-map-plus::before {
  content: "\F982"; }

.mdi-map-search::before {
  content: "\F983"; }

.mdi-map-search-outline::before {
  content: "\F984"; }

.mdi-mapbox::before {
  content: "\FB86"; }

.mdi-margin::before {
  content: "\F353"; }

.mdi-markdown::before {
  content: "\F354"; }

.mdi-markdown-outline::before {
  content: "\FF78"; }

.mdi-marker::before {
  content: "\F652"; }

.mdi-marker-cancel::before {
  content: "\FDB5"; }

.mdi-marker-check::before {
  content: "\F355"; }

.mdi-mastodon::before {
  content: "\FAD0"; }

.mdi-mastodon-variant::before {
  content: "\FAD1"; }

.mdi-material-design::before {
  content: "\F985"; }

.mdi-material-ui::before {
  content: "\F357"; }

.mdi-math-compass::before {
  content: "\F358"; }

.mdi-math-cos::before {
  content: "\FC72"; }

.mdi-math-integral::before {
  content: "\FFE8"; }

.mdi-math-integral-box::before {
  content: "\FFE9"; }

.mdi-math-log::before {
  content: "\F00B0"; }

.mdi-math-norm::before {
  content: "\FFEA"; }

.mdi-math-norm-box::before {
  content: "\FFEB"; }

.mdi-math-sin::before {
  content: "\FC73"; }

.mdi-math-tan::before {
  content: "\FC74"; }

.mdi-matrix::before {
  content: "\F628"; }

.mdi-medal::before {
  content: "\F986"; }

.mdi-medal-outline::before {
  content: "\F0351"; }

.mdi-medical-bag::before {
  content: "\F6EE"; }

.mdi-meditation::before {
  content: "\F01A6"; }

.mdi-medium::before {
  content: "\F35A"; }

.mdi-meetup::before {
  content: "\FAD2"; }

.mdi-memory::before {
  content: "\F35B"; }

.mdi-menu::before {
  content: "\F35C"; }

.mdi-menu-down::before {
  content: "\F35D"; }

.mdi-menu-down-outline::before {
  content: "\F6B5"; }

.mdi-menu-left::before {
  content: "\F35E"; }

.mdi-menu-left-outline::before {
  content: "\FA01"; }

.mdi-menu-open::before {
  content: "\FB87"; }

.mdi-menu-right::before {
  content: "\F35F"; }

.mdi-menu-right-outline::before {
  content: "\FA02"; }

.mdi-menu-swap::before {
  content: "\FA63"; }

.mdi-menu-swap-outline::before {
  content: "\FA64"; }

.mdi-menu-up::before {
  content: "\F360"; }

.mdi-menu-up-outline::before {
  content: "\F6B6"; }

.mdi-merge::before {
  content: "\FF79"; }

.mdi-message::before {
  content: "\F361"; }

.mdi-message-alert::before {
  content: "\F362"; }

.mdi-message-alert-outline::before {
  content: "\FA03"; }

.mdi-message-arrow-left::before {
  content: "\F031D"; }

.mdi-message-arrow-left-outline::before {
  content: "\F031E"; }

.mdi-message-arrow-right::before {
  content: "\F031F"; }

.mdi-message-arrow-right-outline::before {
  content: "\F0320"; }

.mdi-message-bulleted::before {
  content: "\F6A1"; }

.mdi-message-bulleted-off::before {
  content: "\F6A2"; }

.mdi-message-draw::before {
  content: "\F363"; }

.mdi-message-image::before {
  content: "\F364"; }

.mdi-message-image-outline::before {
  content: "\F0197"; }

.mdi-message-lock::before {
  content: "\FFEC"; }

.mdi-message-lock-outline::before {
  content: "\F0198"; }

.mdi-message-minus::before {
  content: "\F0199"; }

.mdi-message-minus-outline::before {
  content: "\F019A"; }

.mdi-message-outline::before {
  content: "\F365"; }

.mdi-message-plus::before {
  content: "\F653"; }

.mdi-message-plus-outline::before {
  content: "\F00E6"; }

.mdi-message-processing::before {
  content: "\F366"; }

.mdi-message-processing-outline::before {
  content: "\F019B"; }

.mdi-message-reply::before {
  content: "\F367"; }

.mdi-message-reply-text::before {
  content: "\F368"; }

.mdi-message-settings::before {
  content: "\F6EF"; }

.mdi-message-settings-outline::before {
  content: "\F019C"; }

.mdi-message-settings-variant::before {
  content: "\F6F0"; }

.mdi-message-settings-variant-outline::before {
  content: "\F019D"; }

.mdi-message-text::before {
  content: "\F369"; }

.mdi-message-text-clock::before {
  content: "\F019E"; }

.mdi-message-text-clock-outline::before {
  content: "\F019F"; }

.mdi-message-text-lock::before {
  content: "\FFED"; }

.mdi-message-text-lock-outline::before {
  content: "\F01A0"; }

.mdi-message-text-outline::before {
  content: "\F36A"; }

.mdi-message-video::before {
  content: "\F36B"; }

.mdi-meteor::before {
  content: "\F629"; }

.mdi-metronome::before {
  content: "\F7D9"; }

.mdi-metronome-tick::before {
  content: "\F7DA"; }

.mdi-micro-sd::before {
  content: "\F7DB"; }

.mdi-microphone::before {
  content: "\F36C"; }

.mdi-microphone-minus::before {
  content: "\F8B2"; }

.mdi-microphone-off::before {
  content: "\F36D"; }

.mdi-microphone-outline::before {
  content: "\F36E"; }

.mdi-microphone-plus::before {
  content: "\F8B3"; }

.mdi-microphone-settings::before {
  content: "\F36F"; }

.mdi-microphone-variant::before {
  content: "\F370"; }

.mdi-microphone-variant-off::before {
  content: "\F371"; }

.mdi-microscope::before {
  content: "\F654"; }

.mdi-microsoft::before {
  content: "\F372"; }

.mdi-microsoft-dynamics::before {
  content: "\F987"; }

.mdi-microwave::before {
  content: "\FC75"; }

.mdi-middleware::before {
  content: "\FF7A"; }

.mdi-middleware-outline::before {
  content: "\FF7B"; }

.mdi-midi::before {
  content: "\F8F0"; }

.mdi-midi-port::before {
  content: "\F8F1"; }

.mdi-mine::before {
  content: "\FDB6"; }

.mdi-minecraft::before {
  content: "\F373"; }

.mdi-mini-sd::before {
  content: "\FA04"; }

.mdi-minidisc::before {
  content: "\FA05"; }

.mdi-minus::before {
  content: "\F374"; }

.mdi-minus-box::before {
  content: "\F375"; }

.mdi-minus-box-multiple::before {
  content: "\F016C"; }

.mdi-minus-box-multiple-outline::before {
  content: "\F016D"; }

.mdi-minus-box-outline::before {
  content: "\F6F1"; }

.mdi-minus-circle::before {
  content: "\F376"; }

.mdi-minus-circle-outline::before {
  content: "\F377"; }

.mdi-minus-network::before {
  content: "\F378"; }

.mdi-minus-network-outline::before {
  content: "\FC76"; }

.mdi-mirror::before {
  content: "\F0228"; }

.mdi-mixcloud::before {
  content: "\F62A"; }

.mdi-mixed-martial-arts::before {
  content: "\FD6B"; }

.mdi-mixed-reality::before {
  content: "\F87E"; }

.mdi-mixer::before {
  content: "\F7DC"; }

.mdi-molecule::before {
  content: "\FB88"; }

.mdi-monitor::before {
  content: "\F379"; }

.mdi-monitor-cellphone::before {
  content: "\F988"; }

.mdi-monitor-cellphone-star::before {
  content: "\F989"; }

.mdi-monitor-clean::before {
  content: "\F012F"; }

.mdi-monitor-dashboard::before {
  content: "\FA06"; }

.mdi-monitor-edit::before {
  content: "\F02F1"; }

.mdi-monitor-lock::before {
  content: "\FDB7"; }

.mdi-monitor-multiple::before {
  content: "\F37A"; }

.mdi-monitor-off::before {
  content: "\FD6C"; }

.mdi-monitor-screenshot::before {
  content: "\FE34"; }

.mdi-monitor-speaker::before {
  content: "\FF7C"; }

.mdi-monitor-speaker-off::before {
  content: "\FF7D"; }

.mdi-monitor-star::before {
  content: "\FDB8"; }

.mdi-moon-first-quarter::before {
  content: "\FF7E"; }

.mdi-moon-full::before {
  content: "\FF7F"; }

.mdi-moon-last-quarter::before {
  content: "\FF80"; }

.mdi-moon-new::before {
  content: "\FF81"; }

.mdi-moon-waning-crescent::before {
  content: "\FF82"; }

.mdi-moon-waning-gibbous::before {
  content: "\FF83"; }

.mdi-moon-waxing-crescent::before {
  content: "\FF84"; }

.mdi-moon-waxing-gibbous::before {
  content: "\FF85"; }

.mdi-moped::before {
  content: "\F00B1"; }

.mdi-more::before {
  content: "\F37B"; }

.mdi-mother-heart::before {
  content: "\F033F"; }

.mdi-mother-nurse::before {
  content: "\FCFD"; }

.mdi-motion-sensor::before {
  content: "\FD6D"; }

.mdi-motorbike::before {
  content: "\F37C"; }

.mdi-mouse::before {
  content: "\F37D"; }

.mdi-mouse-bluetooth::before {
  content: "\F98A"; }

.mdi-mouse-off::before {
  content: "\F37E"; }

.mdi-mouse-variant::before {
  content: "\F37F"; }

.mdi-mouse-variant-off::before {
  content: "\F380"; }

.mdi-move-resize::before {
  content: "\F655"; }

.mdi-move-resize-variant::before {
  content: "\F656"; }

.mdi-movie::before {
  content: "\F381"; }

.mdi-movie-edit::before {
  content: "\F014D"; }

.mdi-movie-edit-outline::before {
  content: "\F014E"; }

.mdi-movie-filter::before {
  content: "\F014F"; }

.mdi-movie-filter-outline::before {
  content: "\F0150"; }

.mdi-movie-open::before {
  content: "\FFEE"; }

.mdi-movie-open-outline::before {
  content: "\FFEF"; }

.mdi-movie-outline::before {
  content: "\FDB9"; }

.mdi-movie-roll::before {
  content: "\F7DD"; }

.mdi-movie-search::before {
  content: "\F01FD"; }

.mdi-movie-search-outline::before {
  content: "\F01FE"; }

.mdi-muffin::before {
  content: "\F98B"; }

.mdi-multiplication::before {
  content: "\F382"; }

.mdi-multiplication-box::before {
  content: "\F383"; }

.mdi-mushroom::before {
  content: "\F7DE"; }

.mdi-mushroom-outline::before {
  content: "\F7DF"; }

.mdi-music::before {
  content: "\F759"; }

.mdi-music-accidental-double-flat::before {
  content: "\FF86"; }

.mdi-music-accidental-double-sharp::before {
  content: "\FF87"; }

.mdi-music-accidental-flat::before {
  content: "\FF88"; }

.mdi-music-accidental-natural::before {
  content: "\FF89"; }

.mdi-music-accidental-sharp::before {
  content: "\FF8A"; }

.mdi-music-box::before {
  content: "\F384"; }

.mdi-music-box-outline::before {
  content: "\F385"; }

.mdi-music-circle::before {
  content: "\F386"; }

.mdi-music-circle-outline::before {
  content: "\FAD3"; }

.mdi-music-clef-alto::before {
  content: "\FF8B"; }

.mdi-music-clef-bass::before {
  content: "\FF8C"; }

.mdi-music-clef-treble::before {
  content: "\FF8D"; }

.mdi-music-note::before {
  content: "\F387"; }

.mdi-music-note-bluetooth::before {
  content: "\F5FE"; }

.mdi-music-note-bluetooth-off::before {
  content: "\F5FF"; }

.mdi-music-note-eighth::before {
  content: "\F388"; }

.mdi-music-note-eighth-dotted::before {
  content: "\FF8E"; }

.mdi-music-note-half::before {
  content: "\F389"; }

.mdi-music-note-half-dotted::before {
  content: "\FF8F"; }

.mdi-music-note-off::before {
  content: "\F38A"; }

.mdi-music-note-off-outline::before {
  content: "\FF90"; }

.mdi-music-note-outline::before {
  content: "\FF91"; }

.mdi-music-note-plus::before {
  content: "\FDBA"; }

.mdi-music-note-quarter::before {
  content: "\F38B"; }

.mdi-music-note-quarter-dotted::before {
  content: "\FF92"; }

.mdi-music-note-sixteenth::before {
  content: "\F38C"; }

.mdi-music-note-sixteenth-dotted::before {
  content: "\FF93"; }

.mdi-music-note-whole::before {
  content: "\F38D"; }

.mdi-music-note-whole-dotted::before {
  content: "\FF94"; }

.mdi-music-off::before {
  content: "\F75A"; }

.mdi-music-rest-eighth::before {
  content: "\FF95"; }

.mdi-music-rest-half::before {
  content: "\FF96"; }

.mdi-music-rest-quarter::before {
  content: "\FF97"; }

.mdi-music-rest-sixteenth::before {
  content: "\FF98"; }

.mdi-music-rest-whole::before {
  content: "\FF99"; }

.mdi-nail::before {
  content: "\FDBB"; }

.mdi-nas::before {
  content: "\F8F2"; }

.mdi-nativescript::before {
  content: "\F87F"; }

.mdi-nature::before {
  content: "\F38E"; }

.mdi-nature-people::before {
  content: "\F38F"; }

.mdi-navigation::before {
  content: "\F390"; }

.mdi-near-me::before {
  content: "\F5CD"; }

.mdi-necklace::before {
  content: "\FF28"; }

.mdi-needle::before {
  content: "\F391"; }

.mdi-netflix::before {
  content: "\F745"; }

.mdi-network::before {
  content: "\F6F2"; }

.mdi-network-off::before {
  content: "\FC77"; }

.mdi-network-off-outline::before {
  content: "\FC78"; }

.mdi-network-outline::before {
  content: "\FC79"; }

.mdi-network-router::before {
  content: "\F00B2"; }

.mdi-network-strength-1::before {
  content: "\F8F3"; }

.mdi-network-strength-1-alert::before {
  content: "\F8F4"; }

.mdi-network-strength-2::before {
  content: "\F8F5"; }

.mdi-network-strength-2-alert::before {
  content: "\F8F6"; }

.mdi-network-strength-3::before {
  content: "\F8F7"; }

.mdi-network-strength-3-alert::before {
  content: "\F8F8"; }

.mdi-network-strength-4::before {
  content: "\F8F9"; }

.mdi-network-strength-4-alert::before {
  content: "\F8FA"; }

.mdi-network-strength-off::before {
  content: "\F8FB"; }

.mdi-network-strength-off-outline::before {
  content: "\F8FC"; }

.mdi-network-strength-outline::before {
  content: "\F8FD"; }

.mdi-new-box::before {
  content: "\F394"; }

.mdi-newspaper::before {
  content: "\F395"; }

.mdi-newspaper-minus::before {
  content: "\FF29"; }

.mdi-newspaper-plus::before {
  content: "\FF2A"; }

.mdi-newspaper-variant::before {
  content: "\F0023"; }

.mdi-newspaper-variant-multiple::before {
  content: "\F0024"; }

.mdi-newspaper-variant-multiple-outline::before {
  content: "\F0025"; }

.mdi-newspaper-variant-outline::before {
  content: "\F0026"; }

.mdi-nfc::before {
  content: "\F396"; }

.mdi-nfc-off::before {
  content: "\FE35"; }

.mdi-nfc-search-variant::before {
  content: "\FE36"; }

.mdi-nfc-tap::before {
  content: "\F397"; }

.mdi-nfc-variant::before {
  content: "\F398"; }

.mdi-nfc-variant-off::before {
  content: "\FE37"; }

.mdi-ninja::before {
  content: "\F773"; }

.mdi-nintendo-switch::before {
  content: "\F7E0"; }

.mdi-nix::before {
  content: "\F0130"; }

.mdi-nodejs::before {
  content: "\F399"; }

.mdi-noodles::before {
  content: "\F01A9"; }

.mdi-not-equal::before {
  content: "\F98C"; }

.mdi-not-equal-variant::before {
  content: "\F98D"; }

.mdi-note::before {
  content: "\F39A"; }

.mdi-note-multiple::before {
  content: "\F6B7"; }

.mdi-note-multiple-outline::before {
  content: "\F6B8"; }

.mdi-note-outline::before {
  content: "\F39B"; }

.mdi-note-plus::before {
  content: "\F39C"; }

.mdi-note-plus-outline::before {
  content: "\F39D"; }

.mdi-note-text::before {
  content: "\F39E"; }

.mdi-note-text-outline::before {
  content: "\F0202"; }

.mdi-notebook::before {
  content: "\F82D"; }

.mdi-notebook-multiple::before {
  content: "\FE38"; }

.mdi-notebook-outline::before {
  content: "\FEDC"; }

.mdi-notification-clear-all::before {
  content: "\F39F"; }

.mdi-npm::before {
  content: "\F6F6"; }

.mdi-npm-variant::before {
  content: "\F98E"; }

.mdi-npm-variant-outline::before {
  content: "\F98F"; }

.mdi-nuke::before {
  content: "\F6A3"; }

.mdi-null::before {
  content: "\F7E1"; }

.mdi-numeric::before {
  content: "\F3A0"; }

.mdi-numeric-0::before {
  content: "\30"; }

.mdi-numeric-0-box::before {
  content: "\F3A1"; }

.mdi-numeric-0-box-multiple::before {
  content: "\FF2B"; }

.mdi-numeric-0-box-multiple-outline::before {
  content: "\F3A2"; }

.mdi-numeric-0-box-outline::before {
  content: "\F3A3"; }

.mdi-numeric-0-circle::before {
  content: "\FC7A"; }

.mdi-numeric-0-circle-outline::before {
  content: "\FC7B"; }

.mdi-numeric-1::before {
  content: "\31"; }

.mdi-numeric-1-box::before {
  content: "\F3A4"; }

.mdi-numeric-1-box-multiple::before {
  content: "\FF2C"; }

.mdi-numeric-1-box-multiple-outline::before {
  content: "\F3A5"; }

.mdi-numeric-1-box-outline::before {
  content: "\F3A6"; }

.mdi-numeric-1-circle::before {
  content: "\FC7C"; }

.mdi-numeric-1-circle-outline::before {
  content: "\FC7D"; }

.mdi-numeric-10::before {
  content: "\F000A"; }

.mdi-numeric-10-box::before {
  content: "\FF9A"; }

.mdi-numeric-10-box-multiple::before {
  content: "\F000B"; }

.mdi-numeric-10-box-multiple-outline::before {
  content: "\F000C"; }

.mdi-numeric-10-box-outline::before {
  content: "\FF9B"; }

.mdi-numeric-10-circle::before {
  content: "\F000D"; }

.mdi-numeric-10-circle-outline::before {
  content: "\F000E"; }

.mdi-numeric-2::before {
  content: "\32"; }

.mdi-numeric-2-box::before {
  content: "\F3A7"; }

.mdi-numeric-2-box-multiple::before {
  content: "\FF2D"; }

.mdi-numeric-2-box-multiple-outline::before {
  content: "\F3A8"; }

.mdi-numeric-2-box-outline::before {
  content: "\F3A9"; }

.mdi-numeric-2-circle::before {
  content: "\FC7E"; }

.mdi-numeric-2-circle-outline::before {
  content: "\FC7F"; }

.mdi-numeric-3::before {
  content: "\33"; }

.mdi-numeric-3-box::before {
  content: "\F3AA"; }

.mdi-numeric-3-box-multiple::before {
  content: "\FF2E"; }

.mdi-numeric-3-box-multiple-outline::before {
  content: "\F3AB"; }

.mdi-numeric-3-box-outline::before {
  content: "\F3AC"; }

.mdi-numeric-3-circle::before {
  content: "\FC80"; }

.mdi-numeric-3-circle-outline::before {
  content: "\FC81"; }

.mdi-numeric-4::before {
  content: "\34"; }

.mdi-numeric-4-box::before {
  content: "\F3AD"; }

.mdi-numeric-4-box-multiple::before {
  content: "\FF2F"; }

.mdi-numeric-4-box-multiple-outline::before {
  content: "\F3AE"; }

.mdi-numeric-4-box-outline::before {
  content: "\F3AF"; }

.mdi-numeric-4-circle::before {
  content: "\FC82"; }

.mdi-numeric-4-circle-outline::before {
  content: "\FC83"; }

.mdi-numeric-5::before {
  content: "\35"; }

.mdi-numeric-5-box::before {
  content: "\F3B0"; }

.mdi-numeric-5-box-multiple::before {
  content: "\FF30"; }

.mdi-numeric-5-box-multiple-outline::before {
  content: "\F3B1"; }

.mdi-numeric-5-box-outline::before {
  content: "\F3B2"; }

.mdi-numeric-5-circle::before {
  content: "\FC84"; }

.mdi-numeric-5-circle-outline::before {
  content: "\FC85"; }

.mdi-numeric-6::before {
  content: "\36"; }

.mdi-numeric-6-box::before {
  content: "\F3B3"; }

.mdi-numeric-6-box-multiple::before {
  content: "\FF31"; }

.mdi-numeric-6-box-multiple-outline::before {
  content: "\F3B4"; }

.mdi-numeric-6-box-outline::before {
  content: "\F3B5"; }

.mdi-numeric-6-circle::before {
  content: "\FC86"; }

.mdi-numeric-6-circle-outline::before {
  content: "\FC87"; }

.mdi-numeric-7::before {
  content: "\37"; }

.mdi-numeric-7-box::before {
  content: "\F3B6"; }

.mdi-numeric-7-box-multiple::before {
  content: "\FF32"; }

.mdi-numeric-7-box-multiple-outline::before {
  content: "\F3B7"; }

.mdi-numeric-7-box-outline::before {
  content: "\F3B8"; }

.mdi-numeric-7-circle::before {
  content: "\FC88"; }

.mdi-numeric-7-circle-outline::before {
  content: "\FC89"; }

.mdi-numeric-8::before {
  content: "\38"; }

.mdi-numeric-8-box::before {
  content: "\F3B9"; }

.mdi-numeric-8-box-multiple::before {
  content: "\FF33"; }

.mdi-numeric-8-box-multiple-outline::before {
  content: "\F3BA"; }

.mdi-numeric-8-box-outline::before {
  content: "\F3BB"; }

.mdi-numeric-8-circle::before {
  content: "\FC8A"; }

.mdi-numeric-8-circle-outline::before {
  content: "\FC8B"; }

.mdi-numeric-9::before {
  content: "\39"; }

.mdi-numeric-9-box::before {
  content: "\F3BC"; }

.mdi-numeric-9-box-multiple::before {
  content: "\FF34"; }

.mdi-numeric-9-box-multiple-outline::before {
  content: "\F3BD"; }

.mdi-numeric-9-box-outline::before {
  content: "\F3BE"; }

.mdi-numeric-9-circle::before {
  content: "\FC8C"; }

.mdi-numeric-9-circle-outline::before {
  content: "\FC8D"; }

.mdi-numeric-9-plus::before {
  content: "\F000F"; }

.mdi-numeric-9-plus-box::before {
  content: "\F3BF"; }

.mdi-numeric-9-plus-box-multiple::before {
  content: "\FF35"; }

.mdi-numeric-9-plus-box-multiple-outline::before {
  content: "\F3C0"; }

.mdi-numeric-9-plus-box-outline::before {
  content: "\F3C1"; }

.mdi-numeric-9-plus-circle::before {
  content: "\FC8E"; }

.mdi-numeric-9-plus-circle-outline::before {
  content: "\FC8F"; }

.mdi-numeric-negative-1::before {
  content: "\F0074"; }

.mdi-nut::before {
  content: "\F6F7"; }

.mdi-nutrition::before {
  content: "\F3C2"; }

.mdi-nuxt::before {
  content: "\F0131"; }

.mdi-oar::before {
  content: "\F67B"; }

.mdi-ocarina::before {
  content: "\FDBC"; }

.mdi-oci::before {
  content: "\F0314"; }

.mdi-ocr::before {
  content: "\F0165"; }

.mdi-octagon::before {
  content: "\F3C3"; }

.mdi-octagon-outline::before {
  content: "\F3C4"; }

.mdi-octagram::before {
  content: "\F6F8"; }

.mdi-octagram-outline::before {
  content: "\F774"; }

.mdi-odnoklassniki::before {
  content: "\F3C5"; }

.mdi-offer::before {
  content: "\F0246"; }

.mdi-office::before {
  content: "\F3C6"; }

.mdi-office-building::before {
  content: "\F990"; }

.mdi-oil::before {
  content: "\F3C7"; }

.mdi-oil-lamp::before {
  content: "\FF36"; }

.mdi-oil-level::before {
  content: "\F0075"; }

.mdi-oil-temperature::before {
  content: "\F0019"; }

.mdi-omega::before {
  content: "\F3C9"; }

.mdi-one-up::before {
  content: "\FB89"; }

.mdi-onedrive::before {
  content: "\F3CA"; }

.mdi-onenote::before {
  content: "\F746"; }

.mdi-onepassword::before {
  content: "\F880"; }

.mdi-opacity::before {
  content: "\F5CC"; }

.mdi-open-in-app::before {
  content: "\F3CB"; }

.mdi-open-in-new::before {
  content: "\F3CC"; }

.mdi-open-source-initiative::before {
  content: "\FB8A"; }

.mdi-openid::before {
  content: "\F3CD"; }

.mdi-opera::before {
  content: "\F3CE"; }

.mdi-orbit::before {
  content: "\F018"; }

.mdi-origin::before {
  content: "\FB2B"; }

.mdi-ornament::before {
  content: "\F3CF"; }

.mdi-ornament-variant::before {
  content: "\F3D0"; }

.mdi-outdoor-lamp::before {
  content: "\F0076"; }

.mdi-outlook::before {
  content: "\FCFE"; }

.mdi-overscan::before {
  content: "\F0027"; }

.mdi-owl::before {
  content: "\F3D2"; }

.mdi-pac-man::before {
  content: "\FB8B"; }

.mdi-package::before {
  content: "\F3D3"; }

.mdi-package-down::before {
  content: "\F3D4"; }

.mdi-package-up::before {
  content: "\F3D5"; }

.mdi-package-variant::before {
  content: "\F3D6"; }

.mdi-package-variant-closed::before {
  content: "\F3D7"; }

.mdi-page-first::before {
  content: "\F600"; }

.mdi-page-last::before {
  content: "\F601"; }

.mdi-page-layout-body::before {
  content: "\F6F9"; }

.mdi-page-layout-footer::before {
  content: "\F6FA"; }

.mdi-page-layout-header::before {
  content: "\F6FB"; }

.mdi-page-layout-header-footer::before {
  content: "\FF9C"; }

.mdi-page-layout-sidebar-left::before {
  content: "\F6FC"; }

.mdi-page-layout-sidebar-right::before {
  content: "\F6FD"; }

.mdi-page-next::before {
  content: "\FB8C"; }

.mdi-page-next-outline::before {
  content: "\FB8D"; }

.mdi-page-previous::before {
  content: "\FB8E"; }

.mdi-page-previous-outline::before {
  content: "\FB8F"; }

.mdi-palette::before {
  content: "\F3D8"; }

.mdi-palette-advanced::before {
  content: "\F3D9"; }

.mdi-palette-outline::before {
  content: "\FE6C"; }

.mdi-palette-swatch::before {
  content: "\F8B4"; }

.mdi-palette-swatch-outline::before {
  content: "\F0387"; }

.mdi-palm-tree::before {
  content: "\F0077"; }

.mdi-pan::before {
  content: "\FB90"; }

.mdi-pan-bottom-left::before {
  content: "\FB91"; }

.mdi-pan-bottom-right::before {
  content: "\FB92"; }

.mdi-pan-down::before {
  content: "\FB93"; }

.mdi-pan-horizontal::before {
  content: "\FB94"; }

.mdi-pan-left::before {
  content: "\FB95"; }

.mdi-pan-right::before {
  content: "\FB96"; }

.mdi-pan-top-left::before {
  content: "\FB97"; }

.mdi-pan-top-right::before {
  content: "\FB98"; }

.mdi-pan-up::before {
  content: "\FB99"; }

.mdi-pan-vertical::before {
  content: "\FB9A"; }

.mdi-panda::before {
  content: "\F3DA"; }

.mdi-pandora::before {
  content: "\F3DB"; }

.mdi-panorama::before {
  content: "\F3DC"; }

.mdi-panorama-fisheye::before {
  content: "\F3DD"; }

.mdi-panorama-horizontal::before {
  content: "\F3DE"; }

.mdi-panorama-vertical::before {
  content: "\F3DF"; }

.mdi-panorama-wide-angle::before {
  content: "\F3E0"; }

.mdi-paper-cut-vertical::before {
  content: "\F3E1"; }

.mdi-paper-roll::before {
  content: "\F0182"; }

.mdi-paper-roll-outline::before {
  content: "\F0183"; }

.mdi-paperclip::before {
  content: "\F3E2"; }

.mdi-parachute::before {
  content: "\FC90"; }

.mdi-parachute-outline::before {
  content: "\FC91"; }

.mdi-parking::before {
  content: "\F3E3"; }

.mdi-party-popper::before {
  content: "\F0078"; }

.mdi-passport::before {
  content: "\F7E2"; }

.mdi-passport-biometric::before {
  content: "\FDBD"; }

.mdi-pasta::before {
  content: "\F018B"; }

.mdi-patio-heater::before {
  content: "\FF9D"; }

.mdi-patreon::before {
  content: "\F881"; }

.mdi-pause::before {
  content: "\F3E4"; }

.mdi-pause-circle::before {
  content: "\F3E5"; }

.mdi-pause-circle-outline::before {
  content: "\F3E6"; }

.mdi-pause-octagon::before {
  content: "\F3E7"; }

.mdi-pause-octagon-outline::before {
  content: "\F3E8"; }

.mdi-paw::before {
  content: "\F3E9"; }

.mdi-paw-off::before {
  content: "\F657"; }

.mdi-paypal::before {
  content: "\F882"; }

.mdi-pdf-box::before {
  content: "\FE39"; }

.mdi-peace::before {
  content: "\F883"; }

.mdi-peanut::before {
  content: "\F001E"; }

.mdi-peanut-off::before {
  content: "\F001F"; }

.mdi-peanut-off-outline::before {
  content: "\F0021"; }

.mdi-peanut-outline::before {
  content: "\F0020"; }

.mdi-pen::before {
  content: "\F3EA"; }

.mdi-pen-lock::before {
  content: "\FDBE"; }

.mdi-pen-minus::before {
  content: "\FDBF"; }

.mdi-pen-off::before {
  content: "\FDC0"; }

.mdi-pen-plus::before {
  content: "\FDC1"; }

.mdi-pen-remove::before {
  content: "\FDC2"; }

.mdi-pencil::before {
  content: "\F3EB"; }

.mdi-pencil-box::before {
  content: "\F3EC"; }

.mdi-pencil-box-multiple::before {
  content: "\F016F"; }

.mdi-pencil-box-multiple-outline::before {
  content: "\F0170"; }

.mdi-pencil-box-outline::before {
  content: "\F3ED"; }

.mdi-pencil-circle::before {
  content: "\F6FE"; }

.mdi-pencil-circle-outline::before {
  content: "\F775"; }

.mdi-pencil-lock::before {
  content: "\F3EE"; }

.mdi-pencil-lock-outline::before {
  content: "\FDC3"; }

.mdi-pencil-minus::before {
  content: "\FDC4"; }

.mdi-pencil-minus-outline::before {
  content: "\FDC5"; }

.mdi-pencil-off::before {
  content: "\F3EF"; }

.mdi-pencil-off-outline::before {
  content: "\FDC6"; }

.mdi-pencil-outline::before {
  content: "\FC92"; }

.mdi-pencil-plus::before {
  content: "\FDC7"; }

.mdi-pencil-plus-outline::before {
  content: "\FDC8"; }

.mdi-pencil-remove::before {
  content: "\FDC9"; }

.mdi-pencil-remove-outline::before {
  content: "\FDCA"; }

.mdi-pencil-ruler::before {
  content: "\F037E"; }

.mdi-penguin::before {
  content: "\FEDD"; }

.mdi-pentagon::before {
  content: "\F6FF"; }

.mdi-pentagon-outline::before {
  content: "\F700"; }

.mdi-percent::before {
  content: "\F3F0"; }

.mdi-percent-outline::before {
  content: "\F02A3"; }

.mdi-periodic-table::before {
  content: "\F8B5"; }

.mdi-periodic-table-co::before {
  content: "\F0329"; }

.mdi-periodic-table-co2::before {
  content: "\F7E3"; }

.mdi-periscope::before {
  content: "\F747"; }

.mdi-perspective-less::before {
  content: "\FCFF"; }

.mdi-perspective-more::before {
  content: "\FD00"; }

.mdi-pharmacy::before {
  content: "\F3F1"; }

.mdi-phone::before {
  content: "\F3F2"; }

.mdi-phone-alert::before {
  content: "\FF37"; }

.mdi-phone-alert-outline::before {
  content: "\F01B9"; }

.mdi-phone-bluetooth::before {
  content: "\F3F3"; }

.mdi-phone-bluetooth-outline::before {
  content: "\F01BA"; }

.mdi-phone-cancel::before {
  content: "\F00E7"; }

.mdi-phone-cancel-outline::before {
  content: "\F01BB"; }

.mdi-phone-check::before {
  content: "\F01D4"; }

.mdi-phone-check-outline::before {
  content: "\F01D5"; }

.mdi-phone-classic::before {
  content: "\F602"; }

.mdi-phone-classic-off::before {
  content: "\F02A4"; }

.mdi-phone-forward::before {
  content: "\F3F4"; }

.mdi-phone-forward-outline::before {
  content: "\F01BC"; }

.mdi-phone-hangup::before {
  content: "\F3F5"; }

.mdi-phone-hangup-outline::before {
  content: "\F01BD"; }

.mdi-phone-in-talk::before {
  content: "\F3F6"; }

.mdi-phone-in-talk-outline::before {
  content: "\F01AD"; }

.mdi-phone-incoming::before {
  content: "\F3F7"; }

.mdi-phone-incoming-outline::before {
  content: "\F01BE"; }

.mdi-phone-lock::before {
  content: "\F3F8"; }

.mdi-phone-lock-outline::before {
  content: "\F01BF"; }

.mdi-phone-log::before {
  content: "\F3F9"; }

.mdi-phone-log-outline::before {
  content: "\F01C0"; }

.mdi-phone-message::before {
  content: "\F01C1"; }

.mdi-phone-message-outline::before {
  content: "\F01C2"; }

.mdi-phone-minus::before {
  content: "\F658"; }

.mdi-phone-minus-outline::before {
  content: "\F01C3"; }

.mdi-phone-missed::before {
  content: "\F3FA"; }

.mdi-phone-missed-outline::before {
  content: "\F01D0"; }

.mdi-phone-off::before {
  content: "\FDCB"; }

.mdi-phone-off-outline::before {
  content: "\F01D1"; }

.mdi-phone-outgoing::before {
  content: "\F3FB"; }

.mdi-phone-outgoing-outline::before {
  content: "\F01C4"; }

.mdi-phone-outline::before {
  content: "\FDCC"; }

.mdi-phone-paused::before {
  content: "\F3FC"; }

.mdi-phone-paused-outline::before {
  content: "\F01C5"; }

.mdi-phone-plus::before {
  content: "\F659"; }

.mdi-phone-plus-outline::before {
  content: "\F01C6"; }

.mdi-phone-return::before {
  content: "\F82E"; }

.mdi-phone-return-outline::before {
  content: "\F01C7"; }

.mdi-phone-ring::before {
  content: "\F01D6"; }

.mdi-phone-ring-outline::before {
  content: "\F01D7"; }

.mdi-phone-rotate-landscape::before {
  content: "\F884"; }

.mdi-phone-rotate-portrait::before {
  content: "\F885"; }

.mdi-phone-settings::before {
  content: "\F3FD"; }

.mdi-phone-settings-outline::before {
  content: "\F01C8"; }

.mdi-phone-voip::before {
  content: "\F3FE"; }

.mdi-pi::before {
  content: "\F3FF"; }

.mdi-pi-box::before {
  content: "\F400"; }

.mdi-pi-hole::before {
  content: "\FDCD"; }

.mdi-piano::before {
  content: "\F67C"; }

.mdi-pickaxe::before {
  content: "\F8B6"; }

.mdi-picture-in-picture-bottom-right::before {
  content: "\FE3A"; }

.mdi-picture-in-picture-bottom-right-outline::before {
  content: "\FE3B"; }

.mdi-picture-in-picture-top-right::before {
  content: "\FE3C"; }

.mdi-picture-in-picture-top-right-outline::before {
  content: "\FE3D"; }

.mdi-pier::before {
  content: "\F886"; }

.mdi-pier-crane::before {
  content: "\F887"; }

.mdi-pig::before {
  content: "\F401"; }

.mdi-pig-variant::before {
  content: "\F0028"; }

.mdi-piggy-bank::before {
  content: "\F0029"; }

.mdi-pill::before {
  content: "\F402"; }

.mdi-pillar::before {
  content: "\F701"; }

.mdi-pin::before {
  content: "\F403"; }

.mdi-pin-off::before {
  content: "\F404"; }

.mdi-pin-off-outline::before {
  content: "\F92F"; }

.mdi-pin-outline::before {
  content: "\F930"; }

.mdi-pine-tree::before {
  content: "\F405"; }

.mdi-pine-tree-box::before {
  content: "\F406"; }

.mdi-pinterest::before {
  content: "\F407"; }

.mdi-pinterest-box::before {
  content: "\F408"; }

.mdi-pinwheel::before {
  content: "\FAD4"; }

.mdi-pinwheel-outline::before {
  content: "\FAD5"; }

.mdi-pipe::before {
  content: "\F7E4"; }

.mdi-pipe-disconnected::before {
  content: "\F7E5"; }

.mdi-pipe-leak::before {
  content: "\F888"; }

.mdi-pipe-wrench::before {
  content: "\F037F"; }

.mdi-pirate::before {
  content: "\FA07"; }

.mdi-pistol::before {
  content: "\F702"; }

.mdi-piston::before {
  content: "\F889"; }

.mdi-pizza::before {
  content: "\F409"; }

.mdi-play::before {
  content: "\F40A"; }

.mdi-play-box::before {
  content: "\F02A5"; }

.mdi-play-box-outline::before {
  content: "\F40B"; }

.mdi-play-circle::before {
  content: "\F40C"; }

.mdi-play-circle-outline::before {
  content: "\F40D"; }

.mdi-play-network::before {
  content: "\F88A"; }

.mdi-play-network-outline::before {
  content: "\FC93"; }

.mdi-play-outline::before {
  content: "\FF38"; }

.mdi-play-pause::before {
  content: "\F40E"; }

.mdi-play-protected-content::before {
  content: "\F40F"; }

.mdi-play-speed::before {
  content: "\F8FE"; }

.mdi-playlist-check::before {
  content: "\F5C7"; }

.mdi-playlist-edit::before {
  content: "\F8FF"; }

.mdi-playlist-minus::before {
  content: "\F410"; }

.mdi-playlist-music::before {
  content: "\FC94"; }

.mdi-playlist-music-outline::before {
  content: "\FC95"; }

.mdi-playlist-play::before {
  content: "\F411"; }

.mdi-playlist-plus::before {
  content: "\F412"; }

.mdi-playlist-remove::before {
  content: "\F413"; }

.mdi-playlist-star::before {
  content: "\FDCE"; }

.mdi-playstation::before {
  content: "\F414"; }

.mdi-plex::before {
  content: "\F6B9"; }

.mdi-plus::before {
  content: "\F415"; }

.mdi-plus-box::before {
  content: "\F416"; }

.mdi-plus-box-multiple::before {
  content: "\F334"; }

.mdi-plus-box-multiple-outline::before {
  content: "\F016E"; }

.mdi-plus-box-outline::before {
  content: "\F703"; }

.mdi-plus-circle::before {
  content: "\F417"; }

.mdi-plus-circle-multiple-outline::before {
  content: "\F418"; }

.mdi-plus-circle-outline::before {
  content: "\F419"; }

.mdi-plus-minus::before {
  content: "\F991"; }

.mdi-plus-minus-box::before {
  content: "\F992"; }

.mdi-plus-network::before {
  content: "\F41A"; }

.mdi-plus-network-outline::before {
  content: "\FC96"; }

.mdi-plus-one::before {
  content: "\F41B"; }

.mdi-plus-outline::before {
  content: "\F704"; }

.mdi-plus-thick::before {
  content: "\F0217"; }

.mdi-pocket::before {
  content: "\F41C"; }

.mdi-podcast::before {
  content: "\F993"; }

.mdi-podium::before {
  content: "\FD01"; }

.mdi-podium-bronze::before {
  content: "\FD02"; }

.mdi-podium-gold::before {
  content: "\FD03"; }

.mdi-podium-silver::before {
  content: "\FD04"; }

.mdi-point-of-sale::before {
  content: "\FD6E"; }

.mdi-pokeball::before {
  content: "\F41D"; }

.mdi-pokemon-go::before {
  content: "\FA08"; }

.mdi-poker-chip::before {
  content: "\F82F"; }

.mdi-polaroid::before {
  content: "\F41E"; }

.mdi-police-badge::before {
  content: "\F0192"; }

.mdi-police-badge-outline::before {
  content: "\F0193"; }

.mdi-poll::before {
  content: "\F41F"; }

.mdi-poll-box::before {
  content: "\F420"; }

.mdi-poll-box-outline::before {
  content: "\F02A6"; }

.mdi-polymer::before {
  content: "\F421"; }

.mdi-pool::before {
  content: "\F606"; }

.mdi-popcorn::before {
  content: "\F422"; }

.mdi-post::before {
  content: "\F002A"; }

.mdi-post-outline::before {
  content: "\F002B"; }

.mdi-postage-stamp::before {
  content: "\FC97"; }

.mdi-pot::before {
  content: "\F65A"; }

.mdi-pot-mix::before {
  content: "\F65B"; }

.mdi-pound::before {
  content: "\F423"; }

.mdi-pound-box::before {
  content: "\F424"; }

.mdi-pound-box-outline::before {
  content: "\F01AA"; }

.mdi-power::before {
  content: "\F425"; }

.mdi-power-cycle::before {
  content: "\F900"; }

.mdi-power-off::before {
  content: "\F901"; }

.mdi-power-on::before {
  content: "\F902"; }

.mdi-power-plug::before {
  content: "\F6A4"; }

.mdi-power-plug-off::before {
  content: "\F6A5"; }

.mdi-power-settings::before {
  content: "\F426"; }

.mdi-power-sleep::before {
  content: "\F903"; }

.mdi-power-socket::before {
  content: "\F427"; }

.mdi-power-socket-au::before {
  content: "\F904"; }

.mdi-power-socket-de::before {
  content: "\F0132"; }

.mdi-power-socket-eu::before {
  content: "\F7E6"; }

.mdi-power-socket-fr::before {
  content: "\F0133"; }

.mdi-power-socket-jp::before {
  content: "\F0134"; }

.mdi-power-socket-uk::before {
  content: "\F7E7"; }

.mdi-power-socket-us::before {
  content: "\F7E8"; }

.mdi-power-standby::before {
  content: "\F905"; }

.mdi-powershell::before {
  content: "\FA09"; }

.mdi-prescription::before {
  content: "\F705"; }

.mdi-presentation::before {
  content: "\F428"; }

.mdi-presentation-play::before {
  content: "\F429"; }

.mdi-printer::before {
  content: "\F42A"; }

.mdi-printer-3d::before {
  content: "\F42B"; }

.mdi-printer-3d-nozzle::before {
  content: "\FE3E"; }

.mdi-printer-3d-nozzle-alert::before {
  content: "\F01EB"; }

.mdi-printer-3d-nozzle-alert-outline::before {
  content: "\F01EC"; }

.mdi-printer-3d-nozzle-outline::before {
  content: "\FE3F"; }

.mdi-printer-alert::before {
  content: "\F42C"; }

.mdi-printer-check::before {
  content: "\F0171"; }

.mdi-printer-off::before {
  content: "\FE40"; }

.mdi-printer-pos::before {
  content: "\F0079"; }

.mdi-printer-settings::before {
  content: "\F706"; }

.mdi-printer-wireless::before {
  content: "\FA0A"; }

.mdi-priority-high::before {
  content: "\F603"; }

.mdi-priority-low::before {
  content: "\F604"; }

.mdi-professional-hexagon::before {
  content: "\F42D"; }

.mdi-progress-alert::before {
  content: "\FC98"; }

.mdi-progress-check::before {
  content: "\F994"; }

.mdi-progress-clock::before {
  content: "\F995"; }

.mdi-progress-close::before {
  content: "\F0135"; }

.mdi-progress-download::before {
  content: "\F996"; }

.mdi-progress-upload::before {
  content: "\F997"; }

.mdi-progress-wrench::before {
  content: "\FC99"; }

.mdi-projector::before {
  content: "\F42E"; }

.mdi-projector-screen::before {
  content: "\F42F"; }

.mdi-propane-tank::before {
  content: "\F0382"; }

.mdi-propane-tank-outline::before {
  content: "\F0383"; }

.mdi-protocol::before {
  content: "\FFF9"; }

.mdi-publish::before {
  content: "\F6A6"; }

.mdi-pulse::before {
  content: "\F430"; }

.mdi-pumpkin::before {
  content: "\FB9B"; }

.mdi-purse::before {
  content: "\FF39"; }

.mdi-purse-outline::before {
  content: "\FF3A"; }

.mdi-puzzle::before {
  content: "\F431"; }

.mdi-puzzle-outline::before {
  content: "\FA65"; }

.mdi-qi::before {
  content: "\F998"; }

.mdi-qqchat::before {
  content: "\F605"; }

.mdi-qrcode::before {
  content: "\F432"; }

.mdi-qrcode-edit::before {
  content: "\F8B7"; }

.mdi-qrcode-minus::before {
  content: "\F01B7"; }

.mdi-qrcode-plus::before {
  content: "\F01B6"; }

.mdi-qrcode-remove::before {
  content: "\F01B8"; }

.mdi-qrcode-scan::before {
  content: "\F433"; }

.mdi-quadcopter::before {
  content: "\F434"; }

.mdi-quality-high::before {
  content: "\F435"; }

.mdi-quality-low::before {
  content: "\FA0B"; }

.mdi-quality-medium::before {
  content: "\FA0C"; }

.mdi-quicktime::before {
  content: "\F436"; }

.mdi-quora::before {
  content: "\FD05"; }

.mdi-rabbit::before {
  content: "\F906"; }

.mdi-racing-helmet::before {
  content: "\FD6F"; }

.mdi-racquetball::before {
  content: "\FD70"; }

.mdi-radar::before {
  content: "\F437"; }

.mdi-radiator::before {
  content: "\F438"; }

.mdi-radiator-disabled::before {
  content: "\FAD6"; }

.mdi-radiator-off::before {
  content: "\FAD7"; }

.mdi-radio::before {
  content: "\F439"; }

.mdi-radio-am::before {
  content: "\FC9A"; }

.mdi-radio-fm::before {
  content: "\FC9B"; }

.mdi-radio-handheld::before {
  content: "\F43A"; }

.mdi-radio-off::before {
  content: "\F0247"; }

.mdi-radio-tower::before {
  content: "\F43B"; }

.mdi-radioactive::before {
  content: "\F43C"; }

.mdi-radioactive-off::before {
  content: "\FEDE"; }

.mdi-radiobox-blank::before {
  content: "\F43D"; }

.mdi-radiobox-marked::before {
  content: "\F43E"; }

.mdi-radius::before {
  content: "\FC9C"; }

.mdi-radius-outline::before {
  content: "\FC9D"; }

.mdi-railroad-light::before {
  content: "\FF3B"; }

.mdi-raspberry-pi::before {
  content: "\F43F"; }

.mdi-ray-end::before {
  content: "\F440"; }

.mdi-ray-end-arrow::before {
  content: "\F441"; }

.mdi-ray-start::before {
  content: "\F442"; }

.mdi-ray-start-arrow::before {
  content: "\F443"; }

.mdi-ray-start-end::before {
  content: "\F444"; }

.mdi-ray-vertex::before {
  content: "\F445"; }

.mdi-react::before {
  content: "\F707"; }

.mdi-read::before {
  content: "\F447"; }

.mdi-receipt::before {
  content: "\F449"; }

.mdi-record::before {
  content: "\F44A"; }

.mdi-record-circle::before {
  content: "\FEDF"; }

.mdi-record-circle-outline::before {
  content: "\FEE0"; }

.mdi-record-player::before {
  content: "\F999"; }

.mdi-record-rec::before {
  content: "\F44B"; }

.mdi-rectangle::before {
  content: "\FE41"; }

.mdi-rectangle-outline::before {
  content: "\FE42"; }

.mdi-recycle::before {
  content: "\F44C"; }

.mdi-reddit::before {
  content: "\F44D"; }

.mdi-redhat::before {
  content: "\F0146"; }

.mdi-redo::before {
  content: "\F44E"; }

.mdi-redo-variant::before {
  content: "\F44F"; }

.mdi-reflect-horizontal::before {
  content: "\FA0D"; }

.mdi-reflect-vertical::before {
  content: "\FA0E"; }

.mdi-refresh::before {
  content: "\F450"; }

.mdi-refresh-circle::before {
  content: "\F03A2"; }

.mdi-regex::before {
  content: "\F451"; }

.mdi-registered-trademark::before {
  content: "\FA66"; }

.mdi-relative-scale::before {
  content: "\F452"; }

.mdi-reload::before {
  content: "\F453"; }

.mdi-reload-alert::before {
  content: "\F0136"; }

.mdi-reminder::before {
  content: "\F88B"; }

.mdi-remote::before {
  content: "\F454"; }

.mdi-remote-desktop::before {
  content: "\F8B8"; }

.mdi-remote-off::before {
  content: "\FEE1"; }

.mdi-remote-tv::before {
  content: "\FEE2"; }

.mdi-remote-tv-off::before {
  content: "\FEE3"; }

.mdi-rename-box::before {
  content: "\F455"; }

.mdi-reorder-horizontal::before {
  content: "\F687"; }

.mdi-reorder-vertical::before {
  content: "\F688"; }

.mdi-repeat::before {
  content: "\F456"; }

.mdi-repeat-off::before {
  content: "\F457"; }

.mdi-repeat-once::before {
  content: "\F458"; }

.mdi-replay::before {
  content: "\F459"; }

.mdi-reply::before {
  content: "\F45A"; }

.mdi-reply-all::before {
  content: "\F45B"; }

.mdi-reply-all-outline::before {
  content: "\FF3C"; }

.mdi-reply-circle::before {
  content: "\F01D9"; }

.mdi-reply-outline::before {
  content: "\FF3D"; }

.mdi-reproduction::before {
  content: "\F45C"; }

.mdi-resistor::before {
  content: "\FB1F"; }

.mdi-resistor-nodes::before {
  content: "\FB20"; }

.mdi-resize::before {
  content: "\FA67"; }

.mdi-resize-bottom-right::before {
  content: "\F45D"; }

.mdi-responsive::before {
  content: "\F45E"; }

.mdi-restart::before {
  content: "\F708"; }

.mdi-restart-alert::before {
  content: "\F0137"; }

.mdi-restart-off::before {
  content: "\FD71"; }

.mdi-restore::before {
  content: "\F99A"; }

.mdi-restore-alert::before {
  content: "\F0138"; }

.mdi-rewind::before {
  content: "\F45F"; }

.mdi-rewind-10::before {
  content: "\FD06"; }

.mdi-rewind-30::before {
  content: "\FD72"; }

.mdi-rewind-5::before {
  content: "\F0224"; }

.mdi-rewind-outline::before {
  content: "\F709"; }

.mdi-rhombus::before {
  content: "\F70A"; }

.mdi-rhombus-medium::before {
  content: "\FA0F"; }

.mdi-rhombus-outline::before {
  content: "\F70B"; }

.mdi-rhombus-split::before {
  content: "\FA10"; }

.mdi-ribbon::before {
  content: "\F460"; }

.mdi-rice::before {
  content: "\F7E9"; }

.mdi-ring::before {
  content: "\F7EA"; }

.mdi-rivet::before {
  content: "\FE43"; }

.mdi-road::before {
  content: "\F461"; }

.mdi-road-variant::before {
  content: "\F462"; }

.mdi-robber::before {
  content: "\F007A"; }

.mdi-robot::before {
  content: "\F6A8"; }

.mdi-robot-industrial::before {
  content: "\FB21"; }

.mdi-robot-mower::before {
  content: "\F0222"; }

.mdi-robot-mower-outline::before {
  content: "\F021E"; }

.mdi-robot-vacuum::before {
  content: "\F70C"; }

.mdi-robot-vacuum-variant::before {
  content: "\F907"; }

.mdi-rocket::before {
  content: "\F463"; }

.mdi-rodent::before {
  content: "\F0352"; }

.mdi-roller-skate::before {
  content: "\FD07"; }

.mdi-rollerblade::before {
  content: "\FD08"; }

.mdi-rollupjs::before {
  content: "\FB9C"; }

.mdi-roman-numeral-1::before {
  content: "\F00B3"; }

.mdi-roman-numeral-10::before {
  content: "\F00BC"; }

.mdi-roman-numeral-2::before {
  content: "\F00B4"; }

.mdi-roman-numeral-3::before {
  content: "\F00B5"; }

.mdi-roman-numeral-4::before {
  content: "\F00B6"; }

.mdi-roman-numeral-5::before {
  content: "\F00B7"; }

.mdi-roman-numeral-6::before {
  content: "\F00B8"; }

.mdi-roman-numeral-7::before {
  content: "\F00B9"; }

.mdi-roman-numeral-8::before {
  content: "\F00BA"; }

.mdi-roman-numeral-9::before {
  content: "\F00BB"; }

.mdi-room-service::before {
  content: "\F88C"; }

.mdi-room-service-outline::before {
  content: "\FD73"; }

.mdi-rotate-3d::before {
  content: "\FEE4"; }

.mdi-rotate-3d-variant::before {
  content: "\F464"; }

.mdi-rotate-left::before {
  content: "\F465"; }

.mdi-rotate-left-variant::before {
  content: "\F466"; }

.mdi-rotate-orbit::before {
  content: "\FD74"; }

.mdi-rotate-right::before {
  content: "\F467"; }

.mdi-rotate-right-variant::before {
  content: "\F468"; }

.mdi-rounded-corner::before {
  content: "\F607"; }

.mdi-router::before {
  content: "\F020D"; }

.mdi-router-wireless::before {
  content: "\F469"; }

.mdi-router-wireless-settings::before {
  content: "\FA68"; }

.mdi-routes::before {
  content: "\F46A"; }

.mdi-routes-clock::before {
  content: "\F007B"; }

.mdi-rowing::before {
  content: "\F608"; }

.mdi-rss::before {
  content: "\F46B"; }

.mdi-rss-box::before {
  content: "\F46C"; }

.mdi-rss-off::before {
  content: "\FF3E"; }

.mdi-ruby::before {
  content: "\FD09"; }

.mdi-rugby::before {
  content: "\FD75"; }

.mdi-ruler::before {
  content: "\F46D"; }

.mdi-ruler-square::before {
  content: "\FC9E"; }

.mdi-ruler-square-compass::before {
  content: "\FEDB"; }

.mdi-run::before {
  content: "\F70D"; }

.mdi-run-fast::before {
  content: "\F46E"; }

.mdi-rv-truck::before {
  content: "\F01FF"; }

.mdi-sack::before {
  content: "\FD0A"; }

.mdi-sack-percent::before {
  content: "\FD0B"; }

.mdi-safe::before {
  content: "\FA69"; }

.mdi-safe-square::before {
  content: "\F02A7"; }

.mdi-safe-square-outline::before {
  content: "\F02A8"; }

.mdi-safety-goggles::before {
  content: "\FD0C"; }

.mdi-sailing::before {
  content: "\FEE5"; }

.mdi-sale::before {
  content: "\F46F"; }

.mdi-salesforce::before {
  content: "\F88D"; }

.mdi-sass::before {
  content: "\F7EB"; }

.mdi-satellite::before {
  content: "\F470"; }

.mdi-satellite-uplink::before {
  content: "\F908"; }

.mdi-satellite-variant::before {
  content: "\F471"; }

.mdi-sausage::before {
  content: "\F8B9"; }

.mdi-saw-blade::before {
  content: "\FE44"; }

.mdi-saxophone::before {
  content: "\F609"; }

.mdi-scale::before {
  content: "\F472"; }

.mdi-scale-balance::before {
  content: "\F5D1"; }

.mdi-scale-bathroom::before {
  content: "\F473"; }

.mdi-scale-off::before {
  content: "\F007C"; }

.mdi-scanner::before {
  content: "\F6AA"; }

.mdi-scanner-off::before {
  content: "\F909"; }

.mdi-scatter-plot::before {
  content: "\FEE6"; }

.mdi-scatter-plot-outline::before {
  content: "\FEE7"; }

.mdi-school::before {
  content: "\F474"; }

.mdi-school-outline::before {
  content: "\F01AB"; }

.mdi-scissors-cutting::before {
  content: "\FA6A"; }

.mdi-scooter::before {
  content: "\F0214"; }

.mdi-scoreboard::before {
  content: "\F02A9"; }

.mdi-scoreboard-outline::before {
  content: "\F02AA"; }

.mdi-screen-rotation::before {
  content: "\F475"; }

.mdi-screen-rotation-lock::before {
  content: "\F476"; }

.mdi-screw-flat-top::before {
  content: "\FDCF"; }

.mdi-screw-lag::before {
  content: "\FE54"; }

.mdi-screw-machine-flat-top::before {
  content: "\FE55"; }

.mdi-screw-machine-round-top::before {
  content: "\FE56"; }

.mdi-screw-round-top::before {
  content: "\FE57"; }

.mdi-screwdriver::before {
  content: "\F477"; }

.mdi-script::before {
  content: "\FB9D"; }

.mdi-script-outline::before {
  content: "\F478"; }

.mdi-script-text::before {
  content: "\FB9E"; }

.mdi-script-text-outline::before {
  content: "\FB9F"; }

.mdi-sd::before {
  content: "\F479"; }

.mdi-seal::before {
  content: "\F47A"; }

.mdi-seal-variant::before {
  content: "\FFFA"; }

.mdi-search-web::before {
  content: "\F70E"; }

.mdi-seat::before {
  content: "\FC9F"; }

.mdi-seat-flat::before {
  content: "\F47B"; }

.mdi-seat-flat-angled::before {
  content: "\F47C"; }

.mdi-seat-individual-suite::before {
  content: "\F47D"; }

.mdi-seat-legroom-extra::before {
  content: "\F47E"; }

.mdi-seat-legroom-normal::before {
  content: "\F47F"; }

.mdi-seat-legroom-reduced::before {
  content: "\F480"; }

.mdi-seat-outline::before {
  content: "\FCA0"; }

.mdi-seat-passenger::before {
  content: "\F0274"; }

.mdi-seat-recline-extra::before {
  content: "\F481"; }

.mdi-seat-recline-normal::before {
  content: "\F482"; }

.mdi-seatbelt::before {
  content: "\FCA1"; }

.mdi-security::before {
  content: "\F483"; }

.mdi-security-network::before {
  content: "\F484"; }

.mdi-seed::before {
  content: "\FE45"; }

.mdi-seed-outline::before {
  content: "\FE46"; }

.mdi-segment::before {
  content: "\FEE8"; }

.mdi-select::before {
  content: "\F485"; }

.mdi-select-all::before {
  content: "\F486"; }

.mdi-select-color::before {
  content: "\FD0D"; }

.mdi-select-compare::before {
  content: "\FAD8"; }

.mdi-select-drag::before {
  content: "\FA6B"; }

.mdi-select-group::before {
  content: "\FF9F"; }

.mdi-select-inverse::before {
  content: "\F487"; }

.mdi-select-marker::before {
  content: "\F02AB"; }

.mdi-select-multiple::before {
  content: "\F02AC"; }

.mdi-select-multiple-marker::before {
  content: "\F02AD"; }

.mdi-select-off::before {
  content: "\F488"; }

.mdi-select-place::before {
  content: "\FFFB"; }

.mdi-select-search::before {
  content: "\F022F"; }

.mdi-selection::before {
  content: "\F489"; }

.mdi-selection-drag::before {
  content: "\FA6C"; }

.mdi-selection-ellipse::before {
  content: "\FD0E"; }

.mdi-selection-ellipse-arrow-inside::before {
  content: "\FF3F"; }

.mdi-selection-marker::before {
  content: "\F02AE"; }

.mdi-selection-multiple-marker::before {
  content: "\F02AF"; }

.mdi-selection-mutliple::before {
  content: "\F02B0"; }

.mdi-selection-off::before {
  content: "\F776"; }

.mdi-selection-search::before {
  content: "\F0230"; }

.mdi-semantic-web::before {
  content: "\F0341"; }

.mdi-send::before {
  content: "\F48A"; }

.mdi-send-check::before {
  content: "\F018C"; }

.mdi-send-check-outline::before {
  content: "\F018D"; }

.mdi-send-circle::before {
  content: "\FE58"; }

.mdi-send-circle-outline::before {
  content: "\FE59"; }

.mdi-send-clock::before {
  content: "\F018E"; }

.mdi-send-clock-outline::before {
  content: "\F018F"; }

.mdi-send-lock::before {
  content: "\F7EC"; }

.mdi-send-lock-outline::before {
  content: "\F0191"; }

.mdi-send-outline::before {
  content: "\F0190"; }

.mdi-serial-port::before {
  content: "\F65C"; }

.mdi-server::before {
  content: "\F48B"; }

.mdi-server-minus::before {
  content: "\F48C"; }

.mdi-server-network::before {
  content: "\F48D"; }

.mdi-server-network-off::before {
  content: "\F48E"; }

.mdi-server-off::before {
  content: "\F48F"; }

.mdi-server-plus::before {
  content: "\F490"; }

.mdi-server-remove::before {
  content: "\F491"; }

.mdi-server-security::before {
  content: "\F492"; }

.mdi-set-all::before {
  content: "\F777"; }

.mdi-set-center::before {
  content: "\F778"; }

.mdi-set-center-right::before {
  content: "\F779"; }

.mdi-set-left::before {
  content: "\F77A"; }

.mdi-set-left-center::before {
  content: "\F77B"; }

.mdi-set-left-right::before {
  content: "\F77C"; }

.mdi-set-none::before {
  content: "\F77D"; }

.mdi-set-right::before {
  content: "\F77E"; }

.mdi-set-top-box::before {
  content: "\F99E"; }

.mdi-settings::before {
  content: "\F493"; }

.mdi-settings-box::before {
  content: "\F494"; }

.mdi-settings-helper::before {
  content: "\FA6D"; }

.mdi-settings-outline::before {
  content: "\F8BA"; }

.mdi-settings-transfer::before {
  content: "\F007D"; }

.mdi-settings-transfer-outline::before {
  content: "\F007E"; }

.mdi-shaker::before {
  content: "\F0139"; }

.mdi-shaker-outline::before {
  content: "\F013A"; }

.mdi-shape::before {
  content: "\F830"; }

.mdi-shape-circle-plus::before {
  content: "\F65D"; }

.mdi-shape-outline::before {
  content: "\F831"; }

.mdi-shape-oval-plus::before {
  content: "\F0225"; }

.mdi-shape-plus::before {
  content: "\F495"; }

.mdi-shape-polygon-plus::before {
  content: "\F65E"; }

.mdi-shape-rectangle-plus::before {
  content: "\F65F"; }

.mdi-shape-square-plus::before {
  content: "\F660"; }

.mdi-share::before {
  content: "\F496"; }

.mdi-share-all::before {
  content: "\F021F"; }

.mdi-share-all-outline::before {
  content: "\F0220"; }

.mdi-share-circle::before {
  content: "\F01D8"; }

.mdi-share-off::before {
  content: "\FF40"; }

.mdi-share-off-outline::before {
  content: "\FF41"; }

.mdi-share-outline::before {
  content: "\F931"; }

.mdi-share-variant::before {
  content: "\F497"; }

.mdi-sheep::before {
  content: "\FCA2"; }

.mdi-shield::before {
  content: "\F498"; }

.mdi-shield-account::before {
  content: "\F88E"; }

.mdi-shield-account-outline::before {
  content: "\FA11"; }

.mdi-shield-airplane::before {
  content: "\F6BA"; }

.mdi-shield-airplane-outline::before {
  content: "\FCA3"; }

.mdi-shield-alert::before {
  content: "\FEE9"; }

.mdi-shield-alert-outline::before {
  content: "\FEEA"; }

.mdi-shield-car::before {
  content: "\FFA0"; }

.mdi-shield-check::before {
  content: "\F565"; }

.mdi-shield-check-outline::before {
  content: "\FCA4"; }

.mdi-shield-cross::before {
  content: "\FCA5"; }

.mdi-shield-cross-outline::before {
  content: "\FCA6"; }

.mdi-shield-edit::before {
  content: "\F01CB"; }

.mdi-shield-edit-outline::before {
  content: "\F01CC"; }

.mdi-shield-half::before {
  content: "\F038B"; }

.mdi-shield-half-full::before {
  content: "\F77F"; }

.mdi-shield-home::before {
  content: "\F689"; }

.mdi-shield-home-outline::before {
  content: "\FCA7"; }

.mdi-shield-key::before {
  content: "\FBA0"; }

.mdi-shield-key-outline::before {
  content: "\FBA1"; }

.mdi-shield-link-variant::before {
  content: "\FD0F"; }

.mdi-shield-link-variant-outline::before {
  content: "\FD10"; }

.mdi-shield-lock::before {
  content: "\F99C"; }

.mdi-shield-lock-outline::before {
  content: "\FCA8"; }

.mdi-shield-off::before {
  content: "\F99D"; }

.mdi-shield-off-outline::before {
  content: "\F99B"; }

.mdi-shield-outline::before {
  content: "\F499"; }

.mdi-shield-plus::before {
  content: "\FAD9"; }

.mdi-shield-plus-outline::before {
  content: "\FADA"; }

.mdi-shield-refresh::before {
  content: "\F01CD"; }

.mdi-shield-refresh-outline::before {
  content: "\F01CE"; }

.mdi-shield-remove::before {
  content: "\FADB"; }

.mdi-shield-remove-outline::before {
  content: "\FADC"; }

.mdi-shield-search::before {
  content: "\FD76"; }

.mdi-shield-star::before {
  content: "\F0166"; }

.mdi-shield-star-outline::before {
  content: "\F0167"; }

.mdi-shield-sun::before {
  content: "\F007F"; }

.mdi-shield-sun-outline::before {
  content: "\F0080"; }

.mdi-ship-wheel::before {
  content: "\F832"; }

.mdi-shoe-formal::before {
  content: "\FB22"; }

.mdi-shoe-heel::before {
  content: "\FB23"; }

.mdi-shoe-print::before {
  content: "\FE5A"; }

.mdi-shopify::before {
  content: "\FADD"; }

.mdi-shopping::before {
  content: "\F49A"; }

.mdi-shopping-music::before {
  content: "\F49B"; }

.mdi-shopping-outline::before {
  content: "\F0200"; }

.mdi-shopping-search::before {
  content: "\FFA1"; }

.mdi-shovel::before {
  content: "\F70F"; }

.mdi-shovel-off::before {
  content: "\F710"; }

.mdi-shower::before {
  content: "\F99F"; }

.mdi-shower-head::before {
  content: "\F9A0"; }

.mdi-shredder::before {
  content: "\F49C"; }

.mdi-shuffle::before {
  content: "\F49D"; }

.mdi-shuffle-disabled::before {
  content: "\F49E"; }

.mdi-shuffle-variant::before {
  content: "\F49F"; }

.mdi-shuriken::before {
  content: "\F03AA"; }

.mdi-sigma::before {
  content: "\F4A0"; }

.mdi-sigma-lower::before {
  content: "\F62B"; }

.mdi-sign-caution::before {
  content: "\F4A1"; }

.mdi-sign-direction::before {
  content: "\F780"; }

.mdi-sign-direction-minus::before {
  content: "\F0022"; }

.mdi-sign-direction-plus::before {
  content: "\FFFD"; }

.mdi-sign-direction-remove::before {
  content: "\FFFE"; }

.mdi-sign-real-estate::before {
  content: "\F0143"; }

.mdi-sign-text::before {
  content: "\F781"; }

.mdi-signal::before {
  content: "\F4A2"; }

.mdi-signal-2g::before {
  content: "\F711"; }

.mdi-signal-3g::before {
  content: "\F712"; }

.mdi-signal-4g::before {
  content: "\F713"; }

.mdi-signal-5g::before {
  content: "\FA6E"; }

.mdi-signal-cellular-1::before {
  content: "\F8BB"; }

.mdi-signal-cellular-2::before {
  content: "\F8BC"; }

.mdi-signal-cellular-3::before {
  content: "\F8BD"; }

.mdi-signal-cellular-outline::before {
  content: "\F8BE"; }

.mdi-signal-distance-variant::before {
  content: "\FE47"; }

.mdi-signal-hspa::before {
  content: "\F714"; }

.mdi-signal-hspa-plus::before {
  content: "\F715"; }

.mdi-signal-off::before {
  content: "\F782"; }

.mdi-signal-variant::before {
  content: "\F60A"; }

.mdi-signature::before {
  content: "\FE5B"; }

.mdi-signature-freehand::before {
  content: "\FE5C"; }

.mdi-signature-image::before {
  content: "\FE5D"; }

.mdi-signature-text::before {
  content: "\FE5E"; }

.mdi-silo::before {
  content: "\FB24"; }

.mdi-silverware::before {
  content: "\F4A3"; }

.mdi-silverware-clean::before {
  content: "\FFFF"; }

.mdi-silverware-fork::before {
  content: "\F4A4"; }

.mdi-silverware-fork-knife::before {
  content: "\FA6F"; }

.mdi-silverware-spoon::before {
  content: "\F4A5"; }

.mdi-silverware-variant::before {
  content: "\F4A6"; }

.mdi-sim::before {
  content: "\F4A7"; }

.mdi-sim-alert::before {
  content: "\F4A8"; }

.mdi-sim-off::before {
  content: "\F4A9"; }

.mdi-simple-icons::before {
  content: "\F0348"; }

.mdi-sina-weibo::before {
  content: "\FADE"; }

.mdi-sitemap::before {
  content: "\F4AA"; }

.mdi-skate::before {
  content: "\FD11"; }

.mdi-skew-less::before {
  content: "\FD12"; }

.mdi-skew-more::before {
  content: "\FD13"; }

.mdi-ski::before {
  content: "\F032F"; }

.mdi-ski-cross-country::before {
  content: "\F0330"; }

.mdi-ski-water::before {
  content: "\F0331"; }

.mdi-skip-backward::before {
  content: "\F4AB"; }

.mdi-skip-backward-outline::before {
  content: "\FF42"; }

.mdi-skip-forward::before {
  content: "\F4AC"; }

.mdi-skip-forward-outline::before {
  content: "\FF43"; }

.mdi-skip-next::before {
  content: "\F4AD"; }

.mdi-skip-next-circle::before {
  content: "\F661"; }

.mdi-skip-next-circle-outline::before {
  content: "\F662"; }

.mdi-skip-next-outline::before {
  content: "\FF44"; }

.mdi-skip-previous::before {
  content: "\F4AE"; }

.mdi-skip-previous-circle::before {
  content: "\F663"; }

.mdi-skip-previous-circle-outline::before {
  content: "\F664"; }

.mdi-skip-previous-outline::before {
  content: "\FF45"; }

.mdi-skull::before {
  content: "\F68B"; }

.mdi-skull-crossbones::before {
  content: "\FBA2"; }

.mdi-skull-crossbones-outline::before {
  content: "\FBA3"; }

.mdi-skull-outline::before {
  content: "\FBA4"; }

.mdi-skype::before {
  content: "\F4AF"; }

.mdi-skype-business::before {
  content: "\F4B0"; }

.mdi-slack::before {
  content: "\F4B1"; }

.mdi-slackware::before {
  content: "\F90A"; }

.mdi-slash-forward::before {
  content: "\F0000"; }

.mdi-slash-forward-box::before {
  content: "\F0001"; }

.mdi-sleep::before {
  content: "\F4B2"; }

.mdi-sleep-off::before {
  content: "\F4B3"; }

.mdi-slope-downhill::before {
  content: "\FE5F"; }

.mdi-slope-uphill::before {
  content: "\FE60"; }

.mdi-slot-machine::before {
  content: "\F013F"; }

.mdi-slot-machine-outline::before {
  content: "\F0140"; }

.mdi-smart-card::before {
  content: "\F00E8"; }

.mdi-smart-card-outline::before {
  content: "\F00E9"; }

.mdi-smart-card-reader::before {
  content: "\F00EA"; }

.mdi-smart-card-reader-outline::before {
  content: "\F00EB"; }

.mdi-smog::before {
  content: "\FA70"; }

.mdi-smoke-detector::before {
  content: "\F392"; }

.mdi-smoking::before {
  content: "\F4B4"; }

.mdi-smoking-off::before {
  content: "\F4B5"; }

.mdi-snapchat::before {
  content: "\F4B6"; }

.mdi-snowboard::before {
  content: "\F0332"; }

.mdi-snowflake::before {
  content: "\F716"; }

.mdi-snowflake-alert::before {
  content: "\FF46"; }

.mdi-snowflake-melt::before {
  content: "\F02F6"; }

.mdi-snowflake-variant::before {
  content: "\FF47"; }

.mdi-snowman::before {
  content: "\F4B7"; }

.mdi-soccer::before {
  content: "\F4B8"; }

.mdi-soccer-field::before {
  content: "\F833"; }

.mdi-sofa::before {
  content: "\F4B9"; }

.mdi-solar-panel::before {
  content: "\FD77"; }

.mdi-solar-panel-large::before {
  content: "\FD78"; }

.mdi-solar-power::before {
  content: "\FA71"; }

.mdi-soldering-iron::before {
  content: "\F00BD"; }

.mdi-solid::before {
  content: "\F68C"; }

.mdi-sort::before {
  content: "\F4BA"; }

.mdi-sort-alphabetical::before {
  content: "\F4BB"; }

.mdi-sort-alphabetical-ascending::before {
  content: "\F0173"; }

.mdi-sort-alphabetical-descending::before {
  content: "\F0174"; }

.mdi-sort-ascending::before {
  content: "\F4BC"; }

.mdi-sort-descending::before {
  content: "\F4BD"; }

.mdi-sort-numeric::before {
  content: "\F4BE"; }

.mdi-sort-variant::before {
  content: "\F4BF"; }

.mdi-sort-variant-lock::before {
  content: "\FCA9"; }

.mdi-sort-variant-lock-open::before {
  content: "\FCAA"; }

.mdi-sort-variant-remove::before {
  content: "\F0172"; }

.mdi-soundcloud::before {
  content: "\F4C0"; }

.mdi-source-branch::before {
  content: "\F62C"; }

.mdi-source-commit::before {
  content: "\F717"; }

.mdi-source-commit-end::before {
  content: "\F718"; }

.mdi-source-commit-end-local::before {
  content: "\F719"; }

.mdi-source-commit-local::before {
  content: "\F71A"; }

.mdi-source-commit-next-local::before {
  content: "\F71B"; }

.mdi-source-commit-start::before {
  content: "\F71C"; }

.mdi-source-commit-start-next-local::before {
  content: "\F71D"; }

.mdi-source-fork::before {
  content: "\F4C1"; }

.mdi-source-merge::before {
  content: "\F62D"; }

.mdi-source-pull::before {
  content: "\F4C2"; }

.mdi-source-repository::before {
  content: "\FCAB"; }

.mdi-source-repository-multiple::before {
  content: "\FCAC"; }

.mdi-soy-sauce::before {
  content: "\F7ED"; }

.mdi-spa::before {
  content: "\FCAD"; }

.mdi-spa-outline::before {
  content: "\FCAE"; }

.mdi-space-invaders::before {
  content: "\FBA5"; }

.mdi-space-station::before {
  content: "\F03AE"; }

.mdi-spade::before {
  content: "\FE48"; }

.mdi-speaker::before {
  content: "\F4C3"; }

.mdi-speaker-bluetooth::before {
  content: "\F9A1"; }

.mdi-speaker-multiple::before {
  content: "\FD14"; }

.mdi-speaker-off::before {
  content: "\F4C4"; }

.mdi-speaker-wireless::before {
  content: "\F71E"; }

.mdi-speedometer::before {
  content: "\F4C5"; }

.mdi-speedometer-medium::before {
  content: "\FFA2"; }

.mdi-speedometer-slow::before {
  content: "\FFA3"; }

.mdi-spellcheck::before {
  content: "\F4C6"; }

.mdi-spider::before {
  content: "\F0215"; }

.mdi-spider-thread::before {
  content: "\F0216"; }

.mdi-spider-web::before {
  content: "\FBA6"; }

.mdi-spotify::before {
  content: "\F4C7"; }

.mdi-spotlight::before {
  content: "\F4C8"; }

.mdi-spotlight-beam::before {
  content: "\F4C9"; }

.mdi-spray::before {
  content: "\F665"; }

.mdi-spray-bottle::before {
  content: "\FADF"; }

.mdi-sprinkler::before {
  content: "\F0081"; }

.mdi-sprinkler-variant::before {
  content: "\F0082"; }

.mdi-sprout::before {
  content: "\FE49"; }

.mdi-sprout-outline::before {
  content: "\FE4A"; }

.mdi-square::before {
  content: "\F763"; }

.mdi-square-edit-outline::before {
  content: "\F90B"; }

.mdi-square-inc::before {
  content: "\F4CA"; }

.mdi-square-inc-cash::before {
  content: "\F4CB"; }

.mdi-square-medium::before {
  content: "\FA12"; }

.mdi-square-medium-outline::before {
  content: "\FA13"; }

.mdi-square-off::before {
  content: "\F0319"; }

.mdi-square-off-outline::before {
  content: "\F031A"; }

.mdi-square-outline::before {
  content: "\F762"; }

.mdi-square-root::before {
  content: "\F783"; }

.mdi-square-root-box::before {
  content: "\F9A2"; }

.mdi-square-small::before {
  content: "\FA14"; }

.mdi-squeegee::before {
  content: "\FAE0"; }

.mdi-ssh::before {
  content: "\F8BF"; }

.mdi-stack-exchange::before {
  content: "\F60B"; }

.mdi-stack-overflow::before {
  content: "\F4CC"; }

.mdi-stackpath::before {
  content: "\F359"; }

.mdi-stadium::before {
  content: "\F001A"; }

.mdi-stadium-variant::before {
  content: "\F71F"; }

.mdi-stairs::before {
  content: "\F4CD"; }

.mdi-stairs-down::before {
  content: "\F02E9"; }

.mdi-stairs-up::before {
  content: "\F02E8"; }

.mdi-stamper::before {
  content: "\FD15"; }

.mdi-standard-definition::before {
  content: "\F7EE"; }

.mdi-star::before {
  content: "\F4CE"; }

.mdi-star-box::before {
  content: "\FA72"; }

.mdi-star-box-multiple::before {
  content: "\F02B1"; }

.mdi-star-box-multiple-outline::before {
  content: "\F02B2"; }

.mdi-star-box-outline::before {
  content: "\FA73"; }

.mdi-star-circle::before {
  content: "\F4CF"; }

.mdi-star-circle-outline::before {
  content: "\F9A3"; }

.mdi-star-face::before {
  content: "\F9A4"; }

.mdi-star-four-points::before {
  content: "\FAE1"; }

.mdi-star-four-points-outline::before {
  content: "\FAE2"; }

.mdi-star-half::before {
  content: "\F4D0"; }

.mdi-star-off::before {
  content: "\F4D1"; }

.mdi-star-outline::before {
  content: "\F4D2"; }

.mdi-star-three-points::before {
  content: "\FAE3"; }

.mdi-star-three-points-outline::before {
  content: "\FAE4"; }

.mdi-state-machine::before {
  content: "\F021A"; }

.mdi-steam::before {
  content: "\F4D3"; }

.mdi-steam-box::before {
  content: "\F90C"; }

.mdi-steering::before {
  content: "\F4D4"; }

.mdi-steering-off::before {
  content: "\F90D"; }

.mdi-step-backward::before {
  content: "\F4D5"; }

.mdi-step-backward-2::before {
  content: "\F4D6"; }

.mdi-step-forward::before {
  content: "\F4D7"; }

.mdi-step-forward-2::before {
  content: "\F4D8"; }

.mdi-stethoscope::before {
  content: "\F4D9"; }

.mdi-sticker::before {
  content: "\F038F"; }

.mdi-sticker-alert::before {
  content: "\F0390"; }

.mdi-sticker-alert-outline::before {
  content: "\F0391"; }

.mdi-sticker-check::before {
  content: "\F0392"; }

.mdi-sticker-check-outline::before {
  content: "\F0393"; }

.mdi-sticker-circle-outline::before {
  content: "\F5D0"; }

.mdi-sticker-emoji::before {
  content: "\F784"; }

.mdi-sticker-minus::before {
  content: "\F0394"; }

.mdi-sticker-minus-outline::before {
  content: "\F0395"; }

.mdi-sticker-outline::before {
  content: "\F0396"; }

.mdi-sticker-plus::before {
  content: "\F0397"; }

.mdi-sticker-plus-outline::before {
  content: "\F0398"; }

.mdi-sticker-remove::before {
  content: "\F0399"; }

.mdi-sticker-remove-outline::before {
  content: "\F039A"; }

.mdi-stocking::before {
  content: "\F4DA"; }

.mdi-stomach::before {
  content: "\F00BE"; }

.mdi-stop::before {
  content: "\F4DB"; }

.mdi-stop-circle::before {
  content: "\F666"; }

.mdi-stop-circle-outline::before {
  content: "\F667"; }

.mdi-store::before {
  content: "\F4DC"; }

.mdi-store-24-hour::before {
  content: "\F4DD"; }

.mdi-store-outline::before {
  content: "\F038C"; }

.mdi-storefront::before {
  content: "\F00EC"; }

.mdi-stove::before {
  content: "\F4DE"; }

.mdi-strategy::before {
  content: "\F0201"; }

.mdi-strava::before {
  content: "\FB25"; }

.mdi-stretch-to-page::before {
  content: "\FF48"; }

.mdi-stretch-to-page-outline::before {
  content: "\FF49"; }

.mdi-string-lights::before {
  content: "\F02E5"; }

.mdi-string-lights-off::before {
  content: "\F02E6"; }

.mdi-subdirectory-arrow-left::before {
  content: "\F60C"; }

.mdi-subdirectory-arrow-right::before {
  content: "\F60D"; }

.mdi-subtitles::before {
  content: "\FA15"; }

.mdi-subtitles-outline::before {
  content: "\FA16"; }

.mdi-subway::before {
  content: "\F6AB"; }

.mdi-subway-alert-variant::before {
  content: "\FD79"; }

.mdi-subway-variant::before {
  content: "\F4DF"; }

.mdi-summit::before {
  content: "\F785"; }

.mdi-sunglasses::before {
  content: "\F4E0"; }

.mdi-surround-sound::before {
  content: "\F5C5"; }

.mdi-surround-sound-2-0::before {
  content: "\F7EF"; }

.mdi-surround-sound-3-1::before {
  content: "\F7F0"; }

.mdi-surround-sound-5-1::before {
  content: "\F7F1"; }

.mdi-surround-sound-7-1::before {
  content: "\F7F2"; }

.mdi-svg::before {
  content: "\F720"; }

.mdi-swap-horizontal::before {
  content: "\F4E1"; }

.mdi-swap-horizontal-bold::before {
  content: "\FBA9"; }

.mdi-swap-horizontal-circle::before {
  content: "\F0002"; }

.mdi-swap-horizontal-circle-outline::before {
  content: "\F0003"; }

.mdi-swap-horizontal-variant::before {
  content: "\F8C0"; }

.mdi-swap-vertical::before {
  content: "\F4E2"; }

.mdi-swap-vertical-bold::before {
  content: "\FBAA"; }

.mdi-swap-vertical-circle::before {
  content: "\F0004"; }

.mdi-swap-vertical-circle-outline::before {
  content: "\F0005"; }

.mdi-swap-vertical-variant::before {
  content: "\F8C1"; }

.mdi-swim::before {
  content: "\F4E3"; }

.mdi-switch::before {
  content: "\F4E4"; }

.mdi-sword::before {
  content: "\F4E5"; }

.mdi-sword-cross::before {
  content: "\F786"; }

.mdi-syllabary-hangul::before {
  content: "\F035E"; }

.mdi-syllabary-hiragana::before {
  content: "\F035F"; }

.mdi-syllabary-katakana::before {
  content: "\F0360"; }

.mdi-syllabary-katakana-half-width::before {
  content: "\F0361"; }

.mdi-symfony::before {
  content: "\FAE5"; }

.mdi-sync::before {
  content: "\F4E6"; }

.mdi-sync-alert::before {
  content: "\F4E7"; }

.mdi-sync-circle::before {
  content: "\F03A3"; }

.mdi-sync-off::before {
  content: "\F4E8"; }

.mdi-tab::before {
  content: "\F4E9"; }

.mdi-tab-minus::before {
  content: "\FB26"; }

.mdi-tab-plus::before {
  content: "\F75B"; }

.mdi-tab-remove::before {
  content: "\FB27"; }

.mdi-tab-unselected::before {
  content: "\F4EA"; }

.mdi-table::before {
  content: "\F4EB"; }

.mdi-table-border::before {
  content: "\FA17"; }

.mdi-table-chair::before {
  content: "\F0083"; }

.mdi-table-column::before {
  content: "\F834"; }

.mdi-table-column-plus-after::before {
  content: "\F4EC"; }

.mdi-table-column-plus-before::before {
  content: "\F4ED"; }

.mdi-table-column-remove::before {
  content: "\F4EE"; }

.mdi-table-column-width::before {
  content: "\F4EF"; }

.mdi-table-edit::before {
  content: "\F4F0"; }

.mdi-table-eye::before {
  content: "\F00BF"; }

.mdi-table-headers-eye::before {
  content: "\F0248"; }

.mdi-table-headers-eye-off::before {
  content: "\F0249"; }

.mdi-table-large::before {
  content: "\F4F1"; }

.mdi-table-large-plus::before {
  content: "\FFA4"; }

.mdi-table-large-remove::before {
  content: "\FFA5"; }

.mdi-table-merge-cells::before {
  content: "\F9A5"; }

.mdi-table-of-contents::before {
  content: "\F835"; }

.mdi-table-plus::before {
  content: "\FA74"; }

.mdi-table-remove::before {
  content: "\FA75"; }

.mdi-table-row::before {
  content: "\F836"; }

.mdi-table-row-height::before {
  content: "\F4F2"; }

.mdi-table-row-plus-after::before {
  content: "\F4F3"; }

.mdi-table-row-plus-before::before {
  content: "\F4F4"; }

.mdi-table-row-remove::before {
  content: "\F4F5"; }

.mdi-table-search::before {
  content: "\F90E"; }

.mdi-table-settings::before {
  content: "\F837"; }

.mdi-table-tennis::before {
  content: "\FE4B"; }

.mdi-tablet::before {
  content: "\F4F6"; }

.mdi-tablet-android::before {
  content: "\F4F7"; }

.mdi-tablet-cellphone::before {
  content: "\F9A6"; }

.mdi-tablet-dashboard::before {
  content: "\FEEB"; }

.mdi-tablet-ipad::before {
  content: "\F4F8"; }

.mdi-taco::before {
  content: "\F761"; }

.mdi-tag::before {
  content: "\F4F9"; }

.mdi-tag-faces::before {
  content: "\F4FA"; }

.mdi-tag-heart::before {
  content: "\F68A"; }

.mdi-tag-heart-outline::before {
  content: "\FBAB"; }

.mdi-tag-minus::before {
  content: "\F90F"; }

.mdi-tag-minus-outline::before {
  content: "\F024A"; }

.mdi-tag-multiple::before {
  content: "\F4FB"; }

.mdi-tag-multiple-outline::before {
  content: "\F0322"; }

.mdi-tag-off::before {
  content: "\F024B"; }

.mdi-tag-off-outline::before {
  content: "\F024C"; }

.mdi-tag-outline::before {
  content: "\F4FC"; }

.mdi-tag-plus::before {
  content: "\F721"; }

.mdi-tag-plus-outline::before {
  content: "\F024D"; }

.mdi-tag-remove::before {
  content: "\F722"; }

.mdi-tag-remove-outline::before {
  content: "\F024E"; }

.mdi-tag-text::before {
  content: "\F024F"; }

.mdi-tag-text-outline::before {
  content: "\F4FD"; }

.mdi-tank::before {
  content: "\FD16"; }

.mdi-tanker-truck::before {
  content: "\F0006"; }

.mdi-tape-measure::before {
  content: "\FB28"; }

.mdi-target::before {
  content: "\F4FE"; }

.mdi-target-account::before {
  content: "\FBAC"; }

.mdi-target-variant::before {
  content: "\FA76"; }

.mdi-taxi::before {
  content: "\F4FF"; }

.mdi-tea::before {
  content: "\FD7A"; }

.mdi-tea-outline::before {
  content: "\FD7B"; }

.mdi-teach::before {
  content: "\F88F"; }

.mdi-teamviewer::before {
  content: "\F500"; }

.mdi-telegram::before {
  content: "\F501"; }

.mdi-telescope::before {
  content: "\FB29"; }

.mdi-television::before {
  content: "\F502"; }

.mdi-television-ambient-light::before {
  content: "\F0381"; }

.mdi-television-box::before {
  content: "\F838"; }

.mdi-television-classic::before {
  content: "\F7F3"; }

.mdi-television-classic-off::before {
  content: "\F839"; }

.mdi-television-clean::before {
  content: "\F013B"; }

.mdi-television-guide::before {
  content: "\F503"; }

.mdi-television-off::before {
  content: "\F83A"; }

.mdi-television-pause::before {
  content: "\FFA6"; }

.mdi-television-play::before {
  content: "\FEEC"; }

.mdi-television-stop::before {
  content: "\FFA7"; }

.mdi-temperature-celsius::before {
  content: "\F504"; }

.mdi-temperature-fahrenheit::before {
  content: "\F505"; }

.mdi-temperature-kelvin::before {
  content: "\F506"; }

.mdi-tennis::before {
  content: "\FD7C"; }

.mdi-tennis-ball::before {
  content: "\F507"; }

.mdi-tent::before {
  content: "\F508"; }

.mdi-terraform::before {
  content: "\F0084"; }

.mdi-terrain::before {
  content: "\F509"; }

.mdi-test-tube::before {
  content: "\F668"; }

.mdi-test-tube-empty::before {
  content: "\F910"; }

.mdi-test-tube-off::before {
  content: "\F911"; }

.mdi-text::before {
  content: "\F9A7"; }

.mdi-text-recognition::before {
  content: "\F0168"; }

.mdi-text-shadow::before {
  content: "\F669"; }

.mdi-text-short::before {
  content: "\F9A8"; }

.mdi-text-subject::before {
  content: "\F9A9"; }

.mdi-text-to-speech::before {
  content: "\F50A"; }

.mdi-text-to-speech-off::before {
  content: "\F50B"; }

.mdi-textarea::before {
  content: "\F00C0"; }

.mdi-textbox::before {
  content: "\F60E"; }

.mdi-textbox-lock::before {
  content: "\F0388"; }

.mdi-textbox-password::before {
  content: "\F7F4"; }

.mdi-texture::before {
  content: "\F50C"; }

.mdi-texture-box::before {
  content: "\F0007"; }

.mdi-theater::before {
  content: "\F50D"; }

.mdi-theme-light-dark::before {
  content: "\F50E"; }

.mdi-thermometer::before {
  content: "\F50F"; }

.mdi-thermometer-alert::before {
  content: "\FE61"; }

.mdi-thermometer-chevron-down::before {
  content: "\FE62"; }

.mdi-thermometer-chevron-up::before {
  content: "\FE63"; }

.mdi-thermometer-high::before {
  content: "\F00ED"; }

.mdi-thermometer-lines::before {
  content: "\F510"; }

.mdi-thermometer-low::before {
  content: "\F00EE"; }

.mdi-thermometer-minus::before {
  content: "\FE64"; }

.mdi-thermometer-plus::before {
  content: "\FE65"; }

.mdi-thermostat::before {
  content: "\F393"; }

.mdi-thermostat-box::before {
  content: "\F890"; }

.mdi-thought-bubble::before {
  content: "\F7F5"; }

.mdi-thought-bubble-outline::before {
  content: "\F7F6"; }

.mdi-thumb-down::before {
  content: "\F511"; }

.mdi-thumb-down-outline::before {
  content: "\F512"; }

.mdi-thumb-up::before {
  content: "\F513"; }

.mdi-thumb-up-outline::before {
  content: "\F514"; }

.mdi-thumbs-up-down::before {
  content: "\F515"; }

.mdi-ticket::before {
  content: "\F516"; }

.mdi-ticket-account::before {
  content: "\F517"; }

.mdi-ticket-confirmation::before {
  content: "\F518"; }

.mdi-ticket-outline::before {
  content: "\F912"; }

.mdi-ticket-percent::before {
  content: "\F723"; }

.mdi-tie::before {
  content: "\F519"; }

.mdi-tilde::before {
  content: "\F724"; }

.mdi-timelapse::before {
  content: "\F51A"; }

.mdi-timeline::before {
  content: "\FBAD"; }

.mdi-timeline-alert::before {
  content: "\FFB2"; }

.mdi-timeline-alert-outline::before {
  content: "\FFB5"; }

.mdi-timeline-clock::before {
  content: "\F0226"; }

.mdi-timeline-clock-outline::before {
  content: "\F0227"; }

.mdi-timeline-help::before {
  content: "\FFB6"; }

.mdi-timeline-help-outline::before {
  content: "\FFB7"; }

.mdi-timeline-outline::before {
  content: "\FBAE"; }

.mdi-timeline-plus::before {
  content: "\FFB3"; }

.mdi-timeline-plus-outline::before {
  content: "\FFB4"; }

.mdi-timeline-text::before {
  content: "\FBAF"; }

.mdi-timeline-text-outline::before {
  content: "\FBB0"; }

.mdi-timer::before {
  content: "\F51B"; }

.mdi-timer-10::before {
  content: "\F51C"; }

.mdi-timer-3::before {
  content: "\F51D"; }

.mdi-timer-off::before {
  content: "\F51E"; }

.mdi-timer-sand::before {
  content: "\F51F"; }

.mdi-timer-sand-empty::before {
  content: "\F6AC"; }

.mdi-timer-sand-full::before {
  content: "\F78B"; }

.mdi-timetable::before {
  content: "\F520"; }

.mdi-toaster::before {
  content: "\F0085"; }

.mdi-toaster-off::before {
  content: "\F01E2"; }

.mdi-toaster-oven::before {
  content: "\FCAF"; }

.mdi-toggle-switch::before {
  content: "\F521"; }

.mdi-toggle-switch-off::before {
  content: "\F522"; }

.mdi-toggle-switch-off-outline::before {
  content: "\FA18"; }

.mdi-toggle-switch-outline::before {
  content: "\FA19"; }

.mdi-toilet::before {
  content: "\F9AA"; }

.mdi-toolbox::before {
  content: "\F9AB"; }

.mdi-toolbox-outline::before {
  content: "\F9AC"; }

.mdi-tools::before {
  content: "\F0086"; }

.mdi-tooltip::before {
  content: "\F523"; }

.mdi-tooltip-account::before {
  content: "\F00C"; }

.mdi-tooltip-edit::before {
  content: "\F524"; }

.mdi-tooltip-edit-outline::before {
  content: "\F02F0"; }

.mdi-tooltip-image::before {
  content: "\F525"; }

.mdi-tooltip-image-outline::before {
  content: "\FBB1"; }

.mdi-tooltip-outline::before {
  content: "\F526"; }

.mdi-tooltip-plus::before {
  content: "\FBB2"; }

.mdi-tooltip-plus-outline::before {
  content: "\F527"; }

.mdi-tooltip-text::before {
  content: "\F528"; }

.mdi-tooltip-text-outline::before {
  content: "\FBB3"; }

.mdi-tooth::before {
  content: "\F8C2"; }

.mdi-tooth-outline::before {
  content: "\F529"; }

.mdi-toothbrush::before {
  content: "\F0154"; }

.mdi-toothbrush-electric::before {
  content: "\F0157"; }

.mdi-toothbrush-paste::before {
  content: "\F0155"; }

.mdi-tor::before {
  content: "\F52A"; }

.mdi-tortoise::before {
  content: "\FD17"; }

.mdi-toslink::before {
  content: "\F02E3"; }

.mdi-tournament::before {
  content: "\F9AD"; }

.mdi-tower-beach::before {
  content: "\F680"; }

.mdi-tower-fire::before {
  content: "\F681"; }

.mdi-towing::before {
  content: "\F83B"; }

.mdi-toy-brick::before {
  content: "\F02B3"; }

.mdi-toy-brick-marker::before {
  content: "\F02B4"; }

.mdi-toy-brick-marker-outline::before {
  content: "\F02B5"; }

.mdi-toy-brick-minus::before {
  content: "\F02B6"; }

.mdi-toy-brick-minus-outline::before {
  content: "\F02B7"; }

.mdi-toy-brick-outline::before {
  content: "\F02B8"; }

.mdi-toy-brick-plus::before {
  content: "\F02B9"; }

.mdi-toy-brick-plus-outline::before {
  content: "\F02BA"; }

.mdi-toy-brick-remove::before {
  content: "\F02BB"; }

.mdi-toy-brick-remove-outline::before {
  content: "\F02BC"; }

.mdi-toy-brick-search::before {
  content: "\F02BD"; }

.mdi-toy-brick-search-outline::before {
  content: "\F02BE"; }

.mdi-track-light::before {
  content: "\F913"; }

.mdi-trackpad::before {
  content: "\F7F7"; }

.mdi-trackpad-lock::before {
  content: "\F932"; }

.mdi-tractor::before {
  content: "\F891"; }

.mdi-trademark::before {
  content: "\FA77"; }

.mdi-traffic-cone::before {
  content: "\F03A7"; }

.mdi-traffic-light::before {
  content: "\F52B"; }

.mdi-train::before {
  content: "\F52C"; }

.mdi-train-car::before {
  content: "\FBB4"; }

.mdi-train-variant::before {
  content: "\F8C3"; }

.mdi-tram::before {
  content: "\F52D"; }

.mdi-tram-side::before {
  content: "\F0008"; }

.mdi-transcribe::before {
  content: "\F52E"; }

.mdi-transcribe-close::before {
  content: "\F52F"; }

.mdi-transfer::before {
  content: "\F0087"; }

.mdi-transfer-down::before {
  content: "\FD7D"; }

.mdi-transfer-left::before {
  content: "\FD7E"; }

.mdi-transfer-right::before {
  content: "\F530"; }

.mdi-transfer-up::before {
  content: "\FD7F"; }

.mdi-transit-connection::before {
  content: "\FD18"; }

.mdi-transit-connection-variant::before {
  content: "\FD19"; }

.mdi-transit-detour::before {
  content: "\FFA8"; }

.mdi-transit-transfer::before {
  content: "\F6AD"; }

.mdi-transition::before {
  content: "\F914"; }

.mdi-transition-masked::before {
  content: "\F915"; }

.mdi-translate::before {
  content: "\F5CA"; }

.mdi-translate-off::before {
  content: "\FE66"; }

.mdi-transmission-tower::before {
  content: "\FD1A"; }

.mdi-trash-can::before {
  content: "\FA78"; }

.mdi-trash-can-outline::before {
  content: "\FA79"; }

.mdi-tray::before {
  content: "\F02BF"; }

.mdi-tray-alert::before {
  content: "\F02C0"; }

.mdi-tray-full::before {
  content: "\F02C1"; }

.mdi-tray-minus::before {
  content: "\F02C2"; }

.mdi-tray-plus::before {
  content: "\F02C3"; }

.mdi-tray-remove::before {
  content: "\F02C4"; }

.mdi-treasure-chest::before {
  content: "\F725"; }

.mdi-tree::before {
  content: "\F531"; }

.mdi-tree-outline::before {
  content: "\FE4C"; }

.mdi-trello::before {
  content: "\F532"; }

.mdi-trending-down::before {
  content: "\F533"; }

.mdi-trending-neutral::before {
  content: "\F534"; }

.mdi-trending-up::before {
  content: "\F535"; }

.mdi-triangle::before {
  content: "\F536"; }

.mdi-triangle-outline::before {
  content: "\F537"; }

.mdi-triforce::before {
  content: "\FBB5"; }

.mdi-trophy::before {
  content: "\F538"; }

.mdi-trophy-award::before {
  content: "\F539"; }

.mdi-trophy-broken::before {
  content: "\FD80"; }

.mdi-trophy-outline::before {
  content: "\F53A"; }

.mdi-trophy-variant::before {
  content: "\F53B"; }

.mdi-trophy-variant-outline::before {
  content: "\F53C"; }

.mdi-truck::before {
  content: "\F53D"; }

.mdi-truck-check::before {
  content: "\FCB0"; }

.mdi-truck-check-outline::before {
  content: "\F02C5"; }

.mdi-truck-delivery::before {
  content: "\F53E"; }

.mdi-truck-delivery-outline::before {
  content: "\F02C6"; }

.mdi-truck-fast::before {
  content: "\F787"; }

.mdi-truck-fast-outline::before {
  content: "\F02C7"; }

.mdi-truck-outline::before {
  content: "\F02C8"; }

.mdi-truck-trailer::before {
  content: "\F726"; }

.mdi-trumpet::before {
  content: "\F00C1"; }

.mdi-tshirt-crew::before {
  content: "\FA7A"; }

.mdi-tshirt-crew-outline::before {
  content: "\F53F"; }

.mdi-tshirt-v::before {
  content: "\FA7B"; }

.mdi-tshirt-v-outline::before {
  content: "\F540"; }

.mdi-tumble-dryer::before {
  content: "\F916"; }

.mdi-tumble-dryer-alert::before {
  content: "\F01E5"; }

.mdi-tumble-dryer-off::before {
  content: "\F01E6"; }

.mdi-tumblr::before {
  content: "\F541"; }

.mdi-tumblr-box::before {
  content: "\F917"; }

.mdi-tumblr-reblog::before {
  content: "\F542"; }

.mdi-tune::before {
  content: "\F62E"; }

.mdi-tune-vertical::before {
  content: "\F66A"; }

.mdi-turnstile::before {
  content: "\FCB1"; }

.mdi-turnstile-outline::before {
  content: "\FCB2"; }

.mdi-turtle::before {
  content: "\FCB3"; }

.mdi-twitch::before {
  content: "\F543"; }

.mdi-twitter::before {
  content: "\F544"; }

.mdi-twitter-box::before {
  content: "\F545"; }

.mdi-twitter-circle::before {
  content: "\F546"; }

.mdi-twitter-retweet::before {
  content: "\F547"; }

.mdi-two-factor-authentication::before {
  content: "\F9AE"; }

.mdi-typewriter::before {
  content: "\FF4A"; }

.mdi-uber::before {
  content: "\F748"; }

.mdi-ubisoft::before {
  content: "\FBB6"; }

.mdi-ubuntu::before {
  content: "\F548"; }

.mdi-ufo::before {
  content: "\F00EF"; }

.mdi-ufo-outline::before {
  content: "\F00F0"; }

.mdi-ultra-high-definition::before {
  content: "\F7F8"; }

.mdi-umbraco::before {
  content: "\F549"; }

.mdi-umbrella::before {
  content: "\F54A"; }

.mdi-umbrella-closed::before {
  content: "\F9AF"; }

.mdi-umbrella-outline::before {
  content: "\F54B"; }

.mdi-undo::before {
  content: "\F54C"; }

.mdi-undo-variant::before {
  content: "\F54D"; }

.mdi-unfold-less-horizontal::before {
  content: "\F54E"; }

.mdi-unfold-less-vertical::before {
  content: "\F75F"; }

.mdi-unfold-more-horizontal::before {
  content: "\F54F"; }

.mdi-unfold-more-vertical::before {
  content: "\F760"; }

.mdi-ungroup::before {
  content: "\F550"; }

.mdi-unicode::before {
  content: "\FEED"; }

.mdi-unity::before {
  content: "\F6AE"; }

.mdi-unreal::before {
  content: "\F9B0"; }

.mdi-untappd::before {
  content: "\F551"; }

.mdi-update::before {
  content: "\F6AF"; }

.mdi-upload::before {
  content: "\F552"; }

.mdi-upload-lock::before {
  content: "\F039E"; }

.mdi-upload-lock-outline::before {
  content: "\F039F"; }

.mdi-upload-multiple::before {
  content: "\F83C"; }

.mdi-upload-network::before {
  content: "\F6F5"; }

.mdi-upload-network-outline::before {
  content: "\FCB4"; }

.mdi-upload-off::before {
  content: "\F00F1"; }

.mdi-upload-off-outline::before {
  content: "\F00F2"; }

.mdi-upload-outline::before {
  content: "\FE67"; }

.mdi-usb::before {
  content: "\F553"; }

.mdi-usb-flash-drive::before {
  content: "\F02C9"; }

.mdi-usb-flash-drive-outline::before {
  content: "\F02CA"; }

.mdi-usb-port::before {
  content: "\F021B"; }

.mdi-valve::before {
  content: "\F0088"; }

.mdi-valve-closed::before {
  content: "\F0089"; }

.mdi-valve-open::before {
  content: "\F008A"; }

.mdi-van-passenger::before {
  content: "\F7F9"; }

.mdi-van-utility::before {
  content: "\F7FA"; }

.mdi-vanish::before {
  content: "\F7FB"; }

.mdi-vanity-light::before {
  content: "\F020C"; }

.mdi-variable::before {
  content: "\FAE6"; }

.mdi-variable-box::before {
  content: "\F013C"; }

.mdi-vector-arrange-above::before {
  content: "\F554"; }

.mdi-vector-arrange-below::before {
  content: "\F555"; }

.mdi-vector-bezier::before {
  content: "\FAE7"; }

.mdi-vector-circle::before {
  content: "\F556"; }

.mdi-vector-circle-variant::before {
  content: "\F557"; }

.mdi-vector-combine::before {
  content: "\F558"; }

.mdi-vector-curve::before {
  content: "\F559"; }

.mdi-vector-difference::before {
  content: "\F55A"; }

.mdi-vector-difference-ab::before {
  content: "\F55B"; }

.mdi-vector-difference-ba::before {
  content: "\F55C"; }

.mdi-vector-ellipse::before {
  content: "\F892"; }

.mdi-vector-intersection::before {
  content: "\F55D"; }

.mdi-vector-line::before {
  content: "\F55E"; }

.mdi-vector-link::before {
  content: "\F0009"; }

.mdi-vector-point::before {
  content: "\F55F"; }

.mdi-vector-polygon::before {
  content: "\F560"; }

.mdi-vector-polyline::before {
  content: "\F561"; }

.mdi-vector-polyline-edit::before {
  content: "\F0250"; }

.mdi-vector-polyline-minus::before {
  content: "\F0251"; }

.mdi-vector-polyline-plus::before {
  content: "\F0252"; }

.mdi-vector-polyline-remove::before {
  content: "\F0253"; }

.mdi-vector-radius::before {
  content: "\F749"; }

.mdi-vector-rectangle::before {
  content: "\F5C6"; }

.mdi-vector-selection::before {
  content: "\F562"; }

.mdi-vector-square::before {
  content: "\F001"; }

.mdi-vector-triangle::before {
  content: "\F563"; }

.mdi-vector-union::before {
  content: "\F564"; }

.mdi-venmo::before {
  content: "\F578"; }

.mdi-vhs::before {
  content: "\FA1A"; }

.mdi-vibrate::before {
  content: "\F566"; }

.mdi-vibrate-off::before {
  content: "\FCB5"; }

.mdi-video::before {
  content: "\F567"; }

.mdi-video-3d::before {
  content: "\F7FC"; }

.mdi-video-3d-variant::before {
  content: "\FEEE"; }

.mdi-video-4k-box::before {
  content: "\F83D"; }

.mdi-video-account::before {
  content: "\F918"; }

.mdi-video-check::before {
  content: "\F008B"; }

.mdi-video-check-outline::before {
  content: "\F008C"; }

.mdi-video-image::before {
  content: "\F919"; }

.mdi-video-input-antenna::before {
  content: "\F83E"; }

.mdi-video-input-component::before {
  content: "\F83F"; }

.mdi-video-input-hdmi::before {
  content: "\F840"; }

.mdi-video-input-scart::before {
  content: "\FFA9"; }

.mdi-video-input-svideo::before {
  content: "\F841"; }

.mdi-video-minus::before {
  content: "\F9B1"; }

.mdi-video-off::before {
  content: "\F568"; }

.mdi-video-off-outline::before {
  content: "\FBB7"; }

.mdi-video-outline::before {
  content: "\FBB8"; }

.mdi-video-plus::before {
  content: "\F9B2"; }

.mdi-video-stabilization::before {
  content: "\F91A"; }

.mdi-video-switch::before {
  content: "\F569"; }

.mdi-video-vintage::before {
  content: "\FA1B"; }

.mdi-video-wireless::before {
  content: "\FEEF"; }

.mdi-video-wireless-outline::before {
  content: "\FEF0"; }

.mdi-view-agenda::before {
  content: "\F56A"; }

.mdi-view-agenda-outline::before {
  content: "\F0203"; }

.mdi-view-array::before {
  content: "\F56B"; }

.mdi-view-carousel::before {
  content: "\F56C"; }

.mdi-view-column::before {
  content: "\F56D"; }

.mdi-view-comfy::before {
  content: "\FE4D"; }

.mdi-view-compact::before {
  content: "\FE4E"; }

.mdi-view-compact-outline::before {
  content: "\FE4F"; }

.mdi-view-dashboard::before {
  content: "\F56E"; }

.mdi-view-dashboard-outline::before {
  content: "\FA1C"; }

.mdi-view-dashboard-variant::before {
  content: "\F842"; }

.mdi-view-day::before {
  content: "\F56F"; }

.mdi-view-grid::before {
  content: "\F570"; }

.mdi-view-grid-outline::before {
  content: "\F0204"; }

.mdi-view-grid-plus::before {
  content: "\FFAA"; }

.mdi-view-grid-plus-outline::before {
  content: "\F0205"; }

.mdi-view-headline::before {
  content: "\F571"; }

.mdi-view-list::before {
  content: "\F572"; }

.mdi-view-module::before {
  content: "\F573"; }

.mdi-view-parallel::before {
  content: "\F727"; }

.mdi-view-quilt::before {
  content: "\F574"; }

.mdi-view-sequential::before {
  content: "\F728"; }

.mdi-view-split-horizontal::before {
  content: "\FBA7"; }

.mdi-view-split-vertical::before {
  content: "\FBA8"; }

.mdi-view-stream::before {
  content: "\F575"; }

.mdi-view-week::before {
  content: "\F576"; }

.mdi-vimeo::before {
  content: "\F577"; }

.mdi-violin::before {
  content: "\F60F"; }

.mdi-virtual-reality::before {
  content: "\F893"; }

.mdi-visual-studio::before {
  content: "\F610"; }

.mdi-visual-studio-code::before {
  content: "\FA1D"; }

.mdi-vk::before {
  content: "\F579"; }

.mdi-vk-box::before {
  content: "\F57A"; }

.mdi-vk-circle::before {
  content: "\F57B"; }

.mdi-vlc::before {
  content: "\F57C"; }

.mdi-voice::before {
  content: "\F5CB"; }

.mdi-voice-off::before {
  content: "\FEF1"; }

.mdi-voicemail::before {
  content: "\F57D"; }

.mdi-volleyball::before {
  content: "\F9B3"; }

.mdi-volume-high::before {
  content: "\F57E"; }

.mdi-volume-low::before {
  content: "\F57F"; }

.mdi-volume-medium::before {
  content: "\F580"; }

.mdi-volume-minus::before {
  content: "\F75D"; }

.mdi-volume-mute::before {
  content: "\F75E"; }

.mdi-volume-off::before {
  content: "\F581"; }

.mdi-volume-plus::before {
  content: "\F75C"; }

.mdi-volume-source::before {
  content: "\F014B"; }

.mdi-volume-variant-off::before {
  content: "\FE68"; }

.mdi-volume-vibrate::before {
  content: "\F014C"; }

.mdi-vote::before {
  content: "\FA1E"; }

.mdi-vote-outline::before {
  content: "\FA1F"; }

.mdi-vpn::before {
  content: "\F582"; }

.mdi-vuejs::before {
  content: "\F843"; }

.mdi-vuetify::before {
  content: "\FE50"; }

.mdi-walk::before {
  content: "\F583"; }

.mdi-wall::before {
  content: "\F7FD"; }

.mdi-wall-sconce::before {
  content: "\F91B"; }

.mdi-wall-sconce-flat::before {
  content: "\F91C"; }

.mdi-wall-sconce-variant::before {
  content: "\F91D"; }

.mdi-wallet::before {
  content: "\F584"; }

.mdi-wallet-giftcard::before {
  content: "\F585"; }

.mdi-wallet-membership::before {
  content: "\F586"; }

.mdi-wallet-outline::before {
  content: "\FBB9"; }

.mdi-wallet-plus::before {
  content: "\FFAB"; }

.mdi-wallet-plus-outline::before {
  content: "\FFAC"; }

.mdi-wallet-travel::before {
  content: "\F587"; }

.mdi-wallpaper::before {
  content: "\FE69"; }

.mdi-wan::before {
  content: "\F588"; }

.mdi-wardrobe::before {
  content: "\FFAD"; }

.mdi-wardrobe-outline::before {
  content: "\FFAE"; }

.mdi-warehouse::before {
  content: "\FFBB"; }

.mdi-washing-machine::before {
  content: "\F729"; }

.mdi-washing-machine-alert::before {
  content: "\F01E7"; }

.mdi-washing-machine-off::before {
  content: "\F01E8"; }

.mdi-watch::before {
  content: "\F589"; }

.mdi-watch-export::before {
  content: "\F58A"; }

.mdi-watch-export-variant::before {
  content: "\F894"; }

.mdi-watch-import::before {
  content: "\F58B"; }

.mdi-watch-import-variant::before {
  content: "\F895"; }

.mdi-watch-variant::before {
  content: "\F896"; }

.mdi-watch-vibrate::before {
  content: "\F6B0"; }

.mdi-watch-vibrate-off::before {
  content: "\FCB6"; }

.mdi-water::before {
  content: "\F58C"; }

.mdi-water-boiler::before {
  content: "\FFAF"; }

.mdi-water-boiler-alert::before {
  content: "\F01DE"; }

.mdi-water-boiler-off::before {
  content: "\F01DF"; }

.mdi-water-off::before {
  content: "\F58D"; }

.mdi-water-outline::before {
  content: "\FE6A"; }

.mdi-water-percent::before {
  content: "\F58E"; }

.mdi-water-polo::before {
  content: "\F02CB"; }

.mdi-water-pump::before {
  content: "\F58F"; }

.mdi-water-pump-off::before {
  content: "\FFB0"; }

.mdi-water-well::before {
  content: "\F008D"; }

.mdi-water-well-outline::before {
  content: "\F008E"; }

.mdi-watermark::before {
  content: "\F612"; }

.mdi-wave::before {
  content: "\FF4B"; }

.mdi-waves::before {
  content: "\F78C"; }

.mdi-waze::before {
  content: "\FBBA"; }

.mdi-weather-cloudy::before {
  content: "\F590"; }

.mdi-weather-cloudy-alert::before {
  content: "\FF4C"; }

.mdi-weather-cloudy-arrow-right::before {
  content: "\FE51"; }

.mdi-weather-fog::before {
  content: "\F591"; }

.mdi-weather-hail::before {
  content: "\F592"; }

.mdi-weather-hazy::before {
  content: "\FF4D"; }

.mdi-weather-hurricane::before {
  content: "\F897"; }

.mdi-weather-lightning::before {
  content: "\F593"; }

.mdi-weather-lightning-rainy::before {
  content: "\F67D"; }

.mdi-weather-night::before {
  content: "\F594"; }

.mdi-weather-night-partly-cloudy::before {
  content: "\FF4E"; }

.mdi-weather-partly-cloudy::before {
  content: "\F595"; }

.mdi-weather-partly-lightning::before {
  content: "\FF4F"; }

.mdi-weather-partly-rainy::before {
  content: "\FF50"; }

.mdi-weather-partly-snowy::before {
  content: "\FF51"; }

.mdi-weather-partly-snowy-rainy::before {
  content: "\FF52"; }

.mdi-weather-pouring::before {
  content: "\F596"; }

.mdi-weather-rainy::before {
  content: "\F597"; }

.mdi-weather-snowy::before {
  content: "\F598"; }

.mdi-weather-snowy-heavy::before {
  content: "\FF53"; }

.mdi-weather-snowy-rainy::before {
  content: "\F67E"; }

.mdi-weather-sunny::before {
  content: "\F599"; }

.mdi-weather-sunny-alert::before {
  content: "\FF54"; }

.mdi-weather-sunset::before {
  content: "\F59A"; }

.mdi-weather-sunset-down::before {
  content: "\F59B"; }

.mdi-weather-sunset-up::before {
  content: "\F59C"; }

.mdi-weather-tornado::before {
  content: "\FF55"; }

.mdi-weather-windy::before {
  content: "\F59D"; }

.mdi-weather-windy-variant::before {
  content: "\F59E"; }

.mdi-web::before {
  content: "\F59F"; }

.mdi-web-box::before {
  content: "\FFB1"; }

.mdi-web-clock::before {
  content: "\F0275"; }

.mdi-webcam::before {
  content: "\F5A0"; }

.mdi-webhook::before {
  content: "\F62F"; }

.mdi-webpack::before {
  content: "\F72A"; }

.mdi-webrtc::before {
  content: "\F0273"; }

.mdi-wechat::before {
  content: "\F611"; }

.mdi-weight::before {
  content: "\F5A1"; }

.mdi-weight-gram::before {
  content: "\FD1B"; }

.mdi-weight-kilogram::before {
  content: "\F5A2"; }

.mdi-weight-lifter::before {
  content: "\F0188"; }

.mdi-weight-pound::before {
  content: "\F9B4"; }

.mdi-whatsapp::before {
  content: "\F5A3"; }

.mdi-wheelchair-accessibility::before {
  content: "\F5A4"; }

.mdi-whistle::before {
  content: "\F9B5"; }

.mdi-whistle-outline::before {
  content: "\F02E7"; }

.mdi-white-balance-auto::before {
  content: "\F5A5"; }

.mdi-white-balance-incandescent::before {
  content: "\F5A6"; }

.mdi-white-balance-iridescent::before {
  content: "\F5A7"; }

.mdi-white-balance-sunny::before {
  content: "\F5A8"; }

.mdi-widgets::before {
  content: "\F72B"; }

.mdi-widgets-outline::before {
  content: "\F0380"; }

.mdi-wifi::before {
  content: "\F5A9"; }

.mdi-wifi-off::before {
  content: "\F5AA"; }

.mdi-wifi-star::before {
  content: "\FE6B"; }

.mdi-wifi-strength-1::before {
  content: "\F91E"; }

.mdi-wifi-strength-1-alert::before {
  content: "\F91F"; }

.mdi-wifi-strength-1-lock::before {
  content: "\F920"; }

.mdi-wifi-strength-2::before {
  content: "\F921"; }

.mdi-wifi-strength-2-alert::before {
  content: "\F922"; }

.mdi-wifi-strength-2-lock::before {
  content: "\F923"; }

.mdi-wifi-strength-3::before {
  content: "\F924"; }

.mdi-wifi-strength-3-alert::before {
  content: "\F925"; }

.mdi-wifi-strength-3-lock::before {
  content: "\F926"; }

.mdi-wifi-strength-4::before {
  content: "\F927"; }

.mdi-wifi-strength-4-alert::before {
  content: "\F928"; }

.mdi-wifi-strength-4-lock::before {
  content: "\F929"; }

.mdi-wifi-strength-alert-outline::before {
  content: "\F92A"; }

.mdi-wifi-strength-lock-outline::before {
  content: "\F92B"; }

.mdi-wifi-strength-off::before {
  content: "\F92C"; }

.mdi-wifi-strength-off-outline::before {
  content: "\F92D"; }

.mdi-wifi-strength-outline::before {
  content: "\F92E"; }

.mdi-wii::before {
  content: "\F5AB"; }

.mdi-wiiu::before {
  content: "\F72C"; }

.mdi-wikipedia::before {
  content: "\F5AC"; }

.mdi-wind-turbine::before {
  content: "\FD81"; }

.mdi-window-close::before {
  content: "\F5AD"; }

.mdi-window-closed::before {
  content: "\F5AE"; }

.mdi-window-closed-variant::before {
  content: "\F0206"; }

.mdi-window-maximize::before {
  content: "\F5AF"; }

.mdi-window-minimize::before {
  content: "\F5B0"; }

.mdi-window-open::before {
  content: "\F5B1"; }

.mdi-window-open-variant::before {
  content: "\F0207"; }

.mdi-window-restore::before {
  content: "\F5B2"; }

.mdi-window-shutter::before {
  content: "\F0147"; }

.mdi-window-shutter-alert::before {
  content: "\F0148"; }

.mdi-window-shutter-open::before {
  content: "\F0149"; }

.mdi-windows::before {
  content: "\F5B3"; }

.mdi-windows-classic::before {
  content: "\FA20"; }

.mdi-wiper::before {
  content: "\FAE8"; }

.mdi-wiper-wash::before {
  content: "\FD82"; }

.mdi-wordpress::before {
  content: "\F5B4"; }

.mdi-worker::before {
  content: "\F5B5"; }

.mdi-wrap::before {
  content: "\F5B6"; }

.mdi-wrap-disabled::before {
  content: "\FBBB"; }

.mdi-wrench::before {
  content: "\F5B7"; }

.mdi-wrench-outline::before {
  content: "\FBBC"; }

.mdi-wunderlist::before {
  content: "\F5B8"; }

.mdi-xamarin::before {
  content: "\F844"; }

.mdi-xamarin-outline::before {
  content: "\F845"; }

.mdi-xaml::before {
  content: "\F673"; }

.mdi-xbox::before {
  content: "\F5B9"; }

.mdi-xbox-controller::before {
  content: "\F5BA"; }

.mdi-xbox-controller-battery-alert::before {
  content: "\F74A"; }

.mdi-xbox-controller-battery-charging::before {
  content: "\FA21"; }

.mdi-xbox-controller-battery-empty::before {
  content: "\F74B"; }

.mdi-xbox-controller-battery-full::before {
  content: "\F74C"; }

.mdi-xbox-controller-battery-low::before {
  content: "\F74D"; }

.mdi-xbox-controller-battery-medium::before {
  content: "\F74E"; }

.mdi-xbox-controller-battery-unknown::before {
  content: "\F74F"; }

.mdi-xbox-controller-menu::before {
  content: "\FE52"; }

.mdi-xbox-controller-off::before {
  content: "\F5BB"; }

.mdi-xbox-controller-view::before {
  content: "\FE53"; }

.mdi-xda::before {
  content: "\F5BC"; }

.mdi-xing::before {
  content: "\F5BD"; }

.mdi-xing-box::before {
  content: "\F5BE"; }

.mdi-xing-circle::before {
  content: "\F5BF"; }

.mdi-xml::before {
  content: "\F5C0"; }

.mdi-xmpp::before {
  content: "\F7FE"; }

.mdi-yahoo::before {
  content: "\FB2A"; }

.mdi-yammer::before {
  content: "\F788"; }

.mdi-yeast::before {
  content: "\F5C1"; }

.mdi-yelp::before {
  content: "\F5C2"; }

.mdi-yin-yang::before {
  content: "\F67F"; }

.mdi-yoga::before {
  content: "\F01A7"; }

.mdi-youtube::before {
  content: "\F5C3"; }

.mdi-youtube-creator-studio::before {
  content: "\F846"; }

.mdi-youtube-gaming::before {
  content: "\F847"; }

.mdi-youtube-subscription::before {
  content: "\FD1C"; }

.mdi-youtube-tv::before {
  content: "\F448"; }

.mdi-z-wave::before {
  content: "\FAE9"; }

.mdi-zend::before {
  content: "\FAEA"; }

.mdi-zigbee::before {
  content: "\FD1D"; }

.mdi-zip-box::before {
  content: "\F5C4"; }

.mdi-zip-box-outline::before {
  content: "\F001B"; }

.mdi-zip-disk::before {
  content: "\FA22"; }

.mdi-zodiac-aquarius::before {
  content: "\FA7C"; }

.mdi-zodiac-aries::before {
  content: "\FA7D"; }

.mdi-zodiac-cancer::before {
  content: "\FA7E"; }

.mdi-zodiac-capricorn::before {
  content: "\FA7F"; }

.mdi-zodiac-gemini::before {
  content: "\FA80"; }

.mdi-zodiac-leo::before {
  content: "\FA81"; }

.mdi-zodiac-libra::before {
  content: "\FA82"; }

.mdi-zodiac-pisces::before {
  content: "\FA83"; }

.mdi-zodiac-sagittarius::before {
  content: "\FA84"; }

.mdi-zodiac-scorpio::before {
  content: "\FA85"; }

.mdi-zodiac-taurus::before {
  content: "\FA86"; }

.mdi-zodiac-virgo::before {
  content: "\FA87"; }

.mdi-blank::before {
  content: "\F68C";
  visibility: hidden; }

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px; }

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px; }

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px; }

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px; }

.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54); }

.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26); }

.mdi-light:before {
  color: white; }

.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3); }

.mdi-rotate-45 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(45deg);
            transform: scaleX(-1) rotate(45deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: scaleY(-1) rotate(45deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-45:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }

.mdi-rotate-90 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(90deg);
            transform: scaleX(-1) rotate(90deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: scaleY(-1) rotate(90deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-90:before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg); }

.mdi-rotate-135 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(135deg);
            transform: scaleX(-1) rotate(135deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: scaleY(-1) rotate(135deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-135:before {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg); }

.mdi-rotate-180 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(180deg);
            transform: scaleX(-1) rotate(180deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: scaleY(-1) rotate(180deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-180:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }

.mdi-rotate-225 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(225deg);
            transform: scaleX(-1) rotate(225deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(225deg);
            -ms-transform: rotate(225deg);
            transform: scaleY(-1) rotate(225deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-225:before {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg); }

.mdi-rotate-270 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(270deg);
            transform: scaleX(-1) rotate(270deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(270deg);
            -ms-transform: rotate(270deg);
            transform: scaleY(-1) rotate(270deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-270:before {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg); }

.mdi-rotate-315 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(315deg);
            transform: scaleX(-1) rotate(315deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(315deg);
            -ms-transform: rotate(315deg);
            transform: scaleY(-1) rotate(315deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-315:before {
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg); }

.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -webkit-filter: FlipH;
          filter: FlipH;
  -ms-filter: "FlipH"; }

.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  -webkit-filter: FlipV;
          filter: FlipV;
  -ms-filter: "FlipV"; }

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear; }

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat; }

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.33333em;
  line-height: 1em; }
  .flag-icon:before {
    content: '\00a0'; }
  .flag-icon.flag-icon-squared {
    width: 1em; }

.flag-icon-ad {
  background-image: url(/static/media/ad.d4227482.svg); }
  .flag-icon-ad.flag-icon-squared {
    background-image: url(/static/media/ad.e15ddeab.svg); }

.flag-icon-ae {
  background-image: url(/static/media/ae.9fd1fcbf.svg); }
  .flag-icon-ae.flag-icon-squared {
    background-image: url(/static/media/ae.7847726d.svg); }

.flag-icon-af {
  background-image: url(/static/media/af.008dc322.svg); }
  .flag-icon-af.flag-icon-squared {
    background-image: url(/static/media/af.fa735e43.svg); }

.flag-icon-ag {
  background-image: url(/static/media/ag.cce32c73.svg); }
  .flag-icon-ag.flag-icon-squared {
    background-image: url(/static/media/ag.53a60086.svg); }

.flag-icon-ai {
  background-image: url(/static/media/ai.c2f29c4d.svg); }
  .flag-icon-ai.flag-icon-squared {
    background-image: url(/static/media/ai.9fe4d6b7.svg); }

.flag-icon-al {
  background-image: url(/static/media/al.4eb491e7.svg); }
  .flag-icon-al.flag-icon-squared {
    background-image: url(/static/media/al.46612c27.svg); }

.flag-icon-am {
  background-image: url(/static/media/am.06509258.svg); }
  .flag-icon-am.flag-icon-squared {
    background-image: url(/static/media/am.c86a9e16.svg); }

.flag-icon-ao {
  background-image: url(/static/media/ao.8b6f2ec2.svg); }
  .flag-icon-ao.flag-icon-squared {
    background-image: url(/static/media/ao.ab8cc21b.svg); }

.flag-icon-aq {
  background-image: url(/static/media/aq.65afe1f1.svg); }
  .flag-icon-aq.flag-icon-squared {
    background-image: url(/static/media/aq.65448909.svg); }

.flag-icon-ar {
  background-image: url(/static/media/ar.d205ca13.svg); }
  .flag-icon-ar.flag-icon-squared {
    background-image: url(/static/media/ar.78827b0b.svg); }

.flag-icon-as {
  background-image: url(/static/media/as.16f433a6.svg); }
  .flag-icon-as.flag-icon-squared {
    background-image: url(/static/media/as.27f3e372.svg); }

.flag-icon-at {
  background-image: url(/static/media/at.e2634e96.svg); }
  .flag-icon-at.flag-icon-squared {
    background-image: url(/static/media/at.5ab33f74.svg); }

.flag-icon-au {
  background-image: url(/static/media/au.503a3a98.svg); }
  .flag-icon-au.flag-icon-squared {
    background-image: url(/static/media/au.9b18ee04.svg); }

.flag-icon-aw {
  background-image: url(/static/media/aw.47ea7038.svg); }
  .flag-icon-aw.flag-icon-squared {
    background-image: url(/static/media/aw.f159ec16.svg); }

.flag-icon-ax {
  background-image: url(/static/media/ax.c26f8374.svg); }
  .flag-icon-ax.flag-icon-squared {
    background-image: url(/static/media/ax.fdd00c43.svg); }

.flag-icon-az {
  background-image: url(/static/media/az.451284ce.svg); }
  .flag-icon-az.flag-icon-squared {
    background-image: url(/static/media/az.0b4258df.svg); }

.flag-icon-ba {
  background-image: url(/static/media/ba.32231661.svg); }
  .flag-icon-ba.flag-icon-squared {
    background-image: url(/static/media/ba.a9dbadd7.svg); }

.flag-icon-bb {
  background-image: url(/static/media/bb.9873885f.svg); }
  .flag-icon-bb.flag-icon-squared {
    background-image: url(/static/media/bb.45c62450.svg); }

.flag-icon-bd {
  background-image: url(/static/media/bd.5102bab0.svg); }
  .flag-icon-bd.flag-icon-squared {
    background-image: url(/static/media/bd.c4a1485f.svg); }

.flag-icon-be {
  background-image: url(/static/media/be.27d8ca49.svg); }
  .flag-icon-be.flag-icon-squared {
    background-image: url(/static/media/be.f1e78c8b.svg); }

.flag-icon-bf {
  background-image: url(/static/media/bf.9a958401.svg); }
  .flag-icon-bf.flag-icon-squared {
    background-image: url(/static/media/bf.48eb94de.svg); }

.flag-icon-bg {
  background-image: url(/static/media/bg.3d762564.svg); }
  .flag-icon-bg.flag-icon-squared {
    background-image: url(/static/media/bg.7163fe76.svg); }

.flag-icon-bh {
  background-image: url(/static/media/bh.ef135f3c.svg); }
  .flag-icon-bh.flag-icon-squared {
    background-image: url(/static/media/bh.90ad3cbd.svg); }

.flag-icon-bi {
  background-image: url(/static/media/bi.06f36479.svg); }
  .flag-icon-bi.flag-icon-squared {
    background-image: url(/static/media/bi.75d5af3d.svg); }

.flag-icon-bj {
  background-image: url(/static/media/bj.c81e8915.svg); }
  .flag-icon-bj.flag-icon-squared {
    background-image: url(/static/media/bj.b6387659.svg); }

.flag-icon-bl {
  background-image: url(/static/media/bl.38e27b68.svg); }
  .flag-icon-bl.flag-icon-squared {
    background-image: url(/static/media/bl.4d724b8e.svg); }

.flag-icon-bm {
  background-image: url(/static/media/bm.0fdefae8.svg); }
  .flag-icon-bm.flag-icon-squared {
    background-image: url(/static/media/bm.09839e2c.svg); }

.flag-icon-bn {
  background-image: url(/static/media/bn.1d4e6091.svg); }
  .flag-icon-bn.flag-icon-squared {
    background-image: url(/static/media/bn.0adbb664.svg); }

.flag-icon-bo {
  background-image: url(/static/media/bo.4128202a.svg); }
  .flag-icon-bo.flag-icon-squared {
    background-image: url(/static/media/bo.eab17936.svg); }

.flag-icon-bq {
  background-image: url(/static/media/bq.b551016f.svg); }
  .flag-icon-bq.flag-icon-squared {
    background-image: url(/static/media/bq.d6da2e84.svg); }

.flag-icon-br {
  background-image: url(/static/media/br.87032851.svg); }
  .flag-icon-br.flag-icon-squared {
    background-image: url(/static/media/br.ef701aba.svg); }

.flag-icon-bs {
  background-image: url(/static/media/bs.9f8a4eae.svg); }
  .flag-icon-bs.flag-icon-squared {
    background-image: url(/static/media/bs.6fe877e1.svg); }

.flag-icon-bt {
  background-image: url(/static/media/bt.65b20c56.svg); }
  .flag-icon-bt.flag-icon-squared {
    background-image: url(/static/media/bt.dbb1623f.svg); }

.flag-icon-bv {
  background-image: url(/static/media/bv.78bef910.svg); }
  .flag-icon-bv.flag-icon-squared {
    background-image: url(/static/media/bv.b70ab2f2.svg); }

.flag-icon-bw {
  background-image: url(/static/media/bw.d1585fdf.svg); }
  .flag-icon-bw.flag-icon-squared {
    background-image: url(/static/media/bw.d9e5e45f.svg); }

.flag-icon-by {
  background-image: url(/static/media/by.f4cbd761.svg); }
  .flag-icon-by.flag-icon-squared {
    background-image: url(/static/media/by.80b2d2dd.svg); }

.flag-icon-bz {
  background-image: url(/static/media/bz.64d617ea.svg); }
  .flag-icon-bz.flag-icon-squared {
    background-image: url(/static/media/bz.e6b5e204.svg); }

.flag-icon-ca {
  background-image: url(/static/media/ca.c976442e.svg); }
  .flag-icon-ca.flag-icon-squared {
    background-image: url(/static/media/ca.8678fc67.svg); }

.flag-icon-cc {
  background-image: url(/static/media/cc.2da4bb97.svg); }
  .flag-icon-cc.flag-icon-squared {
    background-image: url(/static/media/cc.12b2a484.svg); }

.flag-icon-cd {
  background-image: url(/static/media/cd.cd346cdc.svg); }
  .flag-icon-cd.flag-icon-squared {
    background-image: url(/static/media/cd.b43f872e.svg); }

.flag-icon-cf {
  background-image: url(/static/media/cf.2171101e.svg); }
  .flag-icon-cf.flag-icon-squared {
    background-image: url(/static/media/cf.1bc217dc.svg); }

.flag-icon-cg {
  background-image: url(/static/media/cg.4396b867.svg); }
  .flag-icon-cg.flag-icon-squared {
    background-image: url(/static/media/cg.8373836c.svg); }

.flag-icon-ch {
  background-image: url(/static/media/ch.9c26f60a.svg); }
  .flag-icon-ch.flag-icon-squared {
    background-image: url(/static/media/ch.252c409b.svg); }

.flag-icon-ci {
  background-image: url(/static/media/ci.d939dcac.svg); }
  .flag-icon-ci.flag-icon-squared {
    background-image: url(/static/media/ci.26a62321.svg); }

.flag-icon-ck {
  background-image: url(/static/media/ck.960a7b5a.svg); }
  .flag-icon-ck.flag-icon-squared {
    background-image: url(/static/media/ck.22bf8119.svg); }

.flag-icon-cl {
  background-image: url(/static/media/cl.8949f9e6.svg); }
  .flag-icon-cl.flag-icon-squared {
    background-image: url(/static/media/cl.6d63ff70.svg); }

.flag-icon-cm {
  background-image: url(/static/media/cm.5799ad4c.svg); }
  .flag-icon-cm.flag-icon-squared {
    background-image: url(/static/media/cm.c972441e.svg); }

.flag-icon-cn {
  background-image: url(/static/media/cn.02c229de.svg); }
  .flag-icon-cn.flag-icon-squared {
    background-image: url(/static/media/cn.a94c9394.svg); }

.flag-icon-co {
  background-image: url(/static/media/co.3b252a1a.svg); }
  .flag-icon-co.flag-icon-squared {
    background-image: url(/static/media/co.41244c20.svg); }

.flag-icon-cr {
  background-image: url(/static/media/cr.7b4ebd50.svg); }
  .flag-icon-cr.flag-icon-squared {
    background-image: url(/static/media/cr.657d7dbc.svg); }

.flag-icon-cu {
  background-image: url(/static/media/cu.750c91b2.svg); }
  .flag-icon-cu.flag-icon-squared {
    background-image: url(/static/media/cu.0b42edab.svg); }

.flag-icon-cv {
  background-image: url(/static/media/cv.f9922e01.svg); }
  .flag-icon-cv.flag-icon-squared {
    background-image: url(/static/media/cv.20a8cfff.svg); }

.flag-icon-cw {
  background-image: url(/static/media/cw.f1b3043c.svg); }
  .flag-icon-cw.flag-icon-squared {
    background-image: url(/static/media/cw.69f19c22.svg); }

.flag-icon-cx {
  background-image: url(/static/media/cx.aa81bb9e.svg); }
  .flag-icon-cx.flag-icon-squared {
    background-image: url(/static/media/cx.172a41ec.svg); }

.flag-icon-cy {
  background-image: url(/static/media/cy.d069616c.svg); }
  .flag-icon-cy.flag-icon-squared {
    background-image: url(/static/media/cy.9f04989a.svg); }

.flag-icon-cz {
  background-image: url(/static/media/cz.2339f3df.svg); }
  .flag-icon-cz.flag-icon-squared {
    background-image: url(/static/media/cz.80879b0e.svg); }

.flag-icon-de {
  background-image: url(/static/media/de.3e726c2b.svg); }
  .flag-icon-de.flag-icon-squared {
    background-image: url(/static/media/de.4d7bac3b.svg); }

.flag-icon-dj {
  background-image: url(/static/media/dj.423c4156.svg); }
  .flag-icon-dj.flag-icon-squared {
    background-image: url(/static/media/dj.0c386d22.svg); }

.flag-icon-dk {
  background-image: url(/static/media/dk.eb1416e0.svg); }
  .flag-icon-dk.flag-icon-squared {
    background-image: url(/static/media/dk.d046fb5b.svg); }

.flag-icon-dm {
  background-image: url(/static/media/dm.46f58d40.svg); }
  .flag-icon-dm.flag-icon-squared {
    background-image: url(/static/media/dm.664bf042.svg); }

.flag-icon-do {
  background-image: url(/static/media/do.79f8bf8c.svg); }
  .flag-icon-do.flag-icon-squared {
    background-image: url(/static/media/do.07d2b1ed.svg); }

.flag-icon-dz {
  background-image: url(/static/media/dz.4be984a3.svg); }
  .flag-icon-dz.flag-icon-squared {
    background-image: url(/static/media/dz.b03e5aec.svg); }

.flag-icon-ec {
  background-image: url(/static/media/ec.5d6fdbf8.svg); }
  .flag-icon-ec.flag-icon-squared {
    background-image: url(/static/media/ec.5e9624df.svg); }

.flag-icon-ee {
  background-image: url(/static/media/ee.6088c9ce.svg); }
  .flag-icon-ee.flag-icon-squared {
    background-image: url(/static/media/ee.9e932a62.svg); }

.flag-icon-eg {
  background-image: url(/static/media/eg.6b83ab95.svg); }
  .flag-icon-eg.flag-icon-squared {
    background-image: url(/static/media/eg.2ea321dd.svg); }

.flag-icon-eh {
  background-image: url(/static/media/eh.3b662831.svg); }
  .flag-icon-eh.flag-icon-squared {
    background-image: url(/static/media/eh.2a0e164e.svg); }

.flag-icon-er {
  background-image: url(/static/media/er.e5e5e397.svg); }
  .flag-icon-er.flag-icon-squared {
    background-image: url(/static/media/er.bdfbf04c.svg); }

.flag-icon-es {
  background-image: url(/static/media/es.50623e6a.svg); }
  .flag-icon-es.flag-icon-squared {
    background-image: url(/static/media/es.afff2473.svg); }

.flag-icon-et {
  background-image: url(/static/media/et.2ebb0d3d.svg); }
  .flag-icon-et.flag-icon-squared {
    background-image: url(/static/media/et.1d986679.svg); }

.flag-icon-fi {
  background-image: url(/static/media/fi.2649533e.svg); }
  .flag-icon-fi.flag-icon-squared {
    background-image: url(/static/media/fi.b48413be.svg); }

.flag-icon-fj {
  background-image: url(/static/media/fj.60620e85.svg); }
  .flag-icon-fj.flag-icon-squared {
    background-image: url(/static/media/fj.76a7a39e.svg); }

.flag-icon-fk {
  background-image: url(/static/media/fk.519e3de5.svg); }
  .flag-icon-fk.flag-icon-squared {
    background-image: url(/static/media/fk.aeb2d588.svg); }

.flag-icon-fm {
  background-image: url(/static/media/fm.59c5190c.svg); }
  .flag-icon-fm.flag-icon-squared {
    background-image: url(/static/media/fm.3f19d612.svg); }

.flag-icon-fo {
  background-image: url(/static/media/fo.037e466d.svg); }
  .flag-icon-fo.flag-icon-squared {
    background-image: url(/static/media/fo.329cbed5.svg); }

.flag-icon-fr {
  background-image: url(/static/media/fr.b1156355.svg); }
  .flag-icon-fr.flag-icon-squared {
    background-image: url(/static/media/fr.f8952213.svg); }

.flag-icon-ga {
  background-image: url(/static/media/ga.29f203bb.svg); }
  .flag-icon-ga.flag-icon-squared {
    background-image: url(/static/media/ga.33d27fe1.svg); }

.flag-icon-gb {
  background-image: url(/static/media/gb.d3ddd602.svg); }
  .flag-icon-gb.flag-icon-squared {
    background-image: url(/static/media/gb.5db9fea0.svg); }

.flag-icon-gd {
  background-image: url(/static/media/gd.8e690a5a.svg); }
  .flag-icon-gd.flag-icon-squared {
    background-image: url(/static/media/gd.56fdbab2.svg); }

.flag-icon-ge {
  background-image: url(/static/media/ge.16f859b5.svg); }
  .flag-icon-ge.flag-icon-squared {
    background-image: url(/static/media/ge.d3665bf1.svg); }

.flag-icon-gf {
  background-image: url(/static/media/gf.38dfa23a.svg); }
  .flag-icon-gf.flag-icon-squared {
    background-image: url(/static/media/gf.cabf9781.svg); }

.flag-icon-gg {
  background-image: url(/static/media/gg.98f67a6f.svg); }
  .flag-icon-gg.flag-icon-squared {
    background-image: url(/static/media/gg.357e1e33.svg); }

.flag-icon-gh {
  background-image: url(/static/media/gh.caedb912.svg); }
  .flag-icon-gh.flag-icon-squared {
    background-image: url(/static/media/gh.77872d15.svg); }

.flag-icon-gi {
  background-image: url(/static/media/gi.dce455a7.svg); }
  .flag-icon-gi.flag-icon-squared {
    background-image: url(/static/media/gi.b0015a50.svg); }

.flag-icon-gl {
  background-image: url(/static/media/gl.2490aa08.svg); }
  .flag-icon-gl.flag-icon-squared {
    background-image: url(/static/media/gl.48bf3e4e.svg); }

.flag-icon-gm {
  background-image: url(/static/media/gm.50fe2799.svg); }
  .flag-icon-gm.flag-icon-squared {
    background-image: url(/static/media/gm.414139d5.svg); }

.flag-icon-gn {
  background-image: url(/static/media/gn.36a3e9a3.svg); }
  .flag-icon-gn.flag-icon-squared {
    background-image: url(/static/media/gn.1ce64523.svg); }

.flag-icon-gp {
  background-image: url(/static/media/gp.fa4cab3e.svg); }
  .flag-icon-gp.flag-icon-squared {
    background-image: url(/static/media/gp.c2c4da0e.svg); }

.flag-icon-gq {
  background-image: url(/static/media/gq.30ed019c.svg); }
  .flag-icon-gq.flag-icon-squared {
    background-image: url(/static/media/gq.80b56bda.svg); }

.flag-icon-gr {
  background-image: url(/static/media/gr.471d733a.svg); }
  .flag-icon-gr.flag-icon-squared {
    background-image: url(/static/media/gr.0bed56a8.svg); }

.flag-icon-gs {
  background-image: url(/static/media/gs.0ee2d8c9.svg); }
  .flag-icon-gs.flag-icon-squared {
    background-image: url(/static/media/gs.6adf96a8.svg); }

.flag-icon-gt {
  background-image: url(/static/media/gt.656c9899.svg); }
  .flag-icon-gt.flag-icon-squared {
    background-image: url(/static/media/gt.d6b5b664.svg); }

.flag-icon-gu {
  background-image: url(/static/media/gu.2284e60e.svg); }
  .flag-icon-gu.flag-icon-squared {
    background-image: url(/static/media/gu.64936a10.svg); }

.flag-icon-gw {
  background-image: url(/static/media/gw.c1e88a91.svg); }
  .flag-icon-gw.flag-icon-squared {
    background-image: url(/static/media/gw.5ecbd93c.svg); }

.flag-icon-gy {
  background-image: url(/static/media/gy.79fcf270.svg); }
  .flag-icon-gy.flag-icon-squared {
    background-image: url(/static/media/gy.0653b318.svg); }

.flag-icon-hk {
  background-image: url(/static/media/hk.7428ec1c.svg); }
  .flag-icon-hk.flag-icon-squared {
    background-image: url(/static/media/hk.4a0f09ba.svg); }

.flag-icon-hm {
  background-image: url(/static/media/hm.fe514431.svg); }
  .flag-icon-hm.flag-icon-squared {
    background-image: url(/static/media/hm.fc838ac0.svg); }

.flag-icon-hn {
  background-image: url(/static/media/hn.9b9bee13.svg); }
  .flag-icon-hn.flag-icon-squared {
    background-image: url(/static/media/hn.c94622ad.svg); }

.flag-icon-hr {
  background-image: url(/static/media/hr.4680d632.svg); }
  .flag-icon-hr.flag-icon-squared {
    background-image: url(/static/media/hr.88f38f33.svg); }

.flag-icon-ht {
  background-image: url(/static/media/ht.fb289ca0.svg); }
  .flag-icon-ht.flag-icon-squared {
    background-image: url(/static/media/ht.34eb5f59.svg); }

.flag-icon-hu {
  background-image: url(/static/media/hu.0d7409f8.svg); }
  .flag-icon-hu.flag-icon-squared {
    background-image: url(/static/media/hu.e5e334fd.svg); }

.flag-icon-id {
  background-image: url(/static/media/id.17b99676.svg); }
  .flag-icon-id.flag-icon-squared {
    background-image: url(/static/media/id.9f708fe5.svg); }

.flag-icon-ie {
  background-image: url(/static/media/ie.c68ff961.svg); }
  .flag-icon-ie.flag-icon-squared {
    background-image: url(/static/media/ie.798a56e0.svg); }

.flag-icon-il {
  background-image: url(/static/media/il.874270d6.svg); }
  .flag-icon-il.flag-icon-squared {
    background-image: url(/static/media/il.c36a011d.svg); }

.flag-icon-im {
  background-image: url(/static/media/im.8c10222d.svg); }
  .flag-icon-im.flag-icon-squared {
    background-image: url(/static/media/im.ac0c825e.svg); }

.flag-icon-in {
  background-image: url(/static/media/in.e4ab7bd0.svg); }
  .flag-icon-in.flag-icon-squared {
    background-image: url(/static/media/in.209ae8e9.svg); }

.flag-icon-io {
  background-image: url(/static/media/io.3ddd1280.svg); }
  .flag-icon-io.flag-icon-squared {
    background-image: url(/static/media/io.a45231d4.svg); }

.flag-icon-iq {
  background-image: url(/static/media/iq.be991997.svg); }
  .flag-icon-iq.flag-icon-squared {
    background-image: url(/static/media/iq.8d936728.svg); }

.flag-icon-ir {
  background-image: url(/static/media/ir.23e0f96c.svg); }
  .flag-icon-ir.flag-icon-squared {
    background-image: url(/static/media/ir.7bf140ab.svg); }

.flag-icon-is {
  background-image: url(/static/media/is.2ce20c50.svg); }
  .flag-icon-is.flag-icon-squared {
    background-image: url(/static/media/is.ae44c07e.svg); }

.flag-icon-it {
  background-image: url(/static/media/it.8d15de04.svg); }
  .flag-icon-it.flag-icon-squared {
    background-image: url(/static/media/it.22b99ae7.svg); }

.flag-icon-je {
  background-image: url(/static/media/je.e0932aed.svg); }
  .flag-icon-je.flag-icon-squared {
    background-image: url(/static/media/je.ab89781e.svg); }

.flag-icon-jm {
  background-image: url(/static/media/jm.67f96b2f.svg); }
  .flag-icon-jm.flag-icon-squared {
    background-image: url(/static/media/jm.b7b13124.svg); }

.flag-icon-jo {
  background-image: url(/static/media/jo.9e2f2b3a.svg); }
  .flag-icon-jo.flag-icon-squared {
    background-image: url(/static/media/jo.51302798.svg); }

.flag-icon-jp {
  background-image: url(/static/media/jp.3e72015c.svg); }
  .flag-icon-jp.flag-icon-squared {
    background-image: url(/static/media/jp.16a568ca.svg); }

.flag-icon-ke {
  background-image: url(/static/media/ke.dd8a91b8.svg); }
  .flag-icon-ke.flag-icon-squared {
    background-image: url(/static/media/ke.87900162.svg); }

.flag-icon-kg {
  background-image: url(/static/media/kg.1cfa1c79.svg); }
  .flag-icon-kg.flag-icon-squared {
    background-image: url(/static/media/kg.5908392a.svg); }

.flag-icon-kh {
  background-image: url(/static/media/kh.5a13865d.svg); }
  .flag-icon-kh.flag-icon-squared {
    background-image: url(/static/media/kh.61a4b374.svg); }

.flag-icon-ki {
  background-image: url(/static/media/ki.db7e40f6.svg); }
  .flag-icon-ki.flag-icon-squared {
    background-image: url(/static/media/ki.cdeef8df.svg); }

.flag-icon-km {
  background-image: url(/static/media/km.9b06043d.svg); }
  .flag-icon-km.flag-icon-squared {
    background-image: url(/static/media/km.eb69abb6.svg); }

.flag-icon-kn {
  background-image: url(/static/media/kn.bde74c6d.svg); }
  .flag-icon-kn.flag-icon-squared {
    background-image: url(/static/media/kn.4ad12564.svg); }

.flag-icon-kp {
  background-image: url(/static/media/kp.9c534291.svg); }
  .flag-icon-kp.flag-icon-squared {
    background-image: url(/static/media/kp.f08daf33.svg); }

.flag-icon-kr {
  background-image: url(/static/media/kr.60fde7fc.svg); }
  .flag-icon-kr.flag-icon-squared {
    background-image: url(/static/media/kr.7fb0181b.svg); }

.flag-icon-kw {
  background-image: url(/static/media/kw.496fa466.svg); }
  .flag-icon-kw.flag-icon-squared {
    background-image: url(/static/media/kw.33b3292e.svg); }

.flag-icon-ky {
  background-image: url(/static/media/ky.5814c5a9.svg); }
  .flag-icon-ky.flag-icon-squared {
    background-image: url(/static/media/ky.ef1f6537.svg); }

.flag-icon-kz {
  background-image: url(/static/media/kz.740ef4bf.svg); }
  .flag-icon-kz.flag-icon-squared {
    background-image: url(/static/media/kz.a19240f6.svg); }

.flag-icon-la {
  background-image: url(/static/media/la.0f124ae3.svg); }
  .flag-icon-la.flag-icon-squared {
    background-image: url(/static/media/la.6b86f25a.svg); }

.flag-icon-lb {
  background-image: url(/static/media/lb.e33a49a9.svg); }
  .flag-icon-lb.flag-icon-squared {
    background-image: url(/static/media/lb.56f32195.svg); }

.flag-icon-lc {
  background-image: url(/static/media/lc.1c3a5554.svg); }
  .flag-icon-lc.flag-icon-squared {
    background-image: url(/static/media/lc.c056c2a7.svg); }

.flag-icon-li {
  background-image: url(/static/media/li.fb5437d3.svg); }
  .flag-icon-li.flag-icon-squared {
    background-image: url(/static/media/li.748d1f99.svg); }

.flag-icon-lk {
  background-image: url(/static/media/lk.497ee5b9.svg); }
  .flag-icon-lk.flag-icon-squared {
    background-image: url(/static/media/lk.f54e1ef9.svg); }

.flag-icon-lr {
  background-image: url(/static/media/lr.309ccbd8.svg); }
  .flag-icon-lr.flag-icon-squared {
    background-image: url(/static/media/lr.6656f943.svg); }

.flag-icon-ls {
  background-image: url(/static/media/ls.c0799ebf.svg); }
  .flag-icon-ls.flag-icon-squared {
    background-image: url(/static/media/ls.533cb320.svg); }

.flag-icon-lt {
  background-image: url(/static/media/lt.c3aeac0d.svg); }
  .flag-icon-lt.flag-icon-squared {
    background-image: url(/static/media/lt.70975be0.svg); }

.flag-icon-lu {
  background-image: url(/static/media/lu.c858787c.svg); }
  .flag-icon-lu.flag-icon-squared {
    background-image: url(/static/media/lu.2585715a.svg); }

.flag-icon-lv {
  background-image: url(/static/media/lv.8b293d98.svg); }
  .flag-icon-lv.flag-icon-squared {
    background-image: url(/static/media/lv.f3c1274d.svg); }

.flag-icon-ly {
  background-image: url(/static/media/ly.d089645e.svg); }
  .flag-icon-ly.flag-icon-squared {
    background-image: url(/static/media/ly.050ff9b0.svg); }

.flag-icon-ma {
  background-image: url(/static/media/ma.60fbc221.svg); }
  .flag-icon-ma.flag-icon-squared {
    background-image: url(/static/media/ma.bee9c054.svg); }

.flag-icon-mc {
  background-image: url(/static/media/mc.b4f4b90d.svg); }
  .flag-icon-mc.flag-icon-squared {
    background-image: url(/static/media/mc.78528abe.svg); }

.flag-icon-md {
  background-image: url(/static/media/md.5f734d92.svg); }
  .flag-icon-md.flag-icon-squared {
    background-image: url(/static/media/md.75ec533a.svg); }

.flag-icon-me {
  background-image: url(/static/media/me.76c434a6.svg); }
  .flag-icon-me.flag-icon-squared {
    background-image: url(/static/media/me.2d0c8f78.svg); }

.flag-icon-mf {
  background-image: url(/static/media/mf.5b9ff36c.svg); }
  .flag-icon-mf.flag-icon-squared {
    background-image: url(/static/media/mf.487f7bd7.svg); }

.flag-icon-mg {
  background-image: url(/static/media/mg.67f5922d.svg); }
  .flag-icon-mg.flag-icon-squared {
    background-image: url(/static/media/mg.91e10ba0.svg); }

.flag-icon-mh {
  background-image: url(/static/media/mh.6d60cee3.svg); }
  .flag-icon-mh.flag-icon-squared {
    background-image: url(/static/media/mh.8f1f9134.svg); }

.flag-icon-mk {
  background-image: url(/static/media/mk.ed091b88.svg); }
  .flag-icon-mk.flag-icon-squared {
    background-image: url(/static/media/mk.2413b107.svg); }

.flag-icon-ml {
  background-image: url(/static/media/ml.e6f097f9.svg); }
  .flag-icon-ml.flag-icon-squared {
    background-image: url(/static/media/ml.204b0da4.svg); }

.flag-icon-mm {
  background-image: url(/static/media/mm.92e9f832.svg); }
  .flag-icon-mm.flag-icon-squared {
    background-image: url(/static/media/mm.8d6d26bc.svg); }

.flag-icon-mn {
  background-image: url(/static/media/mn.9ebe47eb.svg); }
  .flag-icon-mn.flag-icon-squared {
    background-image: url(/static/media/mn.933606d5.svg); }

.flag-icon-mo {
  background-image: url(/static/media/mo.b6d4d1f6.svg); }
  .flag-icon-mo.flag-icon-squared {
    background-image: url(/static/media/mo.67acac75.svg); }

.flag-icon-mp {
  background-image: url(/static/media/mp.c5eb7f23.svg); }
  .flag-icon-mp.flag-icon-squared {
    background-image: url(/static/media/mp.8a731cbc.svg); }

.flag-icon-mq {
  background-image: url(/static/media/mq.bfeadb02.svg); }
  .flag-icon-mq.flag-icon-squared {
    background-image: url(/static/media/mq.a09e4865.svg); }

.flag-icon-mr {
  background-image: url(/static/media/mr.a46829f1.svg); }
  .flag-icon-mr.flag-icon-squared {
    background-image: url(/static/media/mr.bf379763.svg); }

.flag-icon-ms {
  background-image: url(/static/media/ms.e147bd2b.svg); }
  .flag-icon-ms.flag-icon-squared {
    background-image: url(/static/media/ms.ad88044d.svg); }

.flag-icon-mt {
  background-image: url(/static/media/mt.a816f3a2.svg); }
  .flag-icon-mt.flag-icon-squared {
    background-image: url(/static/media/mt.f6e3733c.svg); }

.flag-icon-mu {
  background-image: url(/static/media/mu.896330b7.svg); }
  .flag-icon-mu.flag-icon-squared {
    background-image: url(/static/media/mu.67c8f362.svg); }

.flag-icon-mv {
  background-image: url(/static/media/mv.3c896bfd.svg); }
  .flag-icon-mv.flag-icon-squared {
    background-image: url(/static/media/mv.0fdc08c6.svg); }

.flag-icon-mw {
  background-image: url(/static/media/mw.6073ddcf.svg); }
  .flag-icon-mw.flag-icon-squared {
    background-image: url(/static/media/mw.baf490bf.svg); }

.flag-icon-mx {
  background-image: url(/static/media/mx.8ee3aa6a.svg); }
  .flag-icon-mx.flag-icon-squared {
    background-image: url(/static/media/mx.3aa223c8.svg); }

.flag-icon-my {
  background-image: url(/static/media/my.e6739f40.svg); }
  .flag-icon-my.flag-icon-squared {
    background-image: url(/static/media/my.263aea34.svg); }

.flag-icon-mz {
  background-image: url(/static/media/mz.ef4657da.svg); }
  .flag-icon-mz.flag-icon-squared {
    background-image: url(/static/media/mz.e99caf39.svg); }

.flag-icon-na {
  background-image: url(/static/media/na.bb49a403.svg); }
  .flag-icon-na.flag-icon-squared {
    background-image: url(/static/media/na.74257fb2.svg); }

.flag-icon-nc {
  background-image: url(/static/media/nc.b8c9f5e4.svg); }
  .flag-icon-nc.flag-icon-squared {
    background-image: url(/static/media/nc.d393b8fa.svg); }

.flag-icon-ne {
  background-image: url(/static/media/ne.b7369ec7.svg); }
  .flag-icon-ne.flag-icon-squared {
    background-image: url(/static/media/ne.e56edd30.svg); }

.flag-icon-nf {
  background-image: url(/static/media/nf.99af5a94.svg); }
  .flag-icon-nf.flag-icon-squared {
    background-image: url(/static/media/nf.801ee09f.svg); }

.flag-icon-ng {
  background-image: url(/static/media/ng.992459a3.svg); }
  .flag-icon-ng.flag-icon-squared {
    background-image: url(/static/media/ng.520463e1.svg); }

.flag-icon-ni {
  background-image: url(/static/media/ni.7b131ab3.svg); }
  .flag-icon-ni.flag-icon-squared {
    background-image: url(/static/media/ni.baafd7d7.svg); }

.flag-icon-nl {
  background-image: url(/static/media/nl.d4811c27.svg); }
  .flag-icon-nl.flag-icon-squared {
    background-image: url(/static/media/nl.390aa40f.svg); }

.flag-icon-no {
  background-image: url(/static/media/no.0b41df77.svg); }
  .flag-icon-no.flag-icon-squared {
    background-image: url(/static/media/no.b7a21f54.svg); }

.flag-icon-np {
  background-image: url(/static/media/np.b66578a5.svg); }
  .flag-icon-np.flag-icon-squared {
    background-image: url(/static/media/np.27f0f4e7.svg); }

.flag-icon-nr {
  background-image: url(/static/media/nr.d16edc69.svg); }
  .flag-icon-nr.flag-icon-squared {
    background-image: url(/static/media/nr.2ef5b7c8.svg); }

.flag-icon-nu {
  background-image: url(/static/media/nu.433deb3d.svg); }
  .flag-icon-nu.flag-icon-squared {
    background-image: url(/static/media/nu.1db5a99d.svg); }

.flag-icon-nz {
  background-image: url(/static/media/nz.3241e927.svg); }
  .flag-icon-nz.flag-icon-squared {
    background-image: url(/static/media/nz.7dab6e5e.svg); }

.flag-icon-om {
  background-image: url(/static/media/om.1798270b.svg); }
  .flag-icon-om.flag-icon-squared {
    background-image: url(/static/media/om.b9b7d0bc.svg); }

.flag-icon-pa {
  background-image: url(/static/media/pa.d0787677.svg); }
  .flag-icon-pa.flag-icon-squared {
    background-image: url(/static/media/pa.beb40ab6.svg); }

.flag-icon-pe {
  background-image: url(/static/media/pe.ea95116f.svg); }
  .flag-icon-pe.flag-icon-squared {
    background-image: url(/static/media/pe.23591f9d.svg); }

.flag-icon-pf {
  background-image: url(/static/media/pf.bab3b7a5.svg); }
  .flag-icon-pf.flag-icon-squared {
    background-image: url(/static/media/pf.2a69c581.svg); }

.flag-icon-pg {
  background-image: url(/static/media/pg.68e1ce33.svg); }
  .flag-icon-pg.flag-icon-squared {
    background-image: url(/static/media/pg.0b07d418.svg); }

.flag-icon-ph {
  background-image: url(/static/media/ph.12f36eed.svg); }
  .flag-icon-ph.flag-icon-squared {
    background-image: url(/static/media/ph.6ae85442.svg); }

.flag-icon-pk {
  background-image: url(/static/media/pk.c2e1a159.svg); }
  .flag-icon-pk.flag-icon-squared {
    background-image: url(/static/media/pk.b67f80e0.svg); }

.flag-icon-pl {
  background-image: url(/static/media/pl.562edca5.svg); }
  .flag-icon-pl.flag-icon-squared {
    background-image: url(/static/media/pl.3fe3bd51.svg); }

.flag-icon-pm {
  background-image: url(/static/media/pm.1e97e8d7.svg); }
  .flag-icon-pm.flag-icon-squared {
    background-image: url(/static/media/pm.89993b1f.svg); }

.flag-icon-pn {
  background-image: url(/static/media/pn.c4a2e49f.svg); }
  .flag-icon-pn.flag-icon-squared {
    background-image: url(/static/media/pn.48bd62e4.svg); }

.flag-icon-pr {
  background-image: url(/static/media/pr.0811a051.svg); }
  .flag-icon-pr.flag-icon-squared {
    background-image: url(/static/media/pr.1d278b02.svg); }

.flag-icon-ps {
  background-image: url(/static/media/ps.42f2391e.svg); }
  .flag-icon-ps.flag-icon-squared {
    background-image: url(/static/media/ps.2992f9b9.svg); }

.flag-icon-pt {
  background-image: url(/static/media/pt.b908edae.svg); }
  .flag-icon-pt.flag-icon-squared {
    background-image: url(/static/media/pt.04fa443d.svg); }

.flag-icon-pw {
  background-image: url(/static/media/pw.20a1d020.svg); }
  .flag-icon-pw.flag-icon-squared {
    background-image: url(/static/media/pw.78aaead2.svg); }

.flag-icon-py {
  background-image: url(/static/media/py.bbc22e41.svg); }
  .flag-icon-py.flag-icon-squared {
    background-image: url(/static/media/py.a70b32d0.svg); }

.flag-icon-qa {
  background-image: url(/static/media/qa.b314986b.svg); }
  .flag-icon-qa.flag-icon-squared {
    background-image: url(/static/media/qa.78909a6f.svg); }

.flag-icon-re {
  background-image: url(/static/media/re.17909e37.svg); }
  .flag-icon-re.flag-icon-squared {
    background-image: url(/static/media/re.01fea3b6.svg); }

.flag-icon-ro {
  background-image: url(/static/media/ro.625aca9e.svg); }
  .flag-icon-ro.flag-icon-squared {
    background-image: url(/static/media/ro.22278e13.svg); }

.flag-icon-rs {
  background-image: url(/static/media/rs.291d0fb6.svg); }
  .flag-icon-rs.flag-icon-squared {
    background-image: url(/static/media/rs.d00d37d2.svg); }

.flag-icon-ru {
  background-image: url(/static/media/ru.0cacf46e.svg); }
  .flag-icon-ru.flag-icon-squared {
    background-image: url(/static/media/ru.e3ee3b09.svg); }

.flag-icon-rw {
  background-image: url(/static/media/rw.7fe5146b.svg); }
  .flag-icon-rw.flag-icon-squared {
    background-image: url(/static/media/rw.997fe41b.svg); }

.flag-icon-sa {
  background-image: url(/static/media/sa.c36d1991.svg); }
  .flag-icon-sa.flag-icon-squared {
    background-image: url(/static/media/sa.135d0c86.svg); }

.flag-icon-sb {
  background-image: url(/static/media/sb.aa819297.svg); }
  .flag-icon-sb.flag-icon-squared {
    background-image: url(/static/media/sb.d64e9848.svg); }

.flag-icon-sc {
  background-image: url(/static/media/sc.e6584421.svg); }
  .flag-icon-sc.flag-icon-squared {
    background-image: url(/static/media/sc.ad1bcb4c.svg); }

.flag-icon-sd {
  background-image: url(/static/media/sd.c466d90e.svg); }
  .flag-icon-sd.flag-icon-squared {
    background-image: url(/static/media/sd.7ab061d8.svg); }

.flag-icon-se {
  background-image: url(/static/media/se.fd663a70.svg); }
  .flag-icon-se.flag-icon-squared {
    background-image: url(/static/media/se.92c66d83.svg); }

.flag-icon-sg {
  background-image: url(/static/media/sg.5e6ed3f1.svg); }
  .flag-icon-sg.flag-icon-squared {
    background-image: url(/static/media/sg.9eb47fe7.svg); }

.flag-icon-sh {
  background-image: url(/static/media/sh.6560d76b.svg); }
  .flag-icon-sh.flag-icon-squared {
    background-image: url(/static/media/sh.487ef1c8.svg); }

.flag-icon-si {
  background-image: url(/static/media/si.31fbdc5b.svg); }
  .flag-icon-si.flag-icon-squared {
    background-image: url(/static/media/si.63ba8c45.svg); }

.flag-icon-sj {
  background-image: url(/static/media/sj.ae547dbe.svg); }
  .flag-icon-sj.flag-icon-squared {
    background-image: url(/static/media/sj.ecbc9e93.svg); }

.flag-icon-sk {
  background-image: url(/static/media/sk.b84444bf.svg); }
  .flag-icon-sk.flag-icon-squared {
    background-image: url(/static/media/sk.a5af0a28.svg); }

.flag-icon-sl {
  background-image: url(/static/media/sl.ddbd1d9b.svg); }
  .flag-icon-sl.flag-icon-squared {
    background-image: url(/static/media/sl.f6315f74.svg); }

.flag-icon-sm {
  background-image: url(/static/media/sm.3b1c9fb5.svg); }
  .flag-icon-sm.flag-icon-squared {
    background-image: url(/static/media/sm.f5665000.svg); }

.flag-icon-sn {
  background-image: url(/static/media/sn.5b654e1a.svg); }
  .flag-icon-sn.flag-icon-squared {
    background-image: url(/static/media/sn.d2bec7ef.svg); }

.flag-icon-so {
  background-image: url(/static/media/so.f91fb92c.svg); }
  .flag-icon-so.flag-icon-squared {
    background-image: url(/static/media/so.c1561217.svg); }

.flag-icon-sr {
  background-image: url(/static/media/sr.788f3e2a.svg); }
  .flag-icon-sr.flag-icon-squared {
    background-image: url(/static/media/sr.be27d1ae.svg); }

.flag-icon-ss {
  background-image: url(/static/media/ss.67001d2a.svg); }
  .flag-icon-ss.flag-icon-squared {
    background-image: url(/static/media/ss.e3933b44.svg); }

.flag-icon-st {
  background-image: url(/static/media/st.1f545eb9.svg); }
  .flag-icon-st.flag-icon-squared {
    background-image: url(/static/media/st.d0a56dbb.svg); }

.flag-icon-sv {
  background-image: url(/static/media/sv.1176ea28.svg); }
  .flag-icon-sv.flag-icon-squared {
    background-image: url(/static/media/sv.26ee8872.svg); }

.flag-icon-sx {
  background-image: url(/static/media/sx.522d898c.svg); }
  .flag-icon-sx.flag-icon-squared {
    background-image: url(/static/media/sx.a7248001.svg); }

.flag-icon-sy {
  background-image: url(/static/media/sy.64f0d2d7.svg); }
  .flag-icon-sy.flag-icon-squared {
    background-image: url(/static/media/sy.73690f50.svg); }

.flag-icon-sz {
  background-image: url(/static/media/sz.cfb8269f.svg); }
  .flag-icon-sz.flag-icon-squared {
    background-image: url(/static/media/sz.dc2faeb7.svg); }

.flag-icon-tc {
  background-image: url(/static/media/tc.d40761f2.svg); }
  .flag-icon-tc.flag-icon-squared {
    background-image: url(/static/media/tc.47c82761.svg); }

.flag-icon-td {
  background-image: url(/static/media/td.a0923ddc.svg); }
  .flag-icon-td.flag-icon-squared {
    background-image: url(/static/media/td.f37a395c.svg); }

.flag-icon-tf {
  background-image: url(/static/media/tf.4ab43cc9.svg); }
  .flag-icon-tf.flag-icon-squared {
    background-image: url(/static/media/tf.2e7dc1af.svg); }

.flag-icon-tg {
  background-image: url(/static/media/tg.025deae8.svg); }
  .flag-icon-tg.flag-icon-squared {
    background-image: url(/static/media/tg.29fa137c.svg); }

.flag-icon-th {
  background-image: url(/static/media/th.76fca72f.svg); }
  .flag-icon-th.flag-icon-squared {
    background-image: url(/static/media/th.904dd785.svg); }

.flag-icon-tj {
  background-image: url(/static/media/tj.a8ed5244.svg); }
  .flag-icon-tj.flag-icon-squared {
    background-image: url(/static/media/tj.980d12c9.svg); }

.flag-icon-tk {
  background-image: url(/static/media/tk.1959d9de.svg); }
  .flag-icon-tk.flag-icon-squared {
    background-image: url(/static/media/tk.7aaccddb.svg); }

.flag-icon-tl {
  background-image: url(/static/media/tl.3c1ccf11.svg); }
  .flag-icon-tl.flag-icon-squared {
    background-image: url(/static/media/tl.0616faaa.svg); }

.flag-icon-tm {
  background-image: url(/static/media/tm.b13d1440.svg); }
  .flag-icon-tm.flag-icon-squared {
    background-image: url(/static/media/tm.ea365f33.svg); }

.flag-icon-tn {
  background-image: url(/static/media/tn.50cd9101.svg); }
  .flag-icon-tn.flag-icon-squared {
    background-image: url(/static/media/tn.fea87146.svg); }

.flag-icon-to {
  background-image: url(/static/media/to.79354e72.svg); }
  .flag-icon-to.flag-icon-squared {
    background-image: url(/static/media/to.238ef1cd.svg); }

.flag-icon-tr {
  background-image: url(/static/media/tr.ed6d5f37.svg); }
  .flag-icon-tr.flag-icon-squared {
    background-image: url(/static/media/tr.ce2e2e8e.svg); }

.flag-icon-tt {
  background-image: url(/static/media/tt.c3647d9b.svg); }
  .flag-icon-tt.flag-icon-squared {
    background-image: url(/static/media/tt.4705d420.svg); }

.flag-icon-tv {
  background-image: url(/static/media/tv.829fb9d8.svg); }
  .flag-icon-tv.flag-icon-squared {
    background-image: url(/static/media/tv.a595f49d.svg); }

.flag-icon-tw {
  background-image: url(/static/media/tw.8a194685.svg); }
  .flag-icon-tw.flag-icon-squared {
    background-image: url(/static/media/tw.26cc9d59.svg); }

.flag-icon-tz {
  background-image: url(/static/media/tz.88c89454.svg); }
  .flag-icon-tz.flag-icon-squared {
    background-image: url(/static/media/tz.d02545a1.svg); }

.flag-icon-ua {
  background-image: url(/static/media/ua.a8b13525.svg); }
  .flag-icon-ua.flag-icon-squared {
    background-image: url(/static/media/ua.841d259d.svg); }

.flag-icon-ug {
  background-image: url(/static/media/ug.be11ef39.svg); }
  .flag-icon-ug.flag-icon-squared {
    background-image: url(/static/media/ug.6d6f8896.svg); }

.flag-icon-um {
  background-image: url(/static/media/um.8754eddf.svg); }
  .flag-icon-um.flag-icon-squared {
    background-image: url(/static/media/um.3d347682.svg); }

.flag-icon-us {
  background-image: url(/static/media/us.ae656592.svg); }
  .flag-icon-us.flag-icon-squared {
    background-image: url(/static/media/us.8ec58318.svg); }

.flag-icon-uy {
  background-image: url(/static/media/uy.79b02850.svg); }
  .flag-icon-uy.flag-icon-squared {
    background-image: url(/static/media/uy.adbc4992.svg); }

.flag-icon-uz {
  background-image: url(/static/media/uz.eb1e00b8.svg); }
  .flag-icon-uz.flag-icon-squared {
    background-image: url(/static/media/uz.ca892343.svg); }

.flag-icon-va {
  background-image: url(/static/media/va.21913d78.svg); }
  .flag-icon-va.flag-icon-squared {
    background-image: url(/static/media/va.90e9f73a.svg); }

.flag-icon-vc {
  background-image: url(/static/media/vc.4ac5124f.svg); }
  .flag-icon-vc.flag-icon-squared {
    background-image: url(/static/media/vc.bbb52fa0.svg); }

.flag-icon-ve {
  background-image: url(/static/media/ve.b2cd5a9a.svg); }
  .flag-icon-ve.flag-icon-squared {
    background-image: url(/static/media/ve.9f23d962.svg); }

.flag-icon-vg {
  background-image: url(/static/media/vg.b37358a1.svg); }
  .flag-icon-vg.flag-icon-squared {
    background-image: url(/static/media/vg.a796b16d.svg); }

.flag-icon-vi {
  background-image: url(/static/media/vi.4952d5bf.svg); }
  .flag-icon-vi.flag-icon-squared {
    background-image: url(/static/media/vi.0aa78210.svg); }

.flag-icon-vn {
  background-image: url(/static/media/vn.6b3aef51.svg); }
  .flag-icon-vn.flag-icon-squared {
    background-image: url(/static/media/vn.a0081482.svg); }

.flag-icon-vu {
  background-image: url(/static/media/vu.859836e7.svg); }
  .flag-icon-vu.flag-icon-squared {
    background-image: url(/static/media/vu.730801ab.svg); }

.flag-icon-wf {
  background-image: url(/static/media/wf.e3ac728c.svg); }
  .flag-icon-wf.flag-icon-squared {
    background-image: url(/static/media/wf.05522b9f.svg); }

.flag-icon-ws {
  background-image: url(/static/media/ws.405a2c5f.svg); }
  .flag-icon-ws.flag-icon-squared {
    background-image: url(/static/media/ws.3ea6d44f.svg); }

.flag-icon-ye {
  background-image: url(/static/media/ye.b5840a84.svg); }
  .flag-icon-ye.flag-icon-squared {
    background-image: url(/static/media/ye.d13e1629.svg); }

.flag-icon-yt {
  background-image: url(/static/media/yt.f06d254d.svg); }
  .flag-icon-yt.flag-icon-squared {
    background-image: url(/static/media/yt.b6042b9c.svg); }

.flag-icon-za {
  background-image: url(/static/media/za.14e70522.svg); }
  .flag-icon-za.flag-icon-squared {
    background-image: url(/static/media/za.67ff2e10.svg); }

.flag-icon-zm {
  background-image: url(/static/media/zm.a9ff495d.svg); }
  .flag-icon-zm.flag-icon-squared {
    background-image: url(/static/media/zm.3eef5dc0.svg); }

.flag-icon-zw {
  background-image: url(/static/media/zw.6ac3949a.svg); }
  .flag-icon-zw.flag-icon-squared {
    background-image: url(/static/media/zw.8b885465.svg); }

.flag-icon-es-ca {
  background-image: url(/static/media/es-ca.e9062265.svg); }
  .flag-icon-es-ca.flag-icon-squared {
    background-image: url(/static/media/es-ca.a35e6a4a.svg); }

.flag-icon-es-ga {
  background-image: url(/static/media/es-ga.2618e21f.svg); }
  .flag-icon-es-ga.flag-icon-squared {
    background-image: url(/static/media/es-ga.151714df.svg); }

.flag-icon-eu {
  background-image: url(/static/media/eu.ee7f4712.svg); }
  .flag-icon-eu.flag-icon-squared {
    background-image: url(/static/media/eu.4c73f57c.svg); }

.flag-icon-gb-eng {
  background-image: url(/static/media/gb-eng.14167f77.svg); }
  .flag-icon-gb-eng.flag-icon-squared {
    background-image: url(/static/media/gb-eng.eabfeadc.svg); }

.flag-icon-gb-nir {
  background-image: url(/static/media/gb-nir.43b61fea.svg); }
  .flag-icon-gb-nir.flag-icon-squared {
    background-image: url(/static/media/gb-nir.9cad35c4.svg); }

.flag-icon-gb-sct {
  background-image: url(/static/media/gb-sct.4c2c379f.svg); }
  .flag-icon-gb-sct.flag-icon-squared {
    background-image: url(/static/media/gb-sct.31ef8bcf.svg); }

.flag-icon-gb-wls {
  background-image: url(/static/media/gb-wls.2d554424.svg); }
  .flag-icon-gb-wls.flag-icon-squared {
    background-image: url(/static/media/gb-wls.85f8b842.svg); }

.flag-icon-un {
  background-image: url(/static/media/un.bdaf37f9.svg); }
  .flag-icon-un.flag-icon-squared {
    background-image: url(/static/media/un.e6aabbd5.svg); }

.flag-icon-xk {
  background-image: url(/static/media/xk.62bc9bcf.svg); }
  .flag-icon-xk.flag-icon-squared {
    background-image: url(/static/media/xk.bd62029e.svg); }

/* === Template mixins === */
/* Animation Mixins */
@-webkit-keyframes dropdownAnimation {
  from {
    opacity: 0;
    transition: all .3s; }
  to {
    opacity: 1;
    transition: all .3s; } }
@keyframes dropdownAnimation {
  from {
    opacity: 0;
    transition: all .3s; }
  to {
    opacity: 1;
    transition: all .3s; } }

.dropdownAnimation, .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-menu {
  -webkit-animation-name: dropdownAnimation;
          animation-name: dropdownAnimation;
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut; }

.infinite-spin, #settings-trigger i {
  -webkit-animation-name: spin;
          animation-name: spin;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fadeInUp, #settings-trigger {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp; }

/* Miscellaneous Mixins */
.settings-panel .chat-list .list .info p {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap; }

/* === Core Styles === */
.bg-primary, .settings-panel .color-tiles .tiles.primary, #theme-settings .settings-close {
  background: #0062ff; }

.bg-secondary {
  background: #8e94a9; }

.bg-success, .settings-panel .color-tiles .tiles.success {
  background: #44ce42; }

.bg-info, .settings-panel .color-tiles .tiles.info {
  background: #a461d8; }

.bg-warning, .settings-panel .color-tiles .tiles.warning {
  background: #ffc542; }

.bg-danger, .settings-panel .color-tiles .tiles.danger {
  background: #fc5a5a; }

.bg-light, .settings-panel .color-tiles .tiles.light {
  background: #aab2bd; }

.bg-dark, .settings-panel .color-tiles .tiles.dark {
  background: #001737; }

/* Typography */
body {
  font-size: 1rem;
  font-family: "Nunito", sans-serif;
  font-weight: initial;
  line-height: normal;
  -webkit-font-smoothing: antialiased; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Nunito", sans-serif;
  font-weight: 600; }

p {
  font-size: 0.875rem; }

h1, .h1 {
  font-size: 1.875rem; }

h2, .h2 {
  font-size: 1.5rem; }

h3, .h3 {
  font-size: 1.25rem; }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 0.875rem; }

p {
  font-size: 0.875rem;
  line-height: 1.5; }

.display-1 {
  font-size: 3.75rem; }
  @media (max-width: 991px) {
    .display-1 {
      font-size: 3rem; } }

.display-2 {
  font-size: 3.125rem; }
  @media (max-width: 991px) {
    .display-2 {
      font-size: 2.5rem; } }

.display-3 {
  font-size: 2.5rem; }
  @media (max-width: 991px) {
    .display-3 {
      font-size: 2rem; } }

.display-4 {
  font-size: 1.875rem; }
  @media (max-width: 991px) {
    .display-4 {
      font-size: 1.5rem; } }

.display-5 {
  font-size: 1.25rem; }
  @media (max-width: 991px) {
    .display-5 {
      font-size: 1rem; } }

.blockquote {
  padding: 1.25rem;
  border: 1px solid rgba(151, 151, 151, 0.3); }

address p {
  margin-bottom: 0; }

.blockquote-primary {
  border-color: #0062ff; }
  .blockquote-primary .blockquote-footer {
    color: #0062ff; }

.blockquote-secondary {
  border-color: #8e94a9; }
  .blockquote-secondary .blockquote-footer {
    color: #8e94a9; }

.blockquote-success {
  border-color: #44ce42; }
  .blockquote-success .blockquote-footer {
    color: #44ce42; }

.blockquote-info {
  border-color: #a461d8; }
  .blockquote-info .blockquote-footer {
    color: #a461d8; }

.blockquote-warning {
  border-color: #ffc542; }
  .blockquote-warning .blockquote-footer {
    color: #ffc542; }

.blockquote-danger {
  border-color: #fc5a5a; }
  .blockquote-danger .blockquote-footer {
    color: #fc5a5a; }

.blockquote-light {
  border-color: #aab2bd; }
  .blockquote-light .blockquote-footer {
    color: #aab2bd; }

.blockquote-dark {
  border-color: #001737; }
  .blockquote-dark .blockquote-footer {
    color: #001737; }

.error-page h1 {
  font-size: 12rem; }
  @media (max-width: 991px) {
    .error-page h1 {
      font-size: 8rem; } }

.icon-lg {
  font-size: 3.438rem; }

.icon-md {
  font-size: 1.875rem; }

.icon-sm {
  font-size: 1rem; }

/* Reset Styles */
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden; }

.form-control,
.form-control:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.form-control {
  box-shadow: none; }

.form-control:focus {
  outline: 0;
  box-shadow: none; }

a,
div,
h1,
h2,
h3,
h4,
h5,
p,
span {
  text-shadow: none; }

[type=button]:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
input[type=file] > input[type=button]::-moz-focus-inner,
select::-moz-focus-inner {
  outline: 0; }

input,
.form-control:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  outline-width: 0;
  outline-color: transparent;
  box-shadow: none;
  outline-style: none; }

textarea {
  resize: none;
  overflow-x: hidden; }

.btn,
.btn-group.open .dropdown-toggle,
.btn:active,
.btn:focus,
.btn:hover,
.btn:visited,
a,
a:active,
a:checked,
a:focus,
a:hover,
a:visited,
body,
button,
button:active,
button:hover,
button:visited,
div,
input,
input:active,
input:focus,
input:hover,
input:visited,
select,
select:active,
select:focus,
select:visited,
textarea,
textarea:active,
textarea:focus,
textarea:hover,
textarea:visited {
  box-shadow: none; }

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button,
button:active,
button:checked,
button:focus,
button:hover,
button:visited {
  outline: 0;
  outline-offset: 0; }

.bootstrap-select .dropdown-toggle:focus {
  outline: 0;
  outline-offset: 0; }

.dropdown-menu > li > a:active,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:visited {
  outline: 0; }

a:focus,
input:focus {
  border-color: transparent;
  outline: none; }

@media (max-width: 991px) {
  .navbar .navbar-brand-wrapper .brand-logo {
    display: none; }
  .navbar .navbar-brand-wrapper .brand-logo-mini {
    display: inline-block; } }

@media screen and (max-width: 991px) {
  .row-offcanvas {
    position: relative;
    transition: all .25s ease-out; }
    .row-offcanvas.row-offcanvas-right {
      right: 0; }
      .row-offcanvas.row-offcanvas-right ::ng-deep .sidebar-offcanvas {
        right: -100%; }
      .row-offcanvas.row-offcanvas-right.active {
        right: calc(50% + 30px); }
        .row-offcanvas.row-offcanvas-right.active ::ng-deep .sidebar-offcanvas {
          right: calc(-50% - 15px); }
    .row-offcanvas.row-offcanvas-left {
      left: 0; }
      .row-offcanvas.row-offcanvas-left ::ng-deep .sidebar-offcanvas {
        left: -100%; }
      .row-offcanvas.row-offcanvas-left.active {
        left: 50%; }
        .row-offcanvas.row-offcanvas-left.active ::ng-deep .sidebar-offcanvas {
          left: -50%; }
    .row-offcanvas ::ng-deep .sidebar-offcanvas {
      position: absolute;
      top: 0;
      width: calc( 50% + 30px); } }

/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0; }

body {
  background: #f0f1f6; }

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto; }

.page-body-wrapper {
  min-height: calc(100vh - 64px);
  padding-top: 64px;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0; }
  .page-body-wrapper.full-page-wrapper {
    min-height: 100vh;
    padding-top: 0; }
    .page-body-wrapper.full-page-wrapper .main-panel {
      width: 100%;
      transition: none; }
      .page-body-wrapper.full-page-wrapper .main-panel .content-wrapper {
        padding: 0; }
        .page-body-wrapper.full-page-wrapper .main-panel .content-wrapper > div {
          height: 100%; }

.main-panel {
  transition: width 0.25s ease, margin 0.25s ease;
  width: calc(100% - 258px);
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column; }
  @media (max-width: 991px) {
    .main-panel {
      margin-left: 0;
      width: 100%; } }
  .main-panel.main-panel-only {
    transition: none; }

.content-wrapper {
  background: #f0f1f6;
  padding: 2.875rem 2.875rem 0 2.875rem;
  width: 100%;
  flex-grow: 1; }
  @media (max-width: 991px) {
    .content-wrapper {
      padding: 2rem 2rem 0 2rem; } }

.container-scroller {
  overflow: hidden; }

pre {
  background: #e8eff4;
  padding: 15px;
  font-size: 14px; }

code {
  padding: 5px;
  color: #fc5a5a;
  font-family: "Nunito", sans-serif;
  font-weight: 300;
  font-size: 0.875rem;
  border-radius: 4px; }

.page-header {
  margin: 0 0 1.5rem 0; }
  .page-header .breadcrumb {
    border: 0;
    margin-bottom: 0; }

.page-title {
  color: #000;
  font-size: 1.125rem;
  margin-bottom: 0; }
  .page-title .page-title-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35); }
    .page-title .page-title-icon i {
      font-size: .9375rem;
      line-height: 36px; }

.dropdown-toggle.hide-carret:after {
  content: '';
  display: none; }

.bg-transparent {
  background: transparent; }

/* Utilities */
.grid-margin, .proBanner .purchase-popup {
  margin-bottom: 30px; }

@media (min-width: 576px) {
  .grid-margin-sm-0 {
    margin-bottom: 0; } }

@media (min-width: 768px) {
  .grid-margin-md-0 {
    margin-bottom: 0; } }

@media (min-width: 992px) {
  .grid-margin-lg-0 {
    margin-bottom: 0; } }

@media (min-width: 1200px) {
  .grid-margin-xl-0 {
    margin-bottom: 0; } }

.img-lg {
  width: 92px;
  height: 92px; }

.img-sm {
  width: 43px;
  height: 43px; }

.img-xs {
  width: 37px;
  height: 37px; }

.img-ss, .image-grouped .text-avatar,
.image-grouped img, .settings-panel .color-tiles .tiles {
  width: 26px;
  height: 26px; }

.stretch-card {
  display: flex;
  align-items: stretch;
  justify-content: stretch; }
  .stretch-card > .card {
    width: 100%;
    min-width: 100%; }

@media (min-width: 576px) {
  .border-right-sm {
    border-right: 1px solid rgba(151, 151, 151, 0.3); } }

@media (min-width: 768px) {
  .border-right-md {
    border-right: 1px solid rgba(151, 151, 151, 0.3); } }

@media (min-width: 992px) {
  .border-right-lg {
    border-right: 1px solid rgba(151, 151, 151, 0.3); } }

@media (min-width: 576px) {
  .border-left-sm {
    border-left: 1px solid rgba(151, 151, 151, 0.3); } }

@media (min-width: 768px) {
  .border-left-md {
    border-left: 1px solid rgba(151, 151, 151, 0.3); } }

@media (min-width: 992px) {
  .border-left-lg {
    border-left: 1px solid rgba(151, 151, 151, 0.3); } }

.text-gray, .card .card-subtitle {
  color: #8c8c8c; }

.text-black {
  color: #000; }

.text-small {
  font-size: 12px; }

.flex-grow {
  flex-grow: 1; }

.font-weight-light {
  font-family: "Nunito", sans-serif;
  font-weight: 300; }

.font-weight-bold {
  font-family: "Nunito", sans-serif;
  font-weight: 700; }
  .font-weight-bold.center {
    text-align: center; }

.font-weight-normal {
  font-family: "Nunito", sans-serif;
  font-weight: 400; }

.cursor-pointer {
  cursor: pointer; }

.image-grouped {
  display: flex; }
  .image-grouped .text-avatar,
  .image-grouped img {
    border-radius: 100%;
    margin-left: -10px;
    z-index: 0;
    border: 4px solid #fff;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-property: "box-shadow", "z-index", "transform", "border-width", "transform";
    transition-property: "box-shadow", "z-index", "transform", "border-width"; }
    .image-grouped .text-avatar:first-child,
    .image-grouped img:first-child {
      margin-left: 0; }
    .image-grouped .text-avatar:hover,
    .image-grouped img:hover {
      z-index: 1;
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
      border-width: 0; }
  .image-grouped .text-avatar {
    color: #0062ff;
    font-size: 11px;
    font-weight: 600; }

.dot-indicator {
  width: 10px;
  height: 10px;
  border-radius: 100%; }

.img-avatar {
  display: inline-block !important;
  width: 64px;
  height: 64px;
  border-radius: 50%; }
  .img-avatar.img-avatar48 {
    width: 48px;
    height: 48px; }
  .img-avatar.img-avatar-thumb {
    margin: .25rem;
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.3); }

.absolute-center {
  position: absolute;
  top: 51%;
  right: 0;
  left: 0;
  margin: auto;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  display: block; }

/* Demo Styles */
.template-demo .slider-wrap {
  height: 100px; }

.template-demo .progress {
  margin-top: 1.5rem; }

.template-demo > h2,
.template-demo > h3,
.template-demo > h4,
.template-demo > h5,
.template-demo > h6,
.template-demo > h1 {
  border-top: 1px solid rgba(151, 151, 151, 0.3);
  padding: 0.5rem 0 0; }

.template-demo .noUi-target.noUi-horizontal {
  margin-top: 2rem; }

.template-demo .noUi-target.noUi-vertical {
  margin-right: 2rem; }

.template-demo > .dropdown {
  display: inline-block;
  margin-bottom: 0.5rem; }

.template-demo nav .breadcrumb {
  margin-bottom: 1.375rem; }

.template-demo nav:last-child .breadcrumb {
  margin-bottom: 0; }

.template-demo .editable-form > .form-group {
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
  padding-bottom: 0.8rem;
  margin-bottom: 0.8rem; }

.template-demo .circle-progress {
  padding: 15px; }

.demo-modal {
  position: static;
  display: block; }
  .demo-modal .modal-dialog.modal-lg {
    max-width: 100%; }

.loader-demo-box {
  width: 100%;
  height: 200px; }

.dropdown-menu-static-demo {
  height: 250px;
  margin-bottom: 20px; }

.main-spinner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #eee; }
  .main-spinner-wrapper .donut {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.3rem solid rgba(151, 159, 208, 0.3);
    border-top-color: #979fd0;
    -webkit-animation: 1.5s spin infinite linear;
            animation: 1.5s spin infinite linear; }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/* Dashboard */
.transaparent-tab-border {
  border-bottom: 1px solid #ebedf2; }

.graph-custom-legend ul {
  display: flex;
  align-items: center;
  float: right; }
  .graph-custom-legend ul li {
    display: flex;
    align-items: center;
    list-style: none;
    margin-left: 1rem; }
    .graph-custom-legend ul li .legend-box {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      margin-right: 10px;
      display: inline-block; }
    .graph-custom-legend ul li:first-child {
      margin-left: 0px; }
  @media (max-width: 991px) {
    .graph-custom-legend ul {
      float: left;
      padding-left: 0; } }

.graph-custom-legend.primary-dot ul li .legend-box {
  background: #0062ff; }

.card-danger-gradient {
  background: linear-gradient(to bottom, #d41459, #911a6c); }

.recent-activity .activity-info {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  text-align: center;
  color: #ffffff;
  padding-top: 5px;
  font-weight: bold;
  margin-right: 1.25rem;
  position: relative; }
  .recent-activity .activity-info:after {
    content: '';
    width: 2px;
    height: 54px;
    position: absolute;
    left: 50%;
    top: 33px;
    border-right: 1px dashed rgba(151, 151, 151, 0.3); }
  .recent-activity .activity-info.hide-border:after {
    display: none; }

.rtl .graph-custom-legend ul {
  float: left;
  padding-left: 0; }
  .rtl .graph-custom-legend ul li {
    margin-left: 0;
    margin-right: 1rem; }
    .rtl .graph-custom-legend ul li .legend-box {
      margin-right: 0;
      margin-left: .625rem; }

.rtl .activity-info {
  margin-left: 1.25rem;
  margin-right: 0; }

.dotted-border {
  border: 1px dashed rgba(151, 151, 151, 0.3); }

.legend-horizontal {
  display: flex;
  padding: 0; }
  .legend-horizontal li {
    list-style: none;
    font-size: .875rem;
    color: #001737; }
    .legend-horizontal li .legend-dots {
      width: .75rem;
      height: .75rem;
      border-radius: 100%;
      display: inline-block;
      margin-right: .5rem;
      margin-left: .5rem; }

.CircularProgressbar .CircularProgressbar-path {
  stroke-width: 5px; }

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #eee;
  stroke-width: 5px; }

.CircularProgressbar .CircularProgressbar-background {
  fill: green; }

.CircularProgressbar.progress-order .CircularProgressbar-path {
  stroke: url(#progress-order); }

.CircularProgressbar.progress-visitors .CircularProgressbar-path {
  stroke: url(#progress-visitors); }

.CircularProgressbar.progress-impressions .CircularProgressbar-path {
  stroke: url(#progress-impressions); }

.CircularProgressbar.progress-followers .CircularProgressbar-path {
  stroke: url(#progress-followers); }

.conversion-border {
  border-right: 1px solid rgba(151, 151, 151, 0.3); }
  .rtl .conversion-border {
    border-right: none;
    border-left: 1px solid rgba(151, 151, 151, 0.3); }
  @media (max-width: 1024px) {
    .conversion-border {
      border: none; }
      .rtl .conversion-border {
        border: none; } }

.proBanner .purchase-popup {
  background: #fff;
  padding: 15px 20px;
  border-radius: 3px; }
  .proBanner .purchase-popup .btn {
    margin-right: 20px;
    font-weight: 500;
    color: #ffffff;
    border-radius: 5px;
    transition-duration: 0.2s; }
    .proBanner .purchase-popup .btn.download-button {
      background: rgba(249, 249, 249, 0.7);
      color: #969292;
      border: 1px solid #d7d7d7; }
    .proBanner .purchase-popup .btn.purchase-button {
      background-color: #a461d8;
      color: #ffffff;
      border: none;
      line-height: 1;
      vertical-align: middle; }
  .proBanner .purchase-popup p {
    margin-bottom: auto;
    margin-top: auto;
    color: #7e7e7e;
    font-weight: 400;
    vertical-align: middle;
    line-height: 1; }
  .proBanner .purchase-popup i {
    vertical-align: middle;
    line-height: 1;
    margin: auto 0;
    color: #b1b1b1; }
  .proBanner .purchase-popup .bannerClose {
    cursor: pointer; }

.proBanner.hide {
  display: none; }

/* === Components === */
/* Forms */
.form-group {
  margin-bottom: 1.5rem; }

.input-group-append,
.input-group-prepend {
  color: #c9c8c8;
  width: auto;
  border: none; }
  .input-group-append .input-group-text,
  .input-group-prepend .input-group-text {
    border-color: rgba(151, 151, 151, 0.3);
    padding: 0.94rem 0.5rem 0.94rem 1rem;
    color: #c9c8c8; }
  .input-group-append button,
  .input-group-prepend button {
    padding-top: .3rem;
    padding-bottom: .3rem; }

.custom-control .custom-control-label {
  line-height: 1.6;
  margin-bottom: 0; }

.form-control {
  border: 1px solid rgba(151, 151, 151, 0.3);
  font-family: "Nunito", sans-serif;
  font-size: 0.8125rem;
  font-weight: 400; }

select.form-control {
  padding: .4375rem .75rem;
  border: 0;
  outline: 1px solid rgba(151, 151, 151, 0.3);
  color: #c9c8c8; }
  select.form-control:focus {
    outline: 1px solid rgba(151, 151, 151, 0.3); }
  select.form-control.border-primary {
    outline: 1px solid #0062ff; }
    select.form-control.border-primary:focus {
      outline: 1px solid #0062ff; }
  select.form-control.border-secondary, select.form-control.loader-demo-box {
    outline: 1px solid #8e94a9; }
    select.form-control.border-secondary:focus, select.form-control.loader-demo-box:focus {
      outline: 1px solid #8e94a9; }
  select.form-control.border-success {
    outline: 1px solid #44ce42; }
    select.form-control.border-success:focus {
      outline: 1px solid #44ce42; }
  select.form-control.border-info {
    outline: 1px solid #a461d8; }
    select.form-control.border-info:focus {
      outline: 1px solid #a461d8; }
  select.form-control.border-warning {
    outline: 1px solid #ffc542; }
    select.form-control.border-warning:focus {
      outline: 1px solid #ffc542; }
  select.form-control.border-danger {
    outline: 1px solid #fc5a5a; }
    select.form-control.border-danger:focus {
      outline: 1px solid #fc5a5a; }
  select.form-control.border-light {
    outline: 1px solid #aab2bd; }
    select.form-control.border-light:focus {
      outline: 1px solid #aab2bd; }
  select.form-control.border-dark {
    outline: 1px solid #001737; }
    select.form-control.border-dark:focus {
      outline: 1px solid #001737; }

.form-group label {
  font-size: 0.875rem;
  line-height: 1;
  vertical-align: top;
  margin-bottom: .5rem; }

.form-group.has-danger .form-control {
  border-color: #fc5a5a; }

.form-group .file-upload-default {
  visibility: hidden;
  position: absolute; }

.form-group .file-upload-info {
  background: transparent; }

.form-check-input {
  margin-left: 0; }

.custom-file .visibility-hidden {
  visibility: hidden; }

.custom-file .custom-file-label {
  background: #ffffff;
  border: 1px solid rgba(151, 151, 151, 0.3);
  height: calc(2.25rem + 2px);
  font-weight: normal;
  font-size: 0.875rem;
  padding: 0.625rem 0.6875rem;
  border-radius: 2px; }
  .custom-file .custom-file-label:after {
    background-color: #0062ff;
    height: auto; }

/* Checkboxes and Radios */
.form-check {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 0; }
  .form-check .form-check-label {
    min-height: 18px;
    display: block;
    margin-left: 1.75rem;
    font-size: 0.875rem;
    line-height: 1.5; }
    .rtl .form-check .form-check-label {
      margin-left: 0;
      margin-right: 1.75rem; }
    .form-check .form-check-label input {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 0;
      margin-top: 0;
      z-index: 1;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0); }
      .rtl .form-check .form-check-label input {
        left: auto;
        right: 0; }
    .form-check .form-check-label input[type="checkbox"] + .input-helper:before, .form-check .form-check-label input[type="checkbox"] + .input-helper:after {
      position: absolute;
      top: 0;
      left: 0; }
      .rtl .form-check .form-check-label input[type="checkbox"] + .input-helper:before, .rtl .form-check .form-check-label input[type="checkbox"] + .input-helper:after {
        left: auto;
        right: 0; }
    .form-check .form-check-label input[type="checkbox"] + .input-helper:before {
      content: "";
      width: 18px;
      height: 18px;
      border-radius: 2px;
      border: solid #0062ff;
      border-width: 2px;
      transition: all;
      transition-duration: 0s;
      transition-duration: 250ms; }
    .form-check .form-check-label input[type="checkbox"] + .input-helper:after {
      transition: all;
      transition-duration: 0s;
      transition-duration: 250ms;
      font-family: 'Material Design Icons';
      opacity: 0;
      filter: alpha(opacity=0);
      -webkit-transform: scale(0);
      transform: scale(0);
      content: '\F12C';
      font-size: .9375rem;
      font-weight: bold;
      color: #ffffff; }
    .form-check .form-check-label input[type="checkbox"]:checked + .input-helper:before {
      background: #0062ff;
      border-width: 0; }
    .form-check .form-check-label input[type="checkbox"]:checked + .input-helper:after {
      width: 18px;
      opacity: 1;
      line-height: 18px;
      filter: alpha(opacity=100);
      -webkit-transform: scale(1);
      transform: scale(1); }
    .form-check .form-check-label input[type="checkbox"]:disabled + .input-helper:before {
      border-color: rgba(151, 151, 151, 0.3); }
    .form-check .form-check-label input[type="checkbox"]:disabled:checked + .input-helper:after {
      background: rgba(151, 151, 151, 0.3);
      color: #ffffff; }
    .form-check .form-check-label input[type="radio"] + .input-helper:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      border: solid #0062ff;
      border-width: 2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      transition: all;
      transition-duration: 0s;
      transition-duration: 250ms; }
      .rtl .form-check .form-check-label input[type="radio"] + .input-helper:before {
        left: auto;
        right: 0; }
    .form-check .form-check-label input[type="radio"] + .input-helper:after {
      content: "";
      width: 8px;
      height: 8px;
      background: #ffffff;
      border-radius: 50%;
      top: 6px;
      left: 6px;
      transition: all;
      transition-duration: 0s;
      transition-duration: 250ms;
      opacity: 0;
      filter: alpha(opacity=0);
      -webkit-transform: scale(0);
      transform: scale(0);
      position: absolute; }
      .rtl .form-check .form-check-label input[type="radio"] + .input-helper:after {
        left: auto;
        right: 6px; }
    .form-check .form-check-label input[type="radio"]:checked + .input-helper:before {
      background: #0062ff;
      border-width: 0; }
    .form-check .form-check-label input[type="radio"]:checked + .input-helper:after {
      opacity: 1;
      line-height: 1.5;
      filter: alpha(opacity=100);
      -webkit-transform: scale(1);
      transform: scale(1); }
    .form-check .form-check-label input[type="radio"]:disabled + .input-helper:before {
      border-color: rgba(151, 151, 151, 0.3); }
    .form-check .form-check-label input[type="radio"]:disabled:checked + .input-helper:before {
      background: rgba(151, 151, 151, 0.3); }
    .form-check .form-check-label input[type="radio"]:disabled:checked + .input-helper:after {
      background: #ffffff; }

.form-check-primary.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-primary.form-check label input[type="radio"] + .input-helper:before {
  border-color: #0062ff; }

.form-check-primary.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-primary.form-check label input[type="radio"]:checked + .input-helper:before {
  background: #0062ff; }

.form-check-secondary.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-secondary.form-check label input[type="radio"] + .input-helper:before {
  border-color: #8e94a9; }

.form-check-secondary.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-secondary.form-check label input[type="radio"]:checked + .input-helper:before {
  background: #8e94a9; }

.form-check-success.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-success.form-check label input[type="radio"] + .input-helper:before {
  border-color: #44ce42; }

.form-check-success.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-success.form-check label input[type="radio"]:checked + .input-helper:before {
  background: #44ce42; }

.form-check-info.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-info.form-check label input[type="radio"] + .input-helper:before {
  border-color: #a461d8; }

.form-check-info.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-info.form-check label input[type="radio"]:checked + .input-helper:before {
  background: #a461d8; }

.form-check-warning.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-warning.form-check label input[type="radio"] + .input-helper:before {
  border-color: #ffc542; }

.form-check-warning.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-warning.form-check label input[type="radio"]:checked + .input-helper:before {
  background: #ffc542; }

.form-check-danger.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-danger.form-check label input[type="radio"] + .input-helper:before {
  border-color: #fc5a5a; }

.form-check-danger.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-danger.form-check label input[type="radio"]:checked + .input-helper:before {
  background: #fc5a5a; }

.form-check-light.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-light.form-check label input[type="radio"] + .input-helper:before {
  border-color: #aab2bd; }

.form-check-light.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-light.form-check label input[type="radio"]:checked + .input-helper:before {
  background: #aab2bd; }

.form-check-dark.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-dark.form-check label input[type="radio"] + .input-helper:before {
  border-color: #001737; }

.form-check-dark.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-dark.form-check label input[type="radio"]:checked + .input-helper:before {
  background: #001737; }

/* Icons */
.icons-list {
  border-left: 1px solid rgba(151, 151, 151, 0.3);
  border-bottom: 1px solid rgba(151, 151, 151, 0.3); }
  .icons-list > div {
    background: #ffffff;
    border-top: 1px solid rgba(151, 151, 151, 0.3);
    border-right: 1px solid rgba(151, 151, 151, 0.3);
    display: flex;
    align-items: center;
    padding: 15px 15px;
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    font-size: 0.875rem; }
    .icons-list > div i {
      display: inline-block;
      font-size: 20px;
      width: 40px;
      text-align: left;
      color: #0062ff; }

/* Tables */
.table {
  margin-bottom: 0; }
  .table thead th {
    border-top: 0;
    border-bottom-width: 1px;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    font-weight: initial; }
    .table thead th i {
      margin-left: 0.325rem; }
  .table th,
  .table td {
    vertical-align: middle;
    font-size: 0.875rem;
    line-height: 1;
    white-space: nowrap; }
    .table th img,
    .table td img {
      width: 36px;
      height: 36px;
      border-radius: 100%; }
    .table th .badge,
    .table td .badge {
      margin-bottom: 0; }
  .table.table-borderless {
    border: none; }
    .table.table-borderless tr, .table.table-borderless td, .table.table-borderless th {
      border: none; }

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none; }

.pagination > .active > a {
  background-color: #47ccde;
  border-color: #47ccde;
  color: #fff; }

.pagination > li > a {
  border: 1px solid #47ccde;
  padding: 5px 10px;
  outline: none;
  cursor: pointer; }

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: #47ccde;
  border-color: #47ccde;
  outline: none; }

.pagination > li > a, .pagination > li > span {
  color: #47ccde; }

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
  border-radius: unset; }

/* Buttons */
.btn {
  font-size: 12px;
  line-height: 1;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  margin-right: 3px;
  /* Buttons with only icons */
  /* Buttons with icon and text */ }
  .btn i {
    font-size: 12px; }
  .btn.btn-rounded {
    border-radius: 50px; }
  .btn.btn-fw {
    min-width: 150px; }
  .btn.btn-sm, .btn-group-sm > .btn {
    font-size: 0.875rem; }
  .btn.btn-lg, .btn-group-lg > .btn {
    font-size: 0.875rem; }
  .btn.btn-xs {
    padding: 0.5rem 0.75rem;
    font-size: 0.625rem; }
  .btn.btn-icon {
    width: 42px;
    height: 42px;
    padding: 0; }
  .btn.btn-icon-text .btn-icon-prepend {
    margin-right: .5rem; }
  .btn.btn-icon-text .btn-icon-append {
    margin-left: .5rem; }
  .btn.btn-social-icon {
    width: 50px;
    height: 50px;
    padding: 0; }
  .btn.btn-primary.dropdown-toggle:focus, .custom-file .btn.dropdown-toggle.custom-file-label:focus:after {
    box-shadow: none; }

@media (min-width: 576px) {
  .btn-group .btn {
    margin-right: 0; }
    .btn-group .btn + .btn {
      border-left: 0; } }

@media (max-width: 575px) {
  .btn-group {
    flex-direction: row; }
    .btn-group > .btn {
      border-radius: 0;
      margin-right: 0;
      margin-left: 0; }
      .btn-group > .btn:first-child {
        border-top-left-radius: 0.1875rem;
        border-top-right-radius: 0.1875rem; }
      .btn-group > .btn:not(:first-child) {
        margin-left: 0; }
      .btn-group > .btn:last-child {
        border-bottom-left-radius: 0.1875rem;
        border-bottom-right-radius: 0.1875rem; }
      .btn-group > .btn + .btn {
        border-top: 0; } }

/*social buttons*/
.btn-twitter {
  background: #2caae1;
  color: #ffffff; }
  .btn-twitter:hover, .btn-twitter:focus {
    background: #1b8dbf;
    color: #ffffff; }
  .btn-twitter.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #59bce7; }
    .btn-twitter.btn-social-icon-text i {
      background: #2caae1;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-twitter {
  border: 1px solid #2caae1;
  color: #2caae1; }
  .btn-outline-twitter:hover {
    background: #2caae1;
    color: #ffffff; }

.btn-facebook {
  background: #3b579d;
  color: #ffffff; }
  .btn-facebook:hover, .btn-facebook:focus {
    background: #2d4278;
    color: #ffffff; }
  .btn-facebook.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #4e6ebd; }
    .btn-facebook.btn-social-icon-text i {
      background: #3b579d;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-facebook {
  border: 1px solid #3b579d;
  color: #3b579d; }
  .btn-outline-facebook:hover {
    background: #3b579d;
    color: #ffffff; }

.btn-google {
  background: #dc4a38;
  color: #ffffff; }
  .btn-google:hover, .btn-google:focus {
    background: #bf3322;
    color: #ffffff; }
  .btn-google.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #e47163; }
    .btn-google.btn-social-icon-text i {
      background: #dc4a38;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-google {
  border: 1px solid #dc4a38;
  color: #dc4a38; }
  .btn-outline-google:hover {
    background: #dc4a38;
    color: #ffffff; }

.btn-linkedin {
  background: #0177b5;
  color: #ffffff; }
  .btn-linkedin:hover, .btn-linkedin:focus {
    background: #015682;
    color: #ffffff; }
  .btn-linkedin.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #0198e8; }
    .btn-linkedin.btn-social-icon-text i {
      background: #0177b5;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-linkedin {
  border: 1px solid #0177b5;
  color: #0177b5; }
  .btn-outline-linkedin:hover {
    background: #0177b5;
    color: #ffffff; }

.btn-pinterest {
  background: #cc2127;
  color: #ffffff; }
  .btn-pinterest:hover, .btn-pinterest:focus {
    background: #a01a1f;
    color: #ffffff; }
  .btn-pinterest.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #e04046; }
    .btn-pinterest.btn-social-icon-text i {
      background: #cc2127;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-pinterest {
  border: 1px solid #cc2127;
  color: #cc2127; }
  .btn-outline-pinterest:hover {
    background: #cc2127;
    color: #ffffff; }

.btn-youtube {
  background: #e52d27;
  color: #ffffff; }
  .btn-youtube:hover, .btn-youtube:focus {
    background: #c21d17;
    color: #ffffff; }
  .btn-youtube.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #ea5955; }
    .btn-youtube.btn-social-icon-text i {
      background: #e52d27;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-youtube {
  border: 1px solid #e52d27;
  color: #e52d27; }
  .btn-outline-youtube:hover {
    background: #e52d27;
    color: #ffffff; }

.btn-github {
  background: #333333;
  color: #ffffff; }
  .btn-github:hover, .btn-github:focus {
    background: #1a1a1a;
    color: #ffffff; }
  .btn-github.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #4d4d4d; }
    .btn-github.btn-social-icon-text i {
      background: #333333;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-github {
  border: 1px solid #333333;
  color: #333333; }
  .btn-outline-github:hover {
    background: #333333;
    color: #ffffff; }

.btn-behance {
  background: #1769ff;
  color: #ffffff; }
  .btn-behance:hover, .btn-behance:focus {
    background: #0050e3;
    color: #ffffff; }
  .btn-behance.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #4a8aff; }
    .btn-behance.btn-social-icon-text i {
      background: #1769ff;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-behance {
  border: 1px solid #1769ff;
  color: #1769ff; }
  .btn-outline-behance:hover {
    background: #1769ff;
    color: #ffffff; }

.btn-dribbble {
  background: #ea4c89;
  color: #ffffff; }
  .btn-dribbble:hover, .btn-dribbble:focus {
    background: #e51e6b;
    color: #ffffff; }
  .btn-dribbble.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #ef7aa7; }
    .btn-dribbble.btn-social-icon-text i {
      background: #ea4c89;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-dribbble {
  border: 1px solid #ea4c89;
  color: #ea4c89; }
  .btn-outline-dribbble:hover {
    background: #ea4c89;
    color: #ffffff; }

.btn-reddit {
  background: #ff4500;
  color: #ffffff; }
  .btn-reddit:hover, .btn-reddit:focus {
    background: #cc3700;
    color: #ffffff; }
  .btn-reddit.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #ff6a33; }
    .btn-reddit.btn-social-icon-text i {
      background: #ff4500;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-reddit {
  border: 1px solid #ff4500;
  color: #ff4500; }
  .btn-outline-reddit:hover {
    background: #ff4500;
    color: #ffffff; }

/* inverse buttons */
.btn-inverse-primary {
  background-color: rgba(0, 98, 255, 0.2);
  background-image: none;
  border-color: rgba(0, 98, 255, 0); }
  .btn-inverse-primary:not(.btn-inverse-light) {
    color: #0062ff; }
  .btn-inverse-primary:hover {
    color: #ffffff;
    background-color: #0062ff;
    border-color: #0062ff; }
  .btn-inverse-primary.focus, .btn-inverse-primary:focus {
    box-shadow: 0 0 0 3px rgba(0, 98, 255, 0.5); }
  .btn-inverse-primary.disabled, .btn-inverse-primary:disabled {
    color: #0062ff;
    background-color: transparent; }
  .btn-inverse-primary.active, .btn-inverse-primary:active,
  .show > .btn-inverse-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #0062ff;
    border-color: #0062ff; }

.btn-primary:not(.btn-light), .custom-file .custom-file-label:not(.btn-light):after {
  color: #ffffff; }
  .btn-primary:not(.btn-light):hover, .custom-file .custom-file-label:not(.btn-light):hover:after, .btn-primary:not(.btn-light):focus, .custom-file .custom-file-label:not(.btn-light):focus:after, .btn-primary:not(.btn-light):active, .custom-file .custom-file-label:not(.btn-light):active:after {
    color: #ffffff; }
  .btn-primary:not(.btn-light):focus, .custom-file .custom-file-label:not(.btn-light):focus:after, .btn-primary:not(.btn-light):active, .custom-file .custom-file-label:not(.btn-light):active:after {
    background: #0062ff;
    border-color: #0062ff; }

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active {
  background: #0062ff;
  color: #ffffff; }

.btn-inverse-secondary {
  background-color: rgba(142, 148, 169, 0.2);
  background-image: none;
  border-color: rgba(142, 148, 169, 0); }
  .btn-inverse-secondary:not(.btn-inverse-light) {
    color: #8e94a9; }
  .btn-inverse-secondary:hover {
    color: #ffffff;
    background-color: #8e94a9;
    border-color: #8e94a9; }
  .btn-inverse-secondary.focus, .btn-inverse-secondary:focus {
    box-shadow: 0 0 0 3px rgba(142, 148, 169, 0.5); }
  .btn-inverse-secondary.disabled, .btn-inverse-secondary:disabled {
    color: #8e94a9;
    background-color: transparent; }
  .btn-inverse-secondary.active, .btn-inverse-secondary:active,
  .show > .btn-inverse-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #8e94a9;
    border-color: #8e94a9; }

.btn-secondary:not(.btn-light) {
  color: #ffffff; }
  .btn-secondary:not(.btn-light):hover, .btn-secondary:not(.btn-light):focus, .btn-secondary:not(.btn-light):active {
    color: #ffffff; }
  .btn-secondary:not(.btn-light):focus, .btn-secondary:not(.btn-light):active {
    background: #8e94a9;
    border-color: #8e94a9; }

.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active {
  background: #8e94a9;
  color: #ffffff; }

.btn-inverse-success {
  background-color: rgba(68, 206, 66, 0.2);
  background-image: none;
  border-color: rgba(68, 206, 66, 0); }
  .btn-inverse-success:not(.btn-inverse-light) {
    color: #44ce42; }
  .btn-inverse-success:hover {
    color: #ffffff;
    background-color: #44ce42;
    border-color: #44ce42; }
  .btn-inverse-success.focus, .btn-inverse-success:focus {
    box-shadow: 0 0 0 3px rgba(68, 206, 66, 0.5); }
  .btn-inverse-success.disabled, .btn-inverse-success:disabled {
    color: #44ce42;
    background-color: transparent; }
  .btn-inverse-success.active, .btn-inverse-success:active,
  .show > .btn-inverse-success.dropdown-toggle {
    color: #ffffff;
    background-color: #44ce42;
    border-color: #44ce42; }

.btn-success:not(.btn-light) {
  color: #ffffff; }
  .btn-success:not(.btn-light):hover, .btn-success:not(.btn-light):focus, .btn-success:not(.btn-light):active {
    color: #ffffff; }
  .btn-success:not(.btn-light):focus, .btn-success:not(.btn-light):active {
    background: #44ce42;
    border-color: #44ce42; }

.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active {
  background: #44ce42;
  color: #ffffff; }

.btn-inverse-info {
  background-color: rgba(164, 97, 216, 0.2);
  background-image: none;
  border-color: rgba(164, 97, 216, 0); }
  .btn-inverse-info:not(.btn-inverse-light) {
    color: #a461d8; }
  .btn-inverse-info:hover {
    color: #ffffff;
    background-color: #a461d8;
    border-color: #a461d8; }
  .btn-inverse-info.focus, .btn-inverse-info:focus {
    box-shadow: 0 0 0 3px rgba(164, 97, 216, 0.5); }
  .btn-inverse-info.disabled, .btn-inverse-info:disabled {
    color: #a461d8;
    background-color: transparent; }
  .btn-inverse-info.active, .btn-inverse-info:active,
  .show > .btn-inverse-info.dropdown-toggle {
    color: #ffffff;
    background-color: #a461d8;
    border-color: #a461d8; }

.btn-info:not(.btn-light) {
  color: #ffffff; }
  .btn-info:not(.btn-light):hover, .btn-info:not(.btn-light):focus, .btn-info:not(.btn-light):active {
    color: #ffffff; }
  .btn-info:not(.btn-light):focus, .btn-info:not(.btn-light):active {
    background: #a461d8;
    border-color: #a461d8; }

.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active {
  background: #a461d8;
  color: #ffffff; }

.btn-inverse-warning {
  background-color: rgba(255, 197, 66, 0.2);
  background-image: none;
  border-color: rgba(255, 197, 66, 0); }
  .btn-inverse-warning:not(.btn-inverse-light) {
    color: #ffc542; }
  .btn-inverse-warning:hover {
    color: #ffffff;
    background-color: #ffc542;
    border-color: #ffc542; }
  .btn-inverse-warning.focus, .btn-inverse-warning:focus {
    box-shadow: 0 0 0 3px rgba(255, 197, 66, 0.5); }
  .btn-inverse-warning.disabled, .btn-inverse-warning:disabled {
    color: #ffc542;
    background-color: transparent; }
  .btn-inverse-warning.active, .btn-inverse-warning:active,
  .show > .btn-inverse-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #ffc542;
    border-color: #ffc542; }

.btn-warning:not(.btn-light) {
  color: #ffffff; }
  .btn-warning:not(.btn-light):hover, .btn-warning:not(.btn-light):focus, .btn-warning:not(.btn-light):active {
    color: #ffffff; }
  .btn-warning:not(.btn-light):focus, .btn-warning:not(.btn-light):active {
    background: #ffc542;
    border-color: #ffc542; }

.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active {
  background: #ffc542;
  color: #ffffff; }

.btn-inverse-danger {
  background-color: rgba(252, 90, 90, 0.2);
  background-image: none;
  border-color: rgba(252, 90, 90, 0); }
  .btn-inverse-danger:not(.btn-inverse-light) {
    color: #fc5a5a; }
  .btn-inverse-danger:hover {
    color: #ffffff;
    background-color: #fc5a5a;
    border-color: #fc5a5a; }
  .btn-inverse-danger.focus, .btn-inverse-danger:focus {
    box-shadow: 0 0 0 3px rgba(252, 90, 90, 0.5); }
  .btn-inverse-danger.disabled, .btn-inverse-danger:disabled {
    color: #fc5a5a;
    background-color: transparent; }
  .btn-inverse-danger.active, .btn-inverse-danger:active,
  .show > .btn-inverse-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #fc5a5a;
    border-color: #fc5a5a; }

.btn-danger:not(.btn-light) {
  color: #ffffff; }
  .btn-danger:not(.btn-light):hover, .btn-danger:not(.btn-light):focus, .btn-danger:not(.btn-light):active {
    color: #ffffff; }
  .btn-danger:not(.btn-light):focus, .btn-danger:not(.btn-light):active {
    background: #fc5a5a;
    border-color: #fc5a5a; }

.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active {
  background: #fc5a5a;
  color: #ffffff; }

.btn-inverse-light {
  background-color: rgba(170, 178, 189, 0.2);
  background-image: none;
  border-color: rgba(170, 178, 189, 0); }
  .btn-inverse-light:not(.btn-inverse-light) {
    color: #aab2bd; }
  .btn-inverse-light:hover {
    color: #ffffff;
    background-color: #aab2bd;
    border-color: #aab2bd; }
  .btn-inverse-light.focus, .btn-inverse-light:focus {
    box-shadow: 0 0 0 3px rgba(170, 178, 189, 0.5); }
  .btn-inverse-light.disabled, .btn-inverse-light:disabled {
    color: #aab2bd;
    background-color: transparent; }
  .btn-inverse-light.active, .btn-inverse-light:active,
  .show > .btn-inverse-light.dropdown-toggle {
    color: #ffffff;
    background-color: #aab2bd;
    border-color: #aab2bd; }

.btn-light:not(.btn-light) {
  color: #ffffff; }
  .btn-light:not(.btn-light):hover, .btn-light:not(.btn-light):focus, .btn-light:not(.btn-light):active {
    color: #ffffff; }
  .btn-light:not(.btn-light):focus, .btn-light:not(.btn-light):active {
    background: #aab2bd;
    border-color: #aab2bd; }

.btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active {
  background: #aab2bd;
  color: #ffffff; }

.btn-inverse-dark {
  background-color: rgba(0, 23, 55, 0.2);
  background-image: none;
  border-color: rgba(0, 23, 55, 0); }
  .btn-inverse-dark:not(.btn-inverse-light) {
    color: #001737; }
  .btn-inverse-dark:hover {
    color: #ffffff;
    background-color: #001737;
    border-color: #001737; }
  .btn-inverse-dark.focus, .btn-inverse-dark:focus {
    box-shadow: 0 0 0 3px rgba(0, 23, 55, 0.5); }
  .btn-inverse-dark.disabled, .btn-inverse-dark:disabled {
    color: #001737;
    background-color: transparent; }
  .btn-inverse-dark.active, .btn-inverse-dark:active,
  .show > .btn-inverse-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #001737;
    border-color: #001737; }

.btn-dark:not(.btn-light) {
  color: #ffffff; }
  .btn-dark:not(.btn-light):hover, .btn-dark:not(.btn-light):focus, .btn-dark:not(.btn-light):active {
    color: #ffffff; }
  .btn-dark:not(.btn-light):focus, .btn-dark:not(.btn-light):active {
    background: #001737;
    border-color: #001737; }

.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active {
  background: #001737;
  color: #ffffff; }

/* Breadcrumbs */
.breadcrumb {
  border: 1px solid rgba(151, 151, 151, 0.3); }
  .breadcrumb .breadcrumb-item {
    font-size: 0.875rem; }
  .breadcrumb.breadcrumb-custom {
    padding: 0 0;
    border-color: #dbe3e6; }
    .breadcrumb.breadcrumb-custom .breadcrumb-item {
      font-size: 0.875rem;
      background: #dbe3e6;
      padding: 0.56rem 10px;
      color: #000;
      display: inline-block;
      vertical-align: top; }
      .breadcrumb.breadcrumb-custom .breadcrumb-item:last-child {
        background: transparent; }
      .breadcrumb.breadcrumb-custom .breadcrumb-item:before {
        content: ""; }
      .breadcrumb.breadcrumb-custom .breadcrumb-item a {
        position: relative;
        color: inherit;
        border: 1px solid #dbe3e6;
        display: inline-block;
        vertical-align: top; }
        .breadcrumb.breadcrumb-custom .breadcrumb-item a:before, .breadcrumb.breadcrumb-custom .breadcrumb-item a:after {
          position: absolute;
          top: -9px;
          width: 0;
          height: 0;
          content: "";
          border-top: 21px solid transparent;
          border-bottom: 21px solid transparent; }
        .breadcrumb.breadcrumb-custom .breadcrumb-item a:before {
          right: -22px;
          z-index: 3;
          border-left-color: #dbe3e6;
          border-left-style: solid;
          border-left-width: 12px; }
        .breadcrumb.breadcrumb-custom .breadcrumb-item a:after {
          border-top: 21px solid transparent;
          border-bottom: 22px solid transparent;
          border-left: 12px solid #ffffff;
          top: -9px;
          right: -23px; }
      .breadcrumb.breadcrumb-custom .breadcrumb-item span {
        display: inline-block;
        vertical-align: top; }
  .breadcrumb.bg-success, .settings-panel .color-tiles .breadcrumb.tiles.success, .breadcrumb.bg-dark, .settings-panel .color-tiles .breadcrumb.tiles.dark, .breadcrumb.bg-danger, .settings-panel .color-tiles .breadcrumb.tiles.danger, .breadcrumb.bg-warning, .settings-panel .color-tiles .breadcrumb.tiles.warning, .breadcrumb.bg-primary, .settings-panel .color-tiles .breadcrumb.tiles.primary, #theme-settings .breadcrumb.settings-close, .breadcrumb.bg-info, .settings-panel .color-tiles .breadcrumb.tiles.info {
    border: none; }
    .breadcrumb.bg-success .breadcrumb-item, .settings-panel .color-tiles .breadcrumb.tiles.success .breadcrumb-item, .breadcrumb.bg-dark .breadcrumb-item, .settings-panel .color-tiles .breadcrumb.tiles.dark .breadcrumb-item, .breadcrumb.bg-danger .breadcrumb-item, .settings-panel .color-tiles .breadcrumb.tiles.danger .breadcrumb-item, .breadcrumb.bg-warning .breadcrumb-item, .settings-panel .color-tiles .breadcrumb.tiles.warning .breadcrumb-item, .breadcrumb.bg-primary .breadcrumb-item, .settings-panel .color-tiles .breadcrumb.tiles.primary .breadcrumb-item, #theme-settings .breadcrumb.settings-close .breadcrumb-item, .breadcrumb.bg-info .breadcrumb-item, .settings-panel .color-tiles .breadcrumb.tiles.info .breadcrumb-item {
      color: #ffffff; }
      .breadcrumb.bg-success .breadcrumb-item:before, .settings-panel .color-tiles .breadcrumb.tiles.success .breadcrumb-item:before, .breadcrumb.bg-dark .breadcrumb-item:before, .settings-panel .color-tiles .breadcrumb.tiles.dark .breadcrumb-item:before, .breadcrumb.bg-danger .breadcrumb-item:before, .settings-panel .color-tiles .breadcrumb.tiles.danger .breadcrumb-item:before, .breadcrumb.bg-warning .breadcrumb-item:before, .settings-panel .color-tiles .breadcrumb.tiles.warning .breadcrumb-item:before, .breadcrumb.bg-primary .breadcrumb-item:before, .settings-panel .color-tiles .breadcrumb.tiles.primary .breadcrumb-item:before, #theme-settings .breadcrumb.settings-close .breadcrumb-item:before, .breadcrumb.bg-info .breadcrumb-item:before, .settings-panel .color-tiles .breadcrumb.tiles.info .breadcrumb-item:before {
        color: inherit; }
      .breadcrumb.bg-success .breadcrumb-item a, .settings-panel .color-tiles .breadcrumb.tiles.success .breadcrumb-item a, .breadcrumb.bg-success .breadcrumb-item span, .settings-panel .color-tiles .breadcrumb.tiles.success .breadcrumb-item span, .breadcrumb.bg-dark .breadcrumb-item a, .settings-panel .color-tiles .breadcrumb.tiles.dark .breadcrumb-item a, .breadcrumb.bg-dark .breadcrumb-item span, .settings-panel .color-tiles .breadcrumb.tiles.dark .breadcrumb-item span, .breadcrumb.bg-danger .breadcrumb-item a, .settings-panel .color-tiles .breadcrumb.tiles.danger .breadcrumb-item a, .breadcrumb.bg-danger .breadcrumb-item span, .settings-panel .color-tiles .breadcrumb.tiles.danger .breadcrumb-item span, .breadcrumb.bg-warning .breadcrumb-item a, .settings-panel .color-tiles .breadcrumb.tiles.warning .breadcrumb-item a, .breadcrumb.bg-warning .breadcrumb-item span, .settings-panel .color-tiles .breadcrumb.tiles.warning .breadcrumb-item span, .breadcrumb.bg-primary .breadcrumb-item a, .settings-panel .color-tiles .breadcrumb.tiles.primary .breadcrumb-item a, #theme-settings .breadcrumb.settings-close .breadcrumb-item a, .breadcrumb.bg-primary .breadcrumb-item span, .settings-panel .color-tiles .breadcrumb.tiles.primary .breadcrumb-item span, #theme-settings .breadcrumb.settings-close .breadcrumb-item span, .breadcrumb.bg-info .breadcrumb-item a, .settings-panel .color-tiles .breadcrumb.tiles.info .breadcrumb-item a, .breadcrumb.bg-info .breadcrumb-item span, .settings-panel .color-tiles .breadcrumb.tiles.info .breadcrumb-item span {
        color: inherit; }

/* inverse breadcrumb */
.bg-inverse-primary, .image-grouped .text-avatar {
  background: #80b1ff;
  border-color: #0062ff; }
  .bg-inverse-primary .breadcrumb-item, .image-grouped .text-avatar .breadcrumb-item {
    color: #0062ff; }
    .bg-inverse-primary .breadcrumb-item:before, .image-grouped .text-avatar .breadcrumb-item:before {
      color: inherit; }
    .bg-inverse-primary .breadcrumb-item a, .image-grouped .text-avatar .breadcrumb-item a {
      color: inherit; }
  .bg-inverse-primary.breadcrumb-custom, .image-grouped .breadcrumb-custom.text-avatar {
    background: transparent; }
    .bg-inverse-primary.breadcrumb-custom .breadcrumb-item, .image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item {
      background: #80b1ff; }
      .bg-inverse-primary.breadcrumb-custom .breadcrumb-item a, .image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item a {
        color: #0062ff;
        border: none; }
        .bg-inverse-primary.breadcrumb-custom .breadcrumb-item a:before, .image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item a:before {
          border-left-color: #80b1ff; }
      .bg-inverse-primary.breadcrumb-custom .breadcrumb-item span, .image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item span {
        color: #0062ff; }
      .bg-inverse-primary.breadcrumb-custom .breadcrumb-item:last-child, .image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item:last-child {
        background: transparent; }

.bg-inverse-secondary {
  background: #d6d9e0;
  border-color: #8e94a9; }
  .bg-inverse-secondary .breadcrumb-item {
    color: #8e94a9; }
    .bg-inverse-secondary .breadcrumb-item:before {
      color: inherit; }
    .bg-inverse-secondary .breadcrumb-item a {
      color: inherit; }
  .bg-inverse-secondary.breadcrumb-custom {
    background: transparent; }
    .bg-inverse-secondary.breadcrumb-custom .breadcrumb-item {
      background: #d6d9e0; }
      .bg-inverse-secondary.breadcrumb-custom .breadcrumb-item a {
        color: #8e94a9;
        border: none; }
        .bg-inverse-secondary.breadcrumb-custom .breadcrumb-item a:before {
          border-left-color: #d6d9e0; }
      .bg-inverse-secondary.breadcrumb-custom .breadcrumb-item span {
        color: #8e94a9; }
      .bg-inverse-secondary.breadcrumb-custom .breadcrumb-item:last-child {
        background: transparent; }

.bg-inverse-success {
  background: #a8e8a7;
  border-color: #44ce42; }
  .bg-inverse-success .breadcrumb-item {
    color: #44ce42; }
    .bg-inverse-success .breadcrumb-item:before {
      color: inherit; }
    .bg-inverse-success .breadcrumb-item a {
      color: inherit; }
  .bg-inverse-success.breadcrumb-custom {
    background: transparent; }
    .bg-inverse-success.breadcrumb-custom .breadcrumb-item {
      background: #a8e8a7; }
      .bg-inverse-success.breadcrumb-custom .breadcrumb-item a {
        color: #44ce42;
        border: none; }
        .bg-inverse-success.breadcrumb-custom .breadcrumb-item a:before {
          border-left-color: #a8e8a7; }
      .bg-inverse-success.breadcrumb-custom .breadcrumb-item span {
        color: #44ce42; }
      .bg-inverse-success.breadcrumb-custom .breadcrumb-item:last-child {
        background: transparent; }

.bg-inverse-info {
  background: #dfc7f1;
  border-color: #a461d8; }
  .bg-inverse-info .breadcrumb-item {
    color: #a461d8; }
    .bg-inverse-info .breadcrumb-item:before {
      color: inherit; }
    .bg-inverse-info .breadcrumb-item a {
      color: inherit; }
  .bg-inverse-info.breadcrumb-custom {
    background: transparent; }
    .bg-inverse-info.breadcrumb-custom .breadcrumb-item {
      background: #dfc7f1; }
      .bg-inverse-info.breadcrumb-custom .breadcrumb-item a {
        color: #a461d8;
        border: none; }
        .bg-inverse-info.breadcrumb-custom .breadcrumb-item a:before {
          border-left-color: #dfc7f1; }
      .bg-inverse-info.breadcrumb-custom .breadcrumb-item span {
        color: #a461d8; }
      .bg-inverse-info.breadcrumb-custom .breadcrumb-item:last-child {
        background: transparent; }

.bg-inverse-warning {
  background: #ffecc2;
  border-color: #ffc542; }
  .bg-inverse-warning .breadcrumb-item {
    color: #ffc542; }
    .bg-inverse-warning .breadcrumb-item:before {
      color: inherit; }
    .bg-inverse-warning .breadcrumb-item a {
      color: inherit; }
  .bg-inverse-warning.breadcrumb-custom {
    background: transparent; }
    .bg-inverse-warning.breadcrumb-custom .breadcrumb-item {
      background: #ffecc2; }
      .bg-inverse-warning.breadcrumb-custom .breadcrumb-item a {
        color: #ffc542;
        border: none; }
        .bg-inverse-warning.breadcrumb-custom .breadcrumb-item a:before {
          border-left-color: #ffecc2; }
      .bg-inverse-warning.breadcrumb-custom .breadcrumb-item span {
        color: #ffc542; }
      .bg-inverse-warning.breadcrumb-custom .breadcrumb-item:last-child {
        background: transparent; }

.bg-inverse-danger {
  background: #fed7d7;
  border-color: #fc5a5a; }
  .bg-inverse-danger .breadcrumb-item {
    color: #fc5a5a; }
    .bg-inverse-danger .breadcrumb-item:before {
      color: inherit; }
    .bg-inverse-danger .breadcrumb-item a {
      color: inherit; }
  .bg-inverse-danger.breadcrumb-custom {
    background: transparent; }
    .bg-inverse-danger.breadcrumb-custom .breadcrumb-item {
      background: #fed7d7; }
      .bg-inverse-danger.breadcrumb-custom .breadcrumb-item a {
        color: #fc5a5a;
        border: none; }
        .bg-inverse-danger.breadcrumb-custom .breadcrumb-item a:before {
          border-left-color: #fed7d7; }
      .bg-inverse-danger.breadcrumb-custom .breadcrumb-item span {
        color: #fc5a5a; }
      .bg-inverse-danger.breadcrumb-custom .breadcrumb-item:last-child {
        background: transparent; }

.bg-inverse-light {
  background: #f2f3f5;
  border-color: #aab2bd; }
  .bg-inverse-light .breadcrumb-item {
    color: #aab2bd; }
    .bg-inverse-light .breadcrumb-item:before {
      color: inherit; }
    .bg-inverse-light .breadcrumb-item a {
      color: inherit; }
  .bg-inverse-light.breadcrumb-custom {
    background: transparent; }
    .bg-inverse-light.breadcrumb-custom .breadcrumb-item {
      background: #f2f3f5; }
      .bg-inverse-light.breadcrumb-custom .breadcrumb-item a {
        color: #aab2bd;
        border: none; }
        .bg-inverse-light.breadcrumb-custom .breadcrumb-item a:before {
          border-left-color: #f2f3f5; }
      .bg-inverse-light.breadcrumb-custom .breadcrumb-item span {
        color: #aab2bd; }
      .bg-inverse-light.breadcrumb-custom .breadcrumb-item:last-child {
        background: transparent; }

.bg-inverse-dark {
  background: #004cb7;
  border-color: #001737; }
  .bg-inverse-dark .breadcrumb-item {
    color: #001737; }
    .bg-inverse-dark .breadcrumb-item:before {
      color: inherit; }
    .bg-inverse-dark .breadcrumb-item a {
      color: inherit; }
  .bg-inverse-dark.breadcrumb-custom {
    background: transparent; }
    .bg-inverse-dark.breadcrumb-custom .breadcrumb-item {
      background: #004cb7; }
      .bg-inverse-dark.breadcrumb-custom .breadcrumb-item a {
        color: #001737;
        border: none; }
        .bg-inverse-dark.breadcrumb-custom .breadcrumb-item a:before {
          border-left-color: #004cb7; }
      .bg-inverse-dark.breadcrumb-custom .breadcrumb-item span {
        color: #001737; }
      .bg-inverse-dark.breadcrumb-custom .breadcrumb-item:last-child {
        background: transparent; }

/* Cards */
.card {
  border: 0;
  border-radius: 0; }
  .card .card-body {
    padding: 2.5rem 2.5rem; }
    @media (max-width: 767px) {
      .card .card-body {
        padding: 2rem 2rem; } }
    .card .card-body + .card-body {
      padding-top: 1rem; }
  .card .card-title {
    color: #000;
    margin-bottom: 1.625rem;
    text-transform: capitalize;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    font-size: 1.125rem; }
  .card .card-subtitle {
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .card .card-description {
    margin-bottom: 1.5rem;
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    color: #76838f; }
  .card.card-outline-success {
    border: 1px solid #44ce42; }
  .card.card-outline-primary {
    border: 1px solid #0062ff; }
  .card.card-outline-warning {
    border: 1px solid #ffc542; }
  .card.card-outline-danger {
    border: 1px solid #fc5a5a; }
  .card.card-rounded {
    border-radius: 5px; }
  .card.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2; }
  .card.card-circle-progress {
    color: #ffffff;
    text-align: center; }
  .card.card-img-holder {
    position: relative; }
    .card.card-img-holder .card-img-absolute {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%; }

.card-inverse-primary {
  background: rgba(0, 98, 255, 0.2);
  border: 1px solid #005aeb;
  color: #004ac2; }

.card-inverse-secondary {
  background: rgba(142, 148, 169, 0.2);
  border: 1px solid #83889b;
  color: #6c7080; }

.card-inverse-success {
  background: rgba(68, 206, 66, 0.2);
  border: 1px solid #3fbe3d;
  color: #349d32; }

.card-inverse-info {
  background: rgba(164, 97, 216, 0.2);
  border: 1px solid #9759c7;
  color: #7d4aa4; }

.card-inverse-warning {
  background: rgba(255, 197, 66, 0.2);
  border: 1px solid #ebb53d;
  color: #c29632; }

.card-inverse-danger {
  background: rgba(252, 90, 90, 0.2);
  border: 1px solid #e85353;
  color: #c04444; }

.card-inverse-light {
  background: rgba(170, 178, 189, 0.2);
  border: 1px solid #9ca4ae;
  color: #818790; }

.card-inverse-dark {
  background: rgba(0, 23, 55, 0.2);
  border: 1px solid #001533;
  color: #00112a; }

/* Preview */
.preview-list .preview-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: .75rem 1.5rem;
  font-size: .875rem; }
  .preview-list .preview-item:last-child {
    border-bottom: 0; }
  .preview-list .preview-item:hover {
    background: #e9ecef; }
  .preview-list .preview-item .form-check {
    margin-top: 8px;
    margin-right: 1rem; }
  .preview-list .preview-item .preview-thumbnail {
    color: #ffffff;
    position: relative; }
    .preview-list .preview-item .preview-thumbnail img,
    .preview-list .preview-item .preview-thumbnail .preview-icon {
      width: 36px;
      height: 36px;
      border-radius: 100%; }
    .preview-list .preview-item .preview-thumbnail .preview-icon {
      padding: 6px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center; }
      .preview-list .preview-item .preview-thumbnail .preview-icon i {
        font-size: 1.125rem;
        margin: 0; }
    .preview-list .preview-item .preview-thumbnail .badge {
      border: 2px solid #ffffff;
      border-radius: 100%;
      bottom: 5px;
      display: block;
      height: 14px;
      left: -5px;
      padding: 0;
      position: absolute;
      width: 14px; }
  .preview-list .preview-item .preview-item-content {
    line-height: 1;
    padding-left: 15px; }
    .preview-list .preview-item .preview-item-content:first-child {
      padding-left: 0; }
    .preview-list .preview-item .preview-item-content p {
      margin-bottom: 10px; }
      .preview-list .preview-item .preview-item-content p .content-category {
        font-family: 'source-sans-pro-semibold', sans-serif;
        padding-right: 15px;
        border-right: 1px solid rgba(151, 151, 151, 0.3); }
    .rtl .preview-list .preview-item .preview-item-content {
      padding-left: 0;
      padding-right: 1rem;
      margin-right: 0;
      margin-left: auto; }
  .preview-list .preview-item .preview-actions {
    display: flex;
    flex-direction: row; }
    .preview-list .preview-item .preview-actions i {
      width: 29px;
      color: #e6e9ed;
      height: 29px;
      border: 2px solid #e6e9ed;
      border-radius: 100%;
      padding: 3px 6px;
      display: inline-block; }
      .preview-list .preview-item .preview-actions i:first-child {
        margin-right: 10px; }

.preview-list.comment-preview .preview-item {
  padding: .87rem 0; }
  .preview-list.comment-preview .preview-item:first-child {
    padding-top: 0; }
  .preview-list.comment-preview .preview-item p {
    line-height: 27px; }

.preview-list.bordered .preview-item {
  border-bottom: 1px solid rgba(151, 151, 151, 0.3); }
  .preview-list.bordered .preview-item:last-child {
    border-bottom: 0; }

/* Bootstrap Progress */
.progress {
  border-radius: 3px;
  height: 8px; }
  .progress .progress-bar {
    border-radius: 3px; }
  .progress.progress-sm {
    height: 0.375rem; }
  .progress.progress-md {
    height: 8px; }
  .progress.progress-lg {
    height: 15px; }
  .progress.progress-xl {
    height: 18px; }

/* Lists */
ul,
ol,
dl {
  padding-left: 1rem;
  font-size: 0.875rem; }
  ul li,
  ol li,
  dl li {
    line-height: 1.8; }

.list-ticked,
.list-arrow,
.list-star {
  list-style: none;
  padding: 0; }
  .list-ticked li,
  .list-arrow li,
  .list-star li {
    padding-left: 1.5rem; }
    .list-ticked li:before,
    .list-arrow li:before,
    .list-star li:before {
      font-family: "Material Design Icons";
      margin-left: -1.5rem;
      width: 1.5rem;
      margin-right: .5rem; }

.list-ticked li:before {
  content: '\F12D';
  color: #fc5a5a; }

.list-arrow li:before {
  content: '\F142';
  color: #44ce42; }

.list-star li:before {
  content: '\F4CE';
  color: #ffc542; }

.bullet-line-list {
  padding-left: 30px;
  margin-bottom: 0;
  position: relative;
  list-style-type: none; }
  .rtl .bullet-line-list {
    padding-right: 0px; }
  .bullet-line-list li {
    position: relative;
    line-height: 1;
    padding-bottom: 10px; }
    .bullet-line-list li:before {
      content: "";
      position: absolute;
      border-radius: 100%;
      width: 12px;
      height: 12px;
      left: -28px;
      top: 6px;
      border: 3px solid #0062ff;
      margin-right: 15px;
      z-index: 2;
      background: #ffffff; }
    .bullet-line-list li:after {
      content: "";
      border: 1px solid rgba(151, 151, 151, 0.3);
      position: absolute;
      bottom: 0;
      left: -23px;
      height: 100%; }
    .bullet-line-list li:first-child:after {
      content: "";
      height: 80%; }
    .bullet-line-list li:last-child {
      padding-bottom: 0; }
      .bullet-line-list li:last-child:after {
        content: "";
        top: 0;
        height: 30%; }

.nav-tabs .nav-link {
  background: #f6f8fa;
  color: #000000;
  border-radius: 0;
  border: 1px solid rgba(151, 151, 151, 0.3);
  padding: .75rem 1.5rem;
  font-size: 0.875rem; }
  @media (max-width: 767px) {
    .nav-tabs .nav-link {
      padding: .75rem 1rem; } }

.nav-tabs .nav-item:first-child .nav-link {
  border-radius: 4px 0 0 0; }

.nav-tabs .nav-item:last-child .nav-link {
  border-radius: 0 4px 0 0; }

.nav-pills {
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
  padding-bottom: 1rem; }
  .nav-pills .nav-link {
    border: 1px solid rgba(151, 151, 151, 0.3);
    padding: .5rem 1rem; }
    @media (max-width: 767px) {
      .nav-pills .nav-link {
        padding: .75rem .5rem; } }
  .nav-pills .nav-item {
    margin-right: 1rem; }
    @media (max-width: 767px) {
      .nav-pills .nav-item {
        margin-right: .25rem;
        margin-bottom: .5rem; } }

.pills-vertical .nav-pills {
  border-bottom: 0; }
  .pills-vertical .nav-pills .nav-item {
    margin-right: 0;
    margin-bottom: 1rem; }
    .pills-vertical .nav-pills .nav-item .nav-link {
      padding: .5rem 1.5rem; }
      @media (max-width: 767px) {
        .pills-vertical .nav-pills .nav-item .nav-link {
          padding: .75rem 1rem; } }

.pills-vertical .tab-content {
  border-top: 1px solid rgba(151, 151, 151, 0.3); }

.tab-content {
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-top: 0;
  padding: 2rem 1rem;
  text-align: justify; }
  .tab-content.tab-content-custom-pill {
    border: 0;
    padding-left: 0; }

.tab-vertical .nav-pills, .tab-custom-vertical .nav-pills, .tab-pills-vertical .nav-pills, .tab-vertical-custom .nav-pills {
  padding-bottom: 0;
  border-bottom: 0; }
  .tab-vertical .nav-pills .nav-item .nav-link, .tab-custom-vertical .nav-pills .nav-item .nav-link, .tab-pills-vertical .nav-pills .nav-item .nav-link, .tab-vertical-custom .nav-pills .nav-item .nav-link {
    background: #f6f8fa;
    color: #000000;
    border-radius: 0;
    border: 1px solid rgba(151, 151, 151, 0.3);
    padding: .75rem 1.5rem;
    font-size: 0.875rem; }
    .tab-vertical .nav-pills .nav-item .nav-link.active, .tab-custom-vertical .nav-pills .nav-item .nav-link.active, .tab-pills-vertical .nav-pills .nav-item .nav-link.active, .tab-vertical-custom .nav-pills .nav-item .nav-link.active {
      background: #ffffff; }
    @media (max-width: 767px) {
      .tab-vertical .nav-pills .nav-item .nav-link, .tab-custom-vertical .nav-pills .nav-item .nav-link, .tab-pills-vertical .nav-pills .nav-item .nav-link, .tab-vertical-custom .nav-pills .nav-item .nav-link {
        padding: .75rem 1rem; } }
  .tab-vertical .nav-pills .nav-item:first-child .nav-link, .tab-custom-vertical .nav-pills .nav-item:first-child .nav-link, .tab-pills-vertical .nav-pills .nav-item:first-child .nav-link, .tab-vertical-custom .nav-pills .nav-item:first-child .nav-link {
    border-radius: 4px 4px 0 0; }
  .tab-vertical .nav-pills .nav-item:last-child .nav-link, .tab-custom-vertical .nav-pills .nav-item:last-child .nav-link, .tab-pills-vertical .nav-pills .nav-item:last-child .nav-link, .tab-vertical-custom .nav-pills .nav-item:last-child .nav-link {
    border-radius: 0 0 4px 4px; }
  .tab-vertical .nav-pills .nav-item:not(:last-child) .nav-link, .tab-custom-vertical .nav-pills .nav-item:not(:last-child) .nav-link, .tab-pills-vertical .nav-pills .nav-item:not(:last-child) .nav-link, .tab-vertical-custom .nav-pills .nav-item:not(:last-child) .nav-link {
    border-bottom: 0; }

.tab-vertical .tab-content, .tab-custom-vertical .tab-content, .tab-pills-vertical .tab-content, .tab-vertical-custom .tab-content {
  border-top: 1px solid rgba(151, 151, 151, 0.3); }

.tab-custom-vertical .nav-pills .nav-item .nav-link {
  background: #0062ff;
  color: #002766;
  border: 1px solid #3381ff;
  padding: 28px;
  font-weight: bold;
  line-height: 1.8; }
  .tab-custom-vertical .nav-pills .nav-item .nav-link.active {
    color: #fff;
    background: #0062ff; }

.tab-custom-vertical .tab-content {
  border: 0; }

.tab-pills-horizontal .nav.nav-pills .nav-item .nav-link, .tab-custom-pills-horizontal .nav.nav-pills .nav-item .nav-link {
  background: #f6f8fa;
  color: #000000;
  border-radius: 0;
  border: 1px solid rgba(151, 151, 151, 0.3);
  padding: .75rem 1.5rem;
  font-size: 0.875rem; }
  .tab-pills-horizontal .nav.nav-pills .nav-item .nav-link.active, .tab-custom-pills-horizontal .nav.nav-pills .nav-item .nav-link.active {
    background: #ffffff; }
  @media (max-width: 767px) {
    .tab-pills-horizontal .nav.nav-pills .nav-item .nav-link, .tab-custom-pills-horizontal .nav.nav-pills .nav-item .nav-link {
      padding: .75rem .5rem; } }

.tab-pills-vertical .nav-pills .nav-item {
  margin-bottom: 16px; }
  .tab-pills-vertical .nav-pills .nav-item .nav-link {
    border-bottom: 1px solid rgba(151, 151, 151, 0.3); }
  .tab-pills-vertical .nav-pills .nav-item:first-child .nav-link, .tab-pills-vertical .nav-pills .nav-item:last-child .nav-link {
    border-radius: 0px; }
  .tab-pills-vertical .nav-pills .nav-item:not(:last-child) .nav-link {
    border-bottom: 1px solid rgba(151, 151, 151, 0.3); }

.tab-vertical-custom .nav-tabs {
  background: #0062ff;
  border-radius: 4px; }
  .tab-vertical-custom .nav-tabs .nav-link {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.2);
    color: #002766;
    font-family: "Nunito", sans-serif;
    font-weight: 700;
    padding: 1.75rem; }
    .tab-vertical-custom .nav-tabs .nav-link.active {
      color: #ffffff;
      border-color: rgba(255, 255, 255, 0.2); }

.tab-vertical-custom.tab-content {
  border: 0;
  padding-top: 0; }

.tab-custom-pills-horizontal .nav.nav-pills .nav-item .nav-link {
  border-radius: 20px; }
  .tab-custom-pills-horizontal .nav.nav-pills .nav-item .nav-link.active {
    background: #fc5a5a;
    color: #ffffff; }

.tab-transparent .nav {
  border-bottom: 1px solid rgba(151, 151, 151, 0.3); }
  .tab-transparent .nav .nav-item.nav-link {
    background: transparent;
    color: #aab2bd;
    border-radius: 0;
    border: none;
    padding: .875rem 1.25rem;
    position: relative;
    line-height: 1;
    letter-spacing: 1.5; }
    @media (max-width: 991px) {
      .tab-transparent .nav .nav-item.nav-link {
        padding: .875rem .5rem; } }
    .tab-transparent .nav .nav-item.nav-link.active {
      color: #001737;
      font-weight: bold; }
      .tab-transparent .nav .nav-item.nav-link.active:after {
        content: '';
        width: 100%;
        height: 2px;
        background: #44ce42;
        position: absolute;
        left: 0;
        bottom: 0; }
    .tab-transparent .nav .nav-item.nav-link:first-child {
      padding-left: 0; }
      .rtl .tab-transparent .nav .nav-item.nav-link:first-child {
        padding-right: 0;
        padding-left: 1.25rem; }
  .tab-transparent .nav .nav-item:first-child .nav-link:first-child {
    padding-left: 0; }

.tab-transparent .tab-content {
  border: none;
  padding: 1.5rem 0 0 0; }

/* Dropdowns */
.dropdown .dropdown-toggle:after {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  font: normal normal normal 24px/1 "Material Design Icons";
  content: "\f140";
  width: auto;
  height: auto;
  vertical-align: baseline;
  font-size: .75rem; }

.dropdown .dropdown-menu {
  margin-top: .75rem;
  font-size: 0.875rem;
  box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35); }
  .rtl .dropdown .dropdown-menu {
    text-align: right; }
  .dropdown .dropdown-menu .dropdown-item {
    font-size: 1rem;
    padding: .25rem 1.5rem;
    cursor: pointer; }
    .rtl .dropdown .dropdown-menu .dropdown-item {
      text-align: right; }
    .dropdown .dropdown-menu .dropdown-item:active {
      background: initial; }

/* Loaders */
#pgloading {
  position: static; }
  #pgloading .bokeh {
    font-size: 100px;
    width: 1em;
    height: 1em;
    position: relative;
    margin: 0 auto;
    border-radius: 50%;
    border: 0.01em solid rgba(151, 151, 151, 0.3);
    list-style: none;
    padding: 0; }
    #pgloading .bokeh li {
      position: absolute;
      width: .2em;
      height: .2em;
      border-radius: 50%; }
      #pgloading .bokeh li:nth-child(1) {
        left: 50%;
        top: 0;
        margin: 0 0 0 -.1em;
        background: #44ce42;
        -webkit-transform-origin: 50% 250%;
        transform-origin: 50% 250%;
        -webkit-animation: rota 1.13s linear infinite, opa 3.67s ease-in-out infinite alternate;
        animation: rota 1.13s linear infinite, opa 3.67s ease-in-out infinite alternate; }
      #pgloading .bokeh li:nth-child(2) {
        top: 50%;
        right: 0;
        margin: -.1em 0 0 0;
        background: #fc5a5a;
        -webkit-transform-origin: -150% 50%;
        transform-origin: -150% 50%;
        -webkit-animation: rota 1.86s linear infinite, opa 4.29s ease-in-out infinite alternate;
        animation: rota 1.86s linear infinite, opa 4.29s ease-in-out infinite alternate; }
      #pgloading .bokeh li:nth-child(3) {
        left: 50%;
        bottom: 0;
        margin: 0 0 0 -.1em;
        background: #ffc542;
        -webkit-transform-origin: 50% -150%;
        transform-origin: 50% -150%;
        -webkit-animation: rota 1.45s linear infinite, opa 5.12s ease-in-out infinite alternate;
        animation: rota 1.45s linear infinite, opa 5.12s ease-in-out infinite alternate; }
      #pgloading .bokeh li:nth-child(4) {
        top: 50%;
        left: 0;
        margin: -.1em 0 0 0;
        background: #0062ff;
        -webkit-transform-origin: 250% 50%;
        transform-origin: 250% 50%;
        -webkit-animation: rota 1.72s linear infinite, opa 5.25s ease-in-out infinite alternate;
        animation: rota 1.72s linear infinite, opa 5.25s ease-in-out infinite alternate; }

@-webkit-keyframes rota {
  from { }
  to {
    -webkit-transform: rotate(360deg); } }

@keyframes rota {
  from { }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes opa {
  0% { }
  12.0% {
    opacity: 0.80; }
  19.5% {
    opacity: 0.88; }
  37.2% {
    opacity: 0.64; }
  40.5% {
    opacity: 0.52; }
  52.7% {
    opacity: 0.69; }
  60.2% {
    opacity: 0.60; }
  66.6% {
    opacity: 0.52; }
  70.0% {
    opacity: 0.63; }
  79.9% {
    opacity: 0.60; }
  84.2% {
    opacity: 0.75; }
  91.0% {
    opacity: 0.87; } }

@keyframes opa {
  0% { }
  12.0% {
    opacity: 0.80; }
  19.5% {
    opacity: 0.88; }
  37.2% {
    opacity: 0.64; }
  40.5% {
    opacity: 0.52; }
  52.7% {
    opacity: 0.69; }
  60.2% {
    opacity: 0.60; }
  66.6% {
    opacity: 0.52; }
  70.0% {
    opacity: 0.63; }
  79.9% {
    opacity: 0.60; }
  84.2% {
    opacity: 0.75; }
  91.0% {
    opacity: 0.87; } }

.glowing-loader {
  position: relative; }

.s2 {
  position: absolute;
  height: 70px;
  width: 70px;
  top: 50px;
  background-color: transparent;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.s1 {
  position: absolute;
  height: 70px;
  width: 70px;
  left: 50%;
  top: 50px;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
  background-color: transparent; }

.bigcon {
  position: absolute;
  height: 75px;
  width: 75px;
  left: 50%;
  top: 50px;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
  background-color: transparent;
  -webkit-animation: bigcon 2s infinite linear;
          animation: bigcon 2s infinite linear;
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s; }

.b {
  border-radius: 50%;
  position: absolute; }

.s {
  width: 15px;
  height: 15px;
  -webkit-animation: small 2s infinite ease;
          animation: small 2s infinite ease;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.3);
  background-color: #0062ff; }

.s:nth-child(1) {
  top: 0%;
  left: 0%; }

.s:nth-child(2) {
  top: 0%;
  right: 0%; }

.s:nth-child(3) {
  right: 0%;
  bottom: 0%; }

.s:nth-child(4) {
  bottom: 0%;
  left: 0%; }

.big {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px #0062ff, 0px 0px 20px #0062ff, 0px 0px 30px #0062ff, 0px 0px 50px #0062ff, 0px 0px 60px #0062ff;
  z-index: 1;
  background-color: #0062ff;
  -webkit-animation: bigball 1s infinite linear;
          animation: bigball 1s infinite linear; }

.sb1 {
  -webkit-animation-delay: -1.75s;
          animation-delay: -1.75s; }

.sb6 {
  -webkit-animation-delay: -1.5s;
          animation-delay: -1.5s; }

.sb2 {
  -webkit-animation-delay: -1.25s;
          animation-delay: -1.25s; }

.sb7 {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }

.sb3 {
  -webkit-animation-delay: -0.75s;
          animation-delay: -0.75s; }

.sb8 {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }

.sb4 {
  -webkit-animation-delay: -0.25s;
          animation-delay: -0.25s; }

.sb5 {
  -webkit-animation-delay: -0s;
          animation-delay: -0s; }

@-webkit-keyframes bigcon {
  0% {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg); }
  100% {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: translate(-50%, -50%) rotate(405deg);
            transform: translate(-50%, -50%) rotate(405deg); } }

@keyframes bigcon {
  0% {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg); }
  100% {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: translate(-50%, -50%) rotate(405deg);
            transform: translate(-50%, -50%) rotate(405deg); } }

@-webkit-keyframes small {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #66a1ff; }
  10% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    background-color: #0062ff; }
  15% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  25% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #0062ff; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #0062ff; } }

@keyframes small {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #66a1ff; }
  10% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    background-color: #0062ff; }
  15% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  25% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #0062ff; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #0062ff; } }

/* Pixel loader */
.pixel-loader {
  width: 10px;
  height: 10px;
  background: #ffc542;
  color: #ffc542;
  margin: 80px auto;
  box-shadow: 15px 15px  0 0, -15px -15px  0 0, 15px -15px  0 0, -15px 15px  0 0, 0 15px  0 0, 15px 0  0 0, -15px 0  0 0, 0 -15px 0 0;
  -webkit-animation: anim 2s linear infinite;
          animation: anim 2s linear infinite; }

@-webkit-keyframes anim {
  0% {
    -webkit-filter: hue-rotate(0deg); }
  50% {
    box-shadow: 20px 20px  0 0, -20px -20px  0 0, 20px -20px  0 0, -20px 20px  0 0, 0 10px  0 0, 10px 0  0 0, -10px 0  0 0, 0 -10px 0 0; }
  75% {
    box-shadow: 20px 20px  0 0, -20px -20px  0 0, 20px -20px  0 0, -20px 20px  0 0, 0 10px  0 0, 10px 0  0 0, -10px 0  0 0, 0 -10px 0 0; }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-filter: hue-rotate(360deg); } }

@keyframes anim {
  0% {
    -webkit-filter: hue-rotate(0deg); }
  50% {
    box-shadow: 20px 20px  0 0, -20px -20px  0 0, 20px -20px  0 0, -20px 20px  0 0, 0 10px  0 0, 10px 0  0 0, -10px 0  0 0, 0 -10px 0 0; }
  75% {
    box-shadow: 20px 20px  0 0, -20px -20px  0 0, 20px -20px  0 0, -20px 20px  0 0, 0 10px  0 0, 10px 0  0 0, -10px 0  0 0, 0 -10px 0 0; }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-filter: hue-rotate(360deg); } }

/* Square box loader */
@-webkit-keyframes dyinglight {
  15% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6); }
  50% {
    -webkit-transform: rotate(-89deg);
            transform: rotate(-89deg); }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); } }
@keyframes dyinglight {
  15% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6); }
  50% {
    -webkit-transform: rotate(-89deg);
            transform: rotate(-89deg); }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); } }

.square-box-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: inline-block; }

.square-box-loader-square {
  display: block;
  width: 30px;
  height: 30px;
  background: #0062ff; }

.square-box-loader-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-transform-origin: 50% 50% 0;
          transform-origin: 50% 50% 0;
  -webkit-animation: dyinglight 1s ease infinite;
          animation: dyinglight 1s ease infinite;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.square-box-loader-corner-top:before,
.square-box-loader-corner-top:after,
.square-box-loader-corner-bottom:before,
.square-box-loader-corner-bottom:after {
  position: absolute;
  width: 10px;
  height: 10px;
  color: #0062ff;
  content: ''; }

.square-box-loader-corner-top:before {
  border-left: 1px solid;
  border-top: 1px solid;
  top: -4px;
  left: -4px; }

.square-box-loader-corner-top:after {
  border-right: 1px solid;
  border-top: 1px solid;
  top: -4px;
  right: -4px; }

.square-box-loader-corner-bottom:before {
  border-left: 1px solid;
  border-bottom: 1px solid;
  bottom: -4px;
  left: -4px; }

.square-box-loader-corner-bottom:after {
  border-right: 1px solid;
  border-bottom: 1px solid;
  bottom: -4px;
  right: -4px; }

/* Circle loader */
.circle-loader {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto; }

.circle-loader:before, .circle-loader:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent;
  border-top-color: #ffc542; }

.circle-loader:before {
  z-index: 10;
  -webkit-animation: spin 1s infinite;
          animation: spin 1s infinite; }

.circle-loader:after {
  border: 10px solid #8e94a9; }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/* Jumping dots loader */
.jumping-dots-loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto; }

.jumping-dots-loader span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: rgba(252, 90, 90, 0.8);
  margin: 35px 5px; }

.jumping-dots-loader span:nth-child(1) {
  -webkit-animation: bounce 1s ease-in-out infinite;
          animation: bounce 1s ease-in-out infinite; }

.jumping-dots-loader span:nth-child(2) {
  -webkit-animation: bounce 1s ease-in-out 0.33s infinite;
          animation: bounce 1s ease-in-out 0.33s infinite; }

.jumping-dots-loader span:nth-child(3) {
  -webkit-animation: bounce 1s ease-in-out 0.66s infinite;
          animation: bounce 1s ease-in-out 0.66s infinite; }

@keyframes bounce {
  0%, 75%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); } }

/* Bar loader */
.bar-loader {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto; }

.bar-loader span {
  display: inline-block;
  width: 5px;
  height: 30px;
  background-color: rgba(252, 90, 90, 0.7); }

.bar-loader span:nth-child(1) {
  -webkit-animation: grow 1s ease-in-out infinite;
          animation: grow 1s ease-in-out infinite; }

.bar-loader span:nth-child(2) {
  -webkit-animation: grow 1s ease-in-out 0.15s infinite;
          animation: grow 1s ease-in-out 0.15s infinite; }

.bar-loader span:nth-child(3) {
  -webkit-animation: grow 1s ease-in-out 0.30s infinite;
          animation: grow 1s ease-in-out 0.30s infinite; }

.bar-loader span:nth-child(4) {
  -webkit-animation: grow 1s ease-in-out 0.45s infinite;
          animation: grow 1s ease-in-out 0.45s infinite; }

@-webkit-keyframes grow {
  0%, 100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1); }
  50% {
    -webkit-transform: scaleY(1.8);
    transform: scaleY(1.8); } }

@keyframes grow {
  0%, 100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1); }
  50% {
    -webkit-transform: scaleY(1.8);
    transform: scaleY(1.8); } }

/* Square path loader */
.square-path-loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto; }

.square-path-loader:before, .square-path-loader:after {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: calc(50% - 10px);
  background-color: rgba(68, 206, 66, 0.7);
  -webkit-animation: squaremove 1s ease-in-out infinite;
          animation: squaremove 1s ease-in-out infinite; }

.square-path-loader:after {
  bottom: 0;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s; }

@-webkit-keyframes squaremove {
  0%, 100% {
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0); }
  25% {
    -webkit-transform: translate(40px, 40px) rotate(45deg);
    transform: translate(40px, 40px) rotate(45deg); }
  50% {
    -webkit-transform: translate(0px, 80px) rotate(0deg);
    transform: translate(0px, 80px) rotate(0deg); }
  75% {
    -webkit-transform: translate(-40px, 40px) rotate(45deg);
    transform: translate(-40px, 40px) rotate(45deg); } }

@keyframes squaremove {
  0%, 100% {
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0); }
  25% {
    -webkit-transform: translate(40px, 40px) rotate(45deg);
    transform: translate(40px, 40px) rotate(45deg); }
  50% {
    -webkit-transform: translate(0px, 80px) rotate(0deg);
    transform: translate(0px, 80px) rotate(0deg); }
  75% {
    -webkit-transform: translate(-40px, 40px) rotate(45deg);
    transform: translate(-40px, 40px) rotate(45deg); } }

/* Dot opacity loader */
.dot-opacity-loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto; }

.dot-opacity-loader span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #0062ff;
  margin: 35px 5px;
  opacity: 0; }

.dot-opacity-loader span:nth-child(1) {
  -webkit-animation: opacitychange 1s ease-in-out infinite;
          animation: opacitychange 1s ease-in-out infinite; }

.dot-opacity-loader span:nth-child(2) {
  -webkit-animation: opacitychange 1s ease-in-out 0.33s infinite;
          animation: opacitychange 1s ease-in-out 0.33s infinite; }

.dot-opacity-loader span:nth-child(3) {
  -webkit-animation: opacitychange 1s ease-in-out 0.66s infinite;
          animation: opacitychange 1s ease-in-out 0.66s infinite; }

@-webkit-keyframes opacitychange {
  0%, 100% {
    opacity: 0; }
  60% {
    opacity: 1; } }

@keyframes opacitychange {
  0%, 100% {
    opacity: 0; }
  60% {
    opacity: 1; } }

/* Flip square */
.flip-square-loader {
  -webkit-perspective: 120px;
  -ms-perspective: 120px;
  perspective: 120px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto; }

.flip-square-loader:before {
  content: "";
  position: absolute;
  left: 25px;
  top: 25px;
  width: 50px;
  height: 50px;
  background-color: rgba(68, 206, 66, 0.7);
  -webkit-animation: flip 1s infinite;
          animation: flip 1s infinite; }

@keyframes flip {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  50% {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  100% {
    -webkit-transform: rotateY(180deg) rotateX(180deg);
            transform: rotateY(180deg) rotateX(180deg); } }

/* Moving square loader */
.moving-square-loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto; }

.moving-square-loader:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: calc(50% - 10px);
  left: 0px;
  background-color: rgba(252, 90, 90, 0.7);
  -webkit-animation: rotatemove 1s infinite;
          animation: rotatemove 1s infinite; }

@-webkit-keyframes rotatemove {
  0% {
    -webkit-transform: scale(1) translateX(0px);
    transform: scale(1) translateX(0px); }
  100% {
    -webkit-transform: scale(2) translateX(45px);
    transform: scale(2) translateX(45px); } }

@keyframes rotatemove {
  0% {
    -webkit-transform: scale(1) translateX(0px);
    transform: scale(1) translateX(0px); }
  100% {
    -webkit-transform: scale(2) translateX(45px);
    transform: scale(2) translateX(45px); } }

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 64px - 5.5rem);
  position: fixed;
  width: calc(100% - 258px - 4rem); }
  @media (max-width: 991px) {
    .spinner-wrapper {
      width: 100%; } }
  .spinner-wrapper .donut {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.3rem solid #99c0ff;
    border-top-color: #3381ff;
    -webkit-animation: 1.5s spin infinite linear;
            animation: 1.5s spin infinite linear; }

:host-context(.sidebar-icon-only) .spinner-wrapper {
  width: calc(100% - 70px - 4rem); }

:host-context(.sidebar-mini) .spinner-wrapper {
  width: calc(100% - 185px - 4rem); }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/* Auth */
.auth {
  height: 100%; }
  .auth .login-half-bg {
    background: url(/static/media/login-bg.2eeb0447.jpg);
    background-size: cover; }
  .auth .register-half-bg {
    background: url(/static/media/register-bg.2ccde6b9.jpg);
    background-size: cover; }
  .auth.lock-full-bg {
    background: url(/static/media/lockscreen-bg.0d7ef366.jpg);
    background-size: cover; }
  .auth .lock-profile-img {
    width: 90px;
    height: 90px;
    border-radius: 100%; }
  .auth .auth-form-light {
    background: #ffffff; }
    .auth .auth-form-light select {
      color: #c9c8c8; }
    .auth .auth-form-light .input-group .form-control:focus, .auth .auth-form-light .input-group .form-control:active {
      border-color: rgba(151, 151, 151, 0.3); }
  .auth .auth-form-transparent {
    background: transparent; }
    .auth .auth-form-transparent .form-control,
    .auth .auth-form-transparent .input-group-text {
      border-color: #8e94a9; }
      .auth .auth-form-transparent .form-control:focus, .auth .auth-form-transparent .form-control:active,
      .auth .auth-form-transparent .input-group-text:focus,
      .auth .auth-form-transparent .input-group-text:active {
        border-color: #8e94a9; }
    .auth .auth-form-transparent select {
      outline-color: #8e94a9; }
  .auth.auth-img-bg {
    padding: 0; }
    @media (min-width: 768px) {
      .auth.auth-img-bg .auth-form-transparent {
        width: 55%;
        margin: auto; } }
  .auth .brand-logo {
    margin-bottom: 2rem;
    text-align: center; }
    .auth .brand-logo img {
      width: 250px; }
  .auth form .form-group {
    margin-bottom: 1.5rem; }
    .auth form .form-group label {
      font-size: .8125rem; }
    .auth form .form-group .form-control {
      background: transparent;
      border-radius: 0;
      font-size: .9375rem; }
  .auth form .auth-form-btn {
    padding: 1rem 3rem;
    line-height: 1.5; }
  .auth form .auth-link {
    font-size: 0.875rem; }
    .auth form .auth-link:hover {
      color: initial; }

/* === Layout === */
/* Navbar */
.navbar {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  background: #fff;
  transition: background 0.25s ease;
  -webkit-transition: background 0.25s ease;
  -moz-transition: background 0.25s ease;
  -ms-transition: background 0.25s ease; }
  .navbar .navbar-brand {
    width: 110px; }
  .navbar .navbar-brand-wrapper {
    transition: width 0.25s ease, background 0.25s ease;
    -webkit-transition: width 0.25s ease, background 0.25s ease;
    -moz-transition: width 0.25s ease, background 0.25s ease;
    -ms-transition: width 0.25s ease, background 0.25s ease;
    background: #181824;
    width: 258px;
    height: 64px; }
    @media (max-width: 991px) {
      .navbar .navbar-brand-wrapper {
        width: 55px; } }
    .navbar .navbar-brand-wrapper .navbar-brand {
      color: #27367f;
      font-size: 1.5rem;
      line-height: 48px;
      margin-right: 0;
      padding: .25rem 0;
      padding-top: 40px;
      width: 100%; }
      .navbar .navbar-brand-wrapper .navbar-brand:active, .navbar .navbar-brand-wrapper .navbar-brand:focus, .navbar .navbar-brand-wrapper .navbar-brand:hover {
        color: #1b2658; }
      .navbar .navbar-brand-wrapper .navbar-brand img {
        width: 80px;
        max-width: 100%;
        height: 80px;
        margin: auto;
        vertical-align: middle; }
      .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
        display: none; }
        .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
          width: calc(70px);
          background-color: transparent;
          max-width: 100%;
          height: 128px;
          margin: auto; }
          @media (max-width: 991px) {
            .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
              width: 40px;
              height: 40px; } }
  .navbar .navbar-menu-wrapper {
    transition: width 0.25s ease;
    -webkit-transition: width 0.25s ease;
    -moz-transition: width 0.25s ease;
    -ms-transition: width 0.25s ease;
    color: #111111;
    padding-left: 24px;
    padding-right: 24px;
    width: calc(100% - 258px);
    height: 64px; }
    @media (max-width: 991px) {
      .navbar .navbar-menu-wrapper {
        width: auto;
        padding-left: 15px;
        padding-right: 15px; } }
    .navbar .navbar-menu-wrapper .navbar-toggler {
      border: 0;
      color: #8e94a9;
      height: 64px;
      border-radius: 0px;
      padding-left: 5px;
      padding-right: 20px; }
      .navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
        font-size: .875rem; }
        @media (max-width: 991px) {
          .navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
            display: none; } }
      @media (max-width: 991px) {
        .navbar .navbar-menu-wrapper .navbar-toggler.navbar-toggler-right {
          padding-left: 15px;
          padding-right: 11px;
          border-right: none; } }
    .navbar .navbar-menu-wrapper .search-field .input-group {
      background: #eef0fa;
      width: 305px; }
      .navbar .navbar-menu-wrapper .search-field .input-group input {
        font-size: 0.875rem;
        padding: .5rem; }
        .navbar .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
          font-size: 0.875rem;
          color: #111111;
          font-family: "Nunito", sans-serif;
          font-weight: 400; }
        .navbar .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
          font-size: 0.875rem;
          color: #111111;
          font-family: "Nunito", sans-serif;
          font-weight: 400; }
        .navbar .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
          font-size: 0.875rem;
          color: #111111;
          font-family: "Nunito", sans-serif;
          font-weight: 400; }
        .navbar .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
          font-size: 0.875rem;
          color: #111111;
          font-family: "Nunito", sans-serif;
          font-weight: 400; }
      .navbar .navbar-menu-wrapper .search-field .input-group i {
        font-size: 14px;
        margin-right: 0;
        color: #8e94a9; }
      .navbar .navbar-menu-wrapper .search-field .input-group .input-group-text {
        background: transparent; }
        .rtl .navbar .navbar-menu-wrapper .search-field .input-group .input-group-text {
          padding: 0.94rem 1rem 0.94rem .5rem; }
    .navbar .navbar-menu-wrapper .count-indicator {
      position: relative; }
      .navbar .navbar-menu-wrapper .count-indicator .count-symbol,
      .navbar .navbar-menu-wrapper .count-indicator .count-number {
        position: absolute;
        border-radius: 100%;
        border: 2px solid #ffffff;
        -webkit-animation: pulse 3s ease-out;
                animation: pulse 3s ease-out;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite; }

@keyframes pulse {
  40% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
            transform: scale3d(1.5, 1.5, 1.5); }
  55% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); }
  60% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
            transform: scale3d(1.5, 1.5, 1.5); }
  65% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }
      .navbar .navbar-menu-wrapper .count-indicator .count-symbol {
        top: 20px;
        right: 0px;
        width: 10px;
        height: 10px; }
      .navbar .navbar-menu-wrapper .count-indicator .count-number {
        min-width: 14px;
        height: 14px;
        font-size: .5rem;
        color: #ffffff;
        bottom: 16px;
        right: -5px;
        line-height: 1;
        text-align: center; }
      .navbar .navbar-menu-wrapper .count-indicator:after {
        display: none; }
    .navbar .navbar-menu-wrapper .navbar-nav {
      flex-direction: row;
      align-items: center; }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
        color: inherit;
        font-size: 0.875rem;
        margin-left: .625rem;
        margin-right: .625rem;
        height: 64px;
        display: flex;
        align-items: center;
        font-weight: 600; }
        @media (max-width: 767px) {
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
            margin-left: .8rem;
            margin-right: .8rem; } }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link i {
          font-size: 1.25rem; }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-toggle {
        border: 0;
        padding: 0;
        background: transparent; }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-toggle:focus {
          box-shadow: none; }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-toggle:after {
          color: #aab2bd;
          font-size: 1.25rem;
          content: "\f35d";
          display: inline-block; }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-toggle.hide-carret:after {
          display: none; }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-menu {
        border: none;
        box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
        min-width: 220px; }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-menu.navbar-dropdown {
          position: absolute;
          font-size: 0.9rem;
          margin-top: 0;
          padding: 0; }
          .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-menu.navbar-dropdown {
            right: auto;
            left: 0; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-menu.navbar-dropdown .dropdown-item {
            margin-bottom: 0;
            padding: 11px 13px;
            cursor: pointer;
            color: #111111;
            font-size: .875rem;
            font-style: normal;
            font-family: "Nunito", sans-serif;
            font-weight: 600; }
            .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-menu.navbar-dropdown .dropdown-item .badge {
              padding: 2px 6px;
              font-size: 10px; }
            .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-menu.navbar-dropdown .dropdown-item i {
              font-size: 17px; }
            .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-menu.navbar-dropdown .dropdown-item .ellipsis {
              max-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis; }
            .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-menu.navbar-dropdown .dropdown-item i {
              margin-left: 10px; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-menu.navbar-dropdown .dropdown-divider {
            margin: 0; }
      @media (max-width: 991px) {
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown {
          position: static; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .navbar-dropdown {
            left: 20px !important;
            right: 20px;
            top: 64px;
            width: calc(100% - 40px); } }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings {
        padding-left: 20px;
        padding-right: 5px; }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings .nav-link {
          margin-right: 0;
          margin-left: 0; }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img {
        position: relative;
        width: 32px;
        height: 32px; }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img img {
          width: 32px;
          height: 32px;
          border-radius: 100%; }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text {
        margin-left: .875rem; }
        .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text {
          margin-left: 0;
          margin-right: .875rem; }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text p {
          line-height: 1; }
        @media (max-width: 767px) {
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text {
            display: none; } }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link.dropdown-toggle:after {
        line-height: 2; }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-language .nav-link .nav-language-icon {
        position: relative;
        width: 22px;
        height: 20px; }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-language .nav-link .nav-language-icon i {
          font-size: 1rem; }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-language .nav-link .nav-language-text {
        margin-left: .875rem; }
        .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-language .nav-link .nav-language-text {
          margin-left: 0;
          margin-right: .875rem; }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-language .nav-link .nav-language-text p {
          line-height: 1; }
        @media (max-width: 767px) {
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-language .nav-link .nav-language-text {
            display: none; } }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-language .nav-link.dropdown-toggle:after {
        line-height: 2; }
      @media (min-width: 992px) {
        .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
          margin-left: auto; }
          .rtl .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
            margin-left: 0;
            margin-right: auto; } }

@media (max-width: 991px) {
  .navbar {
    flex-direction: row; }
    .navbar .navbar-brand-wrapper {
      background: white;
      width: 75px; }
      .navbar .navbar-brand-wrapper .navbar-brand.brand-logo {
        display: none; }
      .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
        display: inline-block; }
  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem; } }

@media (max-width: 480px) {
  .navbar .navbar-brand-wrapper {
    width: 55px; }
    .navbar .navbar-brand-wrapper .brand-logo-mini {
      padding-top: 0px; } }

.sidebar {
  min-height: calc(100vh - 64px);
  background: #181824;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  padding: 0;
  width: 258px;
  z-index: 11;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease; }
  .sidebar .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 60px; }
    .sidebar .nav .nav-item {
      padding: 0 2.375rem;
      transition-duration: 0.25s;
      transition-property: background;
      -webkit-transition-property: background;
      position: relative; }
      .sidebar .nav .nav-item .ReactCollapse--collapse {
        transition: height 0.25s ease; }
      .sidebar .nav .nav-item .nav-link {
        display: flex;
        align-items: center;
        white-space: nowrap;
        padding: 0.625rem 0 0.625rem 0;
        color: #8e94a9;
        transition-duration: 0.45s;
        transition-property: color;
        -webkit-transition-property: color;
        cursor: pointer; }
        .sidebar .nav .nav-item .nav-link .icon-bg {
          margin-right: 0.875rem;
          background: rgba(194, 244, 219, 0.12);
          height: 24px;
          width: 24px;
          position: relative;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          text-align: center; }
          .rtl .sidebar .nav .nav-item .nav-link .icon-bg {
            margin-left: 1rem;
            margin-right: 0; }
          .sidebar .nav .nav-item .nav-link .icon-bg .menu-icon {
            color: #33c92d; }
            .sidebar .nav .nav-item .nav-link .icon-bg .menu-icon:after, .sidebar .nav .nav-item .nav-link .icon-bg .menu-icon:before {
              font-size: 1rem;
              color: inherit; }
        .sidebar .nav .nav-item .nav-link i {
          color: inherit; }
          .sidebar .nav .nav-item .nav-link i.menu-icon {
            font-size: 1.125rem;
            line-height: 1;
            margin-right: 0;
            color: #8e94a9; }
            .rtl .sidebar .nav .nav-item .nav-link i.menu-icon {
              margin-left: 0;
              margin-right: 0; }
            .sidebar .nav .nav-item .nav-link i.menu-icon:before {
              vertical-align: middle; }
          .sidebar .nav .nav-item .nav-link i.menu-arrow {
            font: normal normal normal 24px/1 "Material Design Icons";
            line-height: 1;
            font-size: 1.125rem;
            margin-left: auto;
            color: #9e9da0; }
            .rtl .sidebar .nav .nav-item .nav-link i.menu-arrow {
              margin-left: 0;
              margin-right: auto; }
            .sidebar .nav .nav-item .nav-link i.menu-arrow:before {
              content: "\f142";
              font-size: inherit;
              color: inherit; }
              .rtl .sidebar .nav .nav-item .nav-link i.menu-arrow:before {
                content: "\f141"; }
            .sidebar .nav .nav-item .nav-link i.menu-arrow + .menu-icon {
              margin-left: .25rem; }
              .rtl .sidebar .nav .nav-item .nav-link i.menu-arrow + .menu-icon {
                margin-left: 0;
                margin-right: .25rem; }
        .sidebar .nav .nav-item .nav-link .menu-title {
          color: inherit;
          display: inline-block;
          font-size: 0.937rem;
          line-height: 1;
          vertical-align: middle; }
        .sidebar .nav .nav-item .nav-link .badge {
          margin-right: auto;
          margin-left: 1rem; }
        .sidebar .nav .nav-item .nav-link[aria-expanded="true"] .menu-arrow:before {
          content: "\f140"; }
      .sidebar .nav .nav-item.active {
        background: transparent; }
        .sidebar .nav .nav-item.active > .nav-link:before {
          content: "";
          position: absolute;
          left: 0;
          top: .5rem;
          bottom: 0;
          background: #44ce42;
          height: 24px;
          width: 4px; }
          .rtl .sidebar .nav .nav-item.active > .nav-link:before {
            left: auto;
            right: 0; }
        .sidebar .nav .nav-item.active > .nav-link .menu-title {
          color: #44ce42;
          font-family: "Nunito", sans-serif;
          font-weight: 600; }
        .sidebar .nav .nav-item.active > .nav-link i {
          color: #44ce42; }
      .sidebar .nav .nav-item:hover {
        background: transparent; }
      .sidebar .nav .nav-item.nav-profile .nav-link {
        height: auto;
        line-height: 1;
        border-top: 0;
        padding: 1.25rem 0; }
        .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-image {
          width: 44px;
          height: 44px; }
          .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-image img {
            width: 44px;
            height: 44px;
            border-radius: 100%; }
        .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-text {
          margin-left: 1rem; }
          .rtl .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-text {
            margin-left: auto;
            margin-right: 1rem; }
        .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-badge {
          font-size: 1.125rem;
          margin-left: auto; }
          .rtl .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-badge {
            margin-left: 0;
            margin-right: auto; }
      .sidebar .nav .nav-item.sidebar-actions {
        margin-top: 1rem; }
        .sidebar .nav .nav-item.sidebar-actions .nav-link {
          border-top: 0;
          display: block;
          height: auto; }
        .sidebar .nav .nav-item.sidebar-actions:hover {
          background: initial; }
          .sidebar .nav .nav-item.sidebar-actions:hover .nav-link {
            color: initial; }
      .sidebar .nav .nav-item.nav-category {
        color: #001737;
        font-size: 0.8125rem;
        line-height: 16px;
        margin: 25px 0px 10px 0px;
        color: #8e94a9;
        font-family: "Nunito", sans-serif;
        font-weight: 700; }
        .sidebar .nav .nav-item.nav-category .nav-link {
          padding: 1.2rem 2rem 0.2rem; }
          .sidebar .nav .nav-item.nav-category .nav-link:hover {
            color: #001737; }
      .sidebar .nav .nav-item.documentation-link {
        margin: 2.5rem 0; }
        .sidebar .nav .nav-item.documentation-link .nav-link {
          background: #0062ff;
          color: #ffffff;
          border-radius: 2px;
          padding: .875rem 0;
          font-size: 1.125rem;
          font-weight: bold; }
          .sidebar .nav .nav-item.documentation-link .nav-link .icon-bg {
            display: none; }
          .sidebar .nav .nav-item.documentation-link .nav-link .menu-title {
            margin: auto;
            background: transparent;
            color: #ffffff; }
      .sidebar .nav .nav-item.sidebar-user-actions {
        padding-left: 0;
        padding-right: 0; }
        .sidebar .nav .nav-item.sidebar-user-actions .user-details {
          padding: 1rem 2.375rem;
          width: 100%;
          border-top: 1px solid rgba(151, 151, 151, 0.27);
          border-bottom: 1px solid rgba(151, 151, 151, 0.27);
          margin-bottom: 20px; }
          .sidebar .nav .nav-item.sidebar-user-actions .user-details .sidebar-profile-img {
            width: 31px;
            height: 31px;
            border-radius: 100%;
            margin-right: 12px; }
            .rtl .sidebar .nav .nav-item.sidebar-user-actions .user-details .sidebar-profile-img {
              margin-left: 1rem;
              margin-right: 0; }
          .sidebar .nav .nav-item.sidebar-user-actions .user-details .sidebar-profile-text {
            color: #ffffff; }
          .sidebar .nav .nav-item.sidebar-user-actions .user-details .badge {
            margin-right: 0; }
        .sidebar .nav .nav-item.sidebar-user-actions .sidebar-user-menu {
          padding: 0 2.375rem; }
          .sidebar .nav .nav-item.sidebar-user-actions .sidebar-user-menu .nav-link {
            font-size: 15px; }
            .sidebar .nav .nav-item.sidebar-user-actions .sidebar-user-menu .nav-link i {
              font-size: 18px;
              margin-right: 12px; }
              .rtl .sidebar .nav .nav-item.sidebar-user-actions .sidebar-user-menu .nav-link i {
                margin-right: 0;
                margin-left: 12px; }
    .sidebar .nav:not(.sub-menu) > .nav-item:hover:not(.nav-category):not(.nav-profile) > .nav-link {
      color: #ffffff; }
    .sidebar .nav.sub-menu {
      margin-bottom: 0px;
      margin-top: 0;
      list-style: none; }
      .sidebar .nav.sub-menu .nav-item {
        padding: 0; }
        .sidebar .nav.sub-menu .nav-item .nav-link {
          color: #8e94a9;
          padding: 0.75rem 2rem 0.75rem 2rem;
          position: relative;
          font-size: 0.8125rem;
          line-height: 1;
          height: auto;
          border-top: 0; }
          .sidebar .nav.sub-menu .nav-item .nav-link:before {
            content: "\F054";
            font-family: "Material Design Icons";
            display: block;
            position: absolute;
            left: 0px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            color: #abafbf;
            font-size: .75rem; }
            .rtl .sidebar .nav.sub-menu .nav-item .nav-link:before {
              left: auto;
              right: 0;
              content: "\F04D"; }
          .sidebar .nav.sub-menu .nav-item .nav-link.active {
            color: #44ce42;
            background: transparent; }
            .sidebar .nav.sub-menu .nav-item .nav-link.active:before {
              color: #44ce42; }
          .sidebar .nav.sub-menu .nav-item .nav-link:hover {
            color: #44495a; }
        .sidebar .nav.sub-menu .nav-item:hover {
          background: transparent; }

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - 64px);
    top: 64px;
    bottom: 0;
    overflow: auto;
    right: -258px;
    transition: all 0.25s ease-out; }
    .sidebar-offcanvas.active {
      right: 0; } }

.footer {
  background: #f0f1f6;
  color: ctheme-color(light);
  border-top: 1px solid #e0e2ec;
  padding: 1.5rem 3rem 1.5rem 3rem;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  font-size: calc(0.875rem - 0.05rem);
  font-family: "Nunito", sans-serif;
  font-weight: 400; }
  .footer a {
    color: #a7afb7;
    font-size: inherit; }
  @media (max-width: 991px) {
    .footer {
      margin-left: 0;
      width: 100%; } }

/* Layouts */
.navbar.fixed-top + .page-body-wrapper {
  padding-top: 64px; }

@media (min-width: 992px) {
  .sidebar-mini .navbar .navbar-brand-wrapper {
    width: 185px; }
  .sidebar-mini .navbar .navbar-menu-wrapper {
    width: calc(100% - 185px); }
  .sidebar-mini .sidebar {
    width: 185px; }
    .sidebar-mini .sidebar .nav .nav-item {
      padding: 0;
      margin: 0; }
      .sidebar-mini .sidebar .nav .nav-item .nav-link {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        text-align: center;
        position: relative;
        border-bottom: none; }
        .sidebar-mini .sidebar .nav .nav-item .nav-link .menu-title {
          display: block;
          margin: auto; }
        .sidebar-mini .sidebar .nav .nav-item .nav-link .menu-sub-title {
          margin: auto; }
        .sidebar-mini .sidebar .nav .nav-item .nav-link .badge {
          margin-left: 5px;
          display: none; }
        .sidebar-mini .sidebar .nav .nav-item .nav-link i.menu-icon {
          display: block;
          margin-right: auto;
          margin-left: auto; }
        .sidebar-mini .sidebar .nav .nav-item .nav-link i.menu-arrow {
          display: inline-block;
          margin-left: 5px;
          position: absolute;
          top: 50%;
          right: 10px;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }
          .sidebar-mini .sidebar .nav .nav-item .nav-link i.menu-arrow:before {
            content: "\F140"; }
        .sidebar-mini .sidebar .nav .nav-item .nav-link[aria-expanded="true"] .menu-arrow:before {
          content: "\f143"; }
  .sidebar-mini .main-panel {
    width: calc(100% - 185px); }
  .sidebar-mini:not(.sidebar-icon-only) .sidebar .nav:not(.sub-menu) > .nav-item {
    border-bottom: 1px solid rgba(142, 148, 169, 0.2); }
    .sidebar-mini:not(.sidebar-icon-only) .sidebar .nav:not(.sub-menu) > .nav-item .nav-link {
      height: auto;
      padding: 0.8125rem 1rem 0.8125rem 1rem; }
      .sidebar-mini:not(.sidebar-icon-only) .sidebar .nav:not(.sub-menu) > .nav-item .nav-link i.menu-icon {
        margin-bottom: .5rem; }
  .sidebar-mini:not(.sidebar-icon-only) .sidebar .nav.sub-menu {
    padding: 0;
    border-top: none; }
    .sidebar-mini:not(.sidebar-icon-only) .sidebar .nav.sub-menu .nav-item .nav-link {
      padding: 7px 0 7px 25px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      text-align: left;
      width: 66%; } }

@media (min-width: 992px) {
  .sidebar-icon-only .navbar .navbar-brand-wrapper {
    width: 70px; }
    .sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo {
      display: none; }
    .sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo-mini {
      display: inline-block; }
  .sidebar-icon-only .navbar .navbar-menu-wrapper {
    width: calc(100% - 70px); }
  .sidebar-icon-only .sidebar {
    width: 70px; }
    .sidebar-icon-only .sidebar .nav {
      overflow: visible; }
      .sidebar-icon-only .sidebar .nav .nav-item {
        position: relative;
        padding: 0; }
        .sidebar-icon-only .sidebar .nav .nav-item .nav-link {
          display: block;
          text-align: center; }
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title,
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link .badge, .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-sub-title {
            display: none; }
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
            border-radius: 0 5px 5px 0px; }
            .rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
              border-radius: 5px 0 0 5px; }
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-icon {
            margin-right: 0;
            margin-left: 0; }
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-arrow {
            display: none; }
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link[data-toggle="collapse"] .menu-title {
            border-radius: 0 5px 0 0px; }
            .rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link[data-toggle="collapse"] .menu-title {
              border-radius: 5px 0 0 0; }
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link .icon-bg {
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 10px; }
            .sidebar-icon-only .sidebar .nav .nav-item .nav-link .icon-bg i {
              margin-left: auto;
              margin-right: auto; }
        .sidebar-icon-only .sidebar .nav .nav-item.nav-profile {
          display: none; }
        .sidebar-icon-only .sidebar .nav .nav-item.nav-category {
          display: none; }
        .sidebar-icon-only .sidebar .nav .nav-item.nav-doc {
          margin: 0; }
          .sidebar-icon-only .sidebar .nav .nav-item.nav-doc i {
            display: block; }
        .sidebar-icon-only .sidebar .nav .nav-item .collapse {
          display: none; }
        .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
          display: flex;
          align-items: center;
          background: #181824;
          padding: 0.5rem 1.4rem;
          left: 70px;
          position: absolute;
          text-align: left;
          top: 0;
          bottom: 0;
          width: 190px;
          z-index: 1;
          line-height: 1.8; }
          .sidebar-dark.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
            background: transparent; }
          .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
            left: auto;
            right: 70px;
            text-align: left; }
          .sidebar-dark.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
            color: #ffffff; }
          .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title:after {
            display: none; }
        .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
        .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
          display: block;
          background: #181824;
          border-radius: 0 0 5px 0;
          position: absolute;
          left: 70px;
          width: 190px;
          margin-bottom: 0;
          padding-bottom: 1rem; }
          .sidebar-dark.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse, .sidebar-dark.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
            background: #181824; }
          .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse, .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
            left: auto;
            right: 70px;
            border-radius: 0 0 0 5px; }
        .sidebar-icon-only .sidebar .nav .nav-item.sidebar-user-actions .user-details {
          display: none; }
        .sidebar-icon-only .sidebar .nav .nav-item.sidebar-user-actions .sidebar-user-menu {
          padding: 0 1.5rem; }
        .sidebar-icon-only .sidebar .nav .nav-item.documentation-link {
          margin: 0; }
          .sidebar-icon-only .sidebar .nav .nav-item.documentation-link .nav-link {
            background: transparent; }
            .sidebar-icon-only .sidebar .nav .nav-item.documentation-link .nav-link .icon-bg {
              display: block; }
            .sidebar-icon-only .sidebar .nav .nav-item.documentation-link .nav-link .menu-title {
              background: #181824; }
      .sidebar-icon-only .sidebar .nav.sub-menu {
        padding: 0 0 0 1.5rem; }
        .sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
          text-align: left;
          padding-left: 20px; }
        .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item {
          margin-right: auto;
          margin-left: 0; }
          .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
            text-align: right; }
            .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link:before {
              left: 0;
              right: unset; }
    .sidebar-icon-only .sidebar .sidebar-actions {
      display: none; }
  .sidebar-icon-only .main-panel {
    width: calc(100% - 70px); } }

@media (min-width: 992px) {
  .sidebar-hidden .sidebar {
    transition: width 0.25s ease;
    -webkit-transition: width 0.25s ease;
    -moz-transition: width 0.25s ease;
    -ms-transition: width 0.25s ease;
    width: 0; }
  .sidebar-hidden .main-panel {
    width: 100%; } }

@media (min-width: 992px) {
  .sidebar-absolute .page-body-wrapper {
    position: relative; }
    .sidebar-absolute .page-body-wrapper .sidebar {
      transition: none; }
  .sidebar-absolute:not(.sidebar-hidden) .sidebar {
    position: absolute;
    height: 100%;
    /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    /* Firefox 3.5 - 3.6 */
    box-shadow: 0 0 3px 1px #a7a3a3;
    /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */ }
  .sidebar-absolute .main-panel {
    width: 100%;
    transition: none; } }

@media (min-width: 992px) {
  .sidebar-fixed .sidebar {
    position: fixed;
    max-height: auto; }
    .sidebar-fixed .sidebar .nav {
      max-height: calc(100vh - 64px);
      overflow: auto;
      position: relative; }
      .sidebar-fixed .sidebar .nav.sub-menu {
        max-height: none; }
  .sidebar-fixed .main-panel {
    margin-left: 258px; }
  .sidebar-fixed.sidebar-icon-only .main-panel {
    margin-left: 70px; } }

@media (min-width: 992px) {
  .boxed-layout .container-scroller {
    background: #e1d7ea;
    padding: 0 calc((100% - 1200px) / 2); }
  .boxed-layout .navbar.fixed-top {
    margin: auto;
    max-width: 1200px; } }

.rtl {
  direction: rtl;
  text-align: right; }
  .rtl .sidebar .nav {
    padding-right: 0; }
  .rtl .product-chart-wrapper::-webkit-scrollbar,
  .rtl .settings-panel .tab-content .tab-pane .scroll-wrapper::-webkit-scrollbar,
  .rtl .sidebar-fixed .nav::-webkit-scrollbar,
  .rtl .table-responsive::-webkit-scrollbar,
  .rtl ul.chats::-webkit-scrollbar {
    width: 0.5em; }
  .rtl .product-chart-wrapper::-webkit-scrollbar-track,
  .rtl .settings-panel .tab-content .tab-pane .scroll-wrapper::-webkit-scrollbar-track,
  .rtl .sidebar-fixed .nav::-webkit-scrollbar-track,
  .rtl .table-responsive::-webkit-scrollbar-track,
  .rtl ul.chats::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .rtl .product-chart-wrapper::-webkit-scrollbar-thumb,
  .rtl .settings-panel .tab-content .tab-pane .scroll-wrapper::-webkit-scrollbar-thumb,
  .rtl .sidebar-fixed .nav::-webkit-scrollbar-thumb,
  .rtl .table-responsive::-webkit-scrollbar-thumb,
  .rtl ul.chats::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey; }

/* Settings Panel */
.settings-panel {
  display: block;
  position: fixed;
  top: 0;
  right: -300px;
  bottom: 0;
  width: 300px;
  height: 100vh;
  min-height: 100%;
  background: #ffffff;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-property: right, box-shadow;
  z-index: 9999; }
  .settings-panel .nav-tabs {
    display: flex;
    justify-content: center;
    width: auto;
    margin: 0;
    border-bottom: none; }
    .settings-panel .nav-tabs .nav-item {
      border: none;
      text-align: center;
      border: none;
      background: transparent;
      color: rgba(255, 255, 255, 0.5);
      transition-duration: 0.4s;
      transition-property: color;
      -webkit-transition-property: color;
      justify-content: center;
      padding: 1rem; }
      .settings-panel .nav-tabs .nav-item.active {
        background: transparent;
        color: #ffffff; }
  .settings-panel .tab-content {
    border: none;
    padding: 20px 0 0px 0; }
    .settings-panel .tab-content .tab-pane.scroll-wrapper {
      position: relative;
      max-height: 100vh;
      height: 100%;
      padding-bottom: 100px; }
  .settings-panel .settings-heading {
    padding: 16px 0 13px 35px;
    font-size: 0.875rem;
    font-family: "Nunito", sans-serif;
    font-weight: 700;
    line-height: 1;
    color: rgba(0, 0, 0, 0.9);
    opacity: 0.9;
    margin-bottom: 0;
    border-top: 1px solid rgba(151, 151, 151, 0.3);
    border-bottom: 1px solid rgba(151, 151, 151, 0.3); }
    .rtl .settings-panel .settings-heading {
      padding: 16px 35px 13px 0; }
  .rtl .settings-panel small.settings-heading {
    padding: 16px 0 13px 12px; }
  .settings-panel .sidebar-bg-options {
    padding: 13px 35px;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1;
    color: #595959;
    background: #ffffff;
    transition-duration: 0.25s;
    transition-property: background; }
    .settings-panel .sidebar-bg-options.selected {
      background: #e6e9ed; }
  .settings-panel .color-tiles {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0px 35px 10px 35px;
    padding-top: 15px; }
    .settings-panel .color-tiles .tiles {
      margin: 10px 18px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .settings-panel .color-tiles .tiles:before {
        content: "";
        width: 0px;
        height: 0px;
        opacity: 0;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 100%;
        border: 0px solid rgba(255, 255, 255, 0.4);
        transition-duration: 0.25s;
        transition-timing-function: ease; }
      .settings-panel .color-tiles .tiles.selected:before {
        width: 10px;
        height: 10px;
        opacity: 1;
        border-width: 1px; }
      .settings-panel .color-tiles .tiles.pink {
        background: #E91E63; }
      .settings-panel .color-tiles .tiles.light {
        border: 1px solid #e2e4e7; }
  .settings-panel .chat-list {
    padding-left: 0; }
    .settings-panel .chat-list .list {
      padding: 0.4rem 0.8rem;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(151, 151, 151, 0.3); }
      .settings-panel .chat-list .list:last-child {
        border-bottom: none; }
      .settings-panel .chat-list .list .profile {
        position: relative;
        margin-right: 1rem; }
        .settings-panel .chat-list .list .profile img {
          width: 2.50rem;
          height: 2.50rem;
          border-radius: 100%; }
        .settings-panel .chat-list .list .profile span {
          height: 0.75rem;
          width: 0.75rem;
          position: absolute;
          bottom: 0.34rem;
          right: 0;
          border: 0.13rem solid #ffffff;
          border-radius: 100%; }
          .settings-panel .chat-list .list .profile span.online {
            background: #44ce42; }
          .settings-panel .chat-list .list .profile span.offline {
            background: #ffc542; }
      .settings-panel .chat-list .list .info {
        margin-right: auto; }
        .settings-panel .chat-list .list .info p {
          display: block;
          margin-bottom: 0; }
          .settings-panel .chat-list .list .info p:last-child {
            opacity: 0.5;
            font-size: 0.8rem; }
      .settings-panel .chat-list .list.active {
        background: #aab2bd; }
  .settings-panel.open {
    right: 0;
    box-shadow: 7px 0px 80px -9px rgba(0, 0, 0, 0.15); }
  .settings-panel .settings-close {
    position: absolute;
    top: 14px;
    right: 10px;
    color: #ffffff;
    background: transparent;
    border-radius: 4px;
    padding: 0 3px;
    cursor: pointer;
    transition-duration: 0.2s;
    z-index: 999; }
    .settings-panel .settings-close:hover {
      background: rgba(255, 255, 255, 0.3); }
    .rtl .settings-panel .settings-close {
      right: unset;
      left: 10px; }
  .settings-panel .events p {
    font-family: "Nunito", sans-serif;
    font-weight: 400; }
  .rtl .settings-panel {
    right: unset;
    left: -300px;
    transition-property: left; }
    .rtl .settings-panel .chat-list {
      padding-right: 0; }
      .rtl .settings-panel .chat-list .list .profile {
        margin-right: 0;
        margin-left: 1rem; }
      .rtl .settings-panel .chat-list .list .info {
        margin-right: 0;
        margin-left: auto; }
        .rtl .settings-panel .chat-list .list .info .badge {
          margin-right: 10px; }
    .rtl .settings-panel.open {
      left: 0;
      right: unset; }

#theme-settings .settings-close {
  top: 12px; }

#settings-trigger {
  position: fixed;
  bottom: 40px;
  right: 30px;
  height: 45px;
  width: 45px;
  background: #0062ff;
  z-index: 99;
  box-shadow: 0px 0px 31px 6px rgba(0, 0, 0, 0.17);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }
  #settings-trigger i {
    color: #ffffff;
    font-size: 1rem;
    line-height: 1rem; }
  @media (max-width: 991px) {
    #settings-trigger {
      display: none; } }

